import React from "react";

interface IProps {
    ficode: string;
    description: string;
}

export const Header: React.FC<IProps> = ({ ficode, description }) => (
    <div className="grid lg:grid-cols-2 gap-4 mx-10 mt-4 mb-2">
        <div className="flex-row">
            <div className="text-2xl font-bold">Formula Compare</div>
            <h6 className="text-primary-main-gray text-base">
                {ficode} {description}
            </h6>
        </div>
    </div>
);
