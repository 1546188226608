import React, { useCallback, useRef, useState } from "react";
import { ModalTemplate, Button } from "components/shared";
import { TemplateUploader, UploadFormulaForm } from "./components";
import { useModal } from "common/hooks";
import { type IParsedFormula, MAX_FILE_SIZE, useFileProcessor } from "./libs";

export const UploadFormula = () => {
    const [parsedFormula, setParsedFormula] = useState<IParsedFormula | null>(
        null,
    );
    const fileInputRef = useRef<HTMLInputElement>(null);

    const {
        modalRef: createFormulaRef,
        openModal: openCreateModal,
        closeModal: closeCreateModal,
    } = useModal({
        onClose: () => setParsedFormula(null),
    });

    const {
        modalRef: uploadFormulaRef,
        openModal: openUploadModal,
        closeModal: closeUploadModal,
    } = useModal();

    const onProcessSuccess = useCallback(
        (result: IParsedFormula) => {
            setParsedFormula(result);
            openCreateModal();
        },
        [openCreateModal],
    );

    const { processFile } = useFileProcessor({
        onSuccess: onProcessSuccess,
        options: {
            maxSize: MAX_FILE_SIZE,
            acceptedFormats: [".xlsx", ".xls", ".csv"],
        },
    });

    const handleFileUpload = useCallback(
        (file: File) => {
            processFile(file);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        },
        [processFile],
    );

    const handleClickUpload = useCallback(() => {
        fileInputRef.current?.click();
        closeUploadModal();
    }, [closeUploadModal]);

    return (
        <div className="w-full">
            <ModalTemplate
                text="CREATE FORMULA FROM TEMPLATE"
                onClose={closeCreateModal}
                modalRef={createFormulaRef}
                className="max-w-[1200px]"
            >
                {parsedFormula && (
                    <UploadFormulaForm
                        initialValues={parsedFormula}
                        onClose={closeCreateModal}
                    />
                )}
            </ModalTemplate>

            <ModalTemplate
                text="Upload Formula"
                modalRef={uploadFormulaRef}
                onClose={closeUploadModal}
                className="max-w-[500px]"
            >
                <TemplateUploader
                    onUploadClick={handleClickUpload}
                    onCloseClick={closeUploadModal}
                />
            </ModalTemplate>

            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleFileUpload(file);
                }}
                accept=".xlsx, .xls, .csv"
            />

            <Button
                variant="primary"
                size="lg"
                className="btn-upload"
                onClick={openUploadModal}
                text="Upload Formula"
                isSplitText
            />
        </div>
    );
};
