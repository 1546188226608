import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ColumnDef } from "@tanstack/react-table";
import { PageTemplate } from "components/page-template";
import { ActionCards, SearchBlock } from "./components";
import {
    DataTable,
    IconLink,
    LoadingToast,
    ModalTemplate,
    StatusView,
} from "components/shared";
import { tableConfig } from "./libs/enums";
import { useGetLastSearchFormulas } from "pages/formulas/formulas/queries";
import { useLastRawIngredients } from "pages/rawIngredient/rawIngredients/queries";
import { useLastSubIngredients } from "pages/subIngredient/subIngredients/queries";
import { useLastContaminants } from "pages/contaminant/contaminants/queries";
import { formatLabel, modifyDate } from "common/utils";
import {
    defaultErrorToastOptions,
    routerKeys,
    toastTexts,
} from "common/constants";
import { type ItemLimits } from "./libs/types";
import { type IFormulaItem } from "pages/formulas/formulas/types";
import { type IRawIngredientItem } from "pages/rawIngredient/types";
import { type IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { type IContaminant } from "common/types";
import { ScrollUpButton } from "components/shared/scrollUpButton";
import { TableInfo } from "./components/TableInfo";
import { ItemTitle } from "components/sidebar";
import { useModal, useOwner } from "common/hooks";
import { CreateFormulaForm } from "../formulas/formulas/components/createFormulaForm";
import { UploadFormula } from "../formulas/formulas/components/uploadFormula";

export const DashboardPage = () => {
    const [limit, setLimit] = useState<ItemLimits>({
        formulas: tableConfig.initialItems,
        rawIngredients: tableConfig.initialItems,
        inci: tableConfig.initialItems,
        contaminants: tableConfig.initialItems,
    });

    const [query, setQuery] = useState("");
    const {
        userProfile: { data },
    } = useOwner();

    const { modalRef: createFormulaRef, openModal, closeModal } = useModal();

    const handleUpdateQuery = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const queryParamsFormula = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({
                    query,
                    limit: limit.formulas.toString(),
                }),
            ).toString(),
        [limit.formulas, query],
    );

    const queryParamsRawIngredient = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({
                    query,
                    limit: limit.rawIngredients.toString(),
                }),
            ).toString(),
        [limit.rawIngredients, query],
    );

    const queryParamsINCI = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({ query, limit: limit.inci.toString() }),
            ).toString(),
        [limit.inci, query],
    );

    const queryParamsContaminant = useMemo(
        () =>
            new URLSearchParams(
                Object.entries({ query, limit: limit.contaminants.toString() }),
            ).toString(),
        [limit.contaminants, query],
    );

    const {
        data: formulas = [],
        isLoading: isFormulasLoading,
        isError: isFormulasError,
    } = useGetLastSearchFormulas(queryParamsFormula);

    const {
        data: rawIngredients = [],
        isLoading: isLoadingRawIngredient,
        isError: isErrorRawIngredient,
    } = useLastRawIngredients({ queryParams: queryParamsRawIngredient });

    const {
        data: subIngredients = [],
        isLoading: isLoadingSubIngredient,
        isError: isErrorSubIngredient,
    } = useLastSubIngredients({ queryParams: queryParamsINCI });

    const {
        data: contaminants = [],
        isLoading: isLoadingContaminant,
        isError: isErrorContaminant,
    } = useLastContaminants({ queryParams: queryParamsContaminant });

    useEffect(() => {
        if (
            isFormulasError ||
            isErrorRawIngredient ||
            isErrorSubIngredient ||
            isErrorContaminant
        ) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [
        isFormulasError,
        isErrorRawIngredient,
        isErrorSubIngredient,
        isErrorContaminant,
    ]);

    const formulaColumns = useMemo<ColumnDef<IFormulaItem>[]>(
        () => [
            {
                accessorKey: "ficode",
                header: () => <span className="min-w-[100px]">FI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.ficode}</span>
                ),
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[200px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[200px]">
                        {row.original.description}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {formatLabel(row.getValue("status"))}
                    </span>
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit_formula",
                accessorKey: "edit_formula",
                header: () => <span className=" flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    const rawIngredientColumns = useMemo<ColumnDef<IRawIngredientItem>[]>(
        () => [
            {
                accessorKey: "ricode",
                header: () => <span className="min-w-[100px]">RI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.ricode}</span>
                ),
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[150px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[150px]">
                        {row.original.description}
                    </span>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        className="min-w-[100px]"
                        title={row.original.status}
                        active={row.original.status === "Complete"}
                    />
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    const subIngredientColumns = useMemo<ColumnDef<IPreparedSubIngredient>[]>(
        () => [
            {
                accessorKey: "sicode",
                header: () => <span className="min-w-[100px]">SI Code</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">{row.original.sicode}</span>
                ),
                size: 100,
            },
            {
                accessorKey: "description",
                header: () => <span className="min-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <span className="min-w-[200px]">{row.original.name}</span>
                ),
                size: 200,
            },
            {
                accessorKey: "status",
                header: () => <span className="min-w-[100px]">Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        className="min-w-[100px]"
                        title={row.original.status || ""}
                        active={row.original.status === "Active"}
                    />
                ),
                size: 100,
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "create_date",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [],
    );

    const contaminantColumns = useMemo<ColumnDef<IContaminant>[]>(
        () => [
            {
                accessorKey: "name",
                header: () => <span className="min-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <span className="min-w-[200px]">{row.original.name}</span>
                ),
                size: 200,
            },
            {
                accessorKey: "description",
                header: () => (
                    <span className="min-w-[200px]">Description</span>
                ),
                cell: ({ row }) => (
                    <span className="min-w-[200px]">
                        {row.original.description}
                    </span>
                ),
                size: 200,
            },
            {
                accessorKey: "created_by",
                header: () => <span className="min-w-[100px]">Created By</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {row.original.created_by}
                    </span>
                ),
                size: 100,
            },
            {
                accessorKey: "date_created",
                header: () => <span className="min-w-[100px]">Created</span>,
                cell: ({ row }) => (
                    <span className="min-w-[100px]">
                        {modifyDate(row.original.date_created)}
                    </span>
                ),
                size: 100,
            },
            {
                id: "edit",
                accessorKey: "edit",
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    />
                ),
                size: 100,
            },
        ],
        [],
    );

    return (
        <PageTemplate
            actionCards={<ActionCards openCreateModal={openModal} />}
            title={ItemTitle.DASHBOARD}
            firstName={data?.firstName}
            filledButtonTitle="Formula"
            outlinedButtonTitle="Upload a Formula"
            openCreateModal={openModal}
            uploadButton={<UploadFormula />}
        >
            <ModalTemplate
                text="Create Formula"
                onClose={closeModal}
                modalRef={createFormulaRef}
                className="max-w-[600px]"
            >
                <CreateFormulaForm handleClose={closeModal} />
            </ModalTemplate>

            <h3 className="text-2xl font-semibold mx-8">Your last searches</h3>
            <div className="flex flex-col md:flex-row gap-8 mx-10 my-3">
                <SearchBlock query={query} onChange={handleUpdateQuery} className="w-full my-8"/>
            </div>
            <div className="mx-8 mb-10">
                <TableInfo
                    setLimit={setLimit}
                    title="Formulas"
                    name="formulas"
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={formulas}
                        columns={formulaColumns}
                    />
                </TableInfo>
                <TableInfo
                    setLimit={setLimit}
                    title="Raw Ingredients"
                    name="rawIngredients"
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={rawIngredients}
                        columns={rawIngredientColumns}
                    />
                </TableInfo>
                <TableInfo
                    setLimit={setLimit}
                    name="subIngredients"
                    title="Sub Ingredients"
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={subIngredients}
                        columns={subIngredientColumns}
                    />
                </TableInfo>
                <TableInfo
                    setLimit={setLimit}
                    title="Contaminants"
                    name="contaminants"
                    isVisibleMoreButton
                >
                    <DataTable
                        className="my-4"
                        data={contaminants}
                        columns={contaminantColumns}
                    />
                </TableInfo>
            </div>
            <ScrollUpButton height={600} />
            {isFormulasLoading ||
            isLoadingRawIngredient ||
            isLoadingSubIngredient ||
            isLoadingContaminant ? (
                <LoadingToast />
            ) : (
                <ToastContainer />
            )}
        </PageTemplate>
    );
};
