import {
    keepPreviousData,
    useQuery,
    useMutation,
    useInfiniteQuery,
} from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { contaminantsService } from "services/contaminantsService";
import { ICreateContaminantBody } from "../types/createContaminantBody";
import {
    type IContaminant,
    type IQuery,
    type RequestQueryBodyT,
} from "common/types";

const nameComparator = (a: IContaminant, b: IContaminant) =>
    a.name.localeCompare(b.name);

export const useGetContaminants = ({ queryParams, sorting }: IQuery) => {
    const sortingKey = sorting?.length
        ? `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`
        : undefined;
    const queryKey = [queryKeys.contaminants, queryParams];

    if (sortingKey) {
        queryKey.push(sortingKey);
    }

    return useQuery({
        queryKey,
        queryFn: () => contaminantsService.getContaminants({ queryParams }),
        select: (data) => ({
            count: data?.count,
            rows: data?.rows?.sort(nameComparator),
        }),
        placeholderData: keepPreviousData,
    });
};

export const useLastContaminants = ({ queryParams }: IQuery) => {
    const queryKey = [queryKeys.contaminants, queryParams];

    return useQuery<IContaminant[]>({
        queryKey,
        queryFn: () =>
            contaminantsService
                .getContaminants({ queryParams })
                .then((res) => res?.rows),
        placeholderData: keepPreviousData,
    });
};

export const useGetContaminantsStatistic = () => {
    const queryKey = [queryKeys.contaminants, queryKeys.statistic];

    return useQuery({
        queryKey,
        queryFn: () => contaminantsService.getContaminantsStatistic(),
    });
};

export const useCreateContaminant = () => {
    return useMutation({
        mutationFn: ({ body }: { body: Partial<ICreateContaminantBody> }) =>
            contaminantsService.createContaminant({ body }),
    });
};

export const useGetAllContaminants = ({ enabled = true } = {}) => {
    return useQuery({
        queryKey: [queryKeys.contaminants, "all"],
        queryFn: () => contaminantsService.getAll(),
        enabled,
    });
};

export const useUpdateContaminant = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: number;
            body: Pick<IContaminant, "description" | "name">;
        }) =>
            contaminantsService.updateContaminant({
                id,
                body,
            }),
    });
};

export const useContaminants = ({
    size = 20,
    search,
}: RequestQueryBodyT = {}) => {
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: [queryKeys.contaminants, search],
            queryFn: ({ pageParam = 0 }) =>
                contaminantsService.getContaminants({
                    queryParams: `offset=${pageParam * size}&limit=${size}&query=${search || ""}`,
                }),
            getNextPageParam: (lastPage, allPages) => {
                const totalItems = lastPage?.count || 0;
                const loadedItems = allPages.reduce(
                    (total, page) => total + (page.rows?.length || 0),
                    0,
                );
                return loadedItems < totalItems
                    ? loadedItems / size
                    : undefined;
            },
            initialPageParam: 0,
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            placeholderData: keepPreviousData,
        });

    const flattenedData = data?.pages.flatMap((page) => page.rows || []) || [];

    return {
        contaminants: flattenedData,
        contaminantOptions: flattenedData.map((item) => ({
            label: `${item.name}, ${item.description}`,
            value: `${item.id}`,
        })),
        count: data?.pages[0]?.count || 0,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    };
};
