import { keepPreviousData, useQuery, useMutation } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import {
    formulaService,
    formulasService,
    rawIngredientService,
} from "services";
import { ICreateFormula } from "../../formula-profile/types/createFormula";
import { IOption, IQuery } from "common/types";
import { IFormulaItem, IParsedFormula } from "../types";
import { productTypes } from "common/data/productTypeOptions";

export const useGetFormulas = ({ queryParams, sorting }: IQuery) => {
    const sortingKey = sorting?.length
        ? `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`
        : undefined;
    const queryKey = [queryKeys.formulas, queryParams];

    if (sortingKey) {
        queryKey.push(sortingKey);
    }

    return useQuery({
        queryKey,
        queryFn: () => formulasService.getFormulas({ queryParams, sorting }),
        placeholderData: keepPreviousData,
    });
};

export const useGetLastSearchFormulas = (queryParams: string) =>
    useQuery<IFormulaItem[]>({
        queryKey: [queryKeys.formulasLastSearch, queryParams],
        queryFn: () =>
            formulasService
                .getFormulas({ queryParams })
                .then((data) => data?.rows),
        placeholderData: keepPreviousData,
    });

export const useGetFormulasStatistic = () => {
    return useQuery({
        queryKey: [queryKeys.formulas, queryKeys.statistic],
        queryFn: () => formulasService.getFormulasStatistic(),
    });
};

export const useUploadProductTypes = () =>
    useQuery({
        queryKey: [queryKeys.productTypes],
        queryFn: () => formulasService.getProductTypes(),
        select: (data) =>
            data?.reduce((acc, { product_type }) => {
                const foundProductType = productTypes.find(
                    ({ value }) => value === product_type,
                );

                if (foundProductType) {
                    const { value, label } = foundProductType;
                    acc.push({ value, label });
                }

                return acc;
            }, [] as IOption[]),
    });

export const useCreateFormula = () =>
    useMutation({
        mutationFn: ({ body }: { body: Omit<ICreateFormula, "ficode"> }) =>
            formulaService.createFormula({ body }),
    });

export const useCreateFormulaBulk = () =>
    useMutation({
        mutationFn: ({ body }: { body: IParsedFormula }) =>
            formulaService.createFormulaBulk({ body }),
    });

export const useRawIngredient = (name: string) =>
    useQuery({
        queryKey: [queryKeys.rawIngredient, name],
        queryFn: () => rawIngredientService.getRawIngredientByName(name),
    });

export const useFindSimilarFormulas = (description?: string) =>
    useQuery({
        queryKey: [queryKeys.formulas, queryKeys.similar, description],
        queryFn: () =>
            formulasService.findSimilarFormulas(description?.trim() || ""),
        enabled: Boolean(description?.trim()),
    });
