import React, { FC } from "react";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";
import { modifyDate } from "common/utils";

const dateFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
} as const;

export const SourceLink: FC = () => {
    const { ingredientSource } = useSubIngredientData();

    if (!ingredientSource?.url) {
        return null;
    }

    const createdDate = ingredientSource.date
        ? `at ${modifyDate(ingredientSource.date, dateFormatOptions)}`
        : null;

    return (
        <div
            aria-label={`Sub Ingredient Source link for ${ingredientSource.text || "ingredient"}`}
            className="mt-4"
        >
            <a
                href={ingredientSource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-medium underline hover:no-underline transition-all duration-200 hover:text-blue-800 inline-flex items-center gap-1"
            >
                <span className="relative inline-block pb-0.5 hover:underline hover:decoration-2 hover:decoration-blue-600">
                    Sub Ingredient Source:{" "}
                </span>
                <span>{ingredientSource.text}</span>
            </a>
            {createdDate && (
                <time
                    dateTime={ingredientSource.date}
                    className="text-gray-500 text-xs italic block mt-1 mb-2 text-right"
                >
                    {createdDate}
                </time>
            )}
        </div>
    );
};
