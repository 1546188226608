import React from "react";
import { Title } from "components/shared/title";
import { CompositionTable, TestsTable } from "./components";
import { AddRelatedIngredients } from "../../";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";

export const CompositionTab = () => {
    const { isOwner } = useRawIngredientStore();

    return (
        <div className="md:mx-5 lg:mx-10 py-2">
            <Title
                text="Composition & Testing Data"
                className="mb-6 text-2xl"
            />
            {(isOwner || true) && (
                <div className="mb-6">
                    <AddRelatedIngredients type="Sub Ingredient" />
                </div>
            )}
            <div className="flex flex-col lg:flex-row gap-6">
                <CompositionTable className="w-full lg:w-[70%]" />
                <TestsTable className="w-full lg:w-[30%]" />
            </div>
        </div>
    );
};
