import { backendKeys } from "../common/constants";
import instance from "./axios/instance";
import BaseService from "./baseService";
import {
    type IFormulaCompareResponse,
    type IFormula,
    type IUpdateFormula,
    type ICreateFormula,
    type FormulaMos,
    type IFormulaItem,
    type IParsedFormula,
} from "../pages/formulas";
import {
    type IOption,
    type IReport,
    type IUpdateMosRequest,
} from "../common/types";

class FormulaService extends BaseService {
    constructor(
        public baseUrl = backendKeys.formulas,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getFormula({ id }: { id: string }) {
        return this.handleRequest<IFormula>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async removeFormula({ id }: { id: string }) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async getFormulaReports({ ficode }: { ficode: string }) {
        return this.handleRequest<IReport[]>(
            this.fetchingService.get(`files/${this.baseUrl}/reports/${ficode}`),
        );
    }

    async getFormulaReport({
        ficode,
        filename,
    }: {
        ficode: string;
        filename: string;
    }) {
        return this.handleRequest<Blob>(
            this.fetchingService.get(
                `files/${this.baseUrl}/reports/${ficode}/${filename}`,
                { responseType: "blob" },
            ),
        );
    }

    async generateReport({ id, type }: { id: string; type: string }) {
        return this.handleRequest<IReport>(
            this.fetchingService.post(
                this.getFullUrl(`/${id}/reports/${type}`),
            ),
        );
    }

    async addFormulaReport({
        ficode,
        filename,
        body,
    }: {
        ficode: string;
        filename: string;
        body: FormData;
    }) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        return this.handleRequest(
            this.fetchingService.post(
                `files/${this.baseUrl}/reports/${ficode}/${filename}`,
                body,
                { headers },
            ),
        );
    }

    async removeFormulaReport({
        ficode,
        filename,
    }: {
        ficode: string;
        filename: string;
    }) {
        return this.handleRequest(
            this.fetchingService.delete(
                `files/${this.baseUrl}/reports/${ficode}/${filename}`,
            ),
        );
    }

    async createFormula({ body }: { body: Omit<ICreateFormula, "ficode"> }) {
        return this.handleRequest<IFormula>(
            this.fetchingService.post(this.baseUrl, body),
        );
    }

    async updateFormula({ id, body }: { id: string; body: IUpdateFormula }) {
        return this.handleRequest<IFormula>(
            this.fetchingService.put(this.getFullUrl(`/${id}`), body),
        );
    }

    async updateMos({
        id,
        body,
    }: {
        id: string | number;
        body: IUpdateMosRequest;
    }) {
        return this.handleRequest<IFormula>(
            this.fetchingService.put(this.getFullUrl(`/${id}/mos`), body),
        );
    }

    async getFormulaCompare(id: string, searchParams?: string) {
        return this.handleRequest<IFormulaCompareResponse>(
            this.fetchingService.get(
                this.getFullUrl(`/${id}/compare?${searchParams}`),
            ),
        );
    }

    async createFormulaBulk({ body }: { body: IParsedFormula }) {
        return this.handleRequest<IFormulaCompareResponse>(
            this.fetchingService.post(this.getFullUrl(`/createBulk`), body),
        );
    }

    async updateRelation({
        body,
        id,
        ingredientId,
        relationType,
    }: {
        body: {
            value: string;
        };
        id: string;
        ingredientId: string;
        relationType: string;
    }) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(`/${id}/${ingredientId}/${relationType}`),
                body,
            ),
        );
    }

    async deleteRelation({
        id,
        ingredientId,
        relationType,
    }: {
        id: string;
        ingredientId: string;
        relationType: string;
    }) {
        return this.handleRequest(
            this.fetchingService.delete(
                this.getFullUrl(`/${id}/${ingredientId}/${relationType}`),
            ),
        );
    }

    async getFullSavedFormulas() {
        return this.handleRequest<IFormulaItem[]>(
            this.fetchingService.get(this.getFullUrl("/saved")),
        );
    }

    async getFormulaStatusOptions() {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl("/options/status")),
        );
    }

    async getFormulaMos(formulaId?: number) {
        return this.handleRequest<FormulaMos[]>(
            this.fetchingService.get(this.getFullUrl(`/${formulaId}/mos`)),
        );
    }
}

export const formulaService = new FormulaService();
