import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    onClick?: () => void;
    text: string | number;
    isActive?: boolean;
    isDisabled?: boolean;
    className?: string;
}

export const PaginationButton: FC<IProps> = ({
    onClick,
    isActive = false,
    isDisabled = false,
    text,
    className = "",
}) => (
    <button
        className={classNames(
            "btn btn-pagination",
            {
                "btn-pagination-active": isActive,
                "btn-disabled": isDisabled,
            },
            className,
        )}
        onClick={onClick}
    >
        {text}
    </button>
);
