import React, { FC } from "react";

import { ArchiveAdd } from "assets/icons";
import { ContaminantsTable, FormulaTable, RITable, SITable } from "./components";


interface IProps {
    className?: string;
    title: string;
    subtitle: string;
}

export const SavedItems: FC<IProps> = ({ title, subtitle }) => {
    return (
        <>
            <h2 className="font-semibold text-2xl flex items-center gap-x-2">
                <span>{title}</span> 
                {<ArchiveAdd />}
            </h2>
            <p className="font-medium text-base text-[#A1A1A1] pb-4">{subtitle}</p>
            <FormulaTable/>
            <RITable/>
            <SITable/>
            <ContaminantsTable/>
        </>
    );
};
