import React, { ReactNode } from "react";
import classNames from "classnames";

type ButtonType = "button" | "submit" | "reset";
type ButtonVariant =
    | "primary"
    | "secondary"
    | "outline"
    | "ghost"
    | "danger"
    | "success";
type ButtonSize = "xs" | "sm" | "md" | "lg";

interface IProps {
    text?: string;
    onClick?: () => void;
    isLoading?: boolean;
    className?: string;
    classNameContainer?: string;
    type?: ButtonType;
    children?: ReactNode;
    disabled?: boolean;
    isSplitText?: boolean;
    isOwner?: boolean;
    variant?: ButtonVariant;
    size?: ButtonSize;
    fullWidth?: boolean;
    icon?: ReactNode;
    iconPosition?: "left" | "right";
}

const splitText = (text: string) => {
    const words = text.split(" ");
    if (words.length >= 2) {
        return (
            <span className="text-center">
                <span className="block">{words[0]}</span>
                <span className="block">{words.slice(1).join(" ")}</span>
            </span>
        );
    }
    return text;
};

const getVariantClasses = (variant: ButtonVariant = "primary") => {
    const variants = {
        primary:
            "btn-primary bg-[#113261] hover:bg-[#113261] hover:text-bold transition-colors duration-200 ease-in-out hover:shadow-lg active:shadow-md",
        secondary: "btn-secondary",
        outline: "btn-outline",
        ghost: "btn-ghost",
        danger: "btn-error bg-red-200 hover:text-red-700 hover:bg-red-300",
        success:
            "btn-success bg-green-200 hover:text-green-700 hover:bg-green-300",
    };
    return variants[variant];
};

const getSizeClasses = (size: ButtonSize = "md") => {
    const sizes = {
        xs: "btn-xs h-8",
        sm: "btn-sm h-10",
        md: "h-12",
        lg: "h-14 px-10",
    };
    return sizes[size];
};

export const Button: React.FC<IProps> = ({
    text = "",
    onClick,
    isLoading = false,
    className = "",
    classNameContainer = "",
    type = "button",
    children = null,
    disabled = false,
    isSplitText = false,
    isOwner = true,
    variant = "primary",
    size = "md",
    fullWidth = false,
    icon,
    iconPosition = "left",
}) => {
    if (!isOwner) return null;

    const content = text && (isSplitText ? splitText(text) : text);
    const hasIconOnly = !content && !children && icon;

    return (
        <div
            className={classNames(classNameContainer, { "w-full": fullWidth })}
        >
            <button
                className={classNames(
                    "btn relative inline-flex justify-center items-center gap-2",
                    getVariantClasses(variant),
                    getSizeClasses(size),
                    {
                        "opacity-70 cursor-not-allowed": isLoading || disabled,
                        "w-full": fullWidth,
                        "btn-square": hasIconOnly,
                    },
                    className,
                )}
                onClick={!isLoading && !disabled ? onClick : undefined}
                type={type}
                disabled={disabled}
            >
                <span
                    className={classNames("inline-flex items-center gap-2", {
                        "flex-row-reverse": iconPosition === "right",
                    })}
                >
                    {icon}
                    {content}
                    {children}
                </span>
                {isLoading && (
                    <span className="loading loading-spinner relative pl-2" />
                )}
            </button>
        </div>
    );
};
