import { modifyDate } from "./modifyDate";

export const getLastModifier = (
    modifierData: Record<string, Record<string, string | null>>,
    fieldName: string,
): string | null => {
    const fieldData = modifierData?.[fieldName];
    if (!fieldData) return null;

    const {
        assessor,
        company_name,
        company_email,
        date_modified,
        name,
        email,
    } = fieldData;

    const userIdentifier = assessor || name || email;
    const contactInfo = company_name || company_email || email;

    if (!userIdentifier && !contactInfo) return null;

    const displayName = userIdentifier || contactInfo;
    const additionalInfo =
        userIdentifier && contactInfo && userIdentifier !== contactInfo
            ? ` (${contactInfo})`
            : "";

    const formattedDate = date_modified
        ? ` at ${modifyDate(date_modified)}`
        : "";

    return `${displayName}${additionalInfo}${formattedDate}`;
};
