import React, { FC } from "react";
import { IContaminantRawIngredient } from "pages/contaminant/contaminants/types";
import { TableCeil, TableHeaderCeil, Title } from "components/shared";
import { Link } from "react-router-dom";
import { buildLinkWithQuery } from "common/utils";
import { routerKeys } from "common/constants";
import classNames from "classnames";
import { TabTable } from "../../../rawIngredient/rawIngredientProfile/components";

interface IProps {
    items?: IContaminantRawIngredient[];
}

const TABLE_HEADERS = [
    {
        title: "Raw Ingredient",
        className: "w-[70%]",
    },
    {
        title: "Concentration (ppm)",
        className: "w-[30%] text-center",
    },
] as const;

export const RIContaminantsTable: FC<IProps> = ({ items }) =>
    Boolean(items?.length) ? (
        <div className="lg:mx-10">
            <Title text="Contaminants in Raw Ingredient" className="mt-4" />
            <div className="mt-4 w-[70%]">
                <TabTable>
                    <thead>
                        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                            {TABLE_HEADERS.map(({ title, className }) => (
                                <TableHeaderCeil
                                    key={title}
                                    text={title}
                                    className={classNames(
                                        "py-3 px-4 font-semibold text-gray-700",
                                        className,
                                    )}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Boolean(items?.length) ? (
                            items?.map((item) => (
                                <tr key={item.id}>
                                    <TableCeil className="w-[70%] py-3 px-4">
                                        <Link
                                            to={buildLinkWithQuery(
                                                routerKeys.rawIngredient +
                                                    routerKeys.root,
                                                item.id,
                                                { tab: "Contaminants" },
                                            )}
                                            className="text-[#113261] hover:underline"
                                        >
                                            {item.name}
                                        </Link>
                                    </TableCeil>
                                    <TableCeil
                                        text={item.ppm}
                                        className="w-[30%] py-3 px-4 text-center"
                                    />
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <TableCeil
                                    text="No Relations"
                                    className="p-3 text-gray-500"
                                    colSpan={TABLE_HEADERS.length}
                                />
                            </tr>
                        )}
                    </tbody>
                </TabTable>
            </div>
        </div>
    ) : null;
