import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, TableCeil, Title } from "components/shared";
import { SafetyTechDocActions } from "./components";
import {
    useGetRawIngredient,
    useUploadSafetyDocument,
    useUploadedSafetyDocuments,
    useDeleteSafetyDocument,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { DocumentTypeT, SafetyDocLabel } from ".";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
} from "common/constants";
import { TabTable } from "../../TabTable";

export const SafetyTechnicalDocuments = () => {
    const [uploadLabel, setUploadLabel] = useState("");

    const rawIngredient = useGetRawIngredient();
    const { data: uploadedFiles, refetch } = useUploadedSafetyDocuments(
        rawIngredient?.id ?? null,
    );

    const { mutate: uploadFile } = useUploadSafetyDocument();
    const { mutate: deleteFile } = useDeleteSafetyDocument();

    const handleUploadDocument = (type: DocumentTypeT, file: File | null) => {
        if (file && rawIngredient?.id) {
            uploadFile(
                {
                    id: rawIngredient.id,
                    type,
                    file,
                },
                {
                    onSuccess: () => {
                        toast.success(
                            "File uploaded successfully.",
                            defaultToastOptions,
                        );
                        setUploadLabel("");
                        refetch();
                    },
                    onError: (error) => {
                        toast.error(
                            `Upload failed: ${error.message}`,
                            defaultErrorToastOptions,
                        );
                    },
                },
            );
        } else {
            setUploadLabel("");
        }
    };

    const handleAddFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            const validFileTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ];
            if (!validFileTypes.includes(file.type)) {
                const errorMessage =
                    "Invalid file type. Please upload a PDF, Word document, or Excel file.";
                toast.error(errorMessage, defaultErrorToastOptions);
                setUploadLabel(errorMessage);
                return;
            }

            const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
            if (file.size > maxSizeInBytes) {
                const errorMessage =
                    "File is too large. Maximum size allowed is 10MB.";
                toast.error(errorMessage, defaultErrorToastOptions);
                setUploadLabel(errorMessage);
                return;
            }
        } else {
            setUploadLabel("");
        }
    };

    const handleDeleteFile = (fileType: keyof typeof SafetyDocLabel) => {
        if (rawIngredient?.id) {
            deleteFile(
                { id: rawIngredient.id, type: fileType },
                {
                    onSuccess: () => {
                        toast.success(
                            "File deleted successfully.",
                            defaultToastOptions,
                        );
                        refetch();
                    },
                    onError: (error) => {
                        toast.error(
                            `Delete failed: ${error.message}`,
                            defaultErrorToastOptions,
                        );
                    },
                },
            );
        }
    };

    return (
        <div className="mx-10 my-3 w-[50%]">
            <div className="flex mb-4">
                <Title text="Safety & Technical Documents" />
            </div>
            <TabTable>
                <tbody>
                    {Object.keys(uploadedFiles || {})?.length ? (
                        Object.entries(uploadedFiles || {}).map(
                            ([key, value], index) => (
                                <tr key={`${index}-${key}-${value}`}>
                                    <TableCeil className="w-[90%] py-3 px-4">
                                        <Link
                                            className="flex items-center primary-color hover:font-bold"
                                            to={value}
                                        >
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                            />
                                            <span className="ml-2 inline-block">
                                                {SafetyDocLabel[
                                                    key as keyof typeof SafetyDocLabel
                                                ] || key}
                                            </span>
                                        </Link>
                                    </TableCeil>
                                    <TableCeil className="w-[10%] py-3 px-4">
                                        <Button
                                            text="Delete"
                                            variant="danger"
                                            size="xs"
                                            onClick={() =>
                                                handleDeleteFile(
                                                    key as keyof typeof SafetyDocLabel,
                                                )
                                            }
                                        />
                                    </TableCeil>
                                </tr>
                            ),
                        )
                    ) : (
                        <tr>
                            <TableCeil
                                text="No files uploaded"
                                className="p-3 text-gray-500"
                                colSpan={2}
                            />
                        </tr>
                    )}
                </tbody>
            </TabTable>
            <div className="mt-4">
                <SafetyTechDocActions
                    isOwner={true}
                    uploadLabel={uploadLabel}
                    handleClick={handleUploadDocument}
                    handleAddFile={handleAddFile}
                />
            </div>
        </div>
    );
};
