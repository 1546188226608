import React, { useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import { ColumnDef } from "@tanstack/react-table";
import { PageTemplate } from "components/page-template";
import { rawIngredientContent, routerKeys } from "common/constants";
import { useUploadRawIngredients } from "./queries";
import { LoadingToast } from "components/shared/loadingToast";
import { Filters } from "./components";
import { ModalTemplate } from "components/shared/modalTemplate";
import { CreateRawIngredientForm } from "./components";
import { ButtonBlock } from "components/shared/button-block/ButtonBlock";
import {
    DataTable,
    FilterBlock,
    IconLink,
    StatusView,
} from "components/shared";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { IRawIngredientItem } from "../types";
import { modifyDate } from "common/utils";

export const RawIngredients = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const { data: rawIngredients, isLoading } = useUploadRawIngredients({
        queryParams: getParamsString(),
        sorting,
    });

    const {
        modalRef: createRawIngredientRef,
        openModal,
        closeModal,
    } = useModal();

    const {
        handleUpdateFilters,
        handleUpdateSearch,
        handleFilterVisible,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const columns = useMemo<ColumnDef<IRawIngredientItem>[]>(
        () => [
            {
                accessorKey: "ricode",
                header: () => <span className="max-w-[100px]">RI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[100px]"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... max-w-[100px]"
                            title={row.getValue("ricode")}
                        >
                            {row.getValue("ricode")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[300px]"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... max-w-[300px] cursor-pointer"
                            title={row.getValue("description")}
                        >
                            {row.getValue("description")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        title={row.original.status}
                        active={row.original.status === "Complete"}
                    />
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [],
    );

    return (
        <PageTemplate
            title={rawIngredientContent?.title}
            subtitle={rawIngredientContent?.subTitle}
            searchValue={filters?.query?.value}
            searchPlaceholder={rawIngredientContent?.placeholder}
            onChange={handleUpdateSearch}
            buttonBlock={
                <ButtonBlock
                    title={rawIngredientContent.title}
                    createFn={openModal}
                />
            }
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Raw Ingredient"
                    onClose={closeModal}
                    modalRef={createRawIngredientRef}
                    className="max-w-[600px]"
                >
                    <CreateRawIngredientForm handleClose={closeModal} isLink />
                </ModalTemplate>
                <div className="w-full mx-10">
                    <FilterBlock
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        onRemoveFilter={handleRemoveFilter}
                        filters={filters}
                    />

                    <DataTable
                        data={rawIngredients?.rows || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={rawIngredients?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                    >
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>
                    {isLoading ? <LoadingToast /> : <ToastContainer />}
                </div>
            </div>
        </PageTemplate>
    );
};
