import React, { FC } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { defaultToastOptions, queryKeys, toastTexts } from "common/constants";
import { Button, InputWrapper } from "components/shared";
import { useUpdateRawIngredient } from "../queries";
import {
    IRawIngredientItem,
    IRawIngredientResponse,
} from "pages/rawIngredient/types";
import { getErrorMessage, getQueryKey } from "common/utils";
import { queryClient } from "libraries/queryProvider";
import { useURLStateSync } from "common/hooks";

interface IProps {
    rawIngredient: IRawIngredientResponse;
    handleClose: () => void;
}

const UpdateSchema = Yup.object().shape({
    description: Yup.string().required("Name is required"),
});

export const UpdateRawIngredient: FC<IProps> = ({
    rawIngredient,
    handleClose,
}) => {
    const { id = "" } = useParams();
    const { mutate: updateRawIngredient } = useUpdateRawIngredient();
    const { sorting, getParamsString } = useURLStateSync();

    const formik = useFormik({
        initialValues: {
            description: rawIngredient?.description?.trim() || "",
            ricode: rawIngredient?.ricode?.trim(),
        },
        validationSchema: UpdateSchema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const rawIngredientsKey = getQueryKey(
                queryKeys.rawIngredients,
                sorting,
                getParamsString,
            );

            const previousRawIngredients = queryClient.getQueryData<{
                count: number;
                rows: IRawIngredientItem[];
            }>(rawIngredientsKey);

            if (previousRawIngredients) {
                queryClient.setQueryData(rawIngredientsKey, {
                    ...previousRawIngredients,
                    rows: previousRawIngredients.rows.map(
                        (item: IRawIngredientItem) =>
                            item.id === rawIngredient.id
                                ? {
                                      ...item,
                                      ...values,
                                  }
                                : item,
                    ),
                });
            }

            const previousData = queryClient.getQueryData([
                queryKeys.rawIngredient,
                id,
            ]);

            queryClient.setQueryData([queryKeys.rawIngredient, id], {
                ...rawIngredient,
                ...values,
            });

            updateRawIngredient(
                {
                    id,
                    body: {
                        ...values,
                        ricode: values.ricode,
                        description: values.description.trim(),
                    },
                },
                {
                    onSuccess: () => {
                        toast.dismiss(toastId);
                        handleClose();
                    },
                    onError: (error: Error) => {
                        queryClient.setQueryData(
                            [queryKeys.rawIngredient, id],
                            previousData,
                        );

                        queryClient.setQueryData(
                            rawIngredientsKey,
                            previousRawIngredients,
                        );

                        toast.dismiss(toastId);
                        const message = getErrorMessage(error as AxiosError);

                        formik.setFieldError(
                            "description",
                            typeof message === "string"
                                ? message
                                : "An unexpected error occurred",
                        );
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange } = formik;

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Name"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        rows={2}
                    />
                </InputWrapper>
            </div>
            <div className="mt-4 flex justify-end gap-2">
                <Button variant="ghost" onClick={handleClose} text="Cancel" />
                <Button text="Update" type="submit" />
            </div>
        </form>
    );
};
