import "jspdf-autotable";
import { SimilarityReportTemplate } from "./templates";
import { SimilarityReportData } from "./types";

export type ReportFormat = "pdf" | "docx";

export class SimilarityReportService {
    async generateSimilarityReport(
        reportData: SimilarityReportData,
        format: ReportFormat = "pdf",
    ): Promise<void> {
        try {
            if (!reportData?.baseFormula?.id) {
                throw new Error("Invalid report data");
            }

            if (format === "pdf") {
                const template = new SimilarityReportTemplate();
                const pdf = template.generate(reportData);
                pdf.save(
                    `similarity_report_${reportData.baseFormula.description}.pdf`,
                );
            }
        } catch (error) {
            console.error("Error generating document:", error);
            throw error;
        }
    }
}
