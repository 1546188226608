import React, { FC, useCallback } from "react";
import { ActionCard } from "components/shared";
import { ActionCardData } from "../libs/enums";

interface IProps {
    openCreateModal?: () => void;
}

export const ActionCards: FC<IProps> = ({openCreateModal}) => {
    
    const handleButtonClick = useCallback(() => {
        console.log("Button clicked!");
    }, []);

    const handleCloseClick = useCallback(() => {
        console.log("Card closed!");
    }, []);

    return (
        <>
            <ActionCard
                title={ActionCardData.FORMULAS.title}
                buttonText="See tutorial"
                className="h-[120px] md:h-[140px]"
                onButtonClick={openCreateModal}
                onCloseClick={handleCloseClick}
            >
                <p className="mt-4">{ActionCardData.FORMULAS.description}</p>
            </ActionCard>    
            <ActionCard
                title={ActionCardData.REPORTS.title}
                buttonText="See tutorial"
                className="h-[120px] md:h-[140px]"
                onButtonClick={handleButtonClick}
                onCloseClick={handleCloseClick}
            >
                <p className="mt-4">{ActionCardData.REPORTS.description}</p>
            </ActionCard> 
            <ActionCard
                title={ActionCardData.ACCESS_DATA.title}
                buttonText="See tutorial"
                className="h-[120px] md:h-[140px]"
                onButtonClick={handleButtonClick}
                onCloseClick={handleCloseClick}
            >
                <p className="mt-4">{ActionCardData.ACCESS_DATA.description}</p>
            </ActionCard> 
        </>
    );
};