import React, { useMemo, useState } from "react";
import { IOption } from "common/types";
import { MultiValue } from "react-select";
import { contaminantService } from "services";
import { useGetAllContaminants } from "pages/contaminant/contaminants/queries";
import { AddNewItemTemplate } from "./AddNewItemTemplate";

interface IProps {
    onClose: () => void;
    push: <X = unknown>(obj: X) => void;
}

const defaultContaminantData = {
    name: "",
    description: "",
    ppm: "",
};

export const AddContaminant: React.FC<IProps> = ({ onClose, push }) => {
    const [selectedContaminant, setSelectedContaminant] =
        useState<IOption | null>(null);
    const [error, setError] = useState<string | null>(null);

    const { data, isLoading } = useGetAllContaminants();

    const options = useMemo(() => {
        if (data) {
            return data.map((item) => {
                return {
                    value: String(item?.id),
                    label: `${item?.name}, ${item?.description}`,
                };
            }) as unknown as MultiValue<IOption>;
        } else {
            return [];
        }
    }, [data]);

    const hundleUpdateRelatedList = (newValue: IOption) => {
        setError(null);
        setSelectedContaminant(newValue);
    };

    const handleCreate = () => {
        if (selectedContaminant) {
            contaminantService
                .getContaminant({ id: selectedContaminant.value })
                .then((res) => {
                    const newItem = {
                        name: res.name,
                        description: res.description,
                        ppm: "",
                    };

                    push(newItem);
                })
                .catch(() => {
                    setError("An error occurred! Please try again");
                });
        } else {
            push(defaultContaminantData);
        }

        onClose();
    };

    return (
        <AddNewItemTemplate
            isLoading={isLoading}
            options={options}
            values={selectedContaminant}
            onChange={hundleUpdateRelatedList}
            handleCreate={handleCreate}
            onClose={onClose}
            error={error}
        />
    );
};
