import { backendKeys } from "../common/constants";
import { type IContaminant } from "../common/types";
import BaseService from "./baseService";
import instance from "./axios/instance";

class ContaminantService extends BaseService {
    constructor(
        public baseUrl = backendKeys.contaminants,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getContaminant({ id }: { id: string }) {
        return this.handleRequest<IContaminant>(
            this.fetchingService.get(this.getFullUrl(`/${id}`)),
        );
    }

    async removeContaminant({ id }: { id: string }) {
        return this.handleRequest(
            this.fetchingService.delete(this.getFullUrl(`/${id}`)),
        );
    }

    async getFullSavedContaminant() {
        return this.handleRequest<IContaminant[]>(
            this.fetchingService.get(this.getFullUrl("/saved")),
        );
    }
}

export const contaminantService = new ContaminantService();
