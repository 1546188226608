import { formatLabel } from "common/utils";

interface ColumnConfig {
   key: string;
   label: string;
   format?: (value: string) => string;
 }

export const formulaColumnsConfig: ColumnConfig[] = [
   { key: "ficode", label: "FI Code" },
   { key: "description", label: "Description" },
   { key: "status", label: "Status", format: formatLabel },
];

export const rawIngredientColumnsConfig: ColumnConfig[] = [
   { key: "ricode", label: "RI Code" },
   { key: "description", label: "Description" },
   { key: "status", label: "Status", format: formatLabel },
];

export const subIngredientColumnsConfig: ColumnConfig[] = [
   { key: "sicode", label: "SI Code" },
   { key: "name", label: "Name" },
   { key: "status", label: "Status", format: formatLabel },
];

export const contaminantColumnsConfig: ColumnConfig[] = [
   { key: "name", label: "Name" },
   { key: "description", label: "Description" },
];