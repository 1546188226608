import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys, routerKeys } from "common/constants";
import { formulaService } from "services";
import { useFormulaCompareQuery } from "../store";
import { IFormulaCompareResponse } from "../types";
import { useUploadFormulaSimilar } from "../../formula-profile/queries";

export const useUpdateFormulaCompare = ({
    id,
    searchParams,
}: {
    id: string;
    searchParams: string;
}) => {
    const queryKey = [queryKeys.formulaCompare, id, searchParams];
    const { setKey } = useFormulaCompareQuery();

    return useQuery({
        queryKey,
        queryFn: () =>
            formulaService.getFormulaCompare(id, searchParams).then((res) => {
                setKey(queryKey);
                return res;
            }),
    });
};

export const useGetFormulaCompare = () => {
    const { key } = useFormulaCompareQuery();
    const queryClient = useQueryClient();

    return queryClient.getQueryData<IFormulaCompareResponse>(key);
};

export const useSimilarFormulas = (id: string) => {
    const { data = [] } = useUploadFormulaSimilar({
        id,
    });
    const searchParams = new URLSearchParams();
    const params = data?.map(({ target_formulas_id }) => target_formulas_id);

    searchParams.append(routerKeys.compareWith, params.join(","));

    return useQuery({
        queryKey: [queryKeys.formulaCompare, id, searchParams.toString()],
        queryFn: () =>
            formulaService.getFormulaCompare(id, searchParams.toString()),
    });
};
