import React from "react";
import classNames from "classnames";
import { Title, TableCeil, TableHeaderCeil } from "components/shared";
import { TabTable } from "../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import { AddRelatedIngredients } from "../../";
import { ContaminantRow } from "./ContaminantRow";
import { RawIngredientProfileTab } from "pages/rawIngredient/enums";

const TABLE_HEADERS = [
    {
        title: "Name",
        className: "w-[15%]",
    },
    {
        title: "Description",
        className: "w-[45%]",
    },
    {
        title: "Value (ppm)",
        className: "w-[20%] text-center",
    },
    {
        title: "",
        className: "w-[5%]",
    },
] as const;

export const ContaminantsTab = () => {
    const rawIngredient = useGetRawIngredient();
    const { isOwner } = useRawIngredientStore();

    return (
        <div className="md:flex lg:flex mx-10">
            <div className="w-1/2 md:w-1/2 lg:w-1/2 md:mr-5">
                <Title
                    text={RawIngredientProfileTab.CONTAMINANTS}
                    className="mt-2"
                />
                {(isOwner || true) && (
                    <AddRelatedIngredients type="Contaminant" />
                )}
                <TabTable>
                    <thead className="bg-indigo-100">
                        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                            {TABLE_HEADERS.map(({ title, className }) => (
                                <TableHeaderCeil
                                    key={title}
                                    text={title}
                                    className={classNames(
                                        "py-3 px-4 font-semibold text-gray-700",
                                        className,
                                    )}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody className="sm:flex-none">
                        {rawIngredient?.contaminants?.length ? (
                            <>
                                {rawIngredient.contaminants
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name),
                                    )
                                    .map((contaminant) => (
                                        <ContaminantRow
                                            key={contaminant.id}
                                            contaminant={contaminant}
                                        />
                                    ))}
                            </>
                        ) : (
                            <tr>
                                <TableCeil
                                    text="No Contaminants found"
                                    className="p-3 text-gray-500"
                                    colSpan={TABLE_HEADERS.length}
                                />
                            </tr>
                        )}
                    </tbody>
                </TabTable>
            </div>
            <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
                <Title text="Chemical Specification" className="mt-2" />
            </div>
        </div>
    );
};
