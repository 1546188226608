import { PageTemplate } from "components/page-template";
import React from "react";
import { Header } from "./components";
import { ToastContainer } from "react-toastify";
import { Forms } from "./components/Forms";
import { ItemTitle } from "components/sidebar";

export const AdminPanel = () => {
    return (
        <PageTemplate title={ItemTitle.ADMIN_PANEL}>
            <Header />
            <Forms />
            <ToastContainer />
        </PageTemplate>
    );
};
