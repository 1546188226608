import React, { useCallback, useState } from "react";
import { PageTemplate } from "components/page-template";
import {
    ProfileMenu,
    SavedItems,
    UserProfile,
} from "./components";
import {
    ProfileConfig,
    ProfileItems,
    useGetLocation,
    useGetUserProfile,
} from "./libs";
import { ToastContainer } from "react-toastify";
import { ItemTitle } from "components/sidebar";

interface IProps {
    activeItem: ProfileItems;
}

const ActiveTab: React.FC<IProps> = ({ activeItem }) => {
    const { data: userInfo, refetch: refetchUser } = useGetUserProfile();
    const { data: location } = useGetLocation();

    const subtitle = ProfileConfig[activeItem].subtitle;
    const title = ProfileConfig[activeItem].title;

    switch (activeItem) {
        case ProfileItems.MY_PROFILE:
            return (
                <UserProfile
                    userInfo={userInfo}
                    location={location}
                    refetchUser={refetchUser}
                    subtitle={subtitle}
                    title={title}
                />
            );
        default:
            return <SavedItems subtitle={subtitle} title={title}/>;
    }
};

export const Profile = () => {
    const [activeItem, setActiveItem] = useState(ProfileItems.MY_PROFILE);

    const handleMenuClick = useCallback((active: ProfileItems) => {
        setActiveItem(active);
    }, []);

    return (
        <PageTemplate
            title={ItemTitle.PROFILE}
        >
            <div className="flex gap-8 mx-12 my-8">
                <ProfileMenu
                    activeItem={activeItem}
                    onItemClick={handleMenuClick}
                />
                <div className="w-full px-6">
                    <ActiveTab activeItem={activeItem} />
                </div>
            </div>
            <ToastContainer />
        </PageTemplate>
    );
};
