import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instance from "./axios/instance";
import { IUser, IUserProfileInfo, IUserProfileUpdate } from "common/types";
import { buildLink } from "common/utils";

type UserProfile = {
    id: string;
    email: string;
};

class UserService extends BaseService {
    constructor(
        public baseUrl = backendKeys.users,
        private fetchingService = instance,
    ) {
        super(baseUrl);
    }

    async getUserLocation(): Promise<Pick<IUser, "country_name" | "city">> {
        const response = (await this.handleRequest(
            this.fetchingService.get(backendKeys.getLocation),
        )) as { country_name: string; city: string };

        return response;
    }

    async getUserProfileInfo(): Promise<IUserProfileInfo> {
        return this.handleRequest<IUserProfileInfo>(
            this.fetchingService.get(
                this.getFullUrl(backendKeys.api.profileInfo)
            ),
        );
    }

    async updateUserProfileInfo(payload: IUserProfileUpdate) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(backendKeys.api.profileInfo),
                payload,
            ),
        );
    }

    async updateUser(payload: Partial<IUser>) {
        return this.handleRequest(
            this.fetchingService.put(
                this.getFullUrl(backendKeys.root),
                payload,
            ),
        );
    }

    async addGroupIfEmpty(payload: UserProfile) {
        this.handleRequest(
            this.fetchingService.post(
                this.getFullUrl(backendKeys.api.assignGroup.addGroup),
                payload
            )
        )
    }

    // common saved methods

    async saveData<T>(endpoint: string, payload: T) {
        return this.handleRequest(
            this.fetchingService.post(this.getFullUrl(endpoint), payload),
        );
    }

    async getSavedData<T>(endpoint: string): Promise<T[]> {
        return this.handleRequest(
            this.fetchingService.get(
                this.getFullUrl(endpoint),
            ),
        );
    }

    async removeSavedData<T extends number>(endpoint: string, id: T) {
        return this.handleRequest(
            this.fetchingService.delete(
                this.getFullUrl(buildLink(endpoint, id)),
            ),
        );
    }

    // saved formulas
    async saveFormula(payload: { userId: string; formulaId: number }) {
        return this.handleRequest(
            this.fetchingService.post(
                this.getFullUrl(backendKeys.api.formulas.save),
                payload,
            ),
        );
    }
}

export const userService = new UserService();
