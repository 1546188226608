export const queryKeys = {
    similar: "similar",
    formula: "formula",
    formulaReports: "formulaReports",
    formulas: "formulas",
    formulaData: "formulaData",
    formulaCompare: "formulaCompare",
    lastUpdateFormules: "lastUpdateFormulas",
    rawIngredients: "rawIngredients",
    rawIngredient: "rawIngredient",
    savedRawIngredient: "savedRawIngredient",
    fullSavedRawIngredient: "fullSavedRawIngredient",
    subIngredients: "subIngredients",
    subIngredientsOverallRank: "subIngredientsOverallRank",
    subIngredientsEnvironmentalClass: "subIngredientsEnvironmentalClass",
    subIngredient: "subIngredient",
    fullSavedSubIngredient: "fullSavedSubIngredient",
    savedSubIngredient: "savedSubIngredient",
    subIngredientReports: "subIngredientReports",
    productClassification: "productClassification",
    contaminants: "contaminants",
    contaminant: "contaminant",
    fullSavedContaminants: "fullSavedContaminants",
    savedContaminants: "savedContaminants",
    statistic: "statistic",
    user: "currentUser",
    vendors: "vendors",
    fdaClasses: "fdaClasses",
    productTypes: "productTypes",
    productTypeOptions: "productTypeOptions",
    subIngredientOptions: "subIngredientOptions",
    userProfile: "userProfile",
    userProfileInfo: "userProfileInfo",
    userLocation: "userLocation",
    savedFormulas: "savedFormulas",
    fullSavedFormulas: "fullSavedFormulas",
    currentRawIngredient: "currentRawIngredient",
    formulasLastSearch: "formulasLastSearch",
    formulaStatusOptions: "formulaStatusOptions",
    manufactureOptions: "manufactureOptions",
    tradenameOptions: "tradenameOptions",
    similarItems: "similarItems",
    safetyDocuments: "safetyDocuments",
    crammerClassOptions: "crammerClassOptions",
    subIngredientsFunctionOptions: "subIngredientsFunctionOptions",
    safetySummaryOptions: "safetySummaryOptions",
    toxicReferenceOptions: "toxicReferenceOptions",
    localEffectRefOptions: "localEffectRefOptions",
    tradenames: "tradenames",
    categoryBodies: "categoryBodies",
    productTypeProperties: "productTypeProperties",
    formulaMos: "formulaMos",
    subIngredientSDSFiles: "subIngredientSDSFiles",
    subIngredientCSV: "subIngredientCSV",
} as const;
