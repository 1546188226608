import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUpdateContaminant } from "../queries";
import { IContaminant, IError } from "common/types";
import { Button, InputWrapper } from "components/shared";
import { toastTexts, queryKeys, defaultToastOptions } from "common/constants";
import { useURLStateSync } from "common/hooks";
import { queryClient } from "libraries/queryProvider";
import { getQueryKey } from "common/utils";
import { IContaminantsResponse } from "../types";

interface IProps {
    onClose: () => void;
    contaminant: IContaminant;
}

const UpdateSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().trim().required("Description is required"),
});

export const UpdateContaminantForm: FC<IProps> = ({ onClose, contaminant }) => {
    const { mutate: updateContaminant } = useUpdateContaminant();
    const { sorting, getParamsString } = useURLStateSync();
    const { id = "" } = useParams();

    const formik = useFormik({
        initialValues: {
            name: contaminant.name || "",
            description: contaminant.description || "",
        },
        validationSchema: UpdateSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const contaminantsKey = getQueryKey(
                queryKeys.contaminants,
                sorting,
                getParamsString,
            );

            const previousContaminants =
                queryClient.getQueryData<IContaminantsResponse>(
                    contaminantsKey,
                );

            if (previousContaminants) {
                queryClient.setQueryData(contaminantsKey, {
                    ...previousContaminants,
                    rows: previousContaminants.rows.map((item: IContaminant) =>
                        item.id === contaminant.id
                            ? {
                                  ...item,
                                  ...values,
                              }
                            : item,
                    ),
                });
            }

            const previousData = queryClient.getQueryData([
                queryKeys.contaminants,
                id,
            ]);

            queryClient.setQueryData([queryKeys.contaminants, id], {
                ...contaminant,
                ...values,
            });

            updateContaminant(
                {
                    id: contaminant.id,

                    body: {
                        description: values.description.trim(),
                        name: values.name.trim(),
                    },
                },
                {
                    onSuccess: () => {
                        toast.dismiss(toastId);
                        onClose();
                    },
                    onError: (err: IError) => {
                        queryClient.setQueryData(
                            [queryKeys.contaminants, id],
                            previousData,
                        );

                        queryClient.setQueryData(
                            contaminantsKey,
                            previousContaminants,
                        );

                        toast.dismiss(toastId);

                        const message =
                            err?.response?.data?.message || "Update failed";
                        const field = message.toLowerCase();

                        if (field.includes("name")) {
                            formik.setFieldError("name", message);
                        } else if (field.includes("description")) {
                            formik.setFieldError("description", message);
                        } else {
                            toast.error(message);
                        }
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, handleSubmit } = formik;

    return (
        <>
            <form onSubmit={handleSubmit}>
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="Name"
                    className="mb-2"
                >
                    <input
                        type="text"
                        name="name"
                        className="w-full input input-bordered"
                        value={values.name}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Description"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-3 mt-4">
                    <Button text="Cancel" onClick={onClose} variant="ghost" />
                    <Button text="Update" type="submit" variant="primary" />
                </div>
            </form>
        </>
    );
};
