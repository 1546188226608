import React from "react";
import { Tooltip } from "@mui/material";
import { MinusIcon, PlusIcon } from "assets/icons";
import { getLastModifier } from "common/utils";
import classNames from "classnames";

interface IProps {
    isOpen: boolean;
    text: string;
    children: React.ReactNode;
    onOpen: () => void;
    detailsClassName?: string;
    summaryClassName?: string;
    editorClassName?: string;
    lastModifiedBy?: Record<string, Record<string, string | null>>;
    endpointId?: string;
}

export const Details: React.FC<IProps> = ({
    isOpen,
    text,
    children,
    onOpen,
    detailsClassName = "",
    summaryClassName = "",
    editorClassName = "",
    lastModifiedBy,
    endpointId,
}) => {
    const tooltipMessage =
        endpointId && lastModifiedBy?.[endpointId]
            ? `Field changed by ${getLastModifier(lastModifiedBy, endpointId)}`
            : null;

    return (
        <details
            open={isOpen}
            className={classNames(
                "flex flex-col w-full cursor-pointer mb-3",
                detailsClassName,
            )}
        >
            <summary
                className={classNames(
                    "flex items-center w-full bg-white text-dark py-3 group",
                    summaryClassName,
                )}
                onClick={(e) => {
                    e.preventDefault();
                    onOpen();
                }}
            >
                <span className="flex items-center justify-center">
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                </span>
                <b className="pl-2">{text}</b>
                {endpointId && tooltipMessage && (
                    <Tooltip
                        title={tooltipMessage}
                        placement="top"
                        sx={{
                            "& .MuiTooltip-tooltip": {
                                backgroundColor: "#374151",
                                borderRadius: "6px",
                                padding: "8px",
                                fontSize: "0.875rem",
                                boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1)",
                            },
                        }}
                    >
                        <span className="flex items-center justify-center w-5 h-5 p-2 ml-2 rounded-full bg-blue-100 transition-all cursor-pointer">
                            ?
                        </span>
                    </Tooltip>
                )}
            </summary>
            {isOpen && <div className={editorClassName}>{children}</div>}
        </details>
    );
};
