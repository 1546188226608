import { useKeycloak } from "@react-keycloak/web";
import { useMutation, useQuery } from "@tanstack/react-query";

import { backendKeys, queryKeys } from "common/constants";
import { 
    IContaminant,
    IFormulaSaved, 
    IRawIngredient, 
    ISubIngredient, 
    IUser, 
    IUserProfileInfo, 
    IUserProfileUpdate 
} from "common/types";
import { IFormulaItem } from "pages/formulas/formulas/types";
import { 
    contaminantService, 
    formulaService, 
    rawIngredientService, 
    subIngredientService, 
    userService 
} from "services";

export const useGetUserProfile = () => {
    const queryKey = [queryKeys.userProfile];
    const { keycloak } = useKeycloak();

    return useQuery<IUser>({
        queryKey,
        queryFn: async () => {
            if (!keycloak.authenticated) {
                throw new Error("User is not authenticated");
            }
            try {
                return keycloak.loadUserProfile();
            } catch (error) {
                throw error;
            }
        },
        enabled: keycloak.authenticated,
    });
};

export const useGetLocation = () => {
    const queryKey = [queryKeys.userLocation];

    return useQuery<Pick<IUser, "country_name" | "city">>({
        queryKey,
        queryFn: () => userService.getUserLocation(),
    });
};

export const useGetProfileInfo = () => {
    const queryKey = [queryKeys.userProfileInfo];

    return useQuery<IUserProfileInfo>({
        queryKey,
        queryFn: () => userService.getUserProfileInfo(),
    })
};

export const useUpdateProfileInfo = () =>
    useMutation({
        mutationFn: (payload: IUserProfileUpdate) =>
            userService.updateUserProfileInfo(payload),
    });

export const useUpdateUserProfile = () =>
    useMutation({
        mutationFn: (payload: Partial<IUser>) =>
            userService.updateUser(payload),
    });


// common saved hooks

export const useGetSavedData = <T>(queryKey: string, endpoint: string) => {
    return useQuery<T[]>({
        queryKey: [queryKey],
        queryFn: () => userService.getSavedData<T>(endpoint),
    });
};

export const useSaveDataMutation = <T>(endpoint: string) =>
    useMutation({
        mutationFn: (body: T) => userService.saveData<T>(endpoint, body),
    });

export const useRemoveSavedData = <T extends number>(endpoint: string) => {
    return useMutation({
        mutationFn: (id: T) => userService.removeSavedData(endpoint, id),
    });
};

// formulas saved
export const useSaveFormulaMutation = () =>
    useMutation({
        mutationFn: (body: { userId: string; formulaId: number }) =>
            userService.saveFormula(body),
    });

export const useGetSavedFormulas = () => {
    return useQuery<IFormulaSaved[]>({
        queryKey: [queryKeys.savedFormulas],
        queryFn: () => userService.getSavedData<IFormulaSaved>(backendKeys.api.formulas.saved),
    });
};

export const useFullSavedFormulas = () => {
    const queryKey = [queryKeys.fullSavedFormulas];

    return useQuery<IFormulaItem[]>({
        queryKey,
        queryFn: () => formulaService.getFullSavedFormulas(),
    });
};

// saved raw ingredient
export const useFullSavedRawIngredient = () => {
    const queryKey = [queryKeys.fullSavedRawIngredient];

    return useQuery<IRawIngredient[]>({
        queryKey,
        queryFn: () => rawIngredientService.getFullSavedRawIngredients(),
    });
};

// saved sub ingredient
export const useFullSavedSubIngredient = () => {
    const queryKey = [queryKeys.fullSavedSubIngredient];

    return useQuery<ISubIngredient[]>({
        queryKey,
        queryFn: () => subIngredientService.getFullSavedSubIngredients(),
    });
};

// saved contaminant 
export const useFullSavedContaminants = () => {
    const queryKey = [queryKeys.fullSavedContaminants];

    return useQuery<IContaminant[]>({
        queryKey,
        queryFn: () => contaminantService.getFullSavedContaminant(),
    });
};