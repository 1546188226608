import { PageTemplate } from "components/page-template";
import React, { useState } from "react";
import { Form, FormulaInfoCard } from "./components";

export const FormulaAssessment = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const toggleOpenModal = () => {
        setIsOpenModal(!isOpenModal);
    };

    return (
        <PageTemplate
        // title="Formula Assessment"
        // subtitle="Any descriptive message goes in here."
        // withSearch={false}
        >
            <div className="md:flex lg:flex mx-10 my-10 gap-10">
                <FormulaInfoCard toggleOpenModal={toggleOpenModal} />
                <Form />
            </div>
        </PageTemplate>
    );
};
