import React, { FC } from "react";
import classNames from "classnames";
import { RequestStatus } from "pages/subIngredient/subIngredientProfile/libs";
import { CircularProgress } from "@mui/material";

const predictionMap: Record<string, [string, string]> = {
    NOT_YET_PREDICTED: ["prediction-category--0", "Unknown"],
    FAILED: ["prediction-category--0", "Mandatory parameters missing"],
    NonSensitizer: ["prediction-category--1", "Not a sensitiser"],
    WeakSensitizer: ["prediction-category--2", "Weak sensitiser text-center"],
    ModerateSensitizer: ["prediction-category--3", "Moderate sensitiser"],
    StrongSensitizer: ["prediction-category--4", "Strong sensitiser"],
};

interface PredictionProps {
    status?: RequestStatus;
    sensitizerClass?:
        | "NonSensitizer"
        | "WeakSensitizer"
        | "ModerateSensitizer"
        | "StrongSensitizer";
}

interface IProps {
    prediction?: PredictionProps;
    status?: RequestStatus;
}

export const RegressionPrediction: FC<IProps> = ({ prediction, status }) => {
    const { sensitizerClass } = prediction || {};

    const [divClass, divText] =
        status === "SUCCESS" && sensitizerClass
            ? predictionMap[sensitizerClass]
            : predictionMap["FAILED"];

    if (status === "PENDING") {
        return (
            <div className="space-y-2">
                <div className="text-sm text-gray-700">Multiple regression</div>
                <div className="flex items-center space-x-2">
                    <CircularProgress size={20} color="inherit" />
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    if (!status) return null;

    return (
        <div>
            <div className="text-sm font-semibold text-blue-700">
                Multiple regression
            </div>
            <div className={classNames("rounded px-2 py-1 mt-2", divClass)}>
                {divText}
            </div>
        </div>
    );
};
