import React, { FC } from "react";
import Select, { MultiValue } from "react-select";
import classNames from "classnames";

interface IOption {
    label: string;
    value: string;
}

interface IProps {
    value: string[];
    options?: IOption[];
    onChange: (selected: string[], name: string) => void;
    className?: string;
    name: string;
    isDisabled?: boolean;
    id?: string;
}

export const TableMultiSelect: FC<IProps> = ({
    id,
    value,
    options = [],
    onChange,
    className = "",
    name,
    isDisabled = false,
}) => {
    const selectedOptions = options.filter((option) =>
        value.includes(option.value),
    );

    const handleChange = (selected: MultiValue<IOption>) => {
        const selectedValues = selected.map((option) => option.value);
        onChange(selectedValues, name);
    };

    return (
        <div className={classNames("flex p-3", className)}>
            <Select
                id={id}
                isMulti
                value={selectedOptions}
                onChange={handleChange}
                options={options}
                className="w-full"
                classNamePrefix=""
                isDisabled={isDisabled}
                placeholder="Select options"
                styles={{
                    placeholder: (base) => ({
                        ...base,
                        color: "#9ca3af",
                        fontSize: "0.875rem",
                    }),
                    control: (base) => ({
                        ...base,
                        cursor: "pointer",
                    }),
                }}
            />
        </div>
    );
};
