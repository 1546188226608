import { AppContent } from "./components/app-content";
import React from "react";
import { QueryProvider } from "./libraries/queryProvider";
import "react-toastify/dist/ReactToastify.css";
import { KeycloakProvider } from "./libraries/keycloakProvider";
import { RouterProvider } from "./libraries/routerProvider";
import "./libraries/fontAwesome";

export const App = () => {
    return (
        <KeycloakProvider>
            <QueryProvider>
                <RouterProvider>
                    <AppContent />
                </RouterProvider>
            </QueryProvider>
        </KeycloakProvider>
    );
};
