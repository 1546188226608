import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../button";
import { FilterStatusList } from "../filter-status-list";
import { IFilter } from "common/types";
import classNames from "classnames";

interface FilterBlockProps {
    isFilterVisible: boolean;
    onFilterVisible: () => void;
    onRemoveFilter: (key: string) => void;
    filters: IFilter;
    className?: string;
}

const FilterButton: FC<{ isVisible: boolean; onClick: () => void }> = ({
    isVisible,
    onClick,
}) => (
    <Button
        variant="ghost"
        size="lg"
        className="flex items-center bg-white text-[#113261] text-lg border-none sm:shadow-lg  hover:bg-white hover:border hover:border-gray-300 hover:border-solid hover:border-1"
        onClick={onClick}
    >
        Filters
        <FontAwesomeIcon
            icon={faChevronDown}
            size="sm"
            className={classNames("ml-2 transition-transform duration-200", {
                "rotate-180": isVisible,
            })}
        />
    </Button>
);

export const FilterBlock: FC<FilterBlockProps> = ({
    isFilterVisible,
    onFilterVisible,
    onRemoveFilter,
    filters,
    className,
}) => (
    <div className={classNames("flex items-center gap-5 mb-4", className)}>
        <div>
            <FilterButton
                isVisible={isFilterVisible}
                onClick={onFilterVisible}
            />
        </div>
        <FilterStatusList filters={filters} onRemoveFilter={onRemoveFilter} />
    </div>
);
