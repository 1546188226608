interface IData {
   title: string;
   description: string;
   activeItem: string;
   titleModal: string;
}


export const GetStartedData: IData[] = [
   {
      title: 'Refine your formula',
      description: 'Once you entered this and that you are ready polish your formula , then its ready for a report.',
      activeItem: 'formulas',
      titleModal: 'Create Formula',
   },
   {
      title: 'Start with a Raw Ingredient',
      description: 'You need a formula and give it a name to get to the next step which is bla.',
      activeItem: 'raw_ingredient',
      titleModal: 'Create Raw Ingredient',
   },
   {
      title: 'Enter a Sub Ingredient',
      description: 'You must this and that data log in for your formula to have this and that information.',
      activeItem: 'sun_ingredient',
      titleModal: 'Create Sub Ingredient',
   },
   {
      title: 'Work on Contaminants',
      description: 'This is not mandatory but if your work in this cosmetic issue you may need this and that.',
      activeItem: 'contaminants',
      titleModal: 'Create Contaminants',
   },
];