import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faBell,
    faQuestionCircle,
    faCog,
    faCopy,
    faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { routerKeys } from "common/constants";
import classNames from "classnames";
import { Button } from "../../button";

interface IProps {
    className?: string;
}

export const ActionButtonsHeader: FC<IProps> = ({ className = "" }) => {

    const navigate = useNavigate();
    
    return (
        <div className={classNames(className, "w-full pr-9 py-2 flex items-center justify-end text-[#113261] fixed z-2 right-0 bg-[#f9fafc]")}>
            {/* Search Icon */}
            <Button
                variant="ghost"
                size="sm"
            >
                <FontAwesomeIcon icon={faSearch} size="lg" />
            </Button>

            {/* Copy Icon */}
            <Button
                variant="ghost"
                size="sm"
            >
                <FontAwesomeIcon icon={faCopy} size="lg" />
            </Button>

            {/* Notifications Icon */}
            <Button
                className="relative"
                variant="ghost"
                size="sm"
            >
                <FontAwesomeIcon icon={faBell} size="lg" />
                <span className="absolute top-1 right-1 bg-red-600 text-white text-xs w-4 h-4 rounded-full flex items-center justify-center">
                    3
                </span>
            </Button>

            {/* Settings Icon */}
            <Button
                variant="ghost"
                size="sm"
                onClick={() => navigate(routerKeys.adminPanel)}
            >
                <FontAwesomeIcon icon={faCog} size="lg" />
            </Button>

            {/* Help Icon */}
            <Button
                variant="ghost"
                size="sm"
                onClick={() => navigate(routerKeys.helpDesk)}
            >
                <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            </Button>

            {/* User Icon */}
            <Button
                variant="ghost"
                size="sm"
                onClick={() => navigate(routerKeys.profile)}
            >
                <FontAwesomeIcon icon={faCircleUser} size="2xl" />
            </Button>
        </div>
    );
};