import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
    useCalculateEnvEndpoint,
    useGetSubIngredient,
    useUpdateSubIngredient,
} from "pages/subIngredient/subIngredientProfile/queries";
import { Title } from "components/shared/title";
import { IToxicology } from "common/types";
import { OveralScoreForm } from "./components/forms";
import { TabContentTemplate } from "../../../../../../components/shared/tabs/TabContentTemplate";
import { Button } from "components/shared/button";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import { useQueryClient } from "@tanstack/react-query";
import { emptyToxicology } from "common/data";
import { ErrorToast, LoadingToast } from "components/shared/loadingToast";
import { ISubIngredientResponse } from "pages/subIngredient/subIngredientProfile/libs/types";
import { updateToxicologyState } from "./libs";
import { LeftTable, RightTable } from "./components";

export const EnvironmentalSummaryTab = () => {
    const [toxicology, setToxicology] = useState<IToxicology>(emptyToxicology);
    const [isError, setIsError] = useState(false);

    const queryClient = useQueryClient();
    const subIngredient = useGetSubIngredient();
    const { key, isOwner } = useSubIngredientStore();
    const { mutate: updateSubIngredient, isPending: isPendingUpdate } =
        useUpdateSubIngredient();
    const { mutate: calculateEnvEndpoint, isPending: isPendingCalculate } =
        useCalculateEnvEndpoint();

    useEffect(() => {
        if (subIngredient?.toxicology) {
            setToxicology((curr) =>
                updateToxicologyState(curr, subIngredient.toxicology),
            );
        }
    }, [subIngredient?.toxicology]);

    useEffect(() => {
        if (toxicology) {
            setIsError(false);
            calculateEnvEndpoint(
                { id: `${subIngredient?.id}`, body: toxicology },
                {
                    onSuccess: (response) => {
                        queryClient.setQueryData(
                            key,
                            (prevData: ISubIngredientResponse) => ({
                                ...prevData,
                                toxicology: {
                                    ...prevData.toxicology,
                                    ...response,
                                },
                            }),
                        );
                    },
                    onError: () => {
                        setIsError(true);
                    },
                },
            );
        }
    }, [toxicology]);

    const handleUpdate = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value, dataset } = e.target;
            const isBooleanValue = dataset?.type === "boolean";

            setToxicology((curr) =>
                isBooleanValue
                    ? updateToxicologyState(
                          {
                              ...curr,
                              [name]: !Boolean(
                                  toxicology[name as keyof IToxicology],
                              ),
                          },
                          subIngredient?.toxicology as IToxicology,
                      )
                    : updateToxicologyState(
                          {
                              ...curr,
                              [name]: value,
                              [`fix_${name}`]: Boolean(value),
                          },
                          subIngredient?.toxicology as IToxicology,
                      ),
            );
        },
        [toxicology],
    );

    const handleUpdateToxicology = useCallback(() => {
        setIsError(false);

        updateSubIngredient(
            { id: `${subIngredient?.id}`, body: { toxicology } },
            {
                onSuccess: ({ toxicology }) => {
                    queryClient.setQueryData(
                        key,
                        (prevData: ISubIngredientResponse) => ({
                            ...prevData,
                            toxicology,
                        }),
                    );
                },
                onError: () => {
                    setIsError(true);
                },
            },
        );
    }, [toxicology]);

    return (
        <>
            {isPendingCalculate && <LoadingToast />}
            {isError && <ErrorToast />}
            <TabContentTemplate>
                <Title text="Environmental Summary" />
                <div className="w-full flex flex-col">
                    <OveralScoreForm
                        onChange={handleUpdate}
                        data={toxicology}
                    />
                    <div className="md:flex">
                        <LeftTable
                            toxicology={toxicology}
                            handleUpdate={handleUpdate}
                        />
                        <RightTable
                            toxicology={toxicology}
                            handleUpdate={handleUpdate}
                        />
                    </div>
                </div>
                <div className="md:flex md:gap-2 md:pb-5 lg:pb-5 mt-2">
                    {(isOwner || true) && (
                        <>
                            <Button
                                text="Save environmental endpoints"
                                isLoading={isPendingUpdate}
                                onClick={handleUpdateToxicology}
                            />
                            <Button
                                text="Recalculate environmental endpoints"
                                onClick={handleUpdateToxicology}
                            />
                        </>
                    )}
                </div>
            </TabContentTemplate>
        </>
    );
};
