import React, { FC, ReactNode } from 'react'
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { TitleData } from '../libs'
import { useLogout } from 'common/hooks';
import { Button } from 'components/shared';

interface IProps {
   children?: ReactNode;
   stepNumber: number;
}

const styleStep = 'bg-white text-[#113261] font-black w-14 h-14 flex items-center justify-center rounded-full';

export const OnboardingTemplate: FC<IProps> = ({
   children,
   stepNumber,
}) => {
   const { handleLogout } = useLogout();

  return (
    <div className='w-full flex'>
      <div className='w-full'>
         {children}
      </div>
      <aside className='bg-[#113261] text-white min-w-[550px] h-screen flex flex-col justify-center p-12 relative'>
         <div className='text-2.5xl flex flex-col gap-y-12 relative z-10'>
            {
               TitleData.map(({ stepTitle, step }) => (
                  <div key={step} className='flex items-center col gap-x-4 relative z-1 transition-all duration-300'>
                     <div className={classNames(styleStep, {
                        'scale-100 text-opacity-50' : stepNumber !== step,
                        'scale-125': stepNumber === step
                     })}>
                        {
                           step < stepNumber 
                           ? <FontAwesomeIcon className='text-[#113261]' icon={faCheck} size="lg" /> 
                           : step
                        }
                     </div>
                     <span>{stepTitle}</span>
                  </div>
               ))
            }
            <div className='absolute w-1 h-14 bg-white left-[26px] top-14'></div>
            <div className='absolute w-1 h-14 bg-white left-[26px] bottom-14'></div>
         </div>
         <div className='flex gap-x-9 absolute bottom-28 left-1/2 transform -translate-x-1/2'>
            <Button text='Contact support' variant='ghost'/>
            <Button text='Log out' onClick={handleLogout} variant='ghost'/>
         </div>
         <h1 className='absolute right-20 bottom-8 z-2 font-bold text-4xl text-inherit'>SmartSafety</h1>
         <div className="fixed w-[260px] h-[260px] bg-[#22406B] rounded-full top-[-100px] right-[-100px] z-0"></div>
         <div className="fixed w-[260px] h-[260px] bg-[#22406B] rounded-full top-[160px] right-[229px] z-0"></div>
         <div className="fixed w-[260px] h-[260px] bg-[#22406B] rounded-full top-[400px] right-[-100px] z-0"></div>
         <div className="fixed h-[20px] bg-[#22406B] top-[150px] right-[105px] w-[170px] rotate-[140deg] z-0"></div>
         <div className="fixed h-[20px] bg-[#22406B] top-[400px] right-[120px] w-[170px] rotate-[40deg] z-0"></div>
      </aside>
    </div>
  )
}
