import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useRemoveSubIngredient, useUploadSubIngredient } from "./queries";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
    routerKeys,
    defaultSuccessToastUpdate,
    defaultErrorToastUpdate,
    queryKeys,
    wsConfig,
    wsStatus,
} from "common/constants";
import { useURLStateSync } from "common/hooks";
import { getQueryKey } from "common/utils";
import { Header } from "./components";
import { TabsBox } from "components/tabsBox";
import { TabContent } from "./components/tabs/TabContent";
import { LoadingToast } from "components/shared";
import { useSubIngredientStore } from "./store";
import { type SubIngredientTab, TabHeader, tabHeaders } from "./libs";
import { queryClient } from "libraries/queryProvider";
import { ISubIngredientsResponse } from "../subIngredients/libs/types/subIngredientsResponse";

export const SubIngredientProfile = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const { sorting, getParamsString, addURLParams } = useURLStateSync();
    const { setIsOwner, isOwner, setKey } = useSubIngredientStore();
    const socketRef = useRef<WebSocket | null>(null);

    const {
        data: subIngredient,
        isLoading,
        isError,
    } = useUploadSubIngredient(id);

    const initialTab =
        (new URLSearchParams(window.location.search).get(
            "tab",
        ) as SubIngredientTab) || TabHeader.BASIC_INFO;
    const [selectedTab, setSelectedTab] = useState(initialTab);

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as SubIngredientTab);
            addURLParams({ tab });
        },
        [addURLParams],
    );

    useEffect(() => {
        if (id) {
            setKey([queryKeys.subIngredients, id]);
        }
    }, [id, setKey]);

    useEffect(() => {
        setIsOwner(true);
        return () => setIsOwner(false);
    }, [setIsOwner]);

    useEffect(() => {
        const socket = new WebSocket(wsConfig.host);
        socketRef.current = socket;

        socket.onmessage = (event) => {
            const parsedData = JSON.parse(event?.data);

            if (
                parsedData?.status === wsStatus.UPDATED &&
                parsedData.id === +id
            ) {
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.subIngredients, id],
                });
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.subIngredientSDSFiles, id],
                });
            }
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [id]);

    const { mutate } = useRemoveSubIngredient();

    const removeSubIngredient = useCallback(() => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
        const subIngredientsKey = getQueryKey(
            queryKeys.subIngredients,
            sorting,
            getParamsString,
        );
        const previousSubIngredients =
            queryClient.getQueryData<ISubIngredientsResponse>(
                subIngredientsKey,
            );

        queryClient.setQueryData(
            subIngredientsKey,
            (old: ISubIngredientsResponse) => ({
                ...old,
                rows: old?.rows?.filter((item) => `${item.id}` !== id) || [],
            }),
        );

        mutate(
            { id },
            {
                onSuccess: () => {
                    toast.update(toastId, defaultSuccessToastUpdate);
                    navigate(routerKeys.subIngredient);
                },
                onError: () => {
                    queryClient.setQueryData(
                        subIngredientsKey,
                        previousSubIngredients,
                    );
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            },
        );
    }, [id, sorting, getParamsString, mutate]);

    const actualTabs = tabHeaders.filter(
        (tab) => tab !== TabHeader.SKIN_SAFETY || subIngredient?.unique_smiles,
    );

    if (isLoading) return <LoadingToast />;

    if (!subIngredient || isError) {
        isError
            ? toast.error(toastTexts.error, defaultErrorToastOptions)
            : toast.info(toastTexts.dataLack, defaultToastOptions);
        return <ToastContainer />;
    }

    return (
        <div className="ml-0 md:ml-[324px] flex-grow mt-16">
            <Header />
            <TabsBox
                tabs={actualTabs}
                onSelectTab={handleSelectTab}
                selectedTab={selectedTab}
                onRemove={isOwner ? removeSubIngredient : undefined}
            />
            <TabContent selectedTab={selectedTab} />
            <ToastContainer />
        </div>
    );
};
