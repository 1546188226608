import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../button";

interface IProps {
    onSubmit: () => void;
    onRemove: () => void;
    isEditing?: boolean;
    setIsEditing?: Dispatch<SetStateAction<boolean>>;
}

export const ActionButtons: FC<IProps> = ({
    onSubmit,
    onRemove,
    isEditing,
    setIsEditing,
}) => {
    const handleEditClick = useCallback(() => {
        setIsEditing && setIsEditing((state) => !state);
        if (isEditing) {
            onSubmit();
        }
    }, [setIsEditing, isEditing, onSubmit]);

    return (
        <div className="flex items-center gap-2">
            <Button
                variant={isEditing ? "success" : "ghost"}
                size="sm"
                onClick={handleEditClick}
                icon={
                    <FontAwesomeIcon
                        icon={isEditing ? faCheck : faEdit}
                        className={
                            isEditing ? "text-green-700" : "text-gray-600"
                        }
                        size="lg"
                    />
                }
            />

            <Button
                variant="ghost"
                size="xs"
                onClick={onRemove}
                icon={
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        className="text-red-700"
                        size="lg"
                    />
                }
            />
        </div>
    );
};
