import React, { FC } from "react";
import { ConfidenceInner } from "../../ConfidenceInner";

interface IProps {
    value: number;
}

const getConfidenceAttributes = (value: number): [string, string] => {
    if (value < 3.2) return ["prediction-confidence weak", "weak"];
    if (value < 10) return ["prediction-confidence substantial", "substantial"];
    if (value < 32) return ["prediction-confidence strong", "strong"];
    return ["prediction-confidence strong", "very strong"];
};

export const CommonConfidence: FC<IProps> = ({ value }) => {
    const [confidenceClass, confidenceText] = getConfidenceAttributes(value);

    return (
        <ConfidenceInner
            confidenceClass={confidenceClass}
            confidenceText={confidenceText}
            confidenceValue={value.toFixed(4)}
            withoutMaxWidth={true}
        />
    );
};
