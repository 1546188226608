import { AppContent } from "./components/app-content";
import React from "react";
import { QueryProvider } from "./libraries/queryProvider";
import "react-toastify/dist/ReactToastify.css";
import { KeycloakProvider } from "./libraries/keycloakProvider";
import { RouterProvider } from "./libraries/routerProvider";
import { ErrorBoundary } from "./components/error-boundary";
import "./libraries/fontAwesome";

export const App = () => {
    return (
        <ErrorBoundary>
            <KeycloakProvider>
                <QueryProvider>
                    <RouterProvider>
                        <AppContent />
                    </RouterProvider>
                </QueryProvider>
            </KeycloakProvider>
        </ErrorBoundary>
    );
};
