import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, TabContentTemplate, Title } from "components/shared";
import { Dropdown, EndpointContent, ReportButton } from "./components";
import {
    ReportEndpointKey,
    ReportEndpoints,
} from "pages/subIngredient/subIngredientProfile/libs/types";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import {
    useAddSafetyEndpoint,
    useGetSubIngredient,
    useUpdateSubIngredient,
} from "pages/subIngredient/subIngredientProfile/queries";

import { reportParagraphs } from "pages/subIngredient/subIngredientProfile/libs/data";
import { defaultToastOptions, toastTexts } from "common/constants";
import { IEnvironmentalClass } from "common/types";
import { queryClient } from "libraries/queryProvider";

export const ReportSummaryTab = () => {
    const { key, isOwner } = useSubIngredientStore();

    const {
        mutate: updateSubIngredient,
        isPending: isPendingUpdateSubIngredient,
    } = useUpdateSubIngredient();
    const { mutate: addSafetyEndpoint, isPending: isPendingSafetyEndpoint } =
        useAddSafetyEndpoint();

    const subIngredient = useGetSubIngredient();

    const [reportEndpoints, setReportEndpoints] = useState<ReportEndpoints>({
        fulltext: {
            content: subIngredient?.reports?.content || "",
            id: "fulltext",
            label: "Full text",
        },
    });

    useEffect(() => {
        if (subIngredient?.reports && subIngredient.reports.paragraphs.length) {
            for (const paragraph of subIngredient.reports.paragraphs) {
                setReportEndpoints((curr) => ({
                    ...curr,
                    [paragraph.id]: {
                        ...curr[paragraph.safetyEndpoint.id],
                        id: `${paragraph.id}`,
                        content: paragraph.content as string,
                        label: paragraph.safetyEndpoint
                            .name as ReportEndpointKey,
                    },
                }));
            }
        }
    }, [subIngredient]);

    const handleOpenEndpoint = (endpointKey: string) => {
        addSafetyEndpoint(
            {
                id: `${subIngredient?.id}`,
                body: { safetyEndpoint: endpointKey as ReportEndpointKey },
            },
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(key, data);
                },
            },
        );
    };

    const handleChangeEndpointContent = (name: string, value: string) => {
        setReportEndpoints((curr) => ({
            ...curr,
            [name]: { ...curr[name as ReportEndpointKey], content: value },
        }));
    };

    const paragraphs = useMemo(() => {
        const chosenParagraphs = Object.values(reportEndpoints).map(
            (value) => value.label,
        );

        return reportParagraphs.filter(
            (paragraph) => !chosenParagraphs.includes(paragraph),
        );
    }, [reportEndpoints]);

    const handleUpdateSubIngredient = () => {
        const preparedData = Object.entries(reportEndpoints).reduce(
            (acc, [key, value]) => {
                const { id, content } = value;

                return { ...acc, [key]: { id, content } };
            },
            {},
        );

        const preparedObject = {
            reports: {
                content: reportEndpoints.fulltext.content,
                paragraphs: preparedData,
            },
        };

        updateSubIngredient(
            { id: `${subIngredient?.id}`, body: preparedObject },
            {
                onSuccess: (data: IEnvironmentalClass) => {
                    queryClient.setQueryData(key, data);
                    toast.success(
                        `${toastTexts.success} Sub Ingredient has been updated.`,
                        defaultToastOptions,
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, {
                        ...defaultToastOptions,
                        position: "bottom-left",
                    });
                },
            },
        );
    };

    return (
        <>
            <TabContentTemplate>
                <Title text="Report Summary" />

                <form className="w-full">
                    {Object.values(reportEndpoints).map((reportEndpoint) => (
                        <EndpointContent
                            label={reportEndpoint.label}
                            key={reportEndpoint.id}
                            name={reportEndpoint.id}
                            value={reportEndpoint.content}
                            lastModifiedBy={subIngredient?.last_modified_by}
                            onChange={handleChangeEndpointContent}
                            // disabled={!isOwner}
                        />
                    ))}
                </form>
                {(isOwner || true) && (
                    <div className="md:flex lg:flex md:pb-5 lg:pb-5 mt-3 gap-1">
                        <Dropdown
                            isLoading={isPendingSafetyEndpoint}
                            handleOpenEndpoint={handleOpenEndpoint}
                            reportEndpoints={paragraphs}
                            isDisabled={!paragraphs.length}
                        />
                        <Button
                            onClick={handleUpdateSubIngredient}
                            isLoading={isPendingUpdateSubIngredient}
                            text="Save reports information"
                        />
                        <ReportButton />
                    </div>
                )}
            </TabContentTemplate>
        </>
    );
};
