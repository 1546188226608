import React, { FC, useState } from "react";
// import { HamburgerMenu } from "./components";
import classNames from "classnames";
import { DesktopLink } from "components/navbar/components";
import { routerKeys } from "common/constants";
import { ItemTitle } from "./enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { SidebarHeader } from "./components";

const styleItem =
    "block py-1 px-4 font-medium text-white hover:bg-[#09244A] rounded-xl"

export const Sidebar: FC = () => {
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const toggleDropdown = () => {
        setIsOpenDropdown((prev: boolean) => !prev);
    };

    return (
        <div className="relative">
            {/* <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} /> */}

            <aside
                className={classNames(
                    "bg-[#113261] text-white min-w-[260px] md:min-w-[324px] h-screen flex flex-col fixed top-0 left-0 transform",
                  //   isOpen ? "translate-x-0" : "-translate-x-full",
                    "md:translate-x-0 transition-transform duration-300 z-10",
                )}
            >
                <SidebarHeader />
                <nav className=" flex flex-col gap-1 mt-8 px-4 flex-grow relative z-10">
                    <DesktopLink
                        className={styleItem}
                        to={routerKeys.dashboard}
                        text={ItemTitle.DASHBOARD}
                    />
                    <div className="flex justify-between w-full items-center hover:bg-[#09244A] rounded-xl relative">
                        <DesktopLink
                           className={classNames(styleItem, "w-full")}
                           to={routerKeys.formulas}
                           text={ItemTitle.FORMULAS}
                        />
                        {!isOpenDropdown ? 
                            <FontAwesomeIcon
                            size="xl"
                            icon={faChevronDown}
                            className="text-white cursor-pointer absolute right-5"
                            onClick={toggleDropdown}
                            /> : 
                            <FontAwesomeIcon
                            size="xl"
                            icon={faChevronUp}
                            className="text-white cursor-pointer absolute right-5"
                            onClick={toggleDropdown}
                            /> 
                        }                  
                    </div>
                    {isOpenDropdown && (
                        <div className="ml-6 mt-1 space-y-1">
                            <DesktopLink
                                className={classNames(styleItem)}
                                to={routerKeys.rawIngredient}
                                text={ItemTitle.RAW_INGREDIENT}
                            />
                            <DesktopLink
                                className={classNames(styleItem)}
                                to={routerKeys.subIngredient}
                                text={ItemTitle.SUB_INGREDIENT}
                            />
                            <DesktopLink
                                className={classNames(styleItem)}
                                to={routerKeys.contaminants}
                                text={ItemTitle.CONTAMINANTS}
                            />
                        </div>
                    )}
                    <DesktopLink
                        className={styleItem}
                        to={routerKeys.reports}
                        text={ItemTitle.REPORTS}
                    />
                </nav>
                {/* Background */}
                <div className="fixed w-[288px] h-[296px] bg-[#22406B] rounded-full top-[120px] left-[-50px] z-0"></div>
                <div className="fixed w-[288px] h-[296px] bg-[#22406B] rounded-full top-[460px] left-[-144px] z-0"></div>
                <div className="fixed w-[288px] h-[296px] bg-[#22406B] rounded-full top-[760px] right-0 z-0"></div>
                <div className="fixed h-[20px] bg-[#22406B] top-[430px] left-[-5px] w-[75px] rotate-[105deg] z-0"></div>
                <div className="fixed h-[20px] bg-[#22406B] top-[760px] left-[40px] w-[100px] rotate-[55deg] z-0"></div>
            </aside>
        </div>
    );
};
