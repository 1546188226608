import BaseService from "./baseService";
import instanse from "./axios/instance";
import { type IRawIngredientsResponse } from "../pages/rawIngredient";
import {
    type IQuery,
    type IRawIngredient,
    type IStatisticResponse,
} from "../common/types";
import { backendKeys } from "../common/constants";
import { getSortingParams } from "../common/utils";

class RawIngredientsService extends BaseService {
    constructor(
        public baseUrl = backendKeys.rawIngredients,
        private fetchingService = instanse,
    ) {
        super(baseUrl);
    }

    async getAll() {
        return this.handleRequest<IRawIngredient[]>(
            this.fetchingService.get(this.getFullUrl("/all")),
        );
    }

    async getRawIngredients({ queryParams, sorting }: IQuery) {
        const limit = queryParams.includes("limit") ? "" : "&limit=20";
        const sortingParams = getSortingParams(sorting || []);

        return this.handleRequest<IRawIngredientsResponse>(
            this.fetchingService.get(
                this.getFullUrl(`?${queryParams}${limit}${sortingParams}`),
            ),
        );
    }

    async getRawIngredientsStatistic() {
        return this.handleRequest<IStatisticResponse>(
            this.fetchingService.get(this.getFullUrl("/statistic")),
        );
    }

    async fetchSimilarItems(description: string) {
        return this.handleRequest<IRawIngredientsResponse>(
            this.fetchingService.get(this.getFullUrl(`/?query=${description}`)),
        );
    }
}

export const rawIngredientsService = new RawIngredientsService();
