import React, { useCallback, useEffect, useState } from "react";
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { LoadingToast } from "components/shared";
import { TabsBox } from "components/tabsBox";
import { useUploadContaminant, useRemoveContaminant } from "./queries";
import { useContaminantStore } from "./store";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
    routerKeys,
    queryKeys,
    defaultErrorToastUpdate,
} from "common/constants";
import { Header, TabContent } from "./components";
import { getQueryKey } from "common/utils";
import { IContaminantsResponse } from "../contaminants/types";
import { queryClient } from "libraries/queryProvider";
import { useURLStateSync } from "common/hooks";

const contaminantsTab = ["Concentrations"];

export const ContaminantProfilePage = () => {
    const [selectedTab, setSelectedTab] = useState<string>(contaminantsTab[0]);
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(search);

    const { setIsOwner, isOwner, setKey } = useContaminantStore();
    const { data: contaminant, isLoading, isError } = useUploadContaminant(id);
    const { sorting, getParamsString } = useURLStateSync();

    useEffect(() => {
        const tabValue = searchParams.get("tab");
        if (tabValue) {
            setSearchParams({ tab: tabValue });
        }
    }, [searchParams?.get("tab")]);

    useEffect(() => {
        if (id) {
            const queryKey = [queryKeys.contaminants, id];
            setKey(queryKey);
        }
    }, [id]);

    const isUserOwner = keycloak.tokenParsed?.email === contaminant?.created_by;

    useEffect(() => {
        //setIsOwner(isUserOwner);
        setIsOwner(true);

        return () => {
            setIsOwner(false);
        };
    }, [isUserOwner, setIsOwner]);

    const { mutate: removeContaminantMutate } = useRemoveContaminant();

    const removeContaminant = useCallback(() => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);

        const contaminantsKey = getQueryKey(
            queryKeys.contaminants,
            sorting,
            getParamsString,
        );

        const previousContaminants =
            queryClient.getQueryData<IContaminantsResponse>(contaminantsKey);

        queryClient.setQueryData(
            contaminantsKey,
            (old: IContaminantsResponse) => {
                if (!old?.rows) return old;
                return {
                    ...old,
                    rows: old.rows.filter((item) => `${item.id}` !== id),
                    count: (old.count || 0) - 1,
                };
            },
        );

        removeContaminantMutate(
            { id },
            {
                onSuccess: () => {
                    toast.dismiss(toastId);
                    navigate(routerKeys.contaminants);
                },
                onError: () => {
                    if (!previousContaminants) {
                        queryClient.invalidateQueries({
                            queryKey: contaminantsKey,
                        });
                    } else {
                        queryClient.setQueryData(
                            contaminantsKey,
                            previousContaminants,
                        );
                    }

                    toast.update(toastId, defaultErrorToastUpdate);
                },
            },
        );
    }, [id, navigate, removeContaminantMutate, sorting, getParamsString]);

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab);
            searchParams.set("tab", tab);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams],
    );

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!contaminant || isError) {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        } else {
            toast.info(toastTexts.dataLack, defaultToastOptions);
        }

        return <ToastContainer />;
    }

    return (
        <div className="ml-0 md:ml-[324px] flex-grow mt-16">
            <Header />
            <TabsBox
                tabs={contaminantsTab}
                selectedTab={selectedTab}
                onSelectTab={handleSelectTab}
                onRemove={isOwner ? removeContaminant : undefined}
            />
            <TabContent tab={selectedTab} />
            <ToastContainer />
        </div>
    );
};
