import React, { FC, useEffect } from "react";
import { useEditor, EditorContent, Editor as EditorT } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import "./styles.scss";
import classNames from "classnames";

interface IProps {
    value?: string;
    onChange?: (content: string) => void;
    className?: string;
    disabled?: boolean;
    placeholder?: string;
}

interface MenuBar {
    editor?: EditorT | null;
}

const MenuBar: FC<MenuBar> = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="menu-bar">
            {[
                {
                    label: "B",
                    action: () => editor.chain().focus().toggleBold().run(),
                    isActive: editor.isActive("bold"),
                },
                {
                    label: "I",
                    action: () => editor.chain().focus().toggleItalic().run(),
                    isActive: editor.isActive("italic"),
                },
                {
                    label: "S",
                    action: () => editor.chain().focus().toggleStrike().run(),
                    isActive: editor.isActive("strike"),
                },
                {
                    label: "P",
                    action: () => editor.chain().focus().setParagraph().run(),
                    isActive: editor.isActive("paragraph"),
                },
                {
                    label: "H1",
                    action: () =>
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run(),
                    isActive: editor.isActive("heading", { level: 1 }),
                },
                {
                    label: "H2",
                    action: () =>
                        editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run(),
                    isActive: editor.isActive("heading", { level: 2 }),
                },
            ].map((item, index) => (
                <button
                    key={index}
                    onClick={item.action}
                    className={item.isActive ? "is-active" : ""}
                    type="button"
                >
                    {item.label}
                </button>
            ))}
        </div>
    );
};

export const Editor: FC<IProps> = ({
    value = "",
    onChange,
    className,
    disabled,
}) => {
    const editor = useEditor({
        extensions: [StarterKit],
        content: value,
        editable: !disabled,
        onUpdate: ({ editor }) => {
            onChange && onChange(editor.getHTML());
        },
    });

    useEffect(() => {
        if (editor && editor.getHTML() !== value) {
            editor.commands.setContent(value);
        }
    }, [value, editor]);

    return (
        <div className={classNames("editor-wrapper", className)}>
            <MenuBar editor={editor} />
            <EditorContent editor={editor} className="editor-content" />
        </div>
    );
};
