import React, { FC } from "react";
import { Button } from "components/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import 'swiper/css/bundle';
import { useNavigate } from "react-router-dom";
import { IFormulaItem, IFormulasResponse } from "pages/formulas";
import { StatusBlock } from "./components/statusBlock";
import { LifeCycleStage } from "./components/lifeCycleStage";
import { Status } from "./enum/status";


interface IProps {
   openCreateModal?: () => void;
   formulasData?: IFormulasResponse;
   title: string;
}

const FormulaCard: FC<{formula: IFormulaItem}> = ({
   formula: {description, id, status, composition_status}
}) => {
   const navigate = useNavigate()

   return (
      <div className="flex flex-col justify-between w-64 h-80 mr-4 bg-[rgba(217,217,217,0.2)] rounded-lg p-3 relative">
         <div className="flex justify-between items-center">
            <h2 
               className="font-medium text-lg truncate ... w-3/5 cursor-pointer"
               title={description}
            >
               {description}
            </h2>
            <StatusBlock compositionStatus={composition_status}/>
         </div>
         <div>
            <LifeCycleStage status={status}/>
            <Button
               className="mt-auto w-full mb-4 py-2 px-4 border border-[#DDD] rounded-xl hover:bg-[#113261] hover:text-white transition"
               variant="primary"
               onClick={() => navigate(`/formulas/${id}`)}
            >
               {composition_status === Status.INCOMPLETED ? 'Continue' : 'View'}
            </Button> 
         </div>
      </div>
   )
}

export const UpdateCardsBlock: FC<IProps> = ({openCreateModal, formulasData, title}) => {
   const recentlyUpdeted = formulasData?.rows.slice(0, 3)
   return (
      <div className="bg-white min-h-32 flex justify-between shadow-md rounded-lg w-full my-14 p-7">
         <div className="flex flex-col justify-center items-center mr-4 w-64 h-80 bg-[rgba(217,217,217,0.2)] rounded-lg">
            <Button
               variant="ghost"
               onClick={openCreateModal}
            >
               <FontAwesomeIcon size="lg" icon={faCirclePlus} />
            </Button> 
            <div className="font-medium text-lg">{title}</div>           
         </div>
         {
            recentlyUpdeted?.map((formula) => (
               <FormulaCard key={formula.id} formula={formula}/>
            ))
         }
      </div> 
   )
}