import React, {
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useCallback,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { type ItemLimits } from "../libs/types";
import { tableConfig } from "../libs/enums";
import { Button } from "components/shared";

interface IProps {
    title?: string;
    name?: string;
    children?: ReactNode;
    isVisibleMoreButton?: boolean;
    setLimit: Dispatch<SetStateAction<ItemLimits>>;
}

export const TableInfo: FC<IProps> = ({
    title = "",
    name = "",
    children,
    isVisibleMoreButton = false,
    setLimit,
}) => {
    const handleIncreaseLimit = useCallback(
        () =>
            setLimit((curr) => ({
                ...curr,
                [name]:
                    +curr[name as keyof ItemLimits] + tableConfig.initialItems,
            })),
        [name],
    );

    const handleDecreaseLimit = useCallback(() => {
        setLimit((curr) => ({
            ...curr,
            [name]: Math.max(
                +curr[name as keyof ItemLimits] - tableConfig.initialItems,
                tableConfig.initialItems,
            ),
        }));
    }, [name]);

    return (
        <>
            <h3 className="text-lg font-normal uppercase mx-10 mb-4">
                {title}
            </h3>

            <div className="flex justify-end mr-4">
                {isVisibleMoreButton && (
                    <Button
                        onClick={handleDecreaseLimit}
                        variant="ghost"
                    >
                        <FontAwesomeIcon size="lg" icon={faCircleMinus} />
                    </Button>
                )}
            </div>

            {children}
            <div className=" justify-center font-bold p-4 flex md:flex">
                {isVisibleMoreButton && (
                    <Button
                        onClick={handleIncreaseLimit}
                        variant="ghost"
                    >
                        <FontAwesomeIcon size="lg" icon={faCirclePlus} />
                    </Button>                    
                )}
            </div>
        </>
    );
};
