import React, { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
    title: string;
    link: string;
    description: string;
    icon?: string;
}

export const CategoryCard: FC<IProps> = ({
    title,
    link,
    description,
    icon,
}) => (
    <Link
        to={link}
        className="bg-primary-main-white overflow-hidden m-2 cursor-pointer shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] transition-transform transform hover:scale-105 bg-hover-color"
    >
        <div className="p-3 w-[250px] h-[272px] bg-white flex flex-col items-center" >
            <img src={icon} alt="icon"/>
            <h3 className="py-2 font-semibold text-lg">
               {title}
            </h3>
            <p className="font-medium text-sm text-[#A1A1A1] text-center">{description}</p>
        </div>
    </Link>
);
