import React, { ChangeEvent, FC } from "react";
import { type FormikErrors } from "formik";
import classNames from "classnames";
import { InputLabel } from "../InputLabel";
import { InputBlock } from "../InputBlock";
import { renderValueWithNonZeroDigits } from "../../libs";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { SectionHeader } from "../SectionHeader";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const RegressionTab: FC<IProps> = ({ values, errors, onChange }) => (
    <div className="flex flex-col gap-[20px]">
        <section className="flex flex-col gap-[40px]">
            <SectionHeader
                title="Enter phys-chem property"
                text="Mandatory for making the prediction."
            />

            <div className="flex flex-col flex-nowrap gap-2 mb-10">
                <RequiredDescription />
                <div className="flex flex-col gap-5 justify-between w-1/3 px-1 max-w-[330px]">
                    <InputLabel
                        text="Vapour pressure (Pa)"
                        tooltipText="Vapour pressure of the compound measured in Pa."
                        errorText={errors?.vpMultipleRegression}
                        isRequired
                    >
                        <input
                            className={classNames(inputClassName, "bg-gray-50")}
                            type="text"
                            name="vpMultipleRegression"
                            aria-label="Vapour pressure (Pa)"
                            onChange={onChange}
                            value={renderValueWithNonZeroDigits(
                                values?.vpMultipleRegression,
                            )}
                        />
                    </InputLabel>
                </div>
            </div>
        </section>

        <section className="flex flex-col gap-[40px]">
            <h2 className="text-xl font-semibold">Enter IN VITRO values</h2>
            <p className="text-sm text-gray-600">
                Input data for assays associated with three key events in the
                AOP for Skin sensitisation. Recommended to improve prediction
                estimate.
            </p>
            <div className="flex flex-col flex-nowrap gap-2 mb-10">
                <RequiredDescription />

                <div className="flex flex-wrap gap-5">
                    <InputBlock
                        title="Covalent binding to skin proteins"
                        subtitle="Cor1-C420"
                    >
                        <InputLabel
                            text="Kmax (1/mM/min)"
                            tooltipText="Rate constant from the kDPRA assay."
                            errorText={errors?.kmaxMultipleRegression}
                            isRequired
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="kmaxMultipleRegression"
                                aria-label="Kmax (1/mM/min)"
                                onChange={onChange}
                                value={values?.kmaxMultipleRegression}
                            />
                        </InputLabel>
                    </InputBlock>

                    <InputBlock
                        title="Keratinocyte activation"
                        subtitle="KeratinoSens™"
                    >
                        <InputLabel
                            text="EC1.5 (µM)"
                            tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity."
                            errorText={errors?.keratinoSenseAssayJaworskaKEC15}
                            isRequired
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="keratinoSenseAssayJaworskaKEC15"
                                aria-label="EC1.5 (µM)"
                                onChange={onChange}
                                value={values?.keratinoSenseAssayJaworskaKEC15}
                            />
                        </InputLabel>

                        <InputLabel
                            text="IC50 (µM)"
                            tooltipText="Concentration yielding 50% reduction in cellular viability in the KeratinoSens™ assay."
                            errorText={errors?.keratinoSenseAssayJaworskaIC50}
                            isRequired
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="keratinoSenseAssayJaworskaIC50"
                                aria-label="IC50 (µM)"
                                onChange={onChange}
                                value={values?.keratinoSenseAssayJaworskaIC50}
                            />
                        </InputLabel>
                    </InputBlock>
                </div>
            </div>
        </section>
    </div>
);
