import React, { ChangeEvent, useCallback } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Title } from "components/shared";
import { LeftTable, RightTable, SourceLink } from "./components";
import { TabContentTemplate } from "../../../../../../components/shared/tabs/TabContentTemplate";
import { useUpdateSubIngredient } from "pages/subIngredient/subIngredientProfile/queries";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";

import FileUpload from "./components/FileUpload";

export const BasicInfoTab = () => {
    const { key, isOwner } = useSubIngredientStore();
    const { mutate: updateSubIngredient, isPending } = useUpdateSubIngredient();
    const queryClient = useQueryClient();

    const { data, setData, subIngredient } = useSubIngredientData(key);

    const handleChangeData = useCallback(
        (
            e: ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
            >,
        ) => {
            const { name, value } = e.target;

            setData((curr) => ({
                ...curr,
                [name]: value,
            }));
        },
        [],
    );

    const handleChangeMultiSelect = useCallback(
        (selected: string[], name: string) => {
            setData((curr) => ({
                ...curr,
                [name]: selected.join(","),
            }));
        },
        [],
    );

    const handleSubmit = useCallback(() => {
        updateSubIngredient(
            { id: `${subIngredient?.id}` || "", body: data },
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(key, data);
                    setData(data);
                    toast.success(
                        `${toastTexts.success} INCI has been updated.`,
                        defaultToastOptions,
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [data, subIngredient?.id]);

    // const handleSDSDownloadClick = useCallback(() => {
    //     if (!id) return;
    //     retrieveSDS(id, {
    //         onSuccess: (
    //             response: AxiosResponse<{ filename: string; stream: Blob }>,
    //         ) => {
    //             const { filename, stream } = response.data;
    //             const url = URL.createObjectURL(stream);
    //             const link = document.createElement("a");
    //             link.href = url;
    //             link.setAttribute("download", filename);
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //         },
    //         onError: (error) => {
    //             console.error("Error fetching SDS:", error);
    //         },
    //     });
    // }, [id]);

    return (
        <TabContentTemplate>
            <div className="flex justify-between">
                <div className="">
                    <Title text="Basic Information" />
                    <FileUpload />
                </div>

                <SourceLink />
            </div>
            <div className="md:flex">
                <div className="w-full flex">
                    <LeftTable
                        data={data}
                        smiles={subIngredient?.unique_smiles}
                        onChangeData={handleChangeData}
                        onChangeMultiSelect={handleChangeMultiSelect}
                    />

                    <RightTable data={data} onChangeData={handleChangeData} />
                </div>
            </div>

            <Button
                isOwner={isOwner}
                classNameContainer="md:flex lg:pb-5 mt-2"
                isLoading={isPending}
                onClick={handleSubmit}
                text="Save basic information"
            />
        </TabContentTemplate>
    );
};
