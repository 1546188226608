import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { defaultToastOptions, routerKeys, toastTexts } from "common/constants";
import { toast } from "react-toastify";
import { useGetProfileInfo } from "pages/profile/libs";
import { LoadingToast } from "components/shared";

export const LoginPage = () => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { data: profileInfo, isLoading: isProfileLoading  } = useGetProfileInfo();
    
    const isAuthenticated = Boolean(keycloak.authenticated);

    useEffect(() => {
        if (!isAuthenticated) return;
        if (isProfileLoading || profileInfo === undefined) return;
    
        if (Array.isArray(profileInfo.goals) && profileInfo.goals.length === 0) {
            navigate(routerKeys.onboarding, { replace: true });
        } else {
            navigate(routerKeys.formulas, { replace: true });
        }
    }, [isAuthenticated, isProfileLoading, profileInfo, navigate]);

    useEffect(() => {
        const checkInitialization = async () => {
            if (!initialized) {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );
                try {
                    await keycloak.init({ onLoad: "check-sso" });
                } catch (error) {
                    toast.error(
                        "Failed to initialize authentication",
                        defaultToastOptions,
                    );
                } finally {
                    toast.dismiss(toastId);
                }
            }
        };

        checkInitialization();
    }, [initialized, keycloak]);

    const handleLogin = useCallback(async () => {
        try {
            setIsLoading(true);
            await keycloak.login({
                redirectUri: window.location.origin + routerKeys.formulas,
            });
        } catch (error) {
            toast.error("Login failed. Please try again.", defaultToastOptions);
        } finally {
            setIsLoading(false);
        }
    }, [keycloak]);

    if (isAuthenticated && isProfileLoading) {
        return (
            <LoadingToast/>
        );
    }

    return (
        <div className="flex flex-grow items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
            {!initialized ? (
                <div className="flex flex-col items-center justify-center">
                    <svg
                        className="h-8 w-8 animate-spin text-[#113261]"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                        />
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                    <p className="mt-4 text-[#113261]">
                        Initializing authentication...
                    </p>
                </div>
            ) : (
                <div className="w-full max-w-lg space-y-8 rounded-xl bg-white p-14 shadow-2xl transition-all duration-300">
                    <div className="mb-4">
                        <h2 className="text-center text-3xl font-bold tracking-tight text-[#113261]">
                            SmartSafety
                        </h2>
                        <h5 className="m-4 text-center text-lg font-medium text-[#113261]">
                            Welcome!
                        </h5>
                    </div>
                    <button
                        className="group relative flex w-full justify-center rounded-md bg-[#113261] px-4 py-3 text-base font-semibold text-white transition-colors hover:bg-[#0d274d] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-70"
                        onClick={handleLogin}
                        disabled={isLoading || !initialized}
                    >
                        {isLoading ? (
                            <span className="flex items-center">
                                <svg
                                    className="mr-2 h-5 w-5 animate-spin"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                        fill="none"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                Logging in...
                            </span>
                        ) : (
                            "Log In"
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};
