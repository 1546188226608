import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface IProps {
    placeholder?: string;
    className?: string;
    searchValue?: string;
    query?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    updateSearch?: (value: string) => void;
}

const DEBOUNCE_DELAY = 400;

const defaultPlaceholder =
    "Search formula, raw ingredient, sub ingredient or contaminant";

const baseInputStyles =
    "text-lg subtitle-sm w-full h-14 pl-10 pt-0.5 border-0 focus:border-0 rounded-lg shadow-sm outline-none";
const baseIconStyles =
    "absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400";

export const SearchBlock: React.FC<IProps> = ({
    placeholder = defaultPlaceholder,
    className,
    searchValue = "",
    onChange,
    updateSearch,
    query
}) => {

    const [value, setValue] = useState(searchValue);

    useEffect(() => {
        setValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (!updateSearch) return;

        let isActive = true;
        const t = setTimeout(() => {
            if (isActive) {
                updateSearch(value);
            }
        }, DEBOUNCE_DELAY);

        return () => {
            isActive = false;
            clearTimeout(t);
        };
    }, [value, updateSearch]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <div className={classNames(className)}>
            <div className="relative w-full h-14 transition-all duration-200 sm:shadow-lg rounded-lg">
                <FontAwesomeIcon icon={faSearch} className={baseIconStyles} />
                <input
                    type="text"
                    className={baseInputStyles}
                    placeholder={placeholder}
                    value={query ?? value}
                    onChange={onChange ?? handleChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};
