import Note from "assets/images/icons/document-text.svg";
import Stack from "assets/images/icons/stickynote.svg";
import Stick from "assets/images/icons/note-2-1.svg";
import Settings from "assets/images/icons/setting-2.svg";
import Category from "assets/images/icons/category.svg";
import User from "assets/images/icons/user.svg";
import { routerKeys } from "common/constants/routerKeys";

export const MAIN_NAV_ITEMS = [
    { text: "Dashboard", image: Category, to: routerKeys.dashboard },
    { text: "Formulas", image: Note, to: routerKeys.formulas },
    { text: "Raw Ingredient", image: Stack, to: routerKeys.rawIngredient },
    { text: "Sub Ingredient", image: Stick, to: routerKeys.subIngredient },
    { text: "Contaminants", image: Stick, to: routerKeys.contaminants },
];

export const USER_NAV_ITEMS = [
    { text: "", image: User, to: routerKeys.profile },
    { text: "", image: Settings, to: routerKeys.adminPanel },
];

export const MOBILE_NAV_ITEMS = [
    { text: "Formulas", to: routerKeys.formulas },
    { text: "Raw Ingredient", to: routerKeys.rawIngredient },
    { text: "Sub Ingredient", to: routerKeys.subIngredient },
    {
        text: "Admin Panel",
        to: routerKeys.adminPanel,
        className: "my-10 font-bold",
    },
];
