import React, { FC } from "react";
import classNames from "classnames";
import { Status } from "../../enum/status";

interface IProps {
   compositionStatus: string;
}

export const StatusBlock: FC<IProps> = ({ compositionStatus }) => {
   return (
      <div className={classNames(
         "first-letter:uppercase px-1 w-1/3 h-4 text-center rounded-full font-medium text-xxs border",
         compositionStatus === Status.COMPLETED
           ? "bg-[rgba(102,177,42,0.3)] border-[#66B12A] text-[#66B12A]"
           : "bg-[rgba(227,140,39,0.3)] border-[#E38C27] text-[#E38C27]"
       )}>
         {compositionStatus === Status.COMPLETED ? compositionStatus : "Draft"}
       </div>
   )
};