import React, { FC } from "react";
import { IFormula } from "../types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
    toastTexts,
    queryKeys,
    defaultErrorToastUpdate,
    defaultToastOptions,
} from "common/constants";
import { useUpdateFormula } from "../queries";
import { IError } from "common/types";
import { Button, InputWrapper } from "components/shared";
import { queryClient } from "libraries/queryProvider";
import { useURLStateSync } from "common/hooks";
import { getQueryKey } from "common/utils";

interface IProps {
    formula: IFormula;
    handleClose: () => void;
}

const CreateSchema = Yup.object().shape({
    ficode: Yup.string().trim().required("Ficode is required"),
    description: Yup.string().trim().required("Name is required"),
});

export const EditFormulaForm: FC<IProps> = ({ formula, handleClose }) => {
    const { mutate: updateFormula } = useUpdateFormula();
    const { sorting, getParamsString } = useURLStateSync();

    const formik = useFormik({
        initialValues: {
            ficode: formula.ficode,
            description: formula.description || "",
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values, { setFieldError, resetForm }) => {
            const body = {
                ficode: values.ficode.trim(),
                description: values.description.trim(),
            };
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const formulasKey = getQueryKey(
                queryKeys.formulas,
                sorting,
                getParamsString,
            );
            const formulaKey = [queryKeys.formula, `${formula.id}`];
            const previousData = queryClient.getQueryData<IFormula>(formulaKey);

            queryClient.setQueryData<IFormula | undefined>(formulaKey, (old) =>
                old ? { ...old, ...body } : undefined,
            );

            updateFormula(
                { id: formula.id.toString(), body },
                {
                    onSuccess: (updatedFormula) => {
                        toast.dismiss(toastId);
                        queryClient.setQueryData(formulaKey, updatedFormula);
                        queryClient.refetchQueries({
                            queryKey: formulasKey,
                        });
                        resetForm();
                        handleClose();
                    },
                    onError: (err: IError) => {
                        if (previousData) {
                            queryClient.setQueryData(formulaKey, previousData);
                        }
                        const message = err?.response?.data?.message || "";
                        if (message.toLowerCase().includes("ficode")) {
                            setFieldError("ficode", "Ficode must be unique");
                        }
                        if (message.toLowerCase().includes("description")) {
                            setFieldError(
                                "description",
                                "Description must be unique",
                            );
                        }
                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, handleSubmit, resetForm } =
        formik;

    return (
        <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={!!errors.ficode && touched.ficode}
                    error={errors.ficode}
                    label="Ficode"
                >
                    <input
                        type="text"
                        name="ficode"
                        className="w-full input input-bordered"
                        value={values.ficode}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={!!errors.description && touched.description}
                    error={errors.description}
                    label="Name"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-3 mt-4">
                    <Button
                        text="Clear"
                        onClick={() =>
                            resetForm({ values: formik.initialValues })
                        }
                        variant="ghost"
                    />
                    <Button text="Update" type="submit" />
                </div>
            </div>
        </form>
    );
};
