import React from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useSDS } from "pages/subIngredient/subIngredientProfile/queries";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

const FileDownload: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { mutate: retrieveSDS, status } = useSDS();

    const handleFileDownload = () => {
        if (!id) {
            alert("No ID provided.");
            return;
        }

        retrieveSDS(id, {
            onSuccess: (response: AxiosResponse<Blob>) => {
                const contentDisposition =
                    response.headers?.["content-disposition"];
                const filenameMatch =
                    contentDisposition?.match(/filename="?(.+?)"?$/i);
                const filename = filenameMatch
                    ? filenameMatch[1]
                    : "retrieved_sds_files.zip";

                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            onError: (error) => {
                console.error("Error downloading file:", error);
            },
        });
    };

    return (
        <div className="flex items-center gap-2 h-8 mt-2 mb-1 ml-2">
            <Button
                onClick={handleFileDownload}
                className={`flex items-center justify-center gap-2 px-4 py-1 h-8 text-white rounded-md transition-colors ${
                    status === "pending"
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-gray-300"
                }`}
                disabled={status === "pending"}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    className="text-base"
                    color={status === "pending" ? "#9ca3af" : "#2563eb"}
                />
                {status === "pending" ? "Downloading..." : "Download SDS Files"}
            </Button>
        </div>
    );
};

export default FileDownload;
