import React, { FC } from "react";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { InputWrapper } from "components/shared/inputWrapper";
import * as Yup from "yup";
import { Button } from "components/shared/button";
import { useCreateFdaClass } from "pages/formulas/formula-profile/queries";
import { useQueryClient } from "@tanstack/react-query";
import { IError } from "common/types";
import { useKeycloak } from "@react-keycloak/web";

interface IProps {
    onClose: () => void;
    updateFdaClass: (name: string, fdaClassId: string) => void;
}

const CreateSchema = Yup.object().shape({
    code: Yup.string().required("FDA code is required"),
    name: Yup.string().required("FDA name is required"),
});

export const AddFdaClassForm: FC<IProps> = ({ onClose, updateFdaClass }) => {
    const queryClient = useQueryClient();
    const { keycloak } = useKeycloak();
    const { mutate } = useCreateFdaClass();

    const formik = useFormik({
        initialValues: {
            code: "",
            name: "",
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            mutate(
                {
                    ...values,
                    created_by: keycloak?.tokenParsed?.email,
                },
                {
                    onSuccess: (data) => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: "Success! FDA classification has been added.",
                        });

                        updateFdaClass("fda_class", `${data.id}`);
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.fdaClasses],
                        });

                        formik.resetForm();
                        onClose();
                    },
                    onError: (err: IError) => {
                        const message = err?.response?.data?.message || "";

                        if (message.includes("code")) {
                            formik.setFieldError("code", message);
                        } else if (message.includes("name")) {
                            formik.setFieldError("name", message);
                        }
                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, resetForm } = formik;

    const handleResetForm = () => {
        resetForm();
    };

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={Boolean(errors.code && touched.code)}
                    error={errors.code}
                    label="FDA classification code"
                >
                    <input
                        type="text"
                        name="code"
                        className="w-full input input-bordered"
                        value={values.code}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="FDA classification name"
                >
                    <input
                        type="text"
                        name="name"
                        className="w-full input input-bordered"
                        value={values.name}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Clear" onClick={handleResetForm} />
                    <Button text="Create" type="submit" />
                </div>
            </div>
        </form>
    );
};
