interface IArticle {
   title: string;
   description: string;
}

export const articleCardData: IArticle[] = [
   {
      title: 'How to create a Report',
      description: 'learn how to create a report from scratch....',
   },
   {
      title: 'Why is SmartSafety the best tool for Risk Assesors',
      description: 'Designed with experts and constantly tested and improved....',
   },
]