import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import {
    rawIngredientService,
    rawIngredientsService,
    vendorService,
} from "services";
import { ICreateRawIngredientBody, IRawIngredientItem } from "../../types";
import { useRawIngredientsQuery } from "../store";
import { IQuery, ISimilarItem, IStatisticResponse } from "common/types";

export const useUploadRawIngredients = ({ queryParams, sorting }: IQuery) => {
    const sortingKey = sorting?.length
        ? `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`
        : undefined;
    const queryKey = [queryKeys.rawIngredients, queryParams];

    if (sortingKey) {
        queryKey.push(sortingKey);
    }

    return useQuery({
        queryKey,
        queryFn: () =>
            rawIngredientsService.getRawIngredients({ queryParams, sorting }),
        placeholderData: keepPreviousData,
    });
};

export const useLastRawIngredients = ({ queryParams }: IQuery) => {
    const queryKey = [queryKeys.rawIngredients, queryParams];
    const { setKey } = useRawIngredientsQuery();

    return useQuery<IRawIngredientItem[]>({
        queryKey,
        queryFn: () =>
            rawIngredientsService
                .getRawIngredients({ queryParams })
                .then((res) => {
                    setKey(queryKey);
                    return res?.rows;
                }),
        placeholderData: keepPreviousData,
    });
};

export const useGetRawIngredientsStatistic = () => {
    const queryKey = [queryKeys.rawIngredients, queryKeys.statistic];

    return useQuery<IStatisticResponse>({
        queryKey,
        queryFn: () => rawIngredientsService.getRawIngredientsStatistic(),
    });
};

export const useCreateRawIngredient = () =>
    useMutation({
        mutationFn: (payload: ICreateRawIngredientBody) =>
            rawIngredientService.createRawIngredient(payload),
    });

export const useRawIngredient = (rawId: string) =>
    useQuery({
        queryKey: [queryKeys.currentRawIngredient],
        queryFn: () => rawIngredientsService.getAll(),
        select: (data) => data?.find(({ id }) => +rawId === id),
    });

export const useManufactureOptions = () =>
    useQuery({
        queryKey: [queryKeys.manufactureOptions],
        queryFn: () => vendorService.getAll(),
        select: (data) =>
            data.map(({ description }) => ({
                value: description,
                label: description,
            })),
        placeholderData: keepPreviousData,
    });

export const useTradeNameOptions = () =>
    useQuery({
        queryKey: [queryKeys.tradenameOptions],
        queryFn: () => rawIngredientService.getTradeNames(),
    });

export const useFindSimilarItems = (description: string) =>
    useQuery<ISimilarItem[]>({
        queryKey: [queryKeys.similarItems, description],
        queryFn: () =>
            rawIngredientsService.fetchSimilarItems(description).then(
                (data) =>
                    data?.rows?.map(({ id, description }) => ({
                        id,
                        name: description,
                    })) || [],
            ),
        select: (data) => (description ? data.slice(0, 5) : []),
        placeholderData: keepPreviousData,
    });
