import React, { ChangeEvent, useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import { Button, ModalTemplate, Title } from "components/shared";
import {
    AddDeparturePointForm,
    AddToxicityReferenceForm,
    LeftTable,
    RightTable,
} from "./components";
import { TabContentTemplate } from "pages/subIngredient/subIngredientProfile/components";
import {
    useGetSubIngredient,
    useUpdateSubIngredient,
} from "pages/subIngredient/subIngredientProfile/queries";
import { useSubIngredientStore } from "pages/subIngredient/subIngredientProfile/store";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";
import { type IEnvironmentData } from "pages/subIngredient/subIngredientProfile/libs/types";
import { type FormElement } from "common/types/formElement";
import { useModal } from "common/hooks";
import { AddLocalEffectReferenceForm } from "./components/AddLocalEffectReferenceForm";

export const SafetySummaryTab = () => {
    const { key, isOwner } = useSubIngredientStore();
    const { mutate, isPending } = useUpdateSubIngredient();
    const queryClient = useQueryClient();
    const subIngredient = useGetSubIngredient();

    const [data, setData] = useState<IEnvironmentData>({
        noael: subIngredient?.noael || "",
        nesil: subIngredient?.nesil || "",
        ttc: subIngredient?.ttc || "",
        adi: subIngredient?.adi || "",
        rfd: subIngredient?.rfd || "",
        dermal_penetration: subIngredient?.dermal_penetration || "",
        point_of_departure: subIngredient?.point_of_departure || {},
        point_of_departure_select: "NOAEL",
        toxicity_reference: subIngredient?.toxicity_reference || {},
        toxicity_reference_select: "RfD",
        local_effect_reference: subIngredient?.local_effect_reference || {},
        local_effect_reference_select: "NESIL",
        last_modified_by: subIngredient?.last_modified_by || {},
    });

    const {
        modalRef: createModalRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {},
    });

    const {
        modalRef: createModalRefToxicityReference,
        openModal: openModalToxicityReference,
        closeModal: closeModalToxicityReference,
    } = useModal({
        onClose: () => {},
    });

    const {
        modalRef: createModalRefLocalEffectReference,
        openModal: openModalLocalEffectReference,
        closeModal: closeModalLocalEffectReference,
    } = useModal({
        onClose: () => {},
    });

    const handleChangeData = useCallback((e: ChangeEvent<FormElement>) => {
        const { name, value } = e.target;

        setData((curr) => ({
            ...curr,
            [name]: value,
        }));
    }, []);

    const handleChangePointOfDeparture = useCallback(
        (e: ChangeEvent<FormElement>) => {
            const { value } = e.target;
            if (value === "add_value") {
                openModal();
            }
            setData((curr) => ({
                ...curr,
                point_of_departure_select: value,
            }));
        },
        [data?.point_of_departure_select],
    );

    const handleChangeToxicityReference = useCallback(
        (e: ChangeEvent<FormElement>) => {
            const { value } = e.target;
            if (value === "add_value") {
                openModalToxicityReference();
            }
            setData((curr) => ({
                ...curr,
                toxicity_reference_select: value,
            }));
        },
        [data?.toxicity_reference_select],
    );

    const handleChangeLocalReference = useCallback(
        (e: ChangeEvent<FormElement>) => {
            const { value } = e.target;
            if (value === "add_value") {
                openModalLocalEffectReference();
            }
            setData((curr) => ({
                ...curr,
                local_effect_reference_select: value,
            }));
        },
        [data?.local_effect_reference_select],
    );

    const handleChangeValue = useCallback((e: ChangeEvent<FormElement>) => {
        const { value, name } = e.target;
        setData((curr) => ({
            ...curr,

            ...(name === "point_of_departure" && {
                point_of_departure: {
                    ...curr.point_of_departure,
                    [curr.point_of_departure_select.toLowerCase()]: value,
                },
                ...(curr.point_of_departure_select.toLowerCase() ===
                    "noael" && { noael: value }),
            }),

            ...(name === "toxicity_reference" && {
                toxicity_reference: {
                    ...curr.toxicity_reference,
                    [curr.toxicity_reference_select.toLowerCase()]: value,
                },
                ...(curr.toxicity_reference_select.toLowerCase() === "rfd" && {
                    rfd: value,
                }),
                ...(curr.toxicity_reference_select.toLowerCase() === "adi" && {
                    adi: value,
                }),
            }),

            ...(name === "local_effect_reference" && {
                local_effect_reference: {
                    ...curr.local_effect_reference,
                    [curr.local_effect_reference_select.toLowerCase()]: value,
                },
                ...(curr.local_effect_reference_select.toLowerCase() ===
                    "nesil" && { nesil: value }),
            }),
        }));
    }, []);

    const onSubmit = useCallback(() => {
        const { last_modified_by: _, ...body } = data;
        mutate(
            { id: `${subIngredient?.id}` || "", body },
            {
                onSuccess: (data: IEnvironmentData) => {
                    queryClient.setQueryData(key, data);
                    toast.success(
                        `${toastTexts.success} Sub Ingredient has been updated.`,
                        defaultToastOptions,
                    );
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [data, subIngredient?.id, key]);

    return (
        <>
            <ToastContainer />
            <ModalTemplate
                text="Add Point of Departure"
                onClose={closeModal}
                modalRef={createModalRef}
                className="max-w-[600px]"
            >
                <AddDeparturePointForm
                    handleClose={closeModal}
                    setData={setData}
                />
            </ModalTemplate>
            <ModalTemplate
                text="Add Toxicity Reference"
                onClose={closeModalToxicityReference}
                modalRef={createModalRefToxicityReference}
                className="max-w-[600px]"
            >
                <AddToxicityReferenceForm
                    handleClose={closeModalToxicityReference}
                    setData={setData}
                />
            </ModalTemplate>
            <ModalTemplate
                text="Add Local Effect Reference"
                onClose={closeModalLocalEffectReference}
                modalRef={createModalRefLocalEffectReference}
                className="max-w-[600px]"
            >
                <AddLocalEffectReferenceForm
                    handleClose={closeModalLocalEffectReference}
                    setData={setData}
                />
            </ModalTemplate>
            <TabContentTemplate>
                <Title text="Safety Summary" />
                <div className="md:flex">
                    <form className="w-full flex">
                        <LeftTable
                            data={data}
                            onChangeToxicityReference={
                                handleChangeToxicityReference
                            }
                            onChangePointOfDeparture={
                                handleChangePointOfDeparture
                            }
                            handleChangeValue={handleChangeValue}
                        />

                        <RightTable
                            data={data}
                            onChangeData={handleChangeData}
                            handleChangeValue={handleChangeValue}
                            onChangeLocalReference={handleChangeLocalReference}
                        />
                    </form>
                </div>
                <div className="md:flex lg:flex lg:pb-5 mt-2">
                    {(isOwner || true) && (
                        <Button
                            isLoading={isPending}
                            onClick={onSubmit}
                            text="Save safety summary"
                        />
                    )}
                </div>
            </TabContentTemplate>
        </>
    );
};
