import React, { FC } from "react";
import classNames from "classnames";
import {
    type PredictionStatuses,
    type MoleculeDataType,
} from "pages/subIngredient/subIngredientProfile/libs";
import { DataStatusButton, PredictionStatus } from "./components";
import MoleculeDisplay from "./components/MoleculeDisplay";

interface IProps {
    className?: string;
    model: MoleculeDataType;
    isMichaelAcceptor?: boolean;
    dataStatus?: "error" | "idle" | "pending" | "success";
    predictions?: PredictionStatuses;
    errors?: Record<string, string>;
}

export const Prediction: FC<IProps> = ({
    className = "",
    model,
    dataStatus,
    predictions,
    errors,
}) => (
    <div
        className={classNames(
            "w-1/4 space-y-4 border border-gray-300",
            className,
        )}
    >
        <div className="bg-[#dbdbdb] border-t border-[#365c81] text-[#27425d] text-xl uppercase px-4 py-2">
            Prediction
        </div>
        <div className="px-3 pb-3">
            <DataStatusButton
                dataStatus={dataStatus}
                model={model}
                errors={errors}
            />
            <MoleculeDisplay smiles={model?.smiles} />
            <PredictionStatus predictions={predictions} />
        </div>
    </div>
);
