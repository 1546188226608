import React from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useSDS } from "pages/subIngredient/subIngredientProfile/queries";
import {
    defaultErrorToastUpdate,
    defaultToastOptions,
    toastTexts,
} from "common/constants";

const FileDownload: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { mutate: retrieveSDS, status } = useSDS();
    const isLoading = status === "pending";

    const handleFileDownload = () => {
        if (!id) {
            toast.error("Please select a sub-ingredient", defaultToastOptions);
            return;
        }

        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);

        retrieveSDS(id, {
            onSuccess: (data: Blob) => {
                const url = window.URL.createObjectURL(data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "selected_sds_files.zip");
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                toast.dismiss(toastId);
            },
            onError: () => {
                toast.update(toastId, {
                    ...defaultErrorToastUpdate,
                    render: toastTexts.downloadError,
                });
            },
        });
    };

    return (
        <div className="flex items-center gap-2 h-8 mt-2 mb-1 ml-2">
            <Button
                onClick={handleFileDownload}
                className={classNames(
                    "flex items-center justify-center gap-2 px-4 py-1 h-8 text-white rounded-md transition-colors",
                    {
                        "bg-gray-400 cursor-not-allowed": isLoading,
                        "bg-blue-600 hover:bg-gray-300": !isLoading,
                    },
                )}
                disabled={isLoading}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    className="text-base"
                    color={isLoading ? "#9ca3af" : "#2563eb"}
                />
                <span>
                    {isLoading ? "Downloading..." : "Download SDS Files"}
                </span>
            </Button>
        </div>
    );
};

export default FileDownload;
