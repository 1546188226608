import { PageTemplate } from "components/page-template";
import { queryClient } from "libraries/queryProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";

import {
    backendKeys,
    contaminantsContent,
    defaultErrorToastOptions,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { useGetContaminants } from "./queries";
import { CreateContaminantForm, Filters } from "./components";
import { ModalTemplate } from "components/shared/modalTemplate";
import { DataTable, IconLink, SaveButton } from "components/shared";
import { TableContaminant } from "./types";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { modifyDate } from "common/utils";
import { ItemTitle } from "components/sidebar";
import { SearchFilterPanel } from "components/search-filter-panel";
import { 
    useGetSavedData, 
    useGetUserProfile, 
    useSaveDataMutation 
} from "pages/profile/libs";
import { IContaminantsSaved } from "@/common/types";

export const Contaminants = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const {
        data: contaminants,
        isLoading,
        isError,
    } = useGetContaminants({
        queryParams: getParamsString(),
        sorting,
    });

    const { data: userInfo } = useGetUserProfile();
    const { mutate: saveContaminants } = useSaveDataMutation<{ userId: string; contaminantId: number }>(
        backendKeys.api.contaminants.save
    );
    const { data: savedContaminants } = useGetSavedData<IContaminantsSaved>(
        queryKeys.savedContaminants, 
        backendKeys.api.contaminants.saved
    );

    useEffect(() => {
        if (isError) {
            toast.error(toastTexts.error, defaultErrorToastOptions);
        }
    }, [isError]);

    const {
        modalRef: createModalRef,
        openModal,
        closeModal,
    } = useModal();

    const {
        handleUpdateFilters,
        handleFilterVisible,
        handleUpdateSearch,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const formik = useFormik({
                initialValues: {
                    userId: userInfo?.id || "",
                    contaminantId: 0,
                },
        
                onSubmit: (values) => {
                    const toastId = toast.loading(
                        toastTexts.loading,
                        defaultToastOptions,
                    );
        
                    saveContaminants(values, {
                        onSuccess: () => {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: toastTexts.saved,
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.savedContaminants],
                            });
                            queryClient.refetchQueries({
                                queryKey: [queryKeys.fullSavedContaminants],
                            });
                        },
                        onError: () => {
                            toast.update(toastId, defaultErrorToastUpdate);
                        },
                    });
                },
            });

    const handleSaveContaminant = useCallback(
        (contaminantId: number) => {
            if (!userInfo?.id) return;

            formik.setValues({ userId: userInfo.id, contaminantId });
            formik.submitForm();
        },
        [userInfo?.id, formik],
    );

    const columns = useMemo<ColumnDef<TableContaminant>[]>(
        () => [
            {
                accessorKey: "name",
                header: () => <span className="max-w-[200px]">Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[200px]"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    >
                        <span
                            className="truncate ... max-w-[200px]"
                            title={row.getValue("name")}
                        >
                            {row.getValue("name")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Description</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[150px]"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    >
                        <span
                            className="truncate max-w-[150px] cursor-pointer"
                            title={row.getValue("description")}
                        >
                            {row.getValue("description")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                enableSorting: false,
                header: () => <span className="flex justify-end">Save</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex justify-end hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleSaveContaminant}
                        items={savedContaminants || []}
                        attribute="contaminantId"
                        isDisabled
                    />
                ),
            }, 
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.contaminants}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [savedContaminants, saveContaminants?.length, handleSaveContaminant],
    );

    return (
        <PageTemplate 
            title={ItemTitle.CONTAMINANTS}
            filledButtonTitle="Contaminants"
            openCreateModal={openModal}
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Contaminant"
                    onClose={closeModal}
                    modalRef={createModalRef}
                    className="max-w-[600px]"
                >
                    <CreateContaminantForm handleClose={closeModal} />
                </ModalTemplate>
                <div className="w-full px-10">
                    <SearchFilterPanel
                        placeholder={contaminantsContent.placeholder}
                        searchValue={filters?.query?.value}
                        updateSearch={handleUpdateSearch}
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        filters={filters}
                        onRemoveFilter={handleRemoveFilter}
                    />

                    <DataTable
                        data={contaminants?.rows || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={contaminants?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                        styleTable="md:w-5/4 lg:w-5/4"
                    >
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>
                </div>
                {isLoading ? <LoadingToast /> : <ToastContainer />}
            </div>
        </PageTemplate>
    );
};
