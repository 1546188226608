/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Select, { MultiValue } from "react-select";
import "./style.css";

interface IOption {
    value: string;
    label: string;
}

type ChangeHandler = (newValue: IOption | MultiValue<IOption> | any) => void;

interface IProps {
    isLoading: boolean;
    options: MultiValue<IOption>;
    values: MultiValue<IOption> | IOption | null;
    onChange: ChangeHandler;
    isMulty?: boolean;
    onMenuScrollToBottom?: () => void;
    onInputChange?: (newValue: string) => void;
}

export const SearchableSelect: React.FC<IProps> = ({
    isLoading = false,
    options,
    values,
    onChange,
    isMulty = false,
    onMenuScrollToBottom,
    onInputChange,
}) => {
    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={values}
            onChange={onChange}
            options={options}
            isMulti={isMulty}
            isClearable
            isSearchable
            isLoading={isLoading}
            placeholder=""
            onInputChange={(newValue) => {
                onInputChange && onInputChange(newValue);
            }}
            onMenuScrollToBottom={onMenuScrollToBottom}
            styles={{
                control: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                    opacity: isLoading ? 0.5 : 1,
                }),
                option: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                }),
                multiValueRemove: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                }),
                clearIndicator: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    cursor: "pointer",
                }),
            }}
        />
    );
};
