import React, { FC } from "react";
import classNames from "classnames";
import { ModalTemplate } from "components/shared/modalTemplate";
import { Button } from "components/shared/button";
import { useModal } from "common/hooks";

interface IProps {
    tabs: string[];
    selectedTab: string;
    onSelectTab: (tab: string) => void;
    onMarkAsInactive?: () => void;
    inactivityNotification?: string;
}

export const SoftDeleteTabsBox: FC<IProps> = ({
    tabs,
    selectedTab,
    onSelectTab,
    onMarkAsInactive = undefined,
    inactivityNotification = "Are you sure you want to mark this item as inactive? This will prevent it from being used in new contexts but will not affect existing ones.",
}) => {
    const { modalRef: modalInactiveRef, openModal, closeModal } = useModal({});

    const messages = inactivityNotification
        .split(/\n/)
        .map((msg) => msg.trim())
        .filter((msg) => msg.length > 0);

    return (
        <div className="md:mx-5 lg:mx-10 mt-1">
            <div className="tabs tabs-boxed flex flex-wrap" role="tablist">
                {tabs.map((tab) => (
                    <a
                        className={classNames("tab", {
                            "tab-active": selectedTab === tab,
                        })}
                        role="tab"
                        key={tab}
                        onClick={() => onSelectTab(tab)}
                    >
                        {tab}
                    </a>
                ))}
                <>
                    <ModalTemplate
                        text={messages[0]}
                        modalRef={modalInactiveRef}
                        onClose={closeModal}
                        className="max-w-[600px]"
                    >
                        <div className="mb-10">
                            {messages.map(
                                (msg, index) =>
                                    index !== 0 && <p key={index}>{msg}</p>,
                            )}
                        </div>
                        <div className="flex justify-end gap-4">
                            <Button
                                text="Cancel"
                                onClick={closeModal}
                                variant="success"
                                size="sm"
                            />
                            <Button
                                text="Remove"
                                onClick={onMarkAsInactive}
                                variant="danger"
                                size="sm"
                            />
                        </div>
                    </ModalTemplate>

                    {onMarkAsInactive && (
                        <div className="grow flex justify-end">
                            <button
                                className="tab tab-active !text-white duration-300 hover:!text-secondary-danger"
                                onClick={openModal}
                            >
                                Mark as Inactive
                            </button>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
