import React, {
    FC,
    useState,
    useCallback,
    KeyboardEvent,
    ChangeEvent,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {
    ActionButtons,
    Button,
    EditableInput,
    ModalTemplate,
    TableCeil,
} from "components/shared";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/rawIngredient/rawIngredientProfile/queries";
import { IModifiedSubIngredient } from "pages/rawIngredient/types";
import { useSDSFiles } from "pages/subIngredient/subIngredientProfile/queries";
import { useModal, useURLStateSync } from "common/hooks";
import { useGetFormulas } from "pages/formulas/formulas/queries";
import { IFormulaItem } from "pages/formulas";

interface IProps {
    subIngredient: IModifiedSubIngredient;
    totalAmount: number;
}

export const SubIngredientRow: FC<IProps> = ({
    subIngredient,
    totalAmount,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string>();

    const navigate = useNavigate();
    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useRawIngredientStore();

    const { modalRef, openModal, closeModal } = useModal();

    const { sdsFiles = [] } = useSDSFiles(`${subIngredient?.id ?? ""}`);

    const handlePreviewClick = useCallback((link: string) => {
        setSelectedFile(link);
        openModal();
    }, []);

    const navigateToSubIngredientPage = useCallback(() => {
        navigate(`${routerKeys.subIngredient}/${subIngredient.id}`);
    }, [navigate, subIngredient.id]);

       const {
            getParamsString,
        } = useURLStateSync();

    const { data: formulas } = useGetFormulas({
        queryParams: getParamsString(),
    });
    
    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const maxValue =
        +totalAmount === 100
            ? +subIngredient.rawIngredientsSubIngredients.weight_percent
            : 100 -
              +totalAmount +
              +subIngredient.rawIngredientsSubIngredients.weight_percent;

    const validationSchema = Yup.object().shape({
        weight_percent: Yup.number()
            .max(
                maxValue,
                `Weight percent must be less than or equal to ${maxValue}`,
            )
            .min(0, "Weight percent must be greater than or equal to 0")
            .required("Weight percent is required"),
    });

    const formik = useFormik({
        initialValues: {
            weight_percent:
                subIngredient.rawIngredientsSubIngredients.weight_percent,
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: ({ weight_percent }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${subIngredient.id}`,
                    relationType: "subIngredients",
                    body: { value: weight_percent },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.rawIngredient, id],
                        });
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.rawIngredients],
                        })
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.formulas],
                        })
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.lastUpdateFormules],
                        })
                        if (formulas) {
                            const formulaIds = formulas.rows
                                .filter((formula: IFormulaItem) => formula.ri_ids.includes(+id))
                                .map((formula: IFormulaItem) => String(formula.id));
                        
                            console.log({ formulaIds });
                        
                            formulaIds.forEach((formulaId) => {
                                queryClient.refetchQueries({
                                    queryKey: [queryKeys.formula, formulaId],
                                });
                            });
                        }
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = useCallback(() => {
        deleteRelation(
            {
                id,
                ingredientId: `${subIngredient.id}`,
                relationType: "subIngredients",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredients],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.formulas],
                    })
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.lastUpdateFormules],
                    })
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [deleteRelation, id, queryClient, subIngredient.id]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();
                formik.handleSubmit();
                if (formik.isValid) {
                    setIsEditing(false);
                }
            }
        },
        [formik],
    );

    const handleSubmit = useCallback(() => {
        formik.handleSubmit();
        if (formik.isValid) {
            setIsEditing(false);
        }
    }, [formik]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const formattedValue = value.replace(",", ".");
            formik.setFieldValue("weight_percent", formattedValue);
        },
        [formik],
    );

    return (
        <>
            <tr>
                <TableCeil
                    text={subIngredient.sicode}
                    className="w-[16%] py-3 px-4"
                />
                <TableCeil
                    text={subIngredient.name || ""}
                    onClick={navigateToSubIngredientPage}
                    className="w-[30%] py-3 px-4 cursor-pointer hover:text-blue-600"
                />
                <TableCeil className="w-[14%] py-3 px-4">
                    <EditableInput
                        name="weight_percent"
                        value={formik.values.weight_percent}
                        isEditing={isEditing}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="flex justify-center text-center"
                        inputClassName="text-center"
                        displayClassName="font-semibold text-gray-700"
                        autoFocus={isEditing}
                    />
                    {formik.errors.weight_percent &&
                        formik.touched.weight_percent && (
                            <div className="absolute mt-1 text-red-600 text-xs">
                                <span>{formik.errors.weight_percent}</span>
                            </div>
                        )}
                </TableCeil>

                <TableCeil
                    className="w-[20%] py-3 px-4 whitespace-nowrap hidden sm:table-cell"
                    text={subIngredient.cas_number}
                />
                <TableCeil className="w-[15%] py-3 px-4 whitespace-nowrap hidden sm:table-cell">
                    <>
                        <ModalTemplate
                            text="View Details"
                            modalRef={modalRef}
                            onClose={closeModal}
                            className="w-screen h-screen overflow-auto"
                        >
                            {selectedFile ? (
                                <iframe
                                    src={selectedFile}
                                    width="100%"
                                    height="92%"
                                    title="PDF Preview"
                                />
                            ) : (
                                <p className="p-4 text-gray-500">
                                    No file selected
                                </p>
                            )}
                        </ModalTemplate>
                        {sdsFiles?.map((row) => (
                            <Button
                                key={row.id}
                                onClick={() => handlePreviewClick(row.link)}
                                className="mb-1"
                                variant="ghost"
                                size="sm"
                                text={row.supplier}
                                icon={
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="text-red-400"
                                    />
                                }
                                iconPosition="left"
                            />
                        ))}
                    </>
                </TableCeil>

                {(isOwner || true) && (
                    <TableCeil className="w-[5%] py-3 px-4">
                        <ActionButtons
                            onSubmit={handleSubmit}
                            onRemove={handleRemoveIngredient}
                            isEditing={isEditing}
                            setIsEditing={
                                formik.isValid ? setIsEditing : undefined
                            }
                        />
                    </TableCeil>
                )}
            </tr>
        </>
    );
};
