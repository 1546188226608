import { IFormulaItem } from "@/pages/formulas";

export const countCompositionStatus = (formulas: IFormulaItem[] | undefined) => {
   return formulas?.reduce(
     (acc, formula) => {
       if (formula.composition_status === 'completed') {
         acc.completed++;
       } else if (formula.composition_status === 'incompleted') {
         acc.incompleted++;
       }
       return acc;
     },
     { completed: 0, incompleted: 0 }
   );
 }