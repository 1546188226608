import React, { ChangeEvent } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Button, ModalTemplate, Title } from "components/shared";
import {
    useAddFormulaReport,
    useGenerateFormulaReport,
    useGetFormula,
    useGetFormulaReports,
    useRemoveFormulaReport,
} from "pages/formulas/formula-profile/queries";
import { ReportRow } from "./components";
import {
    defaultErrorToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { useModal } from "common/hooks";
import { IReport } from "common/types";

export const ReportTab = () => {
    const [reportFile, setReportFile] = React.useState<IReport>({
        filename: "",
        modified: "",
    });

    const { data: formula } = useGetFormula();
    const queryClient = useQueryClient();
    const { data: reportFiles, refetch } = useGetFormulaReports({
        ficode: formula?.ficode || "",
    });
    const { mutate: addFormulaReport } = useAddFormulaReport();
    const { mutate: generateReport } = useGenerateFormulaReport();
    const { mutate: removeFormulaReport } = useRemoveFormulaReport();
    const { isOwner } = useFormulaProfileState();
    const { openModal, closeModal, modalRef } = useModal({ onClose: () => {} });

    const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const fileSizeLimit = 10485760;

        if (!file) return;

        if (file.size > fileSizeLimit) {
            toast.error(
                `File size exceeds limit. Max allowed size is ${
                    fileSizeLimit / 1048576
                } MB`,
                defaultErrorToastOptions,
            );
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        addFormulaReport(
            {
                ficode: formula?.ficode || "",
                body: formData,
                filename: file.name,
            },
            {
                onSuccess: () => refetch(),
                onError: () =>
                    toast.error(toastTexts.error, defaultErrorToastOptions),
            },
        );
    };

    const handleGenerateReport = (type: string) => {
        generateReport(
            { id: String(formula?.id) || "", type },
            {
                onSuccess: () => refetch(),
                onError: () =>
                    toast.error(toastTexts.error, defaultErrorToastOptions),
            },
        );
    };

    const deleteFile = () => {
        const key = [queryKeys.formulaReports, formula?.ficode || ""];
        closeModal();

        removeFormulaReport(
            { ficode: formula?.ficode || "", filename: reportFile.filename },
            {
                onSuccess: () => {
                    queryClient.setQueryData<IReport[]>(key, (prevData) =>
                        prevData?.filter(
                            (file) =>
                                file.filename !== reportFile.filename &&
                                file.modified !== reportFile.modified,
                        ),
                    );

                    setReportFile({ filename: "", modified: "" });
                    refetch();
                },
                onError: () =>
                    toast.error(toastTexts.error, defaultErrorToastOptions),
            },
        );
    };

    return (
        <>
            <ModalTemplate
                modalRef={modalRef}
                text="Do you really want to delete the report?"
                onClose={closeModal}
                className="max-w-md pt-10"
            >
                <div className="flex items-center justify-end gap-3">
                    <Button
                        text="No"
                        onClick={closeModal}
                        className="btn-error hover:opacity-80 transition-opacity"
                    />
                    <Button
                        text="Yes, delete"
                        onClick={deleteFile}
                        className="bg-[#113261] text-white hover:bg-[#0d274d] transition-colors"
                    />
                </div>
            </ModalTemplate>
            <div className="mx-10 my-3">
                <Title text="Reports files" />
                <table className="w-full bg-grey-100 rounded-lg shadow-lg mb-3">
                    <tbody>
                        {reportFiles?.length ? (
                            reportFiles.map((file) => (
                                <ReportRow
                                    key={file.filename}
                                    reportFile={file}
                                    openModal={openModal}
                                    setReportFile={setReportFile}
                                />
                            ))
                        ) : (
                            <tr>
                                <td className="px-6 py-4 text-sm text-gray-500">
                                    No reports file found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {isOwner && (
                    <div className="flex flex-col md:flex-row gap-6">
                        <div className="dropdown">
                            <label
                                tabIndex={0}
                                className="btn flex items-center bg-gray-700 text-gray-200 uppercase border-none shadow-md transition-colors hover:bg-gray-500"
                            >
                                Generate report
                            </label>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 mt-1 shadow-lg bg-white rounded-lg w-52 border border-gray-100"
                            >
                                <li className="hover:bg-gray-50 rounded-md">
                                    <a
                                        onClick={() =>
                                            handleGenerateReport("csar")
                                        }
                                    >
                                        CSAR
                                    </a>
                                </li>
                                <li className="hover:bg-gray-50 rounded-md">
                                    <a
                                        onClick={() =>
                                            handleGenerateReport("cpsr")
                                        }
                                    >
                                        CPSR
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="flex-1 max-w-xl">
                            <input
                                className="w-full file-input file-input-bordered transition-colors"
                                type="file"
                                accept=".doc, .docx, .pdf, .txt"
                                onChange={handleAddFile}
                            />
                            <label className="label mt-2">
                                <span className="text-sm text-gray-600">
                                    Click here to add a file relevant to the
                                    cosmetic safety report (doc, docx, pdf, txt)
                                    up to 10 MB in size.
                                </span>
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
