import React, { useCallback, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ColumnDef } from "@tanstack/react-table";
import { useFormik } from "formik";
import { queryClient } from "libraries/queryProvider";

import { PageTemplate } from "components/page-template";
import { backendKeys, defaultErrorToastUpdate, defaultSuccessToastUpdate, defaultToastOptions, queryKeys, rawIngredientContent, routerKeys, toastTexts } from "common/constants";
import { useUploadRawIngredients } from "./queries";
import { LoadingToast } from "components/shared/loadingToast";
import { Filters } from "./components";
import { ModalTemplate } from "components/shared/modalTemplate";
import { CreateRawIngredientForm } from "./components";
import {
    DataTable,
    IconLink,
    SaveButton,
    StatusView,
} from "components/shared";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { IRawIngredientItem } from "../types";
import { modifyDate } from "common/utils";
import { ItemTitle } from "components/sidebar";
import { SearchFilterPanel } from "components/search-filter-panel";
import { useGetSavedData, useGetUserProfile, useSaveDataMutation } from "pages/profile/libs";
import { IRawIngredientSaved } from "@/common/types";

export const RawIngredients = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const { data: rawIngredients, isLoading } = useUploadRawIngredients({
        queryParams: getParamsString(),
        sorting,
    });

    const { data: userInfo } = useGetUserProfile();
    const { mutate: saveRawIngredient } = useSaveDataMutation<{ userId: string; rawIngredientId: number }>(
        backendKeys.api.rawIngredients.save
    );
    const { data: savedRawIngredients } = useGetSavedData<IRawIngredientSaved>(
        queryKeys.savedRawIngredient, 
        backendKeys.api.rawIngredients.saved
    );

    const {
        modalRef: createRawIngredientRef,
        openModal,
        closeModal,
    } = useModal();

    const {
        handleUpdateFilters,
        handleFilterVisible,
        handleUpdateSearch,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const formik = useFormik({
            initialValues: {
                userId: userInfo?.id || "",
                rawIngredientId: 0,
            },
    
            onSubmit: (values) => {
                const toastId = toast.loading(
                    toastTexts.loading,
                    defaultToastOptions,
                );
    
                saveRawIngredient(values, {
                    onSuccess: () => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: toastTexts.saved,
                        });
                        queryClient.invalidateQueries({
                            queryKey: [queryKeys.savedRawIngredient],
                        });
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.fullSavedRawIngredient],
                        });
                    },
                    onError: () => {
                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                });
            },
        });

    const handleSaveRawIngredient = useCallback(
        (rawIngredientId: number) => {
            if (!userInfo?.id) return;

            formik.setValues({ userId: userInfo.id, rawIngredientId });
            formik.submitForm();
        },
        [userInfo?.id, formik],
    );

    const columns = useMemo<ColumnDef<IRawIngredientItem>[]>(
        () => [
            {
                accessorKey: "ricode",
                header: () => <span className="max-w-[100px]">RI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[100px]"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... max-w-[100px]"
                            title={row.getValue("ricode")}
                        >
                            {row.getValue("ricode")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[150px]"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... max-w-[150px] cursor-pointer"
                            title={row.getValue("description")}
                        >
                            {row.getValue("description")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        title={row.original.status}
                        active={row.original.status === "Complete"}
                    />
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                enableSorting: false,
                header: () => <span className="flex justify-end">Save</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex justify-end hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleSaveRawIngredient}
                        items={savedRawIngredients || []}
                        attribute="rawIngredientId"
                        isDisabled
                    />
                ),
            },            
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.rawIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [savedRawIngredients, saveRawIngredient?.length, handleSaveRawIngredient],
    );

    return (
        <PageTemplate 
            title={ItemTitle.RAW_INGREDIENT}
            filledButtonTitle="Raw Ingredient"
            openCreateModal={openModal}
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Raw Ingredient"
                    onClose={closeModal}
                    modalRef={createRawIngredientRef}
                    className="max-w-[600px]"
                >
                    <CreateRawIngredientForm handleClose={closeModal} isLink />
                </ModalTemplate>
                <div className="w-full mx-10">
                    <SearchFilterPanel
                        placeholder={rawIngredientContent.placeholder}
                        searchValue={filters?.query?.value}
                        updateSearch={handleUpdateSearch}
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        filters={filters}
                        onRemoveFilter={handleRemoveFilter}
                    >

                    </SearchFilterPanel>

                    <DataTable
                        data={rawIngredients?.rows || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={rawIngredients?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                        styleTable="md:w-3/4 lg:w-3/4"
                    >
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>
                    {isLoading ? <LoadingToast /> : <ToastContainer />}
                </div>
            </div>
        </PageTemplate>
    );
};
