import React from "react";
import { Link } from "react-router-dom";
import { EditWithPapperIcon } from "assets/icons";
import { routerKeys } from "common/constants";
import { ISubIngredient } from "common/types";

interface IProps {
    subIngredient: ISubIngredient;
}

export const TableRow: React.FC<IProps> = ({ subIngredient }) => {
    return (
        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
            <td className="border-grey-light border hover:bg-gray-100 p-3 cursor-pointer">
                <Link to={`${routerKeys.subIngredient}/${subIngredient.id}`}>
                    <EditWithPapperIcon />
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                {subIngredient.sicode}
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 text-red-400 hover:text-red-600 cursor-pointer">
                <Link to={`${routerKeys.subIngredient}/${subIngredient.id}`}>
                    {subIngredient.name}
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                {subIngredient.function
                    ?.split(",")
                    .map((el) => <div key={el}>{el}</div>)}
            </td>
        </tr>
    );
};
