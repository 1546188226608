import React, { ChangeEventHandler, FC, FocusEventHandler } from 'react'

import { InputWrapper } from 'components/shared'
import classNames from 'classnames'

interface IProps {
   error?: string;
   touched?: boolean;
   value: string;
   onChange: ChangeEventHandler<HTMLInputElement>;
   onBlur: FocusEventHandler<HTMLInputElement>;
   readOnly?: boolean
   name: string;
   placeholder: string;
   label: string;
}

export const InputField: FC<IProps> = ({
   error, 
   touched, 
   value, 
   onChange, 
   onBlur, 
   readOnly = false,
   name,
   placeholder,
   label
}) => {
  return (
    <>
      <InputWrapper label={label} required className='mt-2'>
         <input
           type="text"
           className={classNames("w-full input input-bordered", {
             "border-red-600" : error && touched
           })}
           value={value}
           name={name}
           placeholder={placeholder}
           onChange={onChange}
           onBlur={onBlur}
           readOnly={readOnly}
         />
      </InputWrapper>
      {
        error && touched && (
          <div className="absolute text-red-600 text-xs mt-[2px]">
            <span>{error}</span>
          </div>
        )
      }
    </>
  )
}
