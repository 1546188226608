import React, {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useState,
} from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { InputBlock } from "../InputBlock";
import { InputLabel } from "../InputLabel";
import { SectionHeader } from "../SectionHeader";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { FormikErrors } from "formik";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
    skinDoctorCP?: string;
    setSkinDoctorCP?: Dispatch<SetStateAction<string>>;
}

type SelectName = "kDPRA" | "derek" | "derekOECD" | "skinDoctorCPclass";

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

const withoutBlockInputClassName =
    "bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const BDFTab: FC<IProps> = ({
    values,
    errors,
    onChange,
    skinDoctorCP,
    setSkinDoctorCP,
}) => {
    const [isOpenSelect, setIsOpenSelect] = useState({
        kDPRA: false,
        derek: false,
        derekOECD: false,
        skinDoctorCPclass: false,
    });

    const toggleSelectOpen = useCallback((name: SelectName) => {
        setIsOpenSelect((prev) => ({ ...prev, [name]: !prev[name] }));
    }, []);

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            onChange({
                target: {
                    name: event.target.name,
                    value: event.target.value,
                },
            } as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
        },
        [onChange],
    );

    const handleSkinDoctorChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            setSkinDoctorCP?.(event.target.value);
        },
        [setSkinDoctorCP],
    );

    return (
        <div className="flex flex-col gap-[20px]">
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Review molecular descriptors"
                    text="Values are calculated based on validated QSAR model. Please modify the values if missing or you have better estimates."
                />
                <div className="flex flex-col flex-nowrap gap-2 mb-10">
                    <RequiredDescription />
                    <div className="flex flex-wrap gap-10">
                        <InputLabel
                            text="Protein binding (%)"
                            tooltipText="Percent of compound bound to plasma-proteins calculated using OPERA QSAR model."
                            errorText={errors.operaProteinBinding}
                            isRequired
                        >
                            <input
                                className={withoutBlockInputClassName}
                                type="text"
                                name="operaProteinBinding"
                                onChange={onChange}
                                value={values.operaProteinBinding}
                            />
                        </InputLabel>

                        <InputLabel
                            text="logD @ pH7"
                            tooltipText="Octanol/water partition coefficient at pH 7 calculated using OPERA QSAR model."
                            errorText={errors.operaLogDbdf}
                            isRequired
                        >
                            <input
                                className={withoutBlockInputClassName}
                                type="text"
                                name="operaLogDbdf"
                                onChange={onChange}
                                value={values.operaLogDbdf}
                            />
                        </InputLabel>

                        <InputLabel
                            text="Water solubility @ pH7 (M)"
                            tooltipText="Aqueous solubility at pH 7 calculated using OPERA QSAR model."
                            errorText={errors.operaWsBDF}
                            isRequired
                        >
                            <input
                                className={withoutBlockInputClassName}
                                type="text"
                                name="operaWsBDF"
                                onChange={onChange}
                                value={values.operaWsBDF}
                            />
                        </InputLabel>
                    </div>
                </div>
            </section>
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Enter experimental values"
                    text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                    text2="Recommended to improve prediction estimate."
                />

                <div className="primary-color text-base">
                    <span className="font-bold">Note:</span>
                    To calculate models, at least two optional parameters should
                    be entered.
                </div>

                <div className="flex flex-wrap gap-5">
                    <InputBlock
                        title="Covalent binding to skin proteins"
                        subtitle="DPRA"
                    >
                        <InputLabel
                            text="DPRACys (% depleted)"
                            tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                            errorText={errors.dpraAssayDPRACys}
                        >
                            <input
                                type="text"
                                className={inputClassName}
                                name="dpraAssayDPRACys"
                                data-type="number"
                                onChange={onChange}
                                value={values.dpraAssayDPRACys}
                            />
                        </InputLabel>
                        <InputLabel
                            text="DPRALys (% depleted)"
                            tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                            errorText={errors.dpraAssayDPRALys}
                        >
                            <input
                                type="text"
                                className={inputClassName}
                                name="dpraAssayDPRALys"
                                onChange={onChange}
                                value={values.dpraAssayDPRALys}
                            />
                        </InputLabel>
                        <span className="text-divider">kDPRA</span>
                        <InputLabel
                            text="logKmax (1/sM)"
                            tooltipText="Rate constant from the kDPRA assay."
                            errorText={errors.kdpraBDFlogKmax}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="kdpraBDFlogKmax"
                                onChange={onChange}
                                value={values.kdpraBDFlogKmax}
                            />
                        </InputLabel>
                        <InputLabel
                            text="kDPRA classification"
                            tooltipText="Skin sensitiser potency class from kDPRA assay."
                            errorText={errors.kdpraBDF}
                            isFull
                        >
                            <Select
                                className="custom-select"
                                open={isOpenSelect.kDPRA}
                                onClick={() => toggleSelectOpen("kDPRA")}
                                name="kdpraBDF"
                                value={values.kdpraBDF || ""}
                                onChange={handleSelectChange}
                                fullWidth
                                displayEmpty
                            >
                                <MenuItem value="1B/NC">
                                    GHS Cat 1B/ not classified
                                </MenuItem>
                                <MenuItem value="1A">GHS Cat 1A</MenuItem>
                            </Select>
                        </InputLabel>
                    </InputBlock>

                    <InputBlock
                        title="Keratinocyte activation"
                        subtitle="KeratinoSens™"
                    >
                        <InputLabel
                            text="EC1.5 (µM)"
                            tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input. If the EC1.5 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC1.5."
                            errorText={errors.keratinoSenseAssayJaworskaKEC15}
                        >
                            <input
                                type="text"
                                className={inputClassName}
                                name="keratinoSenseAssayJaworskaKEC15"
                                onChange={onChange}
                                value={values.keratinoSenseAssayJaworskaKEC15}
                            />
                        </InputLabel>
                        <InputLabel
                            text="EC3 (µM)"
                            tooltipText="Concentration yielding 3-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input. If the EC3 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC3."
                            errorText={errors.keratinoSenseAssayJaworskaKEC3}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="keratinoSenseAssayJaworskaKEC3"
                                onChange={onChange}
                                value={values.keratinoSenseAssayJaworskaKEC3}
                            />
                        </InputLabel>
                        <InputLabel
                            text="IC50 (µM)"
                            tooltipText="Concentration yielding 50 % reduction in cellular viability in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input."
                            errorText={errors.keratinoSenseAssayJaworskaIC50}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="keratinoSenseAssayJaworskaIC50"
                                onChange={onChange}
                                value={values.keratinoSenseAssayJaworskaIC50}
                            />
                        </InputLabel>
                    </InputBlock>

                    <InputBlock
                        title="Dendritic cell activation"
                        subtitle="h-CLAT"
                    >
                        <InputLabel
                            text="EC150 (ug/mL)"
                            tooltipText="Concentration yielding 150 % induction of the cell surface activation marker CD86 in the h-CLAT assay. If the compound was inactive, use the concentration of 5000 ug/mL as an input."
                            errorText={errors.hClatAssayEC150}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="hClatAssayEC150"
                                onChange={onChange}
                                value={values.hClatAssayEC150}
                            />
                        </InputLabel>
                        <InputLabel
                            text="EC200 (ug/mL)"
                            tooltipText="Concentration yielding 200 % induction of the cell surface activation marker CD54 in the h-CLAT assay. If the compound was inactive, use the concentration of 5000 ug/mL as an input."
                            errorText={errors.hClatAssayEC200}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="hClatAssayEC200"
                                onChange={onChange}
                                value={values.hClatAssayEC200}
                            />
                        </InputLabel>
                        <InputLabel
                            text="CV75 (ug/mL)"
                            tooltipText="Concentration yielding 25 % reduction in cell viability in the h-CLAT assay. If the compound was inactive, use the concentration of 5000 ug/mL as an input."
                            errorText={errors.hClatAssayCV75}
                        >
                            <input
                                className={inputClassName}
                                type="text"
                                name="hClatAssayCV75"
                                onChange={onChange}
                                value={values.hClatAssayCV75}
                            />
                        </InputLabel>
                    </InputBlock>

                    <InputBlock title="In silico" subtitle="Skin Doctor CP">
                        <InputLabel
                            text="Sensitiser potency"
                            tooltipText="Skin sensitisation class for the compound as predicted by the Skin Doctor CP model."
                            errorText={errors.hClatAssayCV75}
                            isFull
                        >
                            <Select
                                className="custom-select"
                                open={isOpenSelect.skinDoctorCPclass}
                                onClick={() =>
                                    toggleSelectOpen("skinDoctorCPclass")
                                }
                                value={skinDoctorCP || ""}
                                onChange={handleSkinDoctorChange}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value="">-</MenuItem>
                                <MenuItem value="1">Non-sensitiser</MenuItem>
                                <MenuItem value="2">
                                    Weak to moderate sensitiser
                                </MenuItem>
                                <MenuItem value="3">
                                    Strong to extreme sensitiser
                                </MenuItem>
                            </Select>
                        </InputLabel>
                        <span className="text-divider">Derek Nexus</span>
                        <InputLabel
                            text="Sensitiser potency"
                            tooltipText="ECETOC skin sensitisation class for the compound as predicted by the Derek Nexus software."
                            errorText={errors.derekBDFb}
                        >
                            <Select
                                className="custom-select"
                                open={isOpenSelect.derek}
                                onClick={() => toggleSelectOpen("derek")}
                                name="derekBDFb"
                                value={values?.derekBDFb || ""}
                                onChange={handleSelectChange}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value="">-</MenuItem>
                                <MenuItem value="1">Non-sensitiser</MenuItem>
                                <MenuItem value="2">Weak sensitiser</MenuItem>
                                <MenuItem value="3">
                                    Moderate sensitiser
                                </MenuItem>
                                <MenuItem value="4">Strong sensitiser</MenuItem>
                                <MenuItem value="5">
                                    Extreme sensitiser
                                </MenuItem>
                            </Select>
                        </InputLabel>
                    </InputBlock>
                </div>
            </section>
        </div>
    );
};
