import "jspdf-autotable";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { ReportData } from "../../pages/formulas/formula-profile/components/tabs/mosTab/types";
import { MOSReportDocxTemplate, MOSReportTemplate } from "./templates";

export type ReportFormat = "pdf" | "docx";

export class MosReportService {
    async generateMOSReport(
        reportData: ReportData,
        format: ReportFormat = "pdf",
    ): Promise<void> {
        try {
            if (!reportData || !reportData.productId) {
                throw new Error("Invalid report data");
            }

            if (format === "pdf") {
                const template = new MOSReportTemplate();
                const pdf = template.generate(reportData);
                pdf.save(`mos_report_${reportData.productId}.pdf`);
            } else {
                const template = new MOSReportDocxTemplate();
                const doc = await template.generate(reportData);
                const blob = await Packer.toBlob(doc);
                const fileName = `mos_report_${reportData.productId}.docx`;
                saveAs(blob, fileName);
            }
        } catch (error) {
            console.error("Error generating document:", error);
            throw error;
        }
    }
}
