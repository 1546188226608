import classNames from "classnames";
import React from "react";

interface IProps {
    checkedValue: string | null;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    values?: string[];
    isDisabled?: boolean;
    className?: string;
    id?: string;
}

export const TableRadioButtonsCeil: React.FC<IProps> = ({
    id,
    checkedValue,
    onChange,
    name,
    values = ["yes", "no"],
    isDisabled = false,
    className = "",
}) => (
    <div className={classNames("flex p-3", className)}>
        {values.map((value) => (
            <label key={value} className="flex items-center">
                <input
                    id={id}
                    type="radio"
                    name={name}
                    value={value}
                    className="radio"
                    onChange={onChange}
                    checked={checkedValue === value}
                    disabled={isDisabled}
                />
                <p className="px-4 uppercase">{value}</p>
            </label>
        ))}
    </div>
);
