interface IData {
   pageTitle: string;
   stepTitle: string;
   step: number;
}

export const TitleData: IData[] = [
   {
      pageTitle: 'Enter your profile information',
      stepTitle: 'Enter your profile information',
      step: 1,
   },
    {
      pageTitle: 'Tell us about your business so we can tailor your experience',
      stepTitle: 'Tell us about your business',
      step: 2,
   },
   {
      pageTitle: 'Tell us what you’re here to do ',
      stepTitle: 'Tell us what you’re here to do',
      step: 3,
   },
]