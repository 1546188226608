import { useCallback } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { defaultErrorToastOptions } from "common/constants";
import { IParsedFormula } from "../types";
import { parseIngredients } from "../components/uploadFormula/libs";

interface FileProcessorOptions {
    maxSize: number;
    acceptedFormats: string[];
}

interface UseFileProcessorProps {
    onSuccess: (result: IParsedFormula) => void;
    options: FileProcessorOptions;
}

export const useFileProcessor = ({
    onSuccess,
    options,
}: UseFileProcessorProps) => {
    const processFile = useCallback(
        (file: File) => {
            try {
                if (file.size > options.maxSize) {
                    throw new Error(
                        `File size should not exceed ${options.maxSize / 1024 / 1024}MB`,
                    );
                }

                const extension = `.${file.name.split(".").pop()?.toLowerCase()}`;
                if (!options.acceptedFormats.includes(extension)) {
                    throw new Error("Invalid file format");
                }

                const reader = new FileReader();
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    try {
                        const arrayBuffer = event.target?.result as ArrayBuffer;
                        if (!arrayBuffer)
                            throw new Error("Failed to read file");

                        const data = new Uint8Array(arrayBuffer);
                        const workbook = XLSX.read(data, {
                            type: "array",
                            cellDates: false,
                            cellNF: false,
                            cellText: false,
                        });

                        const sheetData = XLSX.utils.sheet_to_json(
                            workbook.Sheets[workbook.SheetNames[0]],
                            { header: 1, raw: true },
                        ) as Record<string, string>[];

                        const headerRow = sheetData.find((row) =>
                            Object.values(row).includes("Supplier"),
                        );

                        if (!headerRow) {
                            throw new Error(
                                "Invalid file format: Supplier column not found",
                            );
                        }

                        const headerRowKeys = Object.fromEntries(
                            Object.entries(headerRow).map(([key, value]) => [
                                value?.trim(),
                                key,
                            ]),
                        );

                        const { description, items } = parseIngredients(
                            sheetData,
                            headerRowKeys,
                        );

                        onSuccess({
                            ficode: "",
                            description,
                            rawIngredients: items,
                        });
                    } catch (error) {
                        toast.error(
                            error instanceof Error
                                ? error.message
                                : "Failed to process file",
                            defaultErrorToastOptions,
                        );
                    }
                };

                reader.readAsArrayBuffer(file);
            } catch (error) {
                toast.error(
                    error instanceof Error
                        ? error.message
                        : "Failed to upload file",
                    defaultErrorToastOptions,
                );
            }
        },
        [onSuccess, options],
    );

    return { processFile };
};
