import React, { Dispatch, FC, SetStateAction } from "react";
import classNames from "classnames";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { SubIngredientMOSTable } from "./SubIngredientMOSTable";
import { TotalWeightRow } from "./TotalWeightRow";
import { IManualInput } from "../types";
import { TableHeaderCeil } from "components/shared";
import { TabTable } from "pages/rawIngredient/rawIngredientProfile/components";

const TABLE_HEADERS = [
    {
        title: "SI Code / Name",
        tooltip: "",
        className: "py-2 pl-1",
    },
    {
        title: "Total weight",
        tooltip:
            "Total weight is the sum of all individual sub-ingredients' weights in the formula.",
        className: "w-[12%]",
    },
    {
        title: "Skin Reten.",
        tooltip:
            "Skin Retention is the percentage of the applied product that remains on the skin after application.",
        className: "w-[12%]",
    },
    {
        title: "Dermal Penetr.",
        tooltip:
            "Dermal Penetration indicates how much of the product penetrates into the skin.",
        className: "w-[12%]",
    },
    {
        title: "Daily Exposure",
        tooltip:
            "Daily Exposure represents the amount of product applied daily based on standard usage patterns.",
        className: "w-[12%]",
    },
    {
        title: "Systemic Exp.",
        tooltip:
            "Systemic Exposure is the amount of the product that enters systemic circulation.",
        className: "w-[12%]",
    },
    {
        title: "NOAEL",
        tooltip:
            "NOAEL (No Observed Adverse Effect Level) is the highest dose at which no adverse effects are observed.",
        className: "w-[12%]",
    },
    {
        title: "MOS",
        tooltip:
            "MOS (Margin of Safety) is the ratio of NOAEL to the estimated daily exposure. A higher MOS indicates a safer margin.",
        className: "w-[12%]",
    },
] as const;

interface IProps {
    mosSubIngredients: IPreparedSubIngredient[];
    manualInputs: IManualInput[];
    onChange: Dispatch<SetStateAction<IManualInput[]>>;
}

export const Table: FC<IProps> = ({
    mosSubIngredients,
    manualInputs,
    onChange,
}) => {
    const { subIngredientsTotalWeight } = useFormulaProfileState();

    return (
        <TabTable>
            <thead>
                <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                    {TABLE_HEADERS.map(({ title, className, tooltip }) => (
                        <TableHeaderCeil
                            key={title}
                            text={title}
                            tooltipMessage={tooltip}
                            tooltipWidth={400}
                            className={classNames(
                                "py-3 px-4 font-semibold text-gray-700 text-center",
                                className,
                            )}
                        />
                    ))}
                </tr>
            </thead>
            <tbody className="sm:flex-none">
                <SubIngredientMOSTable
                    mosSubIngredients={mosSubIngredients}
                    manualInputs={manualInputs}
                    onChange={onChange}
                    headerLength={TABLE_HEADERS.length}
                />
                <TotalWeightRow
                    totalWeight={Number(subIngredientsTotalWeight)}
                />
            </tbody>
        </TabTable>
    );
};
