import React, { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface IProps extends ButtonProps {
    icon?: IconDefinition;
    loadingText?: string;
    btnText: string;
    isLoading?: boolean;
    iconColor?: string;
    loadingIconColor?: string;
}

export const ActionButton: FC<IProps> = ({
    icon,
    loadingText = "Loading...",
    btnText,
    isLoading = false,
    iconColor = "#34d399",
    loadingIconColor = "#9ca3af",
    className,
    ...props
}) => {
    return (
        <Button
            className={`flex items-center justify-center gap-2 px-4 py-1 h-8 text-white rounded-md transition-colors ${
                isLoading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-500 hover:text-gray-400"
            } ${className || ""}`}
            disabled={isLoading}
            {...props}
        >
            {icon && (
                <FontAwesomeIcon
                    icon={icon}
                    className="text-base"
                    color={isLoading ? loadingIconColor : iconColor}
                />
            )}
            {isLoading ? loadingText : btnText}
        </Button>
    );
};
