import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import BigNumber from "bignumber.js";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IManualInput, IMosIngredientData } from "./types";
import { Form, Table } from "./components";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import {
    useGetFormula,
    useUpdateMos,
    useUpdateProductTypeProperties,
    useFormulaMos,
} from "pages/formulas/formula-profile/queries";
import { defaultToastOptions, toastTexts } from "common/constants";
import { ReportGeneration, Button, Title } from "components/shared";
import { MosConstants } from "pages/formulas/formula-profile/enums";
import { useMosIngredientData } from "pages/formulas/formula-profile/hooks";
import { FormulaMos } from "pages/formulas/formulas/types";

import { queryClient } from "libraries/queryProvider";

const { DEFAULT_BODY_WEIGHT, DAILY_EXPOSURE_MULTIPLIER, DECIMALS } =
    MosConstants;

const updateMosSchema = Yup.object().shape({
    product_type: Yup.string(),
    regulatory_body: Yup.string(),
    grams_applied_per_day: Yup.number().typeError("Value must be a number"),
    skin_retention_factor: Yup.number().typeError("Value must be a number"),
    body_weight: Yup.number().typeError("Value must be a number"),
});

export const MosTab = () => {
    const { data: formula } = useGetFormula();
    const { key, subIngredients, isOwner } = useFormulaProfileState();
    
    const [mosSubIngredients, setMosSubIngredients] = useState<
        IPreparedSubIngredient[]
    >([]);
    const [manualInputs, setManualInputs] = useState<IManualInput[]>([]);
    const { mosIngredientData, setMosIngredientData } =
        useMosIngredientData(formula);

    const { data: formulaMos } = useFormulaMos(formula?.id);

    useEffect(() => {
        if (formulaMos && subIngredients) {
            setMosSubIngredients(
                subIngredients.map((sub) => {
                    const mosIngredient = formulaMos.find(
                        (entry: FormulaMos) =>
                            entry.sub_ingredients_id === sub.id,
                    );

                    return {
                        ...sub,
                        noael: mosIngredient?.noael ?? sub.noael ?? "",
                        dermal_penetration:
                            mosIngredient?.dermal_penetration ??
                            sub.dermal_penetration ??
                            "",
                    };
                }),
            );
        }
    }, [subIngredients, formulaMos]);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized && subIngredients?.length) {
            setManualInputs(() => {
                return (
                    subIngredients?.map((subIngredient) => {
                        const formulaMosEntry = formulaMos?.find(
                            (entry: FormulaMos) =>
                                entry.sub_ingredients_id === subIngredient.id,
                        );

                        return {
                            noael:
                                formulaMosEntry?.noael ??
                                subIngredient?.noael ??
                                "",
                            noael_fix: subIngredient?.noael_fix ?? false,
                            dermal_penetration:
                                formulaMosEntry?.dermal_penetration ??
                                subIngredient?.dermal_penetration ??
                                "",
                            dermal_penetration_fix:
                                subIngredient?.dermal_penetration_fix ?? false,
                        };
                    }) || []
                );
            });

            setIsInitialized(true);
        }
    }, [formulaMos, isInitialized]);

    const calculateMos = useCallback(() => {
        const productType = mosIngredientData?.product_type?.value;
        if (!productType) return;

        const { body_weight, grams_applied_per_day, skin_retention_factor } =
            mosIngredientData;

        setMosSubIngredients((current) =>
            current.map((subIngredient, index) => {
                const dermalPenetration =
                    (manualInputs?.[index]?.dermal_penetration ||
                        subIngredient?.dermal_penetration) ??
                    "";

                const noael =
                    (manualInputs?.[index]?.noael || subIngredient?.noael) ??
                    "";

                const dailyExposure = new BigNumber(
                    grams_applied_per_day?.value || 0,
                )
                    .times(skin_retention_factor.value)
                    .times(dermalPenetration)
                    .div(100)
                    .times(subIngredient.rawSub.raw_weight_percent)
                    .div(100)
                    .times(DAILY_EXPOSURE_MULTIPLIER);

                const systematicDailyExposure = dailyExposure.div(
                    body_weight.value,
                );

                const mos = new BigNumber(noael)
                    .div(systematicDailyExposure)
                    .decimalPlaces(DECIMALS);

                return {
                    ...subIngredient,
                    skin_retention_factor: skin_retention_factor?.value || "",
                    daily_exposure: dailyExposure
                        ?.decimalPlaces(DECIMALS)
                        .isNaN()
                        ? ""
                        : dailyExposure?.decimalPlaces(DECIMALS)?.toString(),
                    systematic_daily_exposure: systematicDailyExposure
                        .decimalPlaces(DECIMALS)
                        .isNaN()
                        ? ""
                        : systematicDailyExposure
                              .decimalPlaces(DECIMALS)
                              .toString(),
                    MOS: mos.isNaN() ? "" : mos.toString(),
                };
            }),
        );
    }, [mosIngredientData, manualInputs]);

    useEffect(() => {
        calculateMos();
    }, [mosIngredientData, manualInputs]);

    const handleChangeValue = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            setMosIngredientData((current) => ({
                ...current,
                [name]: { ...current[name as keyof IMosIngredientData], value },
            }));
        },
        [],
    );

    const { mutate: updateFormulaMos, isPending } = useUpdateMos();
    const { mutate: updateProductTypeProperties } =
        useUpdateProductTypeProperties();

    const formik = useFormik({
        initialValues: {
            product_type: mosIngredientData?.product_type?.value || "",
            regulatory_body: mosIngredientData?.regulatory_body?.value || "",
            grams_applied_per_day:
                mosIngredientData?.grams_applied_per_day?.value || "",
            skin_retention_factor:
                mosIngredientData?.skin_retention_factor?.value || "",
            body_weight:
                mosIngredientData?.body_weight?.value || DEFAULT_BODY_WEIGHT,
            dermal_penetration: "",
            noael: "",
        },
        validationSchema: updateMosSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const updatedMosSubIngredients = mosSubIngredients.map(
                (it, index) => ({
                    ...it,
                    ...manualInputs?.[index],
                }),
            );

            updateFormulaMos(
                {
                    id: formula?.id ?? "",
                    body: {
                        product_type: values?.product_type || null,
                        regulatory_body: values?.regulatory_body || null,
                        body_weight: mosIngredientData?.body_weight?.value,
                        mosSubIngredients: updatedMosSubIngredients,
                    },
                },
                {
                    onSuccess: (data) => {
                        toast.success(
                            `${toastTexts.success} Formula has been updated.`,
                            defaultToastOptions,
                        );

                        queryClient.setQueryData(key, data);
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultToastOptions);
                    },
                },
            );

            if (
                !values?.grams_applied_per_day ||
                !values?.skin_retention_factor
            )
                return;

            updateProductTypeProperties(
                {
                    product_type: values.product_type,
                    regulatory_body: values.regulatory_body,
                    grams_applied_per_day: values.grams_applied_per_day,
                    skin_retention_factor: values.skin_retention_factor,
                },
                {
                    onSuccess: () => {
                        toast.success(
                            `${toastTexts.success} Product Type Properties has been updated.`,
                            defaultToastOptions,
                        );
                    },
                    onError: () => {
                        toast.error(toastTexts.error, defaultToastOptions);
                    },
                },
            );
        },
    });

    const { handleSubmit, values, errors } = formik;

    return (
        <>
            <div className="mx-10 my-3">
                <div className="flex justify-between items-center">
                    <Title text="MOS Calculation" />
                    <a
                        href="https://ec.europa.eu/health/scientific_committees/consumer_safety/docs/sccs_s_006.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                    >
                        Reference Document for MOS Calculation
                    </a>
                </div>
                <Form
                    values={values}
                    errors={errors}
                    onChange={handleChangeValue}
                />
                <ReportGeneration mosSubIngredients={mosSubIngredients} />
                <Table
                    mosSubIngredients={mosSubIngredients}
                    manualInputs={manualInputs}
                    onChange={setManualInputs}
                />

                {(isOwner || true) && (
                    <Button
                        className="mt-3"
                        text="Save MOS"
                        onClick={handleSubmit}
                        isLoading={isPending}
                    />
                )}
            </div>
        </>
    );
};
