import classNames from "classnames";
import React from "react";

interface IProps {
    text: string;
    className?: string;
    children?: React.ReactNode;
}

export const TableTitleCeil: React.FC<IProps> = ({
    text,
    className,
    children,
}) => (
    <div
        className={classNames(
            "flex flex-wrap items-center text-xs md:text-base lg:text-base pl-3 py-3 break-words",
            className,
        )}
    >
        <b>{text}</b>
        {children}
    </div>
);
