import React, { useMemo } from "react";
import classNames from "classnames";
import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { TabTable } from "../../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";
import { SubIngredientRow } from "./SubIngredientRow";
import { TableCeil } from "components/shared/tableCeil";

interface IProps {
    className?: string;
}

const TABLE_HEADERS = [
    {
        title: "SI Code",
        className: "w-[16%]",
    },
    {
        title: "Sub Ingredient",
        className: "w-[16%]",
    },
    {
        title: "% w/w",
        className: "w-[16%] text-center",
    },
    {
        title: "CAS #",
        className: "w-[16%] whitespace-nowrap hidden sm:table-cell",
    },
    {
        title: "SDS Files",
        className: "w-[16%] whitespace-nowrap hidden sm:table-cell",
    },
    {
        title: "",
        className: "w-[10%]",
    },
] as const;

export const CompositionTable: React.FC<IProps> = ({ className = "" }) => {
    const { subIngredients = [] } = useGetRawIngredient() || {};

    const totalAmount =
        useMemo(
            () =>
                subIngredients?.reduce((acc, el) => {
                    const value =
                        +el.rawIngredientsSubIngredients.weight_percent;
                    return acc + value;
                }, 0),
            [subIngredients],
        ) || 0;

    return (
        <div className={className}>
            <TabTable>
                <thead>
                    <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                        {TABLE_HEADERS.map(({ title, className }) => (
                            <TableHeaderCeil
                                key={title}
                                text={title}
                                className={classNames(
                                    "py-3 px-4 font-semibold text-gray-700",
                                    className,
                                )}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody className="sm:flex-none">
                    {subIngredients?.length ? (
                        subIngredients
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((subIngredient) => (
                                <SubIngredientRow
                                    key={`sub-${subIngredient.id}`}
                                    subIngredient={subIngredient}
                                    totalAmount={totalAmount}
                                />
                            ))
                    ) : (
                        <tr>
                            <TableCeil
                                text="No Sub Ingredients found"
                                className="p-3 text-gray-500"
                                colSpan={TABLE_HEADERS.length}
                            />
                        </tr>
                    )}
                    {Boolean(subIngredients?.length) && (
                        <tr className="bg-gray-50">
                            <TableCeil
                                className="py-3 px-4 text-right font-semibold text-gray-700"
                                text="Total amount:"
                                colSpan={2}
                            />
                            <TableCeil
                                className="py-3 px-4 text-center font-semibold text-gray-700"
                                text={`${totalAmount.toFixed(2)}%`}
                            />
                            <TableCeil
                                colSpan={TABLE_HEADERS.length - 3}
                                className="py-3 px-4"
                            />
                        </tr>
                    )}
                </tbody>
            </TabTable>
        </div>
    );
};
