import classNames from "classnames";
import React, {
    FC,
    ChangeEvent,
    KeyboardEvent,
    useRef,
    useEffect,
    useState,
} from "react";

interface IProps {
    name: string;
    value: string;
    isEditing: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    inputClassName?: string;
    displayClassName?: string;
    autoFocus?: boolean;
}

export const EditableInput: FC<IProps> = ({
    name,
    value,
    isEditing,
    onChange,
    onKeyDown,
    className = "",
    inputClassName = "",
    displayClassName = "",
    autoFocus = false,
}) => {
    const [inputWidth, setInputWidth] = useState<number>(90);
    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            const span = spanRef.current;
            const computedWidth = span.offsetWidth;
            setInputWidth(Math.max(computedWidth + 32, 90));
        }
    }, [value]);

    return (
        <div className={classNames("relative", className)}>
            {isEditing ? (
                <>
                    <input
                        ref={inputRef}
                        name={name}
                        type="text"
                        value={value}
                        onChange={(e) => {
                            const updatedValue = e.target.value.replace(
                                ",",
                                ".",
                            );
                            onChange({
                                ...e,
                                target: { ...e.target, value: updatedValue },
                            });
                        }}
                        onKeyDown={onKeyDown}
                        style={{ width: `${inputWidth}px` }}
                        className={classNames(
                            `w-full py-1.5 px-3 text-sm rounded-md border border-gray-300 
                          bg-white text-gray-900 
                          focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500 
                          transition-all duration-200 ease-in-out`,
                            inputClassName,
                        )}
                        autoFocus={autoFocus}
                    />
                    <span
                        ref={spanRef}
                        style={{
                            visibility: "hidden",
                            position: "absolute",
                            whiteSpace: "pre",
                            top: 0,
                            left: 0,
                        }}
                    >
                        {value || " "}
                    </span>
                </>
            ) : (
                <div
                    className={`py-1.5 px-3 text-sm font-medium text-gray-900 
                      rounded-md transition-colors duration-200 
                      ${displayClassName}`}
                >
                    {value}
                </div>
            )}
        </div>
    );
};
