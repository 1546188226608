import React, { ChangeEvent, FC } from "react";

import {
    useGetSafetySummaryOptions,
    useGetToxicReferenceOptions,
} from "pages/subIngredient/subIngredientProfile/queries";
import { IEnvironmentData } from "pages/subIngredient/subIngredientProfile/libs/types";
import { FormElement } from "common/types/formElement";
import { TableRow } from "../../basicInfoTab/components/TableRow";

interface IProps {
    data: IEnvironmentData;
    onChangeToxicityReference: (e: ChangeEvent<FormElement>) => void;
    onChangePointOfDeparture: (e: ChangeEvent<FormElement>) => void;
    handleChangeValue: (e: ChangeEvent<FormElement>) => void;
}

export const LeftTable: FC<IProps> = ({
    data,
    onChangeToxicityReference,
    onChangePointOfDeparture,
    handleChangeValue,
}) => {
    const { data: safetySummaryOptions } = useGetSafetySummaryOptions();
    const { data: toxicologicalReferenceOptions } =
        useGetToxicReferenceOptions();

    const podValue =
        data.point_of_departure[data.point_of_departure_select.toLowerCase()] ??
        "";

    const toxicValue =
        data.toxicity_reference[data.toxicity_reference_select.toLowerCase()] ??
        "";

    return (
        <div className="md:w-1/2 lg:w-1/2 md:mr-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <TableRow
                        header="Point of Departure"
                        name="point_of_departure_select"
                        value={data?.point_of_departure_select}
                        onChange={onChangePointOfDeparture}
                        options={safetySummaryOptions}
                        type="dropdown"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="PoD Value (mg/kg)"
                        name="point_of_departure"
                        value={podValue}
                        onChange={handleChangeValue}
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Authoritative Toxicity References"
                        name="toxicity_reference_select"
                        value={data?.toxicity_reference_select}
                        onChange={onChangeToxicityReference}
                        options={toxicologicalReferenceOptions}
                        type="dropdown"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Authoritative Toxicity Reference Value"
                        name="toxicity_reference"
                        value={toxicValue}
                        onChange={handleChangeValue}
                        lastModifiedBy={data?.last_modified_by}
                    />
                </tbody>
            </table>
        </div>
    );
};
