import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    "& .MuiTooltip-tooltip": {
        background:
            "linear-gradient(145deg, rgba(59, 130, 246, 0.95), rgba(37, 99, 235, 0.9))",
        fontSize: "0.95rem",
        padding: "10px 16px",
        borderRadius: "12px",
        boxShadow:
            "0 8px 16px -4px rgba(59, 130, 246, 0.25), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        backdropFilter: "blur(8px)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
    },
    "& .MuiTooltip-arrow": {
        color: "rgba(37, 99, 235, 0.9)",
    },
});

interface IProps extends Omit<TooltipProps, "children"> {
    children: React.ReactElement;
    placement?: "top" | "bottom" | "left" | "right";
    arrow?: boolean;
}

export const TooltipWrapper: React.FC<IProps> = ({
    children,
    placement = "top",
    arrow = true,
    ...props
}) => {
    return (
        <StyledTooltip arrow={arrow} placement={placement} {...props}>
            {children}
        </StyledTooltip>
    );
};
