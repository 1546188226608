import React, { FC, useState, useEffect, useCallback } from "react";
import { Details } from "components/shared/details";
import { Editor } from "components/shared/editor/Editor";

interface IProps {
    value: string;
    name: string;
    label: string;
    lastModifiedBy?: Record<string, Record<string, string | null>>;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
}

export const EndpointContent: FC<IProps> = ({
    value,
    name,
    label,
    onChange,
    disabled = false,
    lastModifiedBy,
}) => {
    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [editorContent, setEditorContent] = useState(value);

    const handleToggleDetailsVisibility = useCallback(() => {
        setIsOpenDetails((prev) => !prev);
    }, []);

    useEffect(() => {
        onChange(name, editorContent);
    }, [editorContent, name]);

    return (
        <Details
            isOpen={isOpenDetails}
            onOpen={handleToggleDetailsVisibility}
            text={label}
            lastModifiedBy={lastModifiedBy}
            endpointId={name}
            summaryClassName="!bg-[#cadaf0] flex py-3 pl-3 rounded-lg"
        >
            <Editor
                value={editorContent}
                onChange={setEditorContent}
                disabled={disabled}
            />
        </Details>
    );
};
