import React from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { TabTable } from "../../../TabTable";
import { useFetchRawIngredientTradenames } from "pages/rawIngredient/rawIngredientProfile/queries";
import { ManufacturerRow } from "./ManufacturerRow";
import { TableCeil } from "components/shared";

const TABLE_HEADERS = [
    {
        title: "Code",
        className: "w-[15%] min-w-[120px]",
    },
    {
        title: "Manufacturer",
        className: "w-[30%] min-w-[180px]",
    },
    {
        title: "Trade Name",
        className: "w-[40%] min-w-[200px]",
    },
    {
        title: "",
        className: "w-[15%] min-w-[100px] hidden lg:table-cell",
    },
] as const;

export const ManufacturesTable = () => {
    const { id = "" } = useParams();
    const { data: tradenames } = useFetchRawIngredientTradenames(id);

    return (
        <TabTable>
            <thead>
                <tr className="border-b border-primary-main-dgray">
                    {TABLE_HEADERS.map(({ title, className }) => (
                        <TableHeaderCeil
                            key={title}
                            text={title}
                            className={classNames(
                                "py-4 px-6 font-semibold text-gray-800 first:pl-4 last:pr-4",
                                className,
                            )}
                        />
                    ))}
                </tr>
            </thead>
            <tbody>
                {Boolean(tradenames?.length) ? (
                    tradenames
                        ?.sort((a, b) => a.tradename.localeCompare(b.tradename))
                        ?.map((tradename) => (
                            <ManufacturerRow
                                key={tradename.id}
                                tradename={tradename}
                            />
                        ))
                ) : (
                    <tr>
                        <TableCeil
                            text="No Manufacturers found"
                            className="p-3 text-gray-500"
                            colSpan={TABLE_HEADERS.length}
                        />
                    </tr>
                )}
            </tbody>
        </TabTable>
    );
};
