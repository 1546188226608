import React, {
    FC,
    useMemo,
    KeyboardEvent,
    useState,
    ChangeEvent,
    useCallback,
} from "react";
import { TableCeil, ActionButtons, EditableInput } from "components/shared";
import { IFormulaRawIngredient } from "pages/formulas/formula-profile/types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    defaultErrorToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import {
    useDeleteRelation,
    useUpdateRelation,
} from "pages/formulas/formula-profile/queries";
import { toast } from "react-toastify";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { SubIngredientsList } from "./SubIngredientList";
import { SubIngredientWeightList } from "./SubIngredientWeightList";

interface IProps {
    rawIngredient: IFormulaRawIngredient;
    rawIngredientsTotalWeight: string;
}

export const RawIngredientRow: FC<IProps> = ({
    rawIngredient,
    rawIngredientsTotalWeight,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const { id = "" } = useParams();
    const queryClient = useQueryClient();
    const { isOwner } = useFormulaProfileState();
    const navigate = useNavigate();

    const navigateToRawIngredientPage = () => {
        navigate(`${routerKeys.rawIngredient}/${rawIngredient.id}`);
    };

    const maxValue = useMemo(() => {
        const totalWeight = parseFloat(rawIngredientsTotalWeight);
        return (
            100 - totalWeight + parseFloat(rawIngredient.formRaw.weight_percent)
        );
    }, [rawIngredientsTotalWeight, rawIngredient.formRaw.weight_percent]);

    const Schema = useMemo(
        () =>
            Yup.object().shape({
                weight_percent: Yup.number()
                    .max(
                        maxValue,
                        `Weight percent must be less than or equal to ${maxValue}`,
                    )
                    .min(0, "Weight percent must be greater than or equal to 0")
                    .required("Weight percent is required"),
            }),
        [maxValue],
    );

    const { mutate: updateRelation } = useUpdateRelation();
    const { mutate: deleteRelation } = useDeleteRelation();

    const formik = useFormik({
        initialValues: {
            weight_percent: rawIngredient.formRaw.weight_percent,
        },
        validationSchema: Schema,
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        onSubmit: ({ weight_percent }) => {
            updateRelation(
                {
                    id,
                    ingredientId: `${rawIngredient.id}`,
                    relationType: "rawIngredients",
                    body: { value: weight_percent },
                },
                {
                    onSuccess() {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.formula, id],
                        });
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.formulas],
                        });
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.lastUpdateFormules],
                        })
                    },
                    onError() {
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
    });

    const handleRemoveIngredient = useCallback(() => {
        deleteRelation(
            {
                id,
                ingredientId: `${rawIngredient.id}`,
                relationType: "rawIngredients",
            },
            {
                onSuccess() {
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.formula, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.formulas],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.lastUpdateFormules],
                    })
                },
                onError() {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [deleteRelation, id, rawIngredient.id, queryClient]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();
                formik.handleSubmit();
                if (formik.isValid) {
                    setIsEditing(false);
                }
            }
        },
        [formik],
    );

    const handleSubmit = useCallback(() => {
        formik.handleSubmit();
        if (formik.isValid) {
            setIsEditing(false);
        }
    }, [formik]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const formattedValue = value.replace(",", ".");
            formik.setFieldValue("weight_percent", formattedValue);
        },
        [formik],
    );

    return (
        <tr key={rawIngredient.id} className="relative">
            <TableCeil text={rawIngredient.ricode} />
            <TableCeil
                text={rawIngredient.description}
                onClick={navigateToRawIngredientPage}
                className="cursor-pointer"
            />
            <TableCeil className="w-auto whitespace-nowrap hidden sm:table-cell">
                <SubIngredientsList items={rawIngredient?.subIngredients} />
            </TableCeil>
            <TableCeil className="w-auto whitespace-nowrap hidden sm:table-cell">
                <SubIngredientWeightList
                    subIngredients={rawIngredient?.subIngredients}
                    parentWeight={formik.values.weight_percent}
                />
            </TableCeil>

            <TableCeil>
                <EditableInput
                    name="weight_percent"
                    value={formik.values.weight_percent}
                    isEditing={isEditing}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="text-center"
                    inputClassName="text-center"
                    displayClassName="font-semibold text-gray-700"
                    autoFocus={isEditing}
                />
                {formik.errors.weight_percent &&
                    formik.touched.weight_percent && (
                        <div className="p-0.5 w-full text-[#dc2626]">
                            {formik.errors.weight_percent}
                        </div>
                    )}
            </TableCeil>

            {(isOwner || true) && (
                <TableCeil>
                    <ActionButtons
                        onSubmit={handleSubmit}
                        onRemove={handleRemoveIngredient}
                        isEditing={isEditing}
                        setIsEditing={formik.isValid ? setIsEditing : undefined}
                    />
                </TableCeil>
            )}
        </tr>
    );
};
