export const ItemTitle = {
   DASHBOARD: "Dashboard",
   FORMULAS: "Formulas",
   RAW_INGREDIENT: "Raw Ingredient",
   SUB_INGREDIENT: "Sub Ingredient",
   CONTAMINANTS: "Contaminants",
   REPORTS: "Reports",
   ADMIN_PANEL: "Admin panel",
   FRAGRANCE: "Fragrance",
   PROFILE: "Profile",
   HELP_DESK: "Help Desk",
} as const;