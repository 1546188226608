import React from "react";
import classNames from "classnames";

interface IProps {
    text: string;
    className?: string;
}

export const TableTitleCeil: React.FC<IProps> = ({ text, className }) => (
    <td
        className={classNames(
            "text-xs md:text-base lg:text-base p-3 max-w-[400px] break-words",
            className,
        )}
    >
        <b>{text}</b>
    </td>
);
