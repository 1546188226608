import React, { FC, useCallback } from "react";
import { FormikErrors, FormikTouched } from "formik";
import { IParsedRawIngredient } from "pages/rawIngredient/types";
import { InputWrapper } from "components/shared";
import { useRawIngredient } from "pages/formulas/formulas/queries";
import { useFormulaStore } from "pages/formulas/formulas/store";
import { useOwner } from "common/hooks";

export const RawIngredientInputs: FC<{
    index: number;
    itemErrors: FormikErrors<IParsedRawIngredient> | undefined;
    itemTouched: FormikTouched<IParsedRawIngredient>;
    itemValues: IParsedRawIngredient;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ index, itemErrors, itemTouched, itemValues, handleChange }) => {
    const { data: existingIngredient } = useRawIngredient(
        itemValues?.description,
    );

    const { formula, changeRawIngredient } = useFormulaStore();
    const { email } = useOwner();

    const ricode = formula?.rawIngredients?.[index]?.ricode;

    const handleClickExisting = useCallback(() => {
        const item = existingIngredient?.rows?.[0];
        if (!item || !email) return;

        changeRawIngredient(existingIngredient, email);
    }, [existingIngredient, changeRawIngredient, email]);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex gap-2">
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.weight_percent) &&
                        Boolean(itemTouched?.weight_percent)
                    }
                    error={itemErrors?.weight_percent}
                    label="Weight percent"
                    className="!w-1/4 !p-0"
                >
                    <input
                        type="number"
                        name={`rawIngredients.${index}.weight_percent`}
                        className="w-full input input-bordered"
                        value={itemValues?.weight_percent || ""}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <InputWrapper
                    isError={
                        Boolean(itemErrors?.description) &&
                        Boolean(itemTouched?.description)
                    }
                    error={itemErrors?.description}
                    label="Name"
                    className="!p-0"
                >
                    <input
                        type="text"
                        name={`rawIngredients.${index}.description`}
                        className="w-full input input-bordered"
                        value={itemValues?.description || ""}
                        onChange={handleChange}
                    />
                </InputWrapper>
            </div>

            {(Boolean(itemValues?.vendor?.trim()) ||
                Boolean(itemValues?.tradename?.trim())) && (
                <div className="flex gap-2">
                    <InputWrapper
                        isError={false}
                        error=""
                        label="Supplier (Vendor)"
                        className="!p-0"
                    >
                        <input
                            type="text"
                            name={`rawIngredients.${index}.vendor`}
                            className="w-full input input-bordered"
                            value={itemValues?.vendor || "None"}
                            onChange={handleChange}
                        />
                    </InputWrapper>
                    <InputWrapper
                        isError={false}
                        error=""
                        label="Tradename"
                        className="!p-0"
                    >
                        <input
                            type="text"
                            name={`rawIngredients.${index}.tradename`}
                            className="w-full input input-bordered"
                            value={itemValues?.tradename || "None"}
                            onChange={handleChange}
                        />
                    </InputWrapper>
                </div>
            )}

            {Boolean(existingIngredient?.rows?.length) && !ricode && (
                <div className="text-warning flex items-center gap-2">
                    <span>Warning: This ingredient already exists.</span>
                    <button
                        type="button"
                        className="btn btn-sm btn-warning"
                        onClick={handleClickExisting}
                    >
                        <span className="text-sm uppercase">
                            Use existing ingredient
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
};
