import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface IProps {
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

const defaultPlaceholder =
    "Search formula, raw ingredient, sub ingredient or contaminant";

const baseInputStyles =
    "text-lg subtitle-sm w-full h-14 pl-10 pt-0.5 border-0 focus:border-0 rounded-md shadow-sm outline-none";
const baseIconStyles =
    "absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400";

export const SearchBlock: React.FC<IProps> = ({
    placeholder = defaultPlaceholder,
    value,
    onChange,
    className,
}) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <div className={classNames("w-full my-8", className)}>
            <div className="relative w-full h-14 transition-all duration-200">
                <FontAwesomeIcon icon={faSearch} className={baseIconStyles} />
                <input
                    type="text"
                    className={baseInputStyles}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};
