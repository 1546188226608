import React, { FC } from "react";
import { Link } from "react-router-dom";
import { routerKeys } from "common/constants";
import { Tooltip } from "@mui/material";

interface IProps {
    id: string | number;
    sicode: string;
    name?: string;
    description: string | null;
}

export const SubIngredientLink: FC<IProps> = ({
    id,
    sicode,
    name,
    description = null,
}) => (
    <Link to={`${routerKeys.subIngredient}/${id}`} className="hover:font-bold">
        {sicode}{" "}
        {(name || description) && (
            <Tooltip
                title={description || ""}
                placement="top"
                arrow
                sx={{
                    "& .MuiTooltip-tooltip": {
                        backgroundColor: "#1f2937",
                        fontSize: "0.875rem",
                        padding: "8px",
                    },
                    "& .MuiTooltip-arrow": {
                        color: "#1f2937",
                    },
                }}
            >
                <span>{name ?? description}</span>
            </Tooltip>
        )}
    </Link>
);
