import React, { ChangeEvent, FC } from "react";
import { IEnvironmentData } from "pages/subIngredient/subIngredientProfile/libs/types";
import { FormElement } from "common/types/formElement";
import { useLocalEffectRefOptions } from "pages/subIngredient/subIngredientProfile/queries";
import { TableRow } from "../../basicInfoTab/components/TableRow";

interface IProps {
    data: IEnvironmentData;
    onChangeData: (e: ChangeEvent<FormElement>) => void;
    handleChangeValue: (e: ChangeEvent<FormElement>) => void;
    onChangeLocalReference: (e: ChangeEvent<FormElement>) => void;
}

export const RightTable: FC<IProps> = ({
    data,
    onChangeData,
    handleChangeValue,
    onChangeLocalReference,
}) => {
    const { data: localEffectRefOptions } = useLocalEffectRefOptions();

    const localEffectValue =
        data.local_effect_reference[
            data.local_effect_reference_select.toLowerCase()
        ] ?? "";

    return (
        <div className="w-1/2 md:w-1/2 lg:w-1/2 md:ml-5">
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg mb-2">
                <tbody>
                    <TableRow
                        header="Local Effect Reference Values"
                        name="local_effect_reference_select"
                        value={data?.local_effect_reference_select}
                        onChange={onChangeLocalReference}
                        options={localEffectRefOptions}
                        type="dropdown"
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Local Effect Reference Values (&#956;g/cm2)"
                        name="local_effect_reference"
                        value={localEffectValue}
                        onChange={handleChangeValue}
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="TTC"
                        name="ttc"
                        value={data?.ttc}
                        onChange={onChangeData}
                        lastModifiedBy={data?.last_modified_by}
                    />
                    <TableRow
                        header="Dermal penetration (&#37;)"
                        name="dermal_penetration"
                        value={data?.dermal_penetration}
                        onChange={onChangeData}
                        lastModifiedBy={data?.last_modified_by}
                    />
                </tbody>
            </table>
        </div>
    );
};
