import React, { FC, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { Button, SearchableSelect, InputWrapper } from "components/shared";
import { IContaminant, IError, IOption } from "common/types";
import { buildLink } from "common/utils";
import { useContaminants, useCreateContaminant } from "../queries";
import { queryClient } from "libraries/queryProvider";

interface IProps {
    handleClose: () => void;
    raw_ingredients_id?: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().trim().required("Description is required"),
    ppm: Yup.number()
        .min(0, "PPM must be a positive number")
        .max(1000000, "PPM cannot exceed 1,000,000")
        .nullable()
        .transform((value) => (isNaN(value) ? null : value)),
});

export const CreateContaminantForm: FC<IProps> = ({
    raw_ingredients_id,
    handleClose,
}) => {
    const [search, setSearch] = useState("");
    const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

    const navigate = useNavigate();

    const { mutate: createContaminant } = useCreateContaminant();

    const {
        contaminantOptions = [],
        isLoading,
        fetchNextPage,
        hasNextPage,
    } = useContaminants({
        search,
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            ppm: "",
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            createContaminant(
                {
                    body: {
                        ...values,
                        raw_ingredients_id,
                    },
                },
                {
                    onSuccess: (response: IContaminant) => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: `Success! Contaminant has been ${raw_ingredients_id ? "updated" : "created"}`,
                        });

                        if (!raw_ingredients_id) {
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.contaminants],
                            });

                            const link = buildLink(
                                routerKeys.contaminants,
                                response.id,
                            );
                            navigate(link);
                        } else {
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.contaminant, response.id],
                            });

                            queryClient.invalidateQueries({
                                queryKey: [
                                    queryKeys.rawIngredient,
                                    raw_ingredients_id,
                                ],
                            });
                        }

                        setSelectedOption(null);
                        formik.resetForm();
                        handleClose();
                    },
                    onError: (err: IError) => {
                        const message = err?.response?.data?.message || "";
                        const field = message.toLowerCase();

                        if (field.includes("name")) {
                            formik.setFieldError("name", message);
                        }
                        if (field.includes("description")) {
                            formik.setFieldError("description", message);
                        }

                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        resetForm,
        setValues,
    } = formik;

    useEffect(() => {
        if (selectedOption) {
            const [name, description] = selectedOption.label
                .split(",")
                .map((item) => item?.trim());

            setValues({
                name,
                description,
                ppm: "",
            });
        }
    }, [selectedOption, setValues]);

    const handleUpdateRelatedList = useCallback((newValue: IOption) => {
        setSelectedOption(newValue);
    }, []);

    const handleLoadMore = useCallback(() => {
        if (!isLoading && hasNextPage) {
            fetchNextPage();
        }
    }, [isLoading, hasNextPage, fetchNextPage]);

    const handleInputChange = useCallback((value?: string) => {
        if (value) {
            setSearch(value);
        }
    }, []);

    return (
        <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
                {raw_ingredients_id && Boolean(contaminantOptions?.length) && (
                    <div className="px-1">
                        <SearchableSelect
                            isLoading={isLoading}
                            options={contaminantOptions}
                            values={selectedOption}
                            onChange={handleUpdateRelatedList}
                            onMenuScrollToBottom={handleLoadMore}
                            onInputChange={handleInputChange}
                        />
                    </div>
                )}
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="Name"
                >
                    <input
                        type="text"
                        name="name"
                        className="w-full input input-bordered"
                        value={values.name}
                        onChange={handleChange}
                    />
                </InputWrapper>

                {raw_ingredients_id && (
                    <InputWrapper
                        isError={Boolean(errors.ppm && touched.ppm)}
                        error={errors.ppm}
                        label="PPM"
                    >
                        <input
                            type="number"
                            name="ppm"
                            className="w-full input input-bordered"
                            value={values.ppm}
                            onChange={handleChange}
                            min="0"
                            max="1000000"
                        />
                    </InputWrapper>
                )}

                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Description"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>

                <div className="flex items-center justify-end gap-3 mt-4">
                    <Button text="Clear" onClick={resetForm} variant="ghost" />
                    <Button text="Create" type="submit" />
                </div>
            </div>
        </form>
    );
};
