import classNames from "classnames";
import React from "react";

interface IProps {
    text: string;
    children?: React.ReactNode;
    className?: string;
}

export const Title: React.FC<IProps> = ({ text, children, className }) => (
    <h1
        className={classNames(
            "pb-2 px-1 ml-2 text-base font-medium",
            className,
        )}
        style={{ color: "#6466f1" }}
    >
        <b>{text}</b>
        {children}
    </h1>
);
