import React, { FC, useCallback, useState } from "react";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button } from "components/shared";
import { ITradename } from "common/types";
import { useUpdateTradenameMutation } from "pages/rawIngredient/rawIngredientProfile/queries";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    queryKeys,
} from "common/constants";
import { defaultToastOptions, toastTexts } from "common/constants";
import { queryClient } from "libraries/queryProvider";

interface IProps {
    tradename: ITradename;
}

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Manufacturer description is required"),
    tradename: Yup.string().required("Trade name is required"),
});

export const ManufacturerRow: FC<IProps> = ({ tradename }) => {
    const [editMode, setEditMode] = useState(false);
    const { id = "" } = useParams();
    const { mutate: updateTradename, isPending } = useUpdateTradenameMutation();

    const formik = useFormik({
        initialValues: {
            vendorId: tradename.vendor?.id || "",
            tradenameId: tradename.id || "",
            description: tradename.vendor?.description || "",
            tradename: tradename.tradename || "",
        },
        validationSchema,
        onSubmit: async (payload) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            updateTradename(payload, {
                onSuccess: () => {
                    setEditMode(false);

                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.tradenames, id],
                    });

                    toast.update(toastId, defaultSuccessToastUpdate);
                },
                onError: () => {
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            });
        },
    });

    const handleCancel = useCallback(() => {
        formik.resetForm();
        setEditMode(false);
    }, [formik]);

    return (
        <tr
            className={classNames(
                "transition-colors duration-200",
                "hover:bg-gray-50",
                editMode && "bg-gray-100",
                "border-b border-gray-200",
            )}
        >
            <td className="p-4 align-middle">
                {tradename.vendor?.vendor_code}
            </td>
            <td className="p-4 align-middle">
                {editMode ? (
                    <div className="flex flex-col gap-1">
                        <input
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classNames(
                                "input input-bordered w-full",
                                formik.touched.description &&
                                    formik.errors.description &&
                                    "input-error",
                            )}
                        />
                        {formik.touched.description &&
                            formik.errors.description && (
                                <span className="text-error text-sm">
                                    {formik.errors.description}
                                </span>
                            )}
                    </div>
                ) : (
                    <span>{tradename.vendor?.description}</span>
                )}
            </td>
            <td className="p-4 align-middle">
                {editMode ? (
                    <div className="flex flex-col gap-1">
                        <input
                            name="tradename"
                            value={formik.values.tradename}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classNames(
                                "input input-bordered w-full",
                                formik.touched.tradename &&
                                    formik.errors.tradename &&
                                    "input-error",
                            )}
                        />
                        {formik.touched.tradename &&
                            formik.errors.tradename && (
                                <span className="text-error text-sm">
                                    {formik.errors.tradename}
                                </span>
                            )}
                    </div>
                ) : (
                    <span>{tradename.tradename}</span>
                )}
            </td>
            <td className="p-4 align-middle">
                <div className="flex gap-2 justify-end">
                    {editMode ? (
                        <>
                            <Button
                                variant="ghost"
                                onClick={handleCancel}
                                disabled={isPending}
                                size="sm"
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="mr-2"
                                />
                                Cancel
                            </Button>
                            <Button
                                size="sm"
                                onClick={() => formik.handleSubmit()}
                                disabled={
                                    !formik.isValid ||
                                    !formik.dirty ||
                                    isPending
                                }
                                isLoading={isPending}
                            >
                                <FontAwesomeIcon
                                    icon={faSave}
                                    className="mr-2"
                                />
                                Save
                            </Button>
                        </>
                    ) : (
                        <Button onClick={() => setEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                        </Button>
                    )}
                </div>
            </td>
        </tr>
    );
};
