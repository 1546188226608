import React, { ReactElement } from "react";
import {
    ColumnDef,
    OnChangeFn,
    PaginationState,
    SortingState,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { ReactPagination } from "../react-pagination/ReactPagination";

interface IProps<T> {
    columns: ColumnDef<T>[];
    data: T[];
    pagination?: PaginationState;
    setPagination?: OnChangeFn<PaginationState>;
    totalCount?: number;
    isFilterVisible?: boolean;
    sorting?: SortingState;
    setSorting?: OnChangeFn<SortingState>;
    children?: React.ReactNode;
    className?: string;
    styleTable?: string;
    position?: boolean;
}

export const DataTable = <T,>({
    columns,
    data,
    pagination,
    setPagination,
    totalCount = 0,
    sorting,
    setSorting,
    className = "",
    isFilterVisible,
    children,
    styleTable,
    position = true
}: IProps<T>): ReactElement => {
    const tableInstance = useReactTable({
        data,
        columns,
        state: {
            pagination,
            sorting,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        rowCount: totalCount,
        manualFiltering: true,
        manualSorting: true,
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
    });

    const currentPage = pagination
        ? Math.min(
              tableInstance.getState().pagination.pageIndex,
              Math.max(tableInstance.getPageCount() - 1, 0),
          )
        : 0;

    return (
        <div
            className={classNames(
                "flex w-full justify-between md:flex-row",
                className,
            )}
        >
        
            <div
                className={classNames("w-full pt-4", isFilterVisible && styleTable, {
                    "md:w-full": !isFilterVisible,
                })}
            >
                <div className="w-full overflow-x-auto">
                    <table className="w-full rounded-lg overflow-hidden">
                        <thead className="light-gray-bg">
                            {tableInstance
                                .getHeaderGroups()  
                                .map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className="border-b light-gray-bg"
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                key={header.id}
                                                className="py-2 px-1 md:px-2 md:max-w-[300px] lg:max-w-full md:min-w-[70px] text-left text-xs sm:text-sm md:text-base border-b-2 border-gray-200 bg-white"
                                                colSpan={header.colSpan}
                                            >
                                                <div
                                                    className={classNames({
                                                        "cursor-pointer select-none":
                                                            header.column.getCanSort(),
                                                        "cursor-default":
                                                            !header.column.getCanSort(),
                                                    })}
                                                    onClick={
                                                        header.column.getCanSort()
                                                            ? header.column.getToggleSortingHandler()
                                                            : undefined
                                                    }
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                    {(sorting &&
                                                        {
                                                            asc: " 🔼",
                                                            desc: " 🔽",
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ]) ??
                                                        null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                        </thead>
                        <tbody>
                            {Boolean(
                                tableInstance.getRowModel()?.rows?.length,
                            ) ? (
                                tableInstance.getRowModel().rows.map((row) => (
                                    <tr key={row.id} className="border-b-2">
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                className="py-2 px-1 md:px-2 md:max-w-[300px] lg:max-w-full md:min-w-[70px] text-xs sm:text-sm md:text-base overflow-hidden border-b-2 border-gray-200 bg-white"
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={
                                            tableInstance?.getHeaderGroups()?.[0]
                                                ?.headers?.length ?? 0
                                        }
                                        className="text-center py-4 text-gray-500 font-medium text-base"
                                    >
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {pagination && (
                    <div className="mt-10 flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
                        <ReactPagination
                            pageCount={Math.max(
                                tableInstance.getPageCount(),
                                1,
                            )}
                            currentPage={currentPage}
                            onPageChange={tableInstance.setPageIndex}
                            breakLabel={".."}
                            previousLabel="prev"
                            nextLabel="next"
                        />

                        <select
                            className="text-sm sm:text-base"
                            value={tableInstance.getState().pagination.pageSize}
                            onChange={(e) => {
                                tableInstance.setPageSize(
                                    Number(e.target.value),
                                );
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                        <div className="text-xs sm:text-sm md:text-base">
                            {`Results: ${totalCount}`}
                        </div>
                    </div>
                )}
            </div>
            {isFilterVisible && position && (
                <aside className="w-full md:w-1/5 lg:w-1/5 py-2 ml-4">
                    {children}
                </aside>
            )}
        </div>
    );
};
