import React, { useState } from 'react'
import { 
   AboutBusiness, 
   OnboardingTemplate, 
   ProfileInformation, 
   UserGoals
} from './components'
import { useGetProfileInfo } from '../profile/libs';

export const Onboarding = () => {
  const [step, setStep] = useState<number>(1);

  const { data: profileInfo } = useGetProfileInfo();
  
  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <main>
      <OnboardingTemplate stepNumber={step}>
        <div className='flex justify-center'>
          {step === 1 && <ProfileInformation nextStep={nextStep} profileInfo={profileInfo}/>}
          {step === 2 && <AboutBusiness nextStep={nextStep} prevStep={prevStep} profileInfo={profileInfo}/>}
          {step === 3 && <UserGoals prevStep={prevStep} profileInfo={profileInfo}/>}
        </div>
      </OnboardingTemplate>
    </main>
  )
}
