import {
    keepPreviousData,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { getOptions } from "common/utils";
import {
    rawIngredientService,
    subIngredientService,
    subIngredientsService,
} from "services";
import { useSubIngredientsQuery } from "../store";
import { ICreateSubIngredientBody } from "../libs/types";
import {
    type IEnvironmentalClass,
    type IOverallRank,
    type IQuery,
    type ISimilarItem,
    type ISubIngredient,
    type RequestQueryBodyT,
} from "common/types";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";

export const useUploadSubIngredients = ({ queryParams, sorting }: IQuery) => {
    const queryKey = [
        queryKeys.subIngredients,
        queryParams,
        `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`,
    ];
    const { setKey } = useSubIngredientsQuery();

    return useQuery({
        queryKey,
        queryFn: () =>
            subIngredientsService
                .getSubIngredients({ queryParams, sorting })
                .then((res) => {
                    setKey(queryKey);
                    return res;
                }),
        placeholderData: keepPreviousData,
    });
};

export const useLastSubIngredients = ({ queryParams }: IQuery) => {
    const queryKey = [queryKeys.subIngredients, queryParams];
    const { setKey } = useSubIngredientsQuery();

    return useQuery<IPreparedSubIngredient[]>({
        queryKey,
        queryFn: () =>
            subIngredientsService
                .getSubIngredients({ queryParams })
                .then((res) => {
                    setKey(queryKey);
                    return res?.rows as IPreparedSubIngredient[];
                }),
        placeholderData: keepPreviousData,
    });
};

export const useGetTableSubIngredients = ({ queryParams, sorting }: IQuery) => {
    const sortingKey = sorting?.length
        ? `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`
        : undefined;

    const queryKey = [queryKeys.subIngredients, queryParams];

    if (sortingKey) {
        queryKey.push(sortingKey);
    }

    const { setKey } = useSubIngredientsQuery();

    return useQuery({
        queryKey,
        queryFn: () =>
            subIngredientsService
                .getSubIngredients({ queryParams, sorting })
                .then((res) => {
                    setKey(queryKey);
                    return res;
                }),
        select: (data) => {
            return {
                data: data?.rows?.map(
                    ({
                        id,
                        sicode,
                        name,
                        function: func,
                        status,
                        date_created,
                        date_modified,
                    }) => ({
                        id,
                        sicode,
                        name,
                        date_created,
                        date_modified,
                        function: func,
                        status: status || "",
                    }),
                ),
                count: data?.count,
            };
        },
        placeholderData: keepPreviousData,
    });
};

export const useGetSubIngredientsStatistic = () => {
    return useQuery({
        queryKey: [queryKeys.subIngredients, queryKeys.statistic],
        queryFn: () => subIngredientsService.getSubIngredientsStatistic(),
    });
};

export const useGetOverallRank = () => {
    return useQuery({
        queryKey: [queryKeys.subIngredientsOverallRank],
        queryFn: () => subIngredientsService.getSubIngredientsOverallRank(),
        select: (data: IOverallRank[]) =>
            getOptions(data, (item) =>
                item.overall_environmental_rank
                    ? {
                          label: item.overall_environmental_rank,
                          value: item.overall_environmental_rank,
                      }
                    : null,
            ),
    });
};

export const useGetEnvironmentalClass = () => {
    return useQuery({
        queryKey: [queryKeys.subIngredientsEnvironmentalClass],
        queryFn: () =>
            subIngredientsService.getSubIngredientsEnvironmentalClass(),
        select: (data: IEnvironmentalClass[]) =>
            getOptions(data, (item) =>
                item.environmental_class
                    ? {
                          label: item.environmental_class,
                          value: item.environmental_class,
                      }
                    : null,
            ),
    });
};

export const useCreateSubIngredient = () =>
    useMutation({
        mutationFn: (body: ICreateSubIngredientBody) =>
            subIngredientsService.createSubIngredient({ body }),
    });

export const useUpdateSubIngredientInRawIngredient = () =>
    useMutation({
        mutationFn: (payload: ICreateSubIngredientBody) =>
            rawIngredientService.updateRawIngredientSubIngredient(payload),
    });

export const useUpdateSubIngredient = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: number;
            body: Pick<ICreateSubIngredientBody, "name">;
        }) =>
            subIngredientsService.updateSubIngredient({
                id,
                body,
            }),
    });
};

export const useGetSubIngredientOptions = (attribute: string) =>
    useQuery({
        queryKey: [queryKeys.subIngredientOptions, attribute],
        queryFn: async () =>
            subIngredientsService.getSubIngredientOptions(attribute),
    });

export const useFindSimilarItems = (name: string) =>
    useQuery<ISimilarItem[]>({
        queryKey: [queryKeys.similarItems, name],
        queryFn: () =>
            subIngredientsService.fetchSimilarItems(name).then(
                (data) =>
                    data?.rows?.map(({ id, name }) => ({
                        id,
                        name,
                    })) || [],
            ),
        select: (data) => (name ? data.slice(0, 5) : []),
        placeholderData: keepPreviousData,
    });

export const useSubIngredientByID = (id?: string) => {
    if (!id) {
        return;
    }

    return useQuery<ISubIngredient>({
        queryKey: [queryKeys.subIngredient, id],
        queryFn: () => {
            if (!id) {
                throw new Error("Sub Ingredient ID is required");
            }
            return subIngredientService.getSubIngredient({ id });
        },
        enabled: Boolean(id),
    });
};

export const useSubIngredients = ({
    size = 20,
    search,
}: RequestQueryBodyT = {}) => {
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: [queryKeys.subIngredients, search],
            queryFn: ({ pageParam = 0 }) =>
                subIngredientsService.getSubIngredients({
                    queryParams: `offset=${pageParam * size}&limit=${size}&query=${search || ""}`,
                }),
            getNextPageParam: (lastPage, allPages) => {
                const totalItems = lastPage?.count || 0;
                const loadedItems = allPages.reduce(
                    (total, page) => total + (page.rows?.length || 0),
                    0,
                );
                return loadedItems < totalItems
                    ? loadedItems / size
                    : undefined;
            },
            initialPageParam: 0,
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            placeholderData: keepPreviousData,
        });

    const flattenedData = data?.pages.flatMap((page) => page.rows || []) || [];

    return {
        subIngredients: flattenedData,
        subIngredientOptions: flattenedData.map((item) => ({
            label: `${item.sicode}, ${item.name}`,
            value: `${item.id}`,
        })),
        count: data?.pages[0]?.count || 0,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    };
};
