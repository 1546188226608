import React from "react";
import classNames from "classnames";
import { TableCeil, TableHeaderCeil } from "components/shared";
import { TabTable } from "../../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";

interface IProps {
    className?: string;
}

const TABLE_HEADERS = [
    {
        title: "Test Code",
        className: "w-[20%]",
    },
    {
        title: "Test Attributes",
        className: "w-[40%]",
    },
    {
        title: "Test Results/Conclusion",
        className: "w-[40%]",
    },
] as const;

export const TestsTable: React.FC<IProps> = ({ className = "" }) => {
    const rawIngredient = useGetRawIngredient();

    return (
        <div className={className}>
            <TabTable>
                <thead>
                    <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                        {TABLE_HEADERS.map(({ title, className }) => (
                            <TableHeaderCeil
                                key={title}
                                text={title}
                                className={classNames(
                                    "py-3 px-4 font-semibold text-gray-700",
                                    className,
                                )}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody className="sm:flex-none">
                    {rawIngredient?.rawIngredientsTests?.length ? (
                        <>
                            {rawIngredient?.rawIngredientsTests?.map((test) => (
                                <tr
                                    className="wrap sm:table-row mb-2 sm:mb-0"
                                    key={test.id}
                                >
                                    <td className="p-3">
                                        {test.test.test_code}
                                    </td>
                                    <td className="p-3">
                                        {test.attributes.map((attribute) => (
                                            <div key={attribute.id}>
                                                {`${attribute.name}: ${attribute.attributes.value}`}
                                            </div>
                                        ))}
                                    </td>
                                    <td className="p-3">
                                        {test.results.map((result) => (
                                            <div key={result.id}>
                                                {`${result.name}: ${result.results.value}`}
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </>
                    ) : (
                        <tr>
                            <TableCeil
                                text="No Tests found"
                                className="p-3 text-gray-500"
                                colSpan={TABLE_HEADERS.length}
                            />
                        </tr>
                    )}
                </tbody>
            </TabTable>
        </div>
    );
};
