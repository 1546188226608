import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { defaultToastOptions, queryKeys, toastTexts } from "common/constants";
import { Button } from "components/shared/button";
import { InputWrapper } from "components/shared/inputWrapper";
import { useUpdateSubIngredient } from "../queries";
import { ISubIngredient } from "common/types";
import { queryClient } from "libraries/queryProvider";
import { useURLStateSync } from "common/hooks";
import { getErrorMessage, getQueryKey } from "common/utils";
import {
    IPreparedSubIngredient,
    ISubIngredientsResponse,
} from "../libs/types/subIngredientsResponse";

interface IProps {
    handleClose: () => void;
    subIngredient: ISubIngredient;
}

const UpdateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

export const UpdateSubIngredientForm: FC<IProps> = ({
    handleClose,
    subIngredient,
}) => {
    const { mutate: updateSubIngredient } = useUpdateSubIngredient();
    const { sorting, getParamsString } = useURLStateSync();
    const { id = "" } = useParams();

    const formik = useFormik({
        initialValues: {
            name: subIngredient.name || "",
        },
        validationSchema: UpdateSchema,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const subIngredientsKey = getQueryKey(
                queryKeys.subIngredients,
                sorting,
                getParamsString,
            );

            const previousSubIngredients =
                queryClient.getQueryData<ISubIngredientsResponse>(
                    subIngredientsKey,
                );

            if (previousSubIngredients) {
                queryClient.setQueryData(subIngredientsKey, {
                    ...previousSubIngredients,
                    rows: previousSubIngredients.rows.map(
                        (item: IPreparedSubIngredient) =>
                            item.id === subIngredient.id
                                ? {
                                      ...item,
                                      ...values,
                                  }
                                : item,
                    ),
                });
            }

            const previousData = queryClient.getQueryData([
                queryKeys.subIngredients,
                id,
            ]);

            queryClient.setQueryData([queryKeys.subIngredients, id], {
                ...subIngredient,
                ...values,
            });

            updateSubIngredient(
                {
                    id: subIngredient.id,
                    body: { name: values.name },
                },
                {
                    onSuccess: () => {
                        toast.dismiss(toastId);
                        handleClose();
                    },
                    onError: (error) => {
                        queryClient.setQueryData(
                            [queryKeys.subIngredient, id],
                            previousData,
                        );

                        queryClient.setQueryData(
                            subIngredientsKey,
                            previousSubIngredients,
                        );

                        toast.dismiss(toastId);

                        const message = getErrorMessage(error as AxiosError);

                        formik.setFieldError(
                            "name",
                            typeof message === "string"
                                ? message
                                : "An unexpected error occurred",
                        );
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, handleSubmit } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <InputWrapper
                isError={Boolean(errors.name && touched.name)}
                error={errors.name}
                label="Name"
            >
                <textarea
                    className="textarea textarea-bordered w-full flex-grow"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                />
            </InputWrapper>
            <div className="flex items-center justify-end gap-3 mt-4">
                <Button text="Cancel" variant="ghost" onClick={handleClose} />
                <Button text="Update" type="submit" />
            </div>
        </form>
    );
};
