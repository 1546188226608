import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { queryKeys } from "../constants";
import { IUser } from "../types";
import { getFullName } from "../utils";

export const useOwner = () => {
    const { keycloak } = useKeycloak();

    const {
        data: userProfile = {},
        isError,
        error,
        isLoading,
    } = useQuery<IUser>({
        queryKey: [queryKeys.userProfile],
        queryFn: () => keycloak.loadUserProfile(),
        enabled: keycloak.authenticated,
    });

    const { email, firstName, lastName } = userProfile;

    const checkOwner = useCallback(
        (ownerEmail: string) => email === ownerEmail,
        [email],
    );

    return {
        email,
        checkOwner,
        userProfile: {
            data: userProfile,
            isLoading,
            isError,
            error,
        },
        userName: getFullName(firstName, lastName),
    };
};
