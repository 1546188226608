import { SortingState } from "@tanstack/react-table";

export const getQueryKey = (
    itemKey: string,
    sorting: SortingState | undefined,
    getParamsString: () => string,
) => {
    const sortingKey = sorting?.length
        ? `${sorting[0].id}-${sorting[0].desc}`
        : undefined;

    const queryParams = getParamsString();
    const key = [itemKey, queryParams];

    if (sortingKey) {
        key.push(sortingKey);
    }

    return key;
};
