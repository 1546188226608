import React, { ChangeEvent, FC } from "react";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { SectionHeader } from "../SectionHeader";
import { InputLabel } from "../InputLabel";
import { RequiredDescription } from "../prediction/components/RequiredDescription";
import { FormikErrors } from "formik";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

const inputClassName =
    "bg-gray-50 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const RfTab: FC<IProps> = ({ values, errors, onChange }) => (
    <div className="flex flex-col gap-[20px]">
        <section className="flex flex-col gap-[40px]">
            <SectionHeader
                title="Enter phys-chem property"
                text="Mandatory for making the prediction."
            />
            <div className="flex flex-col flex-nowrap gap-2 mb-10">
                <div className="my-2 ml-6 w-full">
                    <RequiredDescription />
                </div>
                <div className="flex flex-nowrap gap-10">
                    <InputLabel
                        text="Melting Point (°C)"
                        tooltipText="The temperature at which solid substance transitions to liquid state."
                        errorText={errors.meltingPointDEGCOperaPred}
                        isRequired
                    >
                        <input
                            type="text"
                            className={inputClassName}
                            name="meltingPointDEGCOperaPred"
                            onChange={onChange}
                            value={values.meltingPointDEGCOperaPred}
                        />
                    </InputLabel>
                    <InputLabel
                        text="Surface Tension(dyn/cm)"
                        tooltipText="The Property of a liquid substance to adhere or spread  to skin surface."
                        errorText={errors.surfaceTension}
                        isRequired
                    >
                        <input
                            type="text"
                            className={inputClassName}
                            name="surfaceTension"
                            onChange={onChange}
                            value={values?.surfaceTension}
                        />
                    </InputLabel>
                </div>
            </div>
        </section>
    </div>
);
