import React from "react";
import { DesktopLink } from "./DesktopLink";
import { MAIN_NAV_ITEMS, USER_NAV_ITEMS } from "../libs";

export const DesktopMenu = () => (
    <>
        <div className="flex-initial align-middle">
            <div className="hidden md:flex items-center space-x-1">
                {MAIN_NAV_ITEMS.map((item) => (
                    <DesktopLink key={item.to} {...item} />
                ))}
            </div>
        </div>
        <div className="hidden md:flex item-center space-x-1 mr-2">
            {USER_NAV_ITEMS.map((item) => (
                <DesktopLink
                    key={item.to}
                    className="min-w-5 mx-2 px-1"
                    {...item}
                />
            ))}
        </div>
    </>
);
