import { ValueOf } from "common/utils";
import { ProfileItems } from "./profileItems";

export const ProfileConfig: Record<
    ValueOf<typeof ProfileItems>,
    Record<string, string>
> = {
    [ProfileItems.MY_PROFILE]: {
        title: "User account",
        subtitle:
            "Welcome to your SmartSafety User Account! Here, you can easily access and edit your profile information. For any questions, please contact us at info@smartsafety.com.",
    },
    [ProfileItems.SAVED_ITEMS]: {
        title: "Saved items",
        subtitle: "Welcome to your SmartSafety Saved Items.",
    },
} as const;
