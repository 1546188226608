import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type SortingState, type PaginationState } from "@tanstack/react-table";
import { type IFilter } from "common/types";

const abbreviation: Record<string, string> = {
    cir: "CIR",
} as const;

const getLabelFromKey = (key: string) =>
    key
        ?.split("_")
        .map((w) =>
            abbreviation?.[w]
                ? abbreviation[w]
                : w.charAt(0).toUpperCase() + w.slice(1),
        )
        .join(" ");

export const useURLStateSync = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [filters, setFilters] = useState<IFilter>({});
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [sorting, setSorting] = useState<SortingState>([]);

    const isInitialMount = useRef(true);
    const prevFiltersRef = useRef<IFilter | null>(null);
    const updateTimeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const parsedFilters: IFilter = {};
        params.forEach((value, key) => {
            if (
                key !== "offset" &&
                key !== "limit" &&
                key !== "sort" &&
                value !== "" &&
                value !== "undefined" &&
                value !== "null"
            ) {
                parsedFilters[key] = {
                    value,
                    label: getLabelFromKey(key),
                    filterValue: value === "yes" ? getLabelFromKey(key) : value,
                };
            }
        });

        const cleanedFilters = Object.entries(parsedFilters).reduce(
            (acc, [key, filter]) => {
                if (
                    key === "query" &&
                    filter.value === "" &&
                    filter.label === "Search"
                ) {
                    return acc;
                }
                acc[key] = filter;
                return acc;
            },
            {} as IFilter,
        );

        const offset = parseInt(params.get("offset") || "0", 10);
        const limit = parseInt(params.get("limit") || "10", 10);
        const newPagination = {
            pageIndex: Math.max(0, Math.floor(offset / limit)),
            pageSize: limit > 0 ? limit : 10,
        };

        const sortString = params.get("sort") || "";
        const parsedSorting: SortingState = sortString
            ? JSON.parse(sortString)
            : [];

        if (JSON.stringify(filters) !== JSON.stringify(cleanedFilters)) {
            setFilters(cleanedFilters);
        }
        if (JSON.stringify(pagination) !== JSON.stringify(newPagination)) {
            setPagination(newPagination);
        }
        if (JSON.stringify(sorting) !== JSON.stringify(parsedSorting)) {
            setSorting(parsedSorting);
        }
    }, [location.search]);

    const updateURL = useCallback(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        if (updateTimeoutRef.current) {
            clearTimeout(updateTimeoutRef.current);
        }

        updateTimeoutRef.current = setTimeout(() => {
            const searchParams = new URLSearchParams();

            Object.entries(filters)
                .filter(([_, filter]) => filter?.value)
                .forEach(([key, filter]) =>
                    searchParams.append(key, filter.value),
                );

            searchParams.set(
                "offset",
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            searchParams.set("limit", `${pagination.pageSize}`);

            if (sorting.length > 0) {
                searchParams.set("sort", JSON.stringify(sorting));
            }

            const newSearch = searchParams.toString();
            if (location.search !== `?${newSearch}`) {
                navigate({ search: newSearch }, { replace: true });
            }
        }, 300);
    }, [filters, pagination, sorting, location.search, navigate]);

    useEffect(() => {
        updateURL();
        return () => {
            if (updateTimeoutRef.current) {
                clearTimeout(updateTimeoutRef.current);
            }
        };
    }, [updateURL]);

    useEffect(() => {
        if (!isInitialMount.current && prevFiltersRef.current) {
            const prevFiltersString = JSON.stringify(prevFiltersRef.current);
            const currentFiltersString = JSON.stringify(filters);

            if (prevFiltersString !== currentFiltersString && !filters?.tab) {
                setPagination((prev) => ({
                    ...prev,
                    pageIndex: 0,
                }));
            }
        }
        prevFiltersRef.current = filters;
    }, [filters]);

    const getParamsString = useCallback(() => {
        const searchParams = new URLSearchParams();

        Object.entries(filters)
            .filter(([_, filter]) => filter?.value)
            .forEach(([key, filter]) => searchParams.append(key, filter.value));

        searchParams.set(
            "offset",
            `${pagination.pageIndex * pagination.pageSize}`,
        );
        searchParams.set("limit", `${pagination.pageSize}`);

        return searchParams.toString();
    }, [filters, pagination]);

    const addURLParams = useCallback(
        (params: Record<string, string>) => {
            const currentParams = new URLSearchParams(location.search);

            Object.entries(params).forEach(([key, value]) => {
                currentParams.set(key, value);
            });

            navigate({ search: currentParams.toString() }, { replace: true });
        },
        [location.search, navigate],
    );

    return {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
        addURLParams,
    };
};
