import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { InputBlock } from "../InputBlock";
import { InputLabel } from "../InputLabel";
import { SectionHeader } from "../SectionHeader";
import { FormikErrors } from "formik";
import { RequiredDescription } from "../prediction/components/RequiredDescription";

interface IProps {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
}

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const Rifm: FC<IProps> = ({ values, errors, onChange }) => {
    const [isOpenSelect, setIsOpenSelect] = useState({
        timesPredictedClass: false,
        sensis: false,
    });

    const handleOpenSelect = useCallback((name: keyof typeof isOpenSelect) => {
        setIsOpenSelect((curr) => ({
            timesPredictedClass: false,
            sensis: false,
            [name]: !curr[name],
        }));
    }, []);

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const syntheticEvent = {
                target: {
                    name: event.target.name,
                    value: event.target.value,
                },
            } as ChangeEvent<HTMLInputElement | HTMLSelectElement>;
            onChange(syntheticEvent);
        },
        [onChange],
    );

    const handleOpenSensis = useCallback(() => handleOpenSelect("sensis"), []);

    const handleOpenSensitiserPotency = useCallback(
        () => handleOpenSelect("timesPredictedClass"),
        [],
    );

    const isRequired = [values.timesSSBayesianNetwork, values.sensis].every(
        (it) => !it,
    );

    return (
        <div className="flex flex-col gap-[20px]">
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Enter experimental values"
                    text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                    text2="Recommended to improve prediction estimate."
                />

                <div className="primary-color text-base">
                    <span className="mr-1">Note:</span>
                    To calculate models, at least one optional parameter should
                    be entered.
                </div>
                <div className="flex flex-col flex-nowrap gap-2 mb-10">
                    <RequiredDescription />
                    <div className="flex flex-wrap gap-5 mb-10">
                        <InputBlock
                            title="Covalent binding to skin proteins"
                            subtitle="DPRA"
                        >
                            <InputLabel
                                text="DPRACys (% depleted)"
                                tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRACys}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="dpraAssayDPRACys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRACys}
                                />
                            </InputLabel>
                            <InputLabel
                                text="DPRALys (% depleted)"
                                tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRALys}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="dpraAssayDPRALys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRALys}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Keratinocyte activation"
                            subtitle="KeratinoSens™"
                        >
                            <InputLabel
                                text="EC1.5 (µM)"
                                tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input. If the EC1.5 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC1.5."
                                errorText={
                                    errors.keratinoSenseAssayJaworskaKEC15
                                }
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="keratinoSenseAssayJaworskaKEC15"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaKEC15
                                    }
                                />
                            </InputLabel>
                            <InputLabel
                                text="IC50 (µM)"
                                tooltipText="Concentration yielding 50 % reduction in cellular viability in the KeratinoSens™ assay. If the compound was inactive, use the highest tested concentration as an input."
                                errorText={
                                    errors.keratinoSenseAssayJaworskaIC50
                                }
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="keratinoSenseAssayJaworskaIC50"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaIC50
                                    }
                                />
                            </InputLabel>
                            <InputLabel
                                text="SENS-IS"
                                tooltipText="Potency classification according to the SENS-IS assay."
                                errorText={errors.sensis}
                                isRequired={isRequired}
                                isFull
                            >
                                <Select
                                    className="custom-select"
                                    open={isOpenSelect.sensis}
                                    onClick={handleOpenSensis}
                                    name="sensis"
                                    value={values?.sensis || ""}
                                    onChange={handleSelectChange}
                                    fullWidth
                                    displayEmpty
                                >
                                    <MenuItem value="1">
                                        Non sensitizer
                                    </MenuItem>
                                    <MenuItem value="2">Very weak</MenuItem>
                                    <MenuItem value="3">Weak</MenuItem>
                                    <MenuItem value="4">Moderate</MenuItem>
                                    <MenuItem value="5">Strong</MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Dendritic cell activation"
                            subtitle="h-CLAT"
                        >
                            <InputLabel
                                text="EC150 (ug/mL)"
                                tooltipText="Concentration yielding 150 % induction of the cell surface activation marker CD86 in the h-CLAT assay. If the compound was inactive, use the concentration of 5000 ug/mL as an input."
                                errorText={errors.hClatAssayEC150}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="hClatAssayEC150"
                                    onChange={onChange}
                                    value={values.hClatAssayEC150}
                                />
                            </InputLabel>
                            <InputLabel
                                text="CV75 (ug/mL)"
                                tooltipText="Concentration yielding 25 % reduction in cell viability in the h-CLAT assay. If the compound was inactive, use the concentration of 5000 ug/mL as an input."
                                errorText={errors.hClatAssayCV75}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="hClatAssayCV75"
                                    onChange={onChange}
                                    value={values.hClatAssayCV75}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock title="In silico" subtitle="TIMES-SS">
                            <InputLabel
                                text="Sensitiser potency"
                                tooltipText="The highest skin sensitisation class [1-3] for the compound and its potential metabolites as predicted by TIMES in silico model."
                                errorText={errors.timesSSBayesianNetwork}
                                isRequired={isRequired}
                                isFull
                            >
                                <Select
                                    className="custom-select"
                                    open={isOpenSelect.timesPredictedClass}
                                    onClick={handleOpenSensitiserPotency}
                                    name="timesSSBayesianNetwork"
                                    value={values?.timesSSBayesianNetwork || ""}
                                    onChange={handleSelectChange}
                                    displayEmpty
                                    fullWidth
                                >
                                    <MenuItem value="1">
                                        Non-sensitiser
                                    </MenuItem>
                                    <MenuItem value="2">Weak/moderate</MenuItem>
                                    <MenuItem value="3">Strong</MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>

                        <InputBlock title="In vivo" subtitle="LLNA">
                            <InputLabel
                                text="EC3 (ug/cm2)"
                                tooltipText="The dose in ug/cm2 that would give a stimulation index value of 3.0. If the compound was inactive, use the highest tested concentration as an input. If the EC3 value is greater than the IC50 value, then the assay is treated as negative and 2000 uM should be used for EC3."
                                errorText={errors.llna}
                                isFull
                            >
                                <input
                                    className={inputClassName}
                                    type="text"
                                    name="llna"
                                    onChange={onChange}
                                    value={values.llna}
                                />
                            </InputLabel>
                        </InputBlock>
                    </div>
                </div>
            </section>
        </div>
    );
};
