import React, { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { FormikErrors } from "formik";
import classNames from "classnames";
import { TabContent } from "../TabContent";
import { tabDataList } from "../../libs";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";

interface IProps {
    className?: string;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    skinDoctorCP: string;
    setSkinDoctorCP: Dispatch<SetStateAction<string>>;
    isMichaelAcceptor: boolean;
    toggleMichaelAcceptor: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
}

export const DataModeling: FC<IProps> = ({
    className = "",
    selectedTab,
    setSelectedTab,
    skinDoctorCP,
    setSkinDoctorCP,
    isMichaelAcceptor,
    toggleMichaelAcceptor,
    onChange,
    values,
    errors,
}) => {
    return (
        <div className={classNames("w-full py-4 overflow-x-auto", className)}>
            <div className="flex flex-col gap-8 py-2">
                <header className="flex items-center gap-2">
                    <div className="text-lg uppercase font-semibold text-primary">
                        ENTER EXPERIMENTAL VALUES AND MOLECULAR DESCRIPTORS
                    </div>
                    <span className="text-gray-500">
                        Enter available data for approaches of interest.
                    </span>
                </header>

                <nav className="flex justify-between border-b mb-2 overflow-scroll">
                    {tabDataList.map(({ key, status }) => {
                        const isActive = key === selectedTab;
                        const isDisabled = status === "disabled";

                        return (
                            status !== "hidden" && (
                                <div
                                    key={key}
                                    data-tooltip-id={key}
                                    className={classNames(
                                        "cursor-pointer text-lg py-2 px-4 transition duration-200 ease-in-out text-center",
                                        {
                                            "border-b-2 border-blue-500 text-blue-500 font-semibold":
                                                isActive,
                                            "text-gray-400 cursor-not-allowed":
                                                isDisabled,
                                            "text-gray-700 hover:text-blue-500":
                                                !isActive && !isDisabled,
                                        },
                                    )}
                                    onClick={() => {
                                        if (!isDisabled) {
                                            setSelectedTab(key);
                                        }
                                    }}
                                >
                                    {key}
                                </div>
                            )
                        );
                    })}
                </nav>
            </div>
            <section className="values_content">
                <TabContent
                    tab={selectedTab}
                    onChange={onChange}
                    skinDoctorCP={skinDoctorCP}
                    setSkinDoctorCP={setSkinDoctorCP}
                    isMichaelAcceptor={isMichaelAcceptor}
                    toggleMichaelAcceptor={toggleMichaelAcceptor}
                    values={values}
                    errors={errors}
                />
            </section>
        </div>
    );
};
