import React, { ElementType, FC } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    describeBusiness,
    FORM_FIELD_DATA,
    generateReportTime,
    generateReportTools,
    industryType,
    TitleData,
} from "../libs";
import { GradientButton } from "components/shared";
import { useUpdateProfileInfo } from "pages/profile/libs";
import { IOption, IUserProfileInfo } from "common/types";
import { queryClient } from "libraries/queryProvider";
import { queryKeys } from "common/constants";
import { InputField } from "./InputField";
import { DropdownField } from "./DropdownField";

interface IProps {
    nextStep: () => void;
    prevStep: () => void;
    profileInfo?: IUserProfileInfo;
}

const validationSchema = Yup.object({
    organization: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name cannot be longer than 100 characters")
        .required("Name is required"),
    industry_type: Yup.string().required("Email is required"),
    describe_industry: Yup.string().required("Role is required"),
    generate_report_time: Yup.string().required("Role is required"),
    generate_report_tools: Yup.string(),
});



export const AboutBusiness: FC<IProps> = ({
    nextStep,
    prevStep,
    profileInfo,
}) => {
    const { mutate: updateProfileInfo } = useUpdateProfileInfo();

    const initialValues = {
      organization: profileInfo?.organization || "",
      industry_type: profileInfo?.industry_type || "",
      describe_industry: profileInfo?.describe_industry || "",
      generate_report_time: profileInfo?.generate_report_time || "",
      generate_report_tools: profileInfo?.generate_report_tools || "",
  };

    const { handleSubmit, values, handleChange, errors, touched, handleBlur } =
        useFormik({
            initialValues,
            validationSchema,
            validateOnChange: true,
            validateOnBlur: true,
            onSubmit: (values) => {
                updateProfileInfo(
                    {
                        organization: values.organization,
                        industry_type: values.industry_type,
                        describe_industry: values.describe_industry,
                        generate_report_time: values.generate_report_time,
                        generate_report_tools: values.generate_report_tools,
                    },
                    {
                        onSuccess() {
                            queryClient.refetchQueries({
                                queryKey: [queryKeys.userProfileInfo],
                            });
                            nextStep();
                        },
                    },
                );
            },
        });

        const fields: {
          component: ElementType;
          name: keyof typeof values;
          options?: IOption[];
          required?: boolean;
        }[] = [
          { component: InputField, name: "organization" },
          { component: DropdownField, name: "industry_type", options: industryType },
          { component: DropdownField, name: "describe_industry", options: describeBusiness },
          { component: DropdownField, name: "generate_report_time", options: generateReportTime },
          { component: DropdownField, name: "generate_report_tools", options: generateReportTools, required: false },
        ];

    return (
        <div className="w-[550px] h-screen flex flex-col items-center justify-between pt-16">
            <div className="w-full">
                <h2 className="font-semibold text-2xl mb-4 leading-tight">
                    {TitleData[1].pageTitle}
                </h2>
                <form className="w-full">
                {fields.map(({ component: Component, name, options, required = true }) => (
                        <Component
                            key={name}
                            error={errors[name]}
                            touched={touched[name]}
                            value={values[name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={FORM_FIELD_DATA[name].label}
                            name={FORM_FIELD_DATA[name].name}
                            placeholder={FORM_FIELD_DATA[name].placeholder}
                            options={options}
                            required={required}
                        />
                    ))}
                </form>
            </div>
            <div className="w-full mb-16 inline-flex gap-x-3 justify-end">
                <GradientButton
                    onClick={handleSubmit}
                    label="Save"
                    variant="filled"
                    clasName="w-[168px]"
                    type="submit"
                />
                <GradientButton
                    onClick={prevStep}
                    label="Back"
                    variant="outlined"
                    clasName="w-[168px]"
                />
            </div>
        </div>
    );
};
