import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilePdf,
    faFileDownload,
    faSyncAlt,
    faFile,
    faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
    ModalTemplate,
    Title,
    DataTable,
    ActionButton,
} from "components/shared";
import { useModal } from "common/hooks";
import { TabContentTemplate } from "pages/subIngredient/subIngredientProfile/components";
import { useSDSFiles } from "pages/subIngredient/subIngredientProfile/queries";
import { type SafetyDataSheet } from "common/types";
import { defaultErrorToastUpdate, defaultToastOptions } from "common/constants";
import { handleDownloadFile } from "common/utils";

export const SafetyDataSheets = () => {
    const [selectedFile, setSelectedFile] = useState<string>();
    const [currentFile, setCurrentFile] = useState<string | null>(null);
    const [fileDetails, setFileDetails] = useState<
        Record<string, Record<string, string>>
    >({});
    const [loadingFiles, setLoadingFiles] = useState<Record<string, boolean>>(
        {},
    );
    const { modalRef, openModal, closeModal } = useModal();
    const fileDetailsModal = useModal();

    const { id = "" } = useParams();

    const {
        sdsFiles = [],
        updateSDSFiles,
        isLoading,
        getFileStructure,
        csvFile,
        isLoadingCSV,
    } = useSDSFiles(id);

    const handlePreviewClick = useCallback(
        (link: string) => {
            setSelectedFile(link);
            openModal();
        },
        [openModal],
    );

    const handleUpdateClick = useCallback(() => {
        updateSDSFiles();
    }, [updateSDSFiles]);

    const handleDetailClick = useCallback(
        (filename: string) => {
            if (Object.values(loadingFiles)?.some(Boolean)) {
                toast.warn(
                    "Please wait file is being processed...",
                    defaultToastOptions,
                );
                return;
            }

            if (fileDetails?.[filename]) {
                setCurrentFile(filename);
                fileDetailsModal.openModal();
                return;
            }

            setCurrentFile(filename);
            setLoadingFiles((state) => ({ ...state, [filename]: true }));

            const toastId = toast.loading(
                "Please wait while processing...",
                defaultToastOptions,
            );

            getFileStructure(filename, {
                onSuccess: (data) => {
                    setFileDetails((state) => ({
                        ...state,
                        [filename]: data,
                    }));
                    toast.dismiss(toastId);
                    fileDetailsModal.openModal();
                },
                onError(error) {
                    console.error("Error fetching file structure:", error);
                    toast.update(toastId, defaultErrorToastUpdate);
                },
                onSettled: () => {
                    setLoadingFiles((state) => ({
                        ...state,
                        [filename]: false,
                    }));
                },
            });
        },
        [fileDetails, loadingFiles, getFileStructure],
    );

    const handleDownloadAllClick = useCallback(() => {
        if (!csvFile) return;

        handleDownloadFile(csvFile, "sub-ingredient.csv");
    }, [csvFile]);

    const sdsColumnsConfig: ColumnDef<SafetyDataSheet>[] = useMemo(
        () => [
            {
                accessorKey: "supplier",
                header: () => <span className="font-medium">Supplier</span>,
                enableSorting: false,
            },
            {
                accessorKey: "link",
                header: () => (
                    <span className="font-medium">SDS Download url</span>
                ),
                cell: ({ row }) => (
                    <a
                        href={row.original.link}
                        target="_blank"
                        rel="noreferrer noopenner"
                        download
                        className="flex items-center px-3 py-1.5 space-x-2 text-blue-600 hover:text-blue-500 transition-colors"
                    >
                        <FontAwesomeIcon
                            icon={faFileDownload}
                            className="text-current"
                            size="lg"
                        />
                        <span className="text-sm font-medium">Download</span>
                    </a>
                ),
                enableSorting: false,
            },
            {
                accessorKey: "view",
                header: () => <span className="font-medium">View</span>,
                cell: ({ row }) => (
                    <button
                        onClick={() => handlePreviewClick(row.original.link)}
                        className="flex items-center px-3 py-1.5 space-x-2 text-red-500 hover:text-red-400 transition-colors"
                    >
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            size="lg"
                            className="text-current"
                        />
                        <span className="text-sm font-medium">View</span>
                    </button>
                ),
                enableSorting: false,
            },
            {
                accessorKey: "details",
                header: () => <span className="font-medium">Details</span>,
                cell: ({ row }) => (
                    <button
                        onClick={() => handleDetailClick(row.original.filename)}
                        className="flex items-center px-3 py-1.5 space-x-2 text-gray-600 hover:text-gray-500 transition-colors"
                    >
                        <FontAwesomeIcon
                            icon={faFile}
                            size="lg"
                            className="text-current"
                        />
                        <span className="text-sm font-medium">
                            View Details
                        </span>
                    </button>
                ),
                enableSorting: false,
            },
        ],
        [handleDetailClick],
    );

    return (
        <TabContentTemplate>
            <div className="flex justify-between items-center">
                <Title text="Safety Data Sheets" />
                <ActionButton
                    icon={faSyncAlt}
                    btnText="Refetch SDS Files"
                    loadingText="Refetching..."
                    isLoading={isLoading}
                    onClick={handleUpdateClick}
                />
            </div>

            <DataTable
                className="!w-[60%] bg-white rounded-lg shadow-md overflow-x-auto mb-2"
                columns={sdsColumnsConfig}
                data={sdsFiles}
            />

            <ActionButton
                icon={faFileCsv}
                btnText={
                    Boolean(csvFile)
                        ? "Download the extracted data from all SDS Sheets"
                        : "No extracted data available"
                }
                isLoading={isLoadingCSV}
                onClick={handleDownloadAllClick}
                disabled={!Boolean(csvFile)}
            />

            <ModalTemplate
                text="View Details"
                modalRef={modalRef}
                onClose={closeModal}
                className="w-screen h-screen overflow-auto"
            >
                {selectedFile ? (
                    <iframe
                        src={selectedFile}
                        width="100%"
                        height="92%"
                        title="PDF Preview"
                    />
                ) : (
                    <p>No file selected</p>
                )}
            </ModalTemplate>
            <ModalTemplate
                text={`View SDS File Details for ${currentFile || ""}`}
                modalRef={fileDetailsModal.modalRef}
                onClose={fileDetailsModal.closeModal}
            >
                {fileDetails?.[currentFile || ""] ? (
                    <div className="flex flex-col h-full">
                        <div className="flex-grow overflow-y-auto shadow-sm">
                            <table className="w-full border-collapse border border-gray-200 text-left bg-white rounded-lg shadow-sm">
                                <thead className="bg-gray-300 text-gray-800 text-sm uppercase font-medium">
                                    <tr>
                                        <th className="border border-gray-200 bg-gray-400 px-4 py-2">
                                            Field
                                        </th>
                                        <th className="border border-gray-200 bg-gray-400  px-4 py-2">
                                            Value
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Object.entries(
                                        fileDetails[currentFile || ""],
                                    ).map(([key, value], index) => (
                                        <tr
                                            key={key}
                                            className={
                                                index % 2 === 0
                                                    ? "bg-gray-50 hover:bg-gray-100"
                                                    : "bg-white hover:bg-gray-100"
                                            }
                                        >
                                            <td className="border border-gray-200 px-4 py-2 font-medium text-gray-700 capitalize">
                                                {key.replace(/_/g, " ")}
                                            </td>
                                            <td className="border border-gray-200 px-4 py-2 text-gray-600 break-words">
                                                {value || (
                                                    <span className="italic text-gray-400">
                                                        N/A
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center h-full">
                        <p className="text-gray-500">No file data available</p>
                    </div>
                )}
            </ModalTemplate>
        </TabContentTemplate>
    );
};
