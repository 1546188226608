import React, { FC, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { mosReportService } from "services/mos-report-service";
import { useGetUserProfile } from "pages/profile/libs";
import { mapReport } from "pages/formulas/formula-profile/components/tabs/mosTab/utils";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { useGetFormula } from "pages/formulas/formula-profile/queries";

interface IProps {
    className?: string;
    title?: string;
    mosSubIngredients?: IPreparedSubIngredient[];
}

const icons = [
    {
        format: "pdf",
        icon: faFilePdf,
        color: "#dc3545",
        hoverBg: "hover:bg-red-100",
    },
    {
        format: "docx",
        icon: faFileWord,
        color: "blue",
        hoverBg: "hover:bg-blue-100",
    },
];

export const ReportGeneration: FC<IProps> = ({
    className = "",
    title = "Create report",
    mosSubIngredients = [],
}) => {
    const { data: userInfo } = useGetUserProfile();
    const { data: formula } = useGetFormula();

    const mappedReportData = useMemo(
        () => mapReport({ data: mosSubIngredients, formula, userInfo }),
        [mosSubIngredients, formula, userInfo],
    );

    const handleReportGeneration = useCallback(
        (format: "pdf" | "docx") => {
            mosReportService.generateMOSReport(mappedReportData, format);
        },
        [mappedReportData],
    );

    return (
        <div className={classNames("flex items-center", className)}>
            <span className="mr-2 font-medium">{title}:</span>

            {icons.map(({ format, icon, color, hoverBg }) => (
                <button
                    key={format}
                    onClick={() =>
                        handleReportGeneration(format as "pdf" | "docx")
                    }
                    aria-label={`Generate report in ${format.toUpperCase()} format`}
                    className={`p-1 rounded-full ${hoverBg} transition-colors`}
                >
                    <FontAwesomeIcon icon={icon} size="lg" color={color} />
                </button>
            ))}
        </div>
    );
};
