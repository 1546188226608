import React, { FC, useMemo } from "react";
import { IFormulaRawIngredient } from "pages/formulas/formula-profile/types";
import BigNumber from "bignumber.js";

interface IProps {
    subIngredients: IFormulaRawIngredient["subIngredients"];
    parentWeight: string;
}

const formatWeightPercent = (value: number | null) =>
    value
        ?.toString()
        .replace(/(\.\d*?[1-9])0+$/, "$1")
        .replace(/\.$/, "");

const getWeightPercent = (
    ingredientWeight: string | number | null,
    parentWeight: number,
) => {
    if (ingredientWeight === null || ingredientWeight === undefined) {
        return null;
    }

    const weight = new BigNumber(ingredientWeight);
    const parent = new BigNumber(parentWeight);

    const result = weight.multipliedBy(parent).dividedBy(100);
    return result.toNumber();
};

export const SubIngredientWeightList: FC<IProps> = ({
    subIngredients,
    parentWeight,
}) => {
    const parentWeightNumber = Number(parentWeight);

    const sortedSubIngredients = useMemo(() => {
        return subIngredients
            ?.filter((sub) => sub?.name)
            .sort((a, b) => {
                if (!a?.name) return 1;
                if (!b?.name) return -1;
                return a.name.localeCompare(b.name);
            });
    }, [subIngredients]);

    return (
        <>
            {sortedSubIngredients?.map((sub, index) => (
                <div className="text-center" key={index}>
                    {formatWeightPercent(
                        getWeightPercent(
                            sub.rawSub?.weight_percent,
                            parentWeightNumber,
                        ),
                    )}
                </div>
            ))}
        </>
    );
};
