import React, { ReactNode } from "react";
import { GradientButton } from "../gradient-button";

interface IProps {
    title: string | undefined;
    filledButtonTitle?: string;
    openCreateModal?: () => void;
    children?: ReactNode;
    subtitle?: string;
}

export const HeaderTemplate: React.FC<IProps> = ({
    title,
    filledButtonTitle,
    openCreateModal,
    children,
    subtitle
}) => {
    return (
        <header className="px-4 sm:px-6 py-4 border-b border-[#113261]">
            <div className="flex flex-wrap items-center justify-between !mt-[80px] pb-4 sm:mt-8 space-y-4 sm:space-y-0">
                <div>
                    <h1 className="w-full sm:w-auto text-xl sm:text-2xl font-bold text-center sm:text-left text-[#113261]">
                        {title}
                    </h1>
                    {subtitle && <h2 className="mb-[-30px] mt-2 font-medium text-base text-[#A1A1A1]">{subtitle}</h2>}
                </div>
                <div className="flex justify-center flex-wrap sm:justify-end w-full sm:w-auto gap-2 pr-0 sm:pr-5">
                    {children}

                    {filledButtonTitle && (
                        <GradientButton
                            onClick={openCreateModal}
                            label={`Create ${filledButtonTitle}`}
                            variant="filled"
                        />
                    )}
                </div>
            </div>
        </header>
    );
};