import React, { FC } from 'react'

interface IProps {
   completed?: number;
   incompleted?: number;
}

export const CompositionStatusBlock: FC<IProps> = ({ completed, incompleted }) => {

   return (
      <div className="bg-white h-32 shadow-md rounded-lg w-full my-14 flex justify-around items-center text-[#57595A]">
         <div>
            <div className='font-medium text-4xl text-center'>{completed}</div>
            <p className='font-light text-base'>completed</p>
         </div>
         <div>
            <div className='font-medium text-4xl text-center'>{incompleted}</div>
            <p className='font-light text-base'>in draft</p>
         </div>
      </div>
   )
}