import React, { ChangeEvent, FC } from 'react'

import { IOption, IOptionNumber } from 'common/types';
import { DropdownWrapper, InputWrapper } from 'components/shared';

interface IProps {
   error?: string;
   touched?: boolean;
   value: string;
   onChange: (e: ChangeEvent<HTMLSelectElement>, filterValue?: string | undefined) => void
   name: string;
   label: string;
   options: IOption[] | IOptionNumber[] | undefined
   required?: boolean;
}

export const DropdownField: FC<IProps> = ({
   error, 
   touched, 
   value, 
   onChange, 
   name,
   label,
   options,
   required = true
}) => {
  return (
    <>
      <InputWrapper label={label} required={required} className='mt-2'>
         <DropdownWrapper
           value={value}
           name={name}
           onChange={onChange}
           options={options ?? []}
           defaultLabel='----------'
           isError={Boolean(error && touched)}
         />
      </InputWrapper>
         {
            error && touched && (
              <div className="absolute text-red-600 text-xs mt-[2px]">
                <span>{error}</span>
              </div>
            )
         }    
      </>
  )
}
