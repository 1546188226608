import React, { FC, ReactNode } from "react";
import { SearchBlock } from "pages/dashboard/components";
import { FilterBlock, FilterStatusList } from "../shared";
import { IFilter } from "common/types";

interface IProps {
   children?: ReactNode;
   className?: string;
   placeholder?: string;
   searchValue?: string;
   updateSearch?: (value: string) => void;
   isFilterVisible: boolean;
   onFilterVisible: () => void;
   filters: IFilter;
   onRemoveFilter: (key: string) => void;
}

export const SearchFilterPanel: FC<IProps> = ({
   children,
   placeholder,
   searchValue,
   updateSearch,
   isFilterVisible = false,
   onFilterVisible,
   filters,
   onRemoveFilter,
}) => {
    

   return (
      <div className="px-7 mb-5">
         <div className="flex justify-between w-full ">
            <SearchBlock
               className="w-3/4 rounded-lg"
               placeholder={placeholder}
               searchValue={searchValue}
               updateSearch={updateSearch}
            />
            <FilterBlock
               isFilterVisible={isFilterVisible}
               onFilterVisible={onFilterVisible}
            />
         </div>
         {isFilterVisible && (
            <div className="w-full">
               {children}
            </div>
         )}
         <FilterStatusList filters={filters} onRemoveFilter={onRemoveFilter} />
      </div>  
   );
};