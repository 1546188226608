import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import { TooltipWrapper } from "components/shared";

interface IProps {
    text: string;
    children: ReactNode;
    isFull?: boolean;
    button?: ReactNode | null;
    tooltipText?: string;
    errorText?: string | null;
    isWarn?: boolean;
    isRequired?: boolean;
}

export const InputLabel: FC<IProps> = ({
    text,
    children,
    isFull = true,
    button = null,
    tooltipText,
    errorText = null,
    isWarn = false,
    isRequired = false,
}) => {
    return (
        <div className="flex flex-col gap-2 relative mb-6">
            <span className="flex items-center text-sm text-gray-700 cursor-default">
                <span className="mr-2 text-nowrap">
                    {text}
                    {isRequired && <span className="text-red-500">*</span>}
                </span>
                {button}
                {tooltipText && (
                    <TooltipWrapper title={tooltipText}>
                        <span className="ml-2 w-5 h-5 bg-blue-100 rounded-full flex items-center justify-center cursor-pointer">
                            ?
                        </span>
                    </TooltipWrapper>
                )}
            </span>
            <div
                className={classNames("w-[70%]", {
                    "w-full": isFull,
                })}
            >
                {children}
            </div>
            {errorText && (
                <span className="text-red-500 text-sm">{errorText}</span>
            )}
            {isWarn && (
                <span className="text-yellow-600 text-sm">
                    Experiment providing highest Value of Information (VoI)
                </span>
            )}
        </div>
    );
};
