import React, { FC, useCallback, useState } from "react";
import { ProfileCard } from "../profile-card/ProfileCard";
import { PersonalInfo } from "../personal-info/PersonalInfo";
import { IUser } from "@/common/types";
interface IProps {
    userInfo: IUser | undefined;
    location: Pick<IUser, "country_name" | "city"> | undefined;
    refetchUser: () => void;
    title: string;
    subtitle: string;
}

export const UserProfile: FC<IProps> = ({
    userInfo, 
    location, 
    refetchUser,
    title,
    subtitle
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const handleOnEditClick = useCallback(() => {
        setIsEdit((curr) => !curr);
    }, [isEdit]);

    const {
        email = "",
        firstName = "",
        lastName = "",
        attributes = {},
    } = userInfo || {};

    const { phone = [], organisation = [] } = attributes;

    return (
        <>
            <h2 className="font-semibold text-2xl">{title}</h2>
            <p className="font-medium text-base text-[#A1A1A1] pb-4">{subtitle}</p>
            <ProfileCard
                name={`${firstName} ${lastName}`}
                position={""}
                location={location}
            />
            <PersonalInfo
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone[0]}
                company={organisation[0]}
                editMode={isEdit}
                onEditClick={handleOnEditClick}
                refetch={refetchUser}
            />
        </>
    )
};