import React, { ChangeEvent, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Title } from "components/shared";
import { LeftTable, RightTable, SourceLink } from "./components";
import { TabContentTemplate } from "components/shared/tabs/TabContentTemplate";
import { useUpdateSubIngredient } from "pages/subIngredient/subIngredientProfile/queries";
import { useSubIngredientData } from "pages/subIngredient/subIngredientProfile/hooks";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    toastTexts,
} from "common/constants";
import FileUpload from "./components/FileUpload";
import { queryClient } from "libraries/queryProvider";
import { useSubIngredientStore } from "../../../store";

export const BasicInfoTab = () => {
    const { key } = useSubIngredientStore();
    const { mutate: updateSubIngredient, isPending } = useUpdateSubIngredient();
    const { id = "" } = useParams();

    const { data, setData, subIngredient } = useSubIngredientData(id);

    const handleChangeData = useCallback(
        (
            e: ChangeEvent<
                HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
            >,
        ) => {
            const { name, value } = e.target;

            setData((curr) => ({
                ...curr,
                [name]: value,
            }));
        },
        [setData],
    );

    const handleChangeMultiSelect = useCallback(
        (selected: string[], name: string) => {
            setData((curr) => ({
                ...curr,
                [name]: selected?.join(",") || "",
            }));
        },
        [setData],
    );

    const handleSubmit = useCallback(() => {
        if (!subIngredient?.id || !data) return;

        updateSubIngredient(
            { id: `${subIngredient.id}`, body: data },
            {
                onSuccess: (responseData) => {
                    queryClient.setQueryData(key, responseData);
                    setData(responseData);
                    toast.success(toastTexts.updated, defaultToastOptions);
                },
                onError: () => {
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );
    }, [
        data,
        subIngredient?.id,
        updateSubIngredient,
        queryClient,
        key,
        setData,
    ]);

    const leftTableProps = {
        data,
        smiles: subIngredient?.unique_smiles,
        onChangeData: handleChangeData,
        onChangeMultiSelect: handleChangeMultiSelect,
    };

    const rightTableProps = {
        data,
        onChangeData: handleChangeData,
    };

    return (
        <TabContentTemplate>
            <div className="flex justify-between">
                <div className="">
                    <Title text="Basic Information" />
                    <FileUpload />
                </div>
                <SourceLink />
            </div>
            <div className="md:flex">
                <div className="w-full flex">
                    <LeftTable {...leftTableProps} />
                    <RightTable {...rightTableProps} />
                </div>
            </div>
            <Button
                classNameContainer="md:flex lg:pb-5 mt-2"
                isLoading={isPending}
                onClick={handleSubmit}
                text="Save basic information"
            />
        </TabContentTemplate>
    );
};
