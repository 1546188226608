import classNames from 'classnames';
import { ColumnDef, Row } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify';

import { 
  backendKeys, 
  defaultErrorToastUpdate, 
  defaultSuccessToastUpdate, 
  defaultToastOptions, 
  queryKeys, 
  routerKeys, 
  toastTexts 
} from 'common/constants';
import { IFormulaSaved } from 'common/types';
import { DataTable, IconLink, SaveButton } from 'components/shared';
import { IFormulaItem } from 'pages/formulas';
import { 
  useFullSavedFormulas, 
  useGetSavedData, 
  useRemoveSavedData 
} from 'pages/profile/libs';
import { formulaColumnsConfig } from '../libs/columnsConfigs';

export const FormulaTable = () => {
  const { data: savedFormulas = [], refetch } = useFullSavedFormulas();
  const { mutate: removeSavedFormulasMutation } = useRemoveSavedData<number>(backendKeys.api.formulas.saved);
  const { refetch: refetchSavedFormulas } = useGetSavedData<IFormulaSaved>(
      queryKeys.savedFormulas, 
      backendKeys.api.formulas.saved
  );

  const handleRemove = useCallback((id: number) => {
      const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
      removeSavedFormulasMutation(id, {
          onSuccess: () => {
              toast.update(toastId, {
                  ...defaultSuccessToastUpdate,
                  render: toastTexts.success,
              });
              if (refetch) {
                  refetch();
              }
              if (refetchSavedFormulas) {
                  refetchSavedFormulas();
              }
          },
          onError: () => {
              toast.update(toastId, defaultErrorToastUpdate);
          },
      });
  }, []);

  const columns = useMemo<ColumnDef<IFormulaItem>[]>(() => {
    const staticColumns = [
      {
        id: "unsaved",
        accessorKey: "unsaved",
        header: () => <span>Unsaved</span>,
        cell: ({ row }: { row: Row<IFormulaItem> }) => (
          <SaveButton
            className="flex items-center px-2 hover:cursor-pointer"
            id={row.original.id}
            onAction={handleRemove}
            items={savedFormulas}
            attribute="id"
          />
        ),
      },
      {
        id: "edit_formula",
        accessorKey: "edit_formula",
        header: () => <span>Edit</span>,
        cell: ({ row }: { row: Row<IFormulaItem> }) => (
          <IconLink to={routerKeys.formulas} id={row.original.id} />
        ),
      },
    ];
  
    const dynamicColumns = formulaColumnsConfig.map(({ key, label, format }) => ({
      accessorKey: key,
      header: () => <span>{label}</span>,
      ...(format && {
        cell: ({ row }: { row: Row<IFormulaItem> }) => <span>{format(row.getValue(key))}</span>,
      }),
    }));
  
    return [...dynamicColumns, ...staticColumns];
  }, [savedFormulas, savedFormulas.length, handleRemove]);

  return (
    <div
    className={classNames(
        "bg-profile flex flex-col w-full min-h-[230px] rounded-tl-2xl rounded-tr-2xl px-10 py-4 shadow-md mb-10"
    )}
>
    <h1 className="gray-color text-xl font-medium mb-2">
        Saved Formulas
    </h1>
    <DataTable
        className="pb-4"
        data={savedFormulas}
        columns={columns}
    />
</div>
  )
}

