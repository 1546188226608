import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { checkItemsId } from "common/utils";
import classNames from "classnames";

interface IProps<T> {
    id: number;
    onAction: (id: number) => void;
    items: T[];
    attribute: keyof T;
    className?: string;
    isDisabled?: boolean;
}

export const SaveButton = <T,>({
    id,
    onAction,
    items,
    attribute,
    className = "",
    isDisabled = false,
}: IProps<T>) => {
    const isSaved = checkItemsId(items, id, attribute);

    const buttonStyle = {
        disabled: isSaved,
        "cursor-pointer": !isSaved,
    };

    const handleButtonClick = useCallback(() => onAction(id), [id]);

    const { icon, color, title } = isSaved
        ? {
              icon: faCheckCircle,
              color: "#C9C9C9",
              title: "Formula saved",
          }
        : {
              icon: faPlusCircle,
              color: "secondary",
              title: "Bookmark formula",
          };

    return (
        <div className={`flex items-center px-2 ${className}`}>
            <button
                className={classNames("text-secondary-info", buttonStyle)}
                onClick={handleButtonClick}
                disabled={isDisabled && isSaved}
                title={title?.toString()}
            >
                <FontAwesomeIcon color={color} size="lg" icon={icon} />
            </button>
        </div>
    );
};
