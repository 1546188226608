import { IFilter } from "common/types";

export const defaultFilterValues: IFilter = {
    fda_product_classification: {
        value: "",
        label: "",
        filterValue: "",
    },
    product_type: {
        value: "",
        label: "",
        filterValue: "",
    },
    gluten: {
        value: "",
        label: "",
        filterValue: "",
    },
    animal_derived: {
        value: "",
        label: "",
        filterValue: "",
    },
    animal_testing: {
        value: "",
        label: "",
        filterValue: "",
    },
    cfi_acceptable: {
        value: "",
        label: "",
        filterValue: "",
    },
    vegan: {
        value: "",
        label: "",
        filterValue: "",
    },
    query: {
        value: "",
        label: "",
        filterValue: "",
    },
};
