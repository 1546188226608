import React, { FC } from "react";

interface IProps {
    smiles?: string | null;
}

const MoleculeDisplay: FC<IProps> = ({ smiles }) => (
    <figure className="flex justify-center mb-4 border border-gray-100 bg-white rounded-lg">
        {smiles ? (
            <img
                src={`https://chemidconvert.cloud.douglasconnect.com/v1/asSvg?smiles=${encodeURIComponent(
                    smiles,
                )}&width=140&height=120`}
                className="max-w-[140px] max-h-[120px] object-contain"
                alt={`Molecule representation for SMILES: ${smiles}`}
                loading="lazy"
            />
        ) : (
            <figcaption className="text-gray-500">
                No molecule data available
            </figcaption>
        )}
    </figure>
);

export default MoleculeDisplay;
