interface FormFieldData {
   label: string;
   name: string;
   placeholder?: string;
}

export const  FORM_FIELD_DATA: Record<string, FormFieldData> = {
   name: {
      label: 'What’s your name?',
      name: 'first_name',
      placeholder: 'Your name',
   },
   email: {
      label: 'What’s your email?',
      name: 'email',
      placeholder: 'Your email',
   },
   role_group: {
      label: 'Select your role group.',
      name: 'role_group',
   },
   role: {
      label: 'Select your role.',
      name: 'role',
   },
   organization: {
      label: 'What’s your company’s name?',
      name: 'organization',
      placeholder: 'Your company’s name',
   },
   industry_type: {
      label: 'Industry Type',
      name: 'industry_type',
   },
   describe_industry: {
      label: 'How would you describe your business?',
      name: 'describe_industry',
   },
   generate_report_time: {
      label: 'How long does it take to generate a report in average?',
      name: 'generate_report_time',
   },
   generate_report_tools: {
      label: 'What do you currently use to generate your reports?',
      name: 'generate_report_tools',
   },
}