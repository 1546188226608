import React, { FC } from "react";
import { usePagination, DOTS, PaginationButton } from ".";

interface IProps {
    currentPage: number;
    totalCount: number;
    pageSize?: number;
    setCurrentPage: (page: number) => void;
    siblingCount?: number;
}

export const Pagination: FC<IProps> = ({
    currentPage,
    siblingCount = 1,
    setCurrentPage,
    totalCount,
    pageSize = 20,
}) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (paginationRange.length <= 2) {
        return null;
    }

    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPreviousPage = () => setCurrentPage(currentPage - 1);

    const handleUpdateCurrentPage = (pageNumber: number) =>
        setCurrentPage(pageNumber);

    const lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="flex flex-wrap items-center justify-center p-4">
            <PaginationButton
                className="btn-pagination-start"
                isDisabled={currentPage === 1}
                onClick={onPreviousPage}
                text="prev"
            />

            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return (
                        <PaginationButton
                            key={`dots-${index}`}
                            text={DOTS}
                            className="text-gray-500"
                        />
                    );
                }

                return (
                    <PaginationButton
                        key={pageNumber}
                        isActive={pageNumber === currentPage}
                        onClick={() =>
                            handleUpdateCurrentPage(Number(pageNumber))
                        }
                        text={pageNumber}
                    />
                );
            })}

            <PaginationButton
                className="btn-pagination-end"
                isDisabled={currentPage === lastPage}
                onClick={onNextPage}
                text="next"
            />
        </div>
    );
};
