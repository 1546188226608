import React, { FC, ReactNode, useState } from "react";
import { Swiper as SwiperClass } from 'swiper/types';
import {Swiper} from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './style.scss';

interface IProps {
   children: ReactNode
}
const styleBtn = "text-[#113261] cursor-pointer text-2.75xl hover:bg-black/20 rounded-full p-2 transition-colors duration-300"
export const Slider: FC<IProps> = ({children}) => {
   const [swiperRef, setSwiperRef] = useState<SwiperClass>();
   const [isBeginning, setIsBeginning] = useState(true);
   const [isEnd, setIsEnd] = useState(false);

   const prevHandler = () => {
      swiperRef?.slidePrev();
    };
  
    const nextHandler = () => {
      swiperRef?.slideNext();
    };

   return (
      <div className="flex items-center">
         <FontAwesomeIcon
            size="xl"
            icon={faChevronLeft}
            className={classNames(styleBtn, { "opacity-50 cursor-not-allowed": isBeginning })}
            onClick={isBeginning ? undefined : prevHandler}
         />
         <Swiper
         onSwiper={(swiper: SwiperClass) => {
            setSwiperRef(swiper);
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
            swiper.on("slideChange", () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
            });
         }}
            simulateTouch={false}
            slidesPerView={3.3}
            spaceBetween={20}
         >
            {children}
         </Swiper>
         <FontAwesomeIcon
            size="xl"
            icon={faChevronRight}
            className={classNames(styleBtn, { "opacity-50 cursor-not-allowed": isEnd})}
            onClick={isEnd ? undefined : nextHandler}
         />
      </div>
   )
}