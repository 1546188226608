import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "components/shared";
import { ISimilarItem } from "common/types";
import { buildLink } from "common/utils";
import classNames from "classnames";

interface IProps {
    title?: string;
    baseLink: string;
    links?: ISimilarItem[];
    handleResetForm: () => void;
    onClick?: (id: string) => void;
    submitButtonText?: string;
}

export const FormActions: FC<IProps> = ({
    title = "Similar ingredients:",
    baseLink = "",
    links = [],
    handleResetForm,
    onClick,
    submitButtonText = "Create",
}) => (
    <div className="flex flex-col gap-4 mt-4">
        <div
            className={classNames("text-sm text-gray-600", {
                "min-h-[136px]": links.length > 0,
            })}
        >
            {links.length > 0 && (
                <>
                    <h6 className="text-gray-500 font-semibold">{title}</h6>
                    <ul className="list-inside pl-4 pt-2">
                        {links.map(({ id, name }) => (
                            <li key={id}>
                                <Link
                                    className="primary-color hover:underline"
                                    to={
                                        onClick
                                            ? ""
                                            : buildLink(baseLink, `${id}`)
                                    }
                                    onClick={() => onClick && onClick(`${id}`)}
                                >
                                    {name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>

        <div className="flex items-center justify-end gap-3 mt-4">
            <Button text="Clear" onClick={handleResetForm} variant="ghost" />
            <Button text={submitButtonText} type="submit" />
        </div>
    </div>
);
