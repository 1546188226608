import React from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import * as Yup from "yup";
import {
    defaultErrorToastOptions,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { Button, InputWrapper } from "components/shared";
import { useCreateRawIngredientTradeName } from "pages/rawIngredient/rawIngredientProfile/queries";
import { getErrorMessage } from "common/utils";
import { TradeNameList } from "./TradeNameList";
import { queryClient } from "libraries/queryProvider";

const CreateSchema = Yup.object().shape({
    manufacturer: Yup.string().trim().required("Manufacturer is required"),
    tradename: Yup.string().trim().required("Tradename is required"),
});

export const CreateTradename = () => {
    const { id = "" } = useParams();
    const { mutate, isPending } = useCreateRawIngredientTradeName();

    const formik = useFormik({
        initialValues: {
            manufacturer: "",
            tradename: "",
            raw_ingredients_id: id,
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );
            mutate(values, {
                onSuccess() {
                    toast.update(toastId, defaultSuccessToastUpdate);

                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredient, id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.tradenames, id],
                    });
                    resetForm();
                },
                onError(error) {
                    const errMsg = getErrorMessage(error as AxiosError);
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                    formik.setErrors({
                        ...formik.errors,
                        manufacturer: errMsg as string,
                        tradename: errMsg as string,
                    });
                },
            });
        },
    });

    return (
        <form className="space-y-4" onSubmit={formik.handleSubmit}>
            <InputWrapper
                isError={Boolean(
                    formik.errors.manufacturer && formik.touched.manufacturer,
                )}
                error={formik.errors.manufacturer}
                label="Manufacturer"
            >
                <input
                    name="manufacturer"
                    className="w-full input input-bordered"
                    value={formik.values.manufacturer}
                    onChange={formik.handleChange}
                />
            </InputWrapper>
            <InputWrapper
                isError={Boolean(
                    formik.errors.tradename && formik.touched.tradename,
                )}
                error={formik.errors.tradename}
                label="Trade Name"
            >
                <input
                    name="tradename"
                    className="w-full input input-bordered"
                    value={formik.values.tradename}
                    onChange={formik.handleChange}
                />
            </InputWrapper>
            <div className="flex gap-2 justify-end items-center pt-4">
                <Button
                    text="Clear"
                    onClick={() => formik.resetForm()}
                    variant="ghost"
                />
                <Button
                    text="Add Trade Name"
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty || isPending}
                    isLoading={isPending}
                />
            </div>
            <TradeNameList id={id} />
        </form>
    );
};
