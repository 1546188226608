import React, { useCallback, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ColumnDef } from "@tanstack/react-table";
import { useFormik } from "formik";
import { queryClient } from "libraries/queryProvider";

import { PageTemplate } from "components/page-template";
import { backendKeys, defaultErrorToastUpdate, defaultSuccessToastUpdate, defaultToastOptions, queryKeys, routerKeys, subIngredientContent, toastTexts } from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { CreateSubIngredientForm, Filters } from "./components";
import { useGetTableSubIngredients } from "./queries";
import { ModalTemplate } from "components/shared/modalTemplate";
import {
    DataTable,
    IconLink,
    SaveButton,
    StatusView,
} from "components/shared";
import { TableSubIngredient } from "./libs/types";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { modifyDate } from "common/utils";
import { ItemTitle } from "components/sidebar";
import { SearchFilterPanel } from "components/search-filter-panel";
import { useGetSavedData, useGetUserProfile, useSaveDataMutation } from "pages/profile/libs";
import { ISubIngredientSaved } from "@/common/types";

export const SubIngredients = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const { data: subIngredients, isLoading } = useGetTableSubIngredients({
        queryParams: getParamsString(),
        sorting,
    });

    const { data: userInfo } = useGetUserProfile();
    const { mutate: saveSubIngredient } = useSaveDataMutation<{ userId: string; subIngredientId: number }>(
        backendKeys.api.subIngredients.save
    );
    const { data: savedSubIngredients } = useGetSavedData<ISubIngredientSaved>(
        queryKeys.savedSubIngredient, 
        backendKeys.api.subIngredients.saved
    );

    const {
        modalRef: createSubIngredientRef,
        openModal,
        closeModal,
    } = useModal();

    const {
        handleUpdateFilters,
        handleFilterVisible,
        handleUpdateSearch,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const formik = useFormik({
                initialValues: {
                    userId: userInfo?.id || "",
                    subIngredientId: 0,
                },
        
                onSubmit: (values) => {
                    const toastId = toast.loading(
                        toastTexts.loading,
                        defaultToastOptions,
                    );
        
                    saveSubIngredient(values, {
                        onSuccess: () => {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: toastTexts.saved,
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.savedSubIngredient],
                            });
                            queryClient.refetchQueries({
                                queryKey: [queryKeys.fullSavedSubIngredient],
                            });
                        },
                        onError: () => {
                            toast.update(toastId, defaultErrorToastUpdate);
                        },
                    });
                },
            });

            const handleSaveSubIngredient = useCallback(
                (subIngredientId: number) => {
                    if (!userInfo?.id) return;
        
                    formik.setValues({ userId: userInfo.id, subIngredientId });
                    formik.submitForm();
                },
                [userInfo?.id, formik],
            );

    const columns = useMemo<ColumnDef<TableSubIngredient>[]>(
        () => [
            {
                accessorKey: "sicode",
                header: () => <span className="max-w-[100px]">SI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[110px]"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    >
                        <span
                            className="truncate ... w-[100px]"
                            title={row.getValue("sicode")}
                        >
                            {row.getValue("sicode")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "name",
                header: () => <span>Name</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[100px] lg:w-[120px] xl:w-[130px] 2xl:w-[150px]"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... w-[100px] lg:w-[120px] xl:w-[130px] 2xl:w-[150px] cursor-pointer"
                            title={row.getValue("name")}
                        >
                            {row.getValue("name")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "function",
                header: () => <span>Functions</span>,
                cell: ({ row }) =>
                    (row.getValue("function") as string)
                        ?.split(",")
                        ?.map((item) => (
                            <p
                                className="truncate ... w-[120px]"
                                title={item}
                                key={item}
                            >
                                {item}
                            </p>
                        )),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <StatusView
                        title={row.original.status || ""}
                        active={row.original.status === "Active"}
                    />
                ),
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                enableSorting: false,
                header: () => <span className="flex justify-end">Save</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex justify-end hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleSaveSubIngredient}
                        items={savedSubIngredients || []}
                        attribute="subIngredientId"
                        isDisabled
                    />
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end max-w-[70px]">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.subIngredient}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [savedSubIngredients, saveSubIngredient?.length, handleSaveSubIngredient],
    );

    return (
        <PageTemplate 
            title={ItemTitle.SUB_INGREDIENT}
            filledButtonTitle="Sub Ingredient"
            openCreateModal={openModal}
        >
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Sub Ingredient"
                    onClose={closeModal}
                    modalRef={createSubIngredientRef}
                    className="max-w-[600px]"
                >
                    <CreateSubIngredientForm handleClose={closeModal} isLink />
                </ModalTemplate>
                <div className="w-full mx-10">
                    <SearchFilterPanel
                        placeholder={subIngredientContent.placeholder}
                        searchValue={filters?.query?.value}
                        updateSearch={handleUpdateSearch}
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        filters={filters}
                        onRemoveFilter={handleRemoveFilter}
                    />

                    <DataTable
                        data={subIngredients?.data || []}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={subIngredients?.count || 0}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                        styleTable="md:w-[77%] lg:w-[77%]"
                    >
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </DataTable>

                    {isLoading ? <LoadingToast /> : <ToastContainer />}
                </div>
            </div>
        </PageTemplate>
    );
};
