import React from "react";
import { Navigation } from "../../navigation";
import { ActionButtonsHeader, Footer } from "../shared";
import { Sidebar } from "../sidebar";
import { useLocation } from "react-router-dom";
import { routerKeys } from "common/constants";
import { Navbar } from "../navbar";

export const AppContent = () => {
    const { pathname } = useLocation();
    const isLoginPage = pathname === routerKeys.authLogin;
    const onboardingPage = pathname === routerKeys.onboarding;

    return (
        <div className="flex flex-col min-h-screen">
            {!isLoginPage && !onboardingPage && <ActionButtonsHeader />}
            <div className="flex flex-col flex-grow">
                {isLoginPage && <Navbar />}
                {!isLoginPage && !onboardingPage && <Sidebar />} 
                <Navigation />
            </div>
            {isLoginPage && <Footer />}
        </div>
    );
};
