import React, { FC } from 'react'
import { useFormik } from 'formik';

import { checkboxData, TitleData } from '../libs'
import { CheckboxGroup, GradientButton } from 'components/shared'
import { useUpdateProfileInfo } from 'pages/profile/libs';
import { IUserProfileInfo } from '@/common/types';
import { useNavigate } from 'react-router-dom';
import { defaultToastOptions, routerKeys, toastTexts } from 'common/constants';
import { toast } from 'react-toastify';

interface IProps {
  prevStep: () => void;
  profileInfo?: IUserProfileInfo;
}

export const UserGoals: FC<IProps> = ({ prevStep, profileInfo }) => {
  const navigate = useNavigate();
  
  const { mutate: updateProfileInfo } = useUpdateProfileInfo();

  const {values, handleSubmit, setFieldValue} = useFormik({
    initialValues: {
      selectedItems: profileInfo?.goals || [] as string[],
    },
    onSubmit: (values) => {
      updateProfileInfo(
        {
          goals: values.selectedItems
        },
        {
          onSuccess() {
            toast.success(toastTexts.success, defaultToastOptions);
            navigate(routerKeys.dashboard)
          }
        }
      )
    },
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setFieldValue('selectedItems', [...values.selectedItems, value]);
    } else {
      setFieldValue(
        'selectedItems',
        values.selectedItems.filter((item) => item !== value)
      );
    }
  };

  return (
    <div className='w-[550px] h-screen flex flex-col items-center justify-between pt-16'>
      <div className='w-full'>
        <h2 className='font-semibold text-2xl leading-tight'>
          {TitleData[2].pageTitle}
        </h2>
        <h4 className='mb-4 font-light text-xl'>We’ll use this to help you get started</h4>
        <form className='w-full pl-12'>
          {
            checkboxData.map(({value, label}) => (
              <CheckboxGroup 
              key={value}
              label={label}
              value={value}
              isChecked={values.selectedItems.includes(value)}
              onChange={handleCheckboxChange}
              className='font-semibold text-lg'
            />
            ))
          }
        </form>
      </div>
      <div className='w-full mb-16 inline-flex gap-x-3 justify-end'>
          <GradientButton
            onClick={handleSubmit}
            label='Save'
            variant="filled"
            clasName='w-[168px]'
            type='submit'
          />
          <GradientButton
            onClick={prevStep}
            label='Back'
            variant="outlined"
            clasName='w-[168px]'
          />
        </div>
    </div>
  )
}
