import React, { FC } from "react";
import { useLogout } from "common/hooks";
import { ProfileItems } from "../../libs";
import { MenuButton } from "../profile-menu-button/MenuButton";

interface IProps {
    activeItem: string;
    onItemClick: (item: ProfileItems) => void;
}

const menuItems = Object.values(ProfileItems);

export const ProfileMenu: FC<IProps> = ({ activeItem, onItemClick }) => {
    const { handleLogout } = useLogout();

    return (
        <div className="bg-profile max-h-fit w-1/5 rounded-tl-2xl rounded-bl-2xl p-4 flex items-center flex-col gap-4 shadow-md">
            {menuItems.map((item) => (
                <MenuButton
                    key={item}
                    text={item}
                    isActive={activeItem === item}
                    onClick={() => onItemClick(item)}
                />
            ))}
            <MenuButton text="Log out" onClick={handleLogout}/>
            <MenuButton text="Delete account" onClick={() => {}} className="red-color"/>
        </div>
    );
};
