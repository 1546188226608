import { EditWithPapperIcon } from "assets/icons";
import { routerKeys } from "common/constants";
import React from "react";
import { Link } from "react-router-dom";
import { IContaminant } from "common/types";
import { modifyDate } from "common/utils/modifyDate";

interface IProps {
    contaminant: IContaminant;
}

export const TableRow: React.FC<IProps> = ({ contaminant }) => {
    return (
        <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
            <td className="border-grey-light border hover:bg-gray-100 p-3 cursor-pointer">
                <Link to={`${routerKeys.contaminants}/${contaminant.id}`}>
                    <EditWithPapperIcon />
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                {contaminant.name}
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3 text-red-400 hover:text-red-600 cursor-pointer">
                <Link to={`${routerKeys.contaminants}/${contaminant.id}`}>
                    {contaminant.description}
                </Link>
            </td>
            <td className="border-grey-light border hover:bg-gray-100 p-3">
                {modifyDate(contaminant.date_created)}
            </td>
        </tr>
    );
};
