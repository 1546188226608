import { useState, useEffect } from "react";
import { useGetSubIngredient } from "../queries";
import { IBasicData } from "../libs/types";
import { InciSource } from "common/data";

const isValidEmail = (email?: string | number): boolean =>
    email
        ? /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email.toString())
        : false;

export const useSubIngredientData = (key?: (string | number)[]) => {
    const subIngredient = useGetSubIngredient();

    const getDefaultData = (): IBasicData => ({
        environmental_class: "",
        skin_sensitizer: "",
        food_use: "",
        function: "",
        cir_citation: "",
        cir_ro: "",
        cir_lo: "",
        cir_reviewed: "",
        preservatives: "",
        uv_filter: "",
        colorant: "",
        iecic_lo: "",
        iecic_ro: "",
        gluten: "",
        cramer_class: "",
        cas_number: "",
        unique_smiles: "",
        inchi: "",
        skin_sensitizer_comment: "",
        food_use_comment: "",
        gluten_comment: "",
        colorant_comment: "",
        uv_filter_comment: "",
        source: "",
        date_created: "",
        last_modified_by: {},
    });

    const [data, setData] = useState<IBasicData>(() => ({
        ...getDefaultData(),
        ...subIngredient,
    }));

    useEffect(() => {
        if (subIngredient) {
            setData((prevData) => ({
                ...prevData,
                ...subIngredient,
                skin_sensitizer:
                    subIngredient.skin_sensitizer?.toLowerCase() || "",
                food_use: subIngredient.food_use?.toLowerCase() || "",
                colorant: subIngredient.colorant?.toLowerCase() || "",
                gluten: subIngredient.gluten?.toLowerCase() || "",
            }));
        }
    }, [subIngredient, key]);

    const ingredientSource = (() => {
        if (isValidEmail(data?.source)) {
            return {
                url: `mailto:${data.source}`,
                text: data.source,
                date: data.date_created,
            };
        }
        return data?.source && data.source in InciSource
            ? InciSource[data.source]
            : InciSource.cosIng;
    })();

    return { data, setData, subIngredient, ingredientSource };
};
