import {
    Document,
    Paragraph,
    Table,
    TableRow,
    TableCell,
    TextRun,
    WidthType,
    ImageRun,
    BorderStyle,
    convertInchesToTwip,
    AlignmentType,
} from "docx";
import { ReportData } from "./types";
import logo from "assets/images/logo.png";

export class MOSReportDocxTemplate {
    private doc: Document;
    private readonly margin = convertInchesToTwip(0.6);

    constructor() {
        this.doc = new Document({
            sections: [
                {
                    properties: {
                        page: {
                            margin: {
                                top: this.margin,
                                right: this.margin,
                                bottom: this.margin,
                                left: this.margin,
                            },
                        },
                    },
                    children: [],
                },
            ],
        });
    }

    private async createLogo(reportData: ReportData) {
        const { email, organization } = reportData || {};
        const contactInfo = [
            organization?.organisation?.[0] || "SmartSafety",
            "www.smartsafety.edelweissconnect.com",
            email || "",
            organization?.phone?.[0] || "",
        ].filter(Boolean);

        const response = await fetch(logo);
        const blob = await response.blob();
        const buffer = await blob.arrayBuffer();

        return new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new ImageRun({
                                            data: buffer,
                                            transformation: {
                                                width: 150,
                                                height: 45,
                                            },
                                            type: "png",
                                        }),
                                    ],
                                }),
                            ],
                            borders: {
                                top: { style: BorderStyle.NONE },
                                bottom: { style: BorderStyle.NONE },
                                left: { style: BorderStyle.NONE },
                                right: { style: BorderStyle.NONE },
                            },
                        }),
                        new TableCell({
                            children: contactInfo.map(
                                (text) =>
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text,
                                                size: 20,
                                                color: "505050",
                                            }),
                                        ],
                                    }),
                            ),
                            borders: {
                                top: { style: BorderStyle.NONE },
                                bottom: { style: BorderStyle.NONE },
                                left: { style: BorderStyle.NONE },
                                right: { style: BorderStyle.NONE },
                            },
                        }),
                    ],
                }),
            ],
        });
    }

    private createHeader(title: string) {
        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: title,
                        bold: true,
                        size: 36,
                        color: "113267",
                    }),
                ],
                spacing: { after: 200 },
                shading: { fill: "F0F2F5" },
            }),
        ];
    }

    private createInfoTable(reportData: ReportData) {
        return new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: [
                ["FI Code:", reportData.productId],
                ["Formula Name:", reportData.productName],
                ["Generated by:", reportData.author],
                ["Date:", reportData.date],
            ].map(
                ([label, value]) =>
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: label,
                                                bold: true,
                                                size: 20,
                                            }),
                                        ],
                                    }),
                                ],
                                width: { size: 30, type: WidthType.PERCENTAGE },
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: value?.toString() || "",
                                                size: 20,
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
            ),
        });
    }

    private createStyledTable(data: [string, string | number][]) {
        return new Table({
            width: { size: 100, type: WidthType.PERCENTAGE },
            rows: data.map(
                ([label, value]) =>
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: label,
                                                bold: true,
                                                size: 20,
                                            }),
                                        ],
                                    }),
                                ],
                                width: { size: 30, type: WidthType.PERCENTAGE },
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: value?.toString() || "",
                                                size: 20,
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
            ),
        });
    }

    private createSummarySection(reportData: ReportData) {
        const rows = [];
        if (reportData.totalWeight) {
            rows.push(["Total Weight:", reportData.totalWeight]);
        }
        if (reportData.highestExposureFactor) {
            rows.push([
                "Highest Exposure Factor:",
                reportData.highestExposureFactor,
            ]);
        }
        if (reportData.mos) {
            rows.push(["MOS:", reportData.mos]);
        }

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: "MOS Calculation Summary",
                        bold: true,
                        size: 28,
                        color: "113267",
                    }),
                ],
                spacing: { before: 400, after: 200 },
            }),
            this.createStyledTable(rows as [string, string | number][]),
        ];
    }

    private createMetricsSection(reportData: ReportData) {
        const { calculationMetrics } = reportData;
        const metricsData = [
            calculationMetrics.productType && [
                "Product Type:",
                calculationMetrics.productType,
            ],
            calculationMetrics.bodyWeight && [
                "Body Weight:",
                calculationMetrics.bodyWeight,
            ],
            calculationMetrics.gramsAppliedPerDay && [
                "Grams Applied Per Day:",
                calculationMetrics.gramsAppliedPerDay,
            ],
            calculationMetrics.skinRetentionFactor && [
                "Skin Retention Factor:",
                calculationMetrics.skinRetentionFactor,
            ],
        ].filter(Boolean) as [string, string | number][];

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: "Calculation Metrics",
                        bold: true,
                        size: 28,
                        color: "113267",
                    }),
                ],
                spacing: { before: 400, after: 200 },
            }),
            this.createStyledTable(metricsData),
        ];
    }

    private createIngredientsTable(reportData: ReportData) {
        const headers = [
            "SI Code",
            "Name",
            "Total Weight (%)",
            "Derm. Pen.",
            "Daily Exp.",
            "Syst. Exp.",
            "NOAEL",
            "MOS",
        ];

        const rows = [
            new TableRow({
                children: headers.map(
                    (header) =>
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: header,
                                            bold: true,
                                            color: "FFFFFF",
                                        }),
                                    ],
                                }),
                            ],
                            shading: { fill: "113267" },
                        }),
                ),
            }),
            ...reportData.ingredients.map(
                (ing) =>
                    new TableRow({
                        children: [
                            ing.sicode,
                            ing.name,
                            ing.total_weight,
                            ing.dermal_penetration,
                            ing.daily_exposure,
                            ing.systematic_daily_exposure,
                            ing.noael,
                            ing.mos,
                        ].map(
                            (value) =>
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            children: [
                                                new TextRun({
                                                    text:
                                                        value?.toString() || "",
                                                    size: 18,
                                                }),
                                            ],
                                            alignment: AlignmentType.CENTER,
                                        }),
                                    ],
                                }),
                        ),
                    }),
            ),
        ];

        return [
            new Paragraph({
                children: [
                    new TextRun({
                        text: "Ingredients and Exposure Analysis",
                        bold: true,
                        size: 28,
                        color: "113267",
                    }),
                ],
                spacing: { before: 400, after: 200 },
            }),
            new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows,
                borders: {
                    top: { style: BorderStyle.SINGLE, size: 1 },
                    bottom: { style: BorderStyle.SINGLE, size: 1 },
                    left: { style: BorderStyle.SINGLE, size: 1 },
                    right: { style: BorderStyle.SINGLE, size: 1 },
                    insideHorizontal: { style: BorderStyle.SINGLE, size: 1 },
                    insideVertical: { style: BorderStyle.SINGLE, size: 1 },
                },
            }),
        ];
    }

    async generate(reportData: ReportData) {
        const children = [
            await this.createLogo(reportData),
            ...this.createHeader("Formula MOS Report"),
            this.createInfoTable(reportData),
        ];

        if (
            reportData.totalWeight ||
            reportData.highestExposureFactor ||
            reportData.mos
        ) {
            children.push(...this.createSummarySection(reportData));
        }

        if (reportData.calculationMetrics) {
            children.push(...this.createMetricsSection(reportData));
        }

        if (reportData.ingredients.length) {
            children.push(...this.createIngredientsTable(reportData));
        }

        this.doc = new Document({
            sections: [
                {
                    properties: {},
                    children,
                },
            ],
        });

        return this.doc;
    }
}
