export const industryType = [
   {value: 'cosmetics', label: 'Cosmetics'},
   {value: 'automotive', label: 'Automotive'},
   {value: 'textiles', label: 'Textiles'},
];

export const describeBusiness = [
   {value: 'corporate company', label: 'Corporate company'},
   {value: 'small or medium enterprise', label: 'Small or medium enterprise (SME)'},
   {value: 'startup', label: 'Startup'},
   {value: 'government agency', label: 'Government agency'},
   {value: 'regulatory body', label: 'Regulatory body'},
   {value: 'academic or research institution', label: 'Academic or research institution'},
   {value: 'consultant or service provider', label: 'Consultant or service provider'},
   {value: 'manufacturer', label: 'Manufacturer'},
   {value: 'supplier or distributor', label: 'Supplier or distributor'},
   {value: 'NGO / Non-profit', label: 'NGO / Non-profit'},
   {value: 'other (with optional text input)', label: 'Other (with optional text input)'},
];

export const generateReportTime = [
   {value: 'less than an hour', label: 'Less than an hour'},
   {value: '1–2 hours', label: '1–2 hours'},
   {value: '1 day', label: '1 day'},
   {value: '2–3 days', label: '2–3 days'},
   {value: '4–5 days', label: '4–5 days'},
   {value: '1 week', label: '1 week'},
   {value: 'more than 1 week', label: 'More than 1 week'},
   {value: 'it varies', label: 'It varies'},
];

export const generateReportTools = [
   {value: 'by hand entries (e.g., spreadsheets or paper)', label: 'By hand entries (e.g., spreadsheets or paper)'},
   {value: 'Microsoft Excel / Google Sheets', label: 'Microsoft Excel / Google Sheets'},
   {value: 'custom internal tools', label: 'Custom internal tools'},
   {value: 'third-party software (e.g., regulatory platforms)', label: 'Third-party software (e.g., regulatory platforms)'},
   {value: 'data integration platforms', label: 'Data integration platforms'},
   {value: 'APIs and automated workflows', label: 'APIs and automated workflows'},
   {value: 'other (with optional text input)', label: 'Other (with optional text input)'},
];

export const roleGroup = [
   {value: 'General Organizational Roles', label: 'General Organizational Roles'},
   {value: 'Scientific & Technical Roles', label: 'Scientific & Technical Roles'},
   {value: 'R&D and Laboratory Roles', label: 'R&D and Laboratory Roles'},
   {value: 'Documentation & Reporting Roles', label: 'Documentation & Reporting Roles'},
   {value: 'Cosmetics', label: 'Cosmetics'},
   {value: 'Chemicals / Manufacturing', label: 'Chemicals / Manufacturing'},
   {value: 'IT and Support Roles', label: 'IT and Support Roles'},
];

export const roles = {
   General_Organizational_Roles: [
      {value: 'Administrator', label: 'Administrator'},
      {value: 'Manager', label: 'Manager'},
      {value: 'Project Manager', label: 'Project Manager'},
      {value: 'Team Lead', label: 'Team Lead'},
      {value: 'Supervisor', label: 'Supervisor'},
      {value: 'External Consultant', label: 'External Consultant'},
      {value: 'Auditor', label: 'Auditor'},
      {value: 'Client Representative', label: 'Client Representative'},
   ],
   Scientific_Technical_Roles: [
      {value: 'Toxicologist', label: 'Toxicologist'},
      {value: 'Cosmetic Safety Assessor', label: 'Cosmetic Safety Assessor'},
      {value: 'Risk Assessor', label: 'Risk Assessor'},
      {value: 'Risk Analyst', label: 'Risk Analyst'},
      {value: 'Exposure Scientist', label: 'Exposure Scientist'},
      {value: 'Chemical Safety Specialist', label: 'Chemical Safety Specialist'},
      {value: 'Regulatory Affairs Specialist', label: 'Regulatory Affairs Specialist'},
      {value: 'Data Scientist', label: 'Data Scientist'},
      {value: 'Data Analyst', label: 'Data Analyst'},
      {value: 'Biostatistician', label: 'Biostatistician'},
      {value: 'Epidemiologist', label: 'Epidemiologist'},
   ],
   Laboratory_Roles: [
      {value: 'Formulation Scientist', label: 'Formulation Scientist'},
      {value: 'Laboratory Technician', label: 'Laboratory Technician'},
      {value: 'Laboratory Analyst', label: 'Laboratory Analyst'},
      {value: 'Quality Control (QC) Analyst', label: 'Quality Control (QC) Analyst'},
      {value: 'Quality Assurance (QA) Officer', label: 'Quality Assurance (QA) Officer'},
   ],
   Documentation_Reporting_Roles: [
      {value: 'Report Writer', label: 'Report Writer'},
      {value: 'Medical Writer', label: 'Medical Writer'},
      {value: 'Documentation Specialist', label: 'Documentation Specialist'},
      {value: 'Reviewer', label: 'Reviewer'},
      {value: 'Approver', label: 'Approver'},
   ],
   Cosmetics: [
      {value: 'INCI Specialist', label: 'INCI Specialist'},
      {value: 'Dermatologist', label: 'Dermatologist'},
      {value: 'Clinical Safety Expert', label: 'Clinical Safety Expert'},
      {value: 'Claims Substantiation Expert', label: 'Claims Substantiation Expert'},
      {value: 'Product Stewardship Manager', label: 'Product Stewardship Manager'},
   ],
   Chemicals_Manufacturing: [
      {value: 'Process Safety Engineer', label: 'Process Safety Engineer'},
      {value: 'Environmental Risk Assessor', label: 'Environmental Risk Assessor'},
      {value: 'Occupational Health Specialist', label: 'Occupational Health Specialist'},
   ],
   IT_and_Support_Roles: [
      {value: 'System Administrator', label: 'System Administrator'},
      {value: 'Technical Support', label: 'Technical Support'},
      {value: 'Developer', label: 'Developer'},
      {value: 'Integrator', label: 'Integrator'},
   ],
}