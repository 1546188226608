export const toastTexts = {
    loading: "Loading...",
    processing: "Processing your request...",
    uploading: "Uploading...",
    downloading: "Downloading...",
    success: "Success!",
    created: "Created successfully!",
    updated: "Updated successfully!",
    remove: "Removed successfully!",
    error: "An error occurred. Please try again",
    dataLack: "Sorry, but there isn't any data to show right now.",
    saved: "Changes saved successfully!",
    uploaded: "File uploaded successfully!",
    downloaded: "File downloaded successfully!",
    copied: "Copied to clipboard!",
    warning: "Please check your input and try again",
    networkError: "Network connection error. Please check your connection",
    sessionExpired: "Your session has expired. Please log in again",
    unauthorized: "You don't have permission to perform this action",
    validationError: "Please fill in all required fields correctly",
    serverError: "Server error occurred. Please try again later",
    duplicateError: "This item already exists",
    downloadError: "Failed to download file",
} as const;
