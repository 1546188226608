export const wsConfig = {
    port: 4500,
    protocol: window.location.protocol === "https:" ? "wss:" : "ws:",
    host: `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${window.location.hostname}:4500`,
};

export const wsStatus = {
    CONNECTED: "connected",
    UPDATED: "updated",
};
