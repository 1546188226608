import React, { FC } from "react";
import classNames from "classnames";

interface IProps {
    isChecked: boolean;
    toggleIsChecked: () => void;
}

export const Switcher: FC<IProps> = ({ isChecked, toggleIsChecked }) => (
    <label
        className={classNames("switcher", { "switcher--checked": isChecked })}
    >
        <input type="checkbox" onClick={toggleIsChecked} />
        <span className="switcher_truck cursor-pointer" />
    </label>
);
