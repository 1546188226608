import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import { Title } from "components/shared/title";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TableCeil } from "components/shared/tableCeil";
import { useNavigate, useParams } from "react-router-dom";
import { routerKeys } from "common/constants";
import {
    useUploadFormula,
    useUploadFormulaSimilar,
} from "pages/formulas/formula-profile/queries";
import { LoadingToast } from "components/shared/loadingToast";
import { Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { similarityReportService } from "services/similarity-report";
import { useGetUserProfile } from "pages/profile/libs";
import { IUser } from "common/types";
import { getFullName, modifyDate } from "common/utils";
import { IFormula } from "../../../types";
import { useSimilarFormulas } from "pages/formulas/formula-compare/queries";

const mapUserInfo = (userInfo: IUser) => {
    const {
        email = "",
        firstName = "",
        lastName = "",
        attributes = {},
    } = userInfo || {};

    return {
        author: getFullName(firstName, lastName),
        organization: attributes,
        email,
    };
};

export const SimilarTab = () => {
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const [compareWith, setCompareWith] = useState<number[]>([]);
    const searchParams = new URLSearchParams();

    const { data: similarFormulas = [], isLoading } = useUploadFormulaSimilar({
        id,
    });
    const { data: userInfo = {} } = useGetUserProfile();
    const { data: baseFormula = {} as IFormula } = useUploadFormula({ id });
    const { data: similarIngredients } = useSimilarFormulas(id);

    const handleUpdateCompareWith = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { value } = event.target;

        if (compareWith.includes(+value)) {
            setCompareWith((currentValues) =>
                currentValues.filter((element) => element !== +value),
            );
        } else {
            setCompareWith((currentValues) => [...currentValues, +value]);
        }
    };

    const handleNavigateToCompare = () => {
        if (compareWith.length) {
            searchParams.append(routerKeys.compareWith, compareWith.join(","));
            navigate(`${routerKeys.compare}?${searchParams}`);
        } else {
            navigate(routerKeys.compare);
        }
    };

    const handleGenerateReport = () => {
        similarityReportService.generateSimilarityReport({
            similarFormulas,
            ...mapUserInfo(userInfo),
            date: modifyDate(new Date().toISOString()),
            baseFormula,
            similarIngredients,
        });
    };

    return (
        <div>
            <div className="md:flex lg:flex">
                <div className="md:w-2/3 lg:w-2/3 mx-10 my-3">
                    <Title text="Suggested Reference Formulas">
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                marginLeft: 2,
                                backgroundColor: "primary.main",
                                "&:hover": {
                                    backgroundColor: "primary.dark",
                                },
                            }}
                            onClick={handleNavigateToCompare}
                        >
                            Compare
                        </Button>
                        <Button
                            startIcon={<PictureAsPdfIcon />}
                            onClick={handleGenerateReport}
                            disabled={!similarFormulas?.length}
                            sx={(theme) => ({
                                ml: 2,
                                color: theme.palette.error.main,
                                "&:hover": {
                                    color: theme.palette.error.dark,
                                },
                            })}
                        >
                            Generate report
                        </Button>
                    </Title>

                    <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                        <thead className="bg-indigo-100">
                            <tr className="sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 border-b border-primary-main-dgray">
                                <TableHeaderCeil />
                                <TableHeaderCeil text="FICODE" />
                                <TableHeaderCeil text="Description" />
                                <TableHeaderCeil text="Test Code attributes and results" />
                                <TableHeaderCeil text="% Similarity" />
                            </tr>
                        </thead>
                        <tbody>
                            {similarFormulas?.map((similarFormula) => (
                                <tr key={uuidv4()}>
                                    <TableCeil>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            value={
                                                similarFormula.target_formulas_id
                                            }
                                            onChange={handleUpdateCompareWith}
                                            checked={compareWith.includes(
                                                similarFormula.target_formulas_id,
                                            )}
                                        />
                                    </TableCeil>
                                    <TableCeil>
                                        {similarFormula.similarity.ficode}
                                    </TableCeil>
                                    <TableCeil>
                                        {similarFormula.similarity.description}
                                    </TableCeil>
                                    <TableCeil></TableCeil>
                                    <TableCeil>
                                        {similarFormula.score}
                                    </TableCeil>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isLoading && <LoadingToast />}
        </div>
    );
};
