import React, { FC } from "react";
import classNames from "classnames";
import { ModalTemplate } from "components/shared/modalTemplate";
import { Button } from "components/shared/button";
import { useModal } from "common/hooks";

interface IProps {
    tabs?: string[];
    selectedTab?: string;
    onSelectTab?: (tab: string) => void;
    onRemove?: () => void;
    removeNotification?: string;
}

export const TabsBox: FC<IProps> = ({
    tabs,
    selectedTab,
    onSelectTab,
    onRemove = undefined,
    removeNotification = "Are you sure you want to remove?",
}) => {
    const { modalRef: modalRemoveRef, openModal, closeModal } = useModal();

    return (
        <div className="md:mx-5 lg:mx-10 mt-1">
            <div className="tabs tabs-boxed flex flex-wrap" role="tablist">
                {tabs?.map((tab) => (
                    <a
                        className={classNames("tab", {
                            "tab-active": selectedTab === tab,
                        })}
                        role="tab"
                        key={tab}
                        onClick={() => onSelectTab && onSelectTab(tab)}
                    >
                        {tab}
                    </a>
                ))}
                <>
                    <ModalTemplate
                        text={removeNotification}
                        modalRef={modalRemoveRef}
                        onClose={closeModal}
                        className="max-w-[500px]"
                    >
                        <div className="flex justify-end gap-4">
                            <Button
                                text="Cancel"
                                onClick={closeModal}
                                variant="success"
                                size="sm"
                            />
                            <Button
                                text="Remove"
                                onClick={onRemove}
                                variant="danger"
                                size="sm"
                            />
                        </div>
                    </ModalTemplate>

                    {onRemove && (
                        <div className="grow flex justify-end">
                            <button
                                className="tab tab-active !text-white duration-300 hover:!text-secondary-danger"
                                onClick={openModal}
                            >
                                Remove
                            </button>
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};
