import { Button } from "components/shared";
import React, { FC } from "react";

interface IProps {
    title: string;
    description: string;
}

export const ArticleCard: FC<IProps> = ({
    title,
    description,
}) => (
    <div className="flex justify-between items-center w-[709px] h-[128px] bg-white p-6 mt-5">
        <div>
            <h3 className="pb-2 font-semibold text-lg pt-0 text-[#3F57FF]">
               {title}
            </h3>
            <p className="font-light text-base text-[#A1A1A1] text-center">
               {description}
            </p>
        </div>
        <Button 
            text='Read now'
            variant="success"
            className="w-[129px] h-[61px] rounded-full font-semibold text-base hover:scale-105 border-none" 
        />
    </div>
);