import { ItemTitle } from "components/sidebar";
import { PageTemplate } from "components/page-template";
import React from "react";

export const Fragrance = () => {
    return (
        <PageTemplate title={ItemTitle.FRAGRANCE}>
            <div className="md:flex lg:flex p-4">
                <h6>Fragrance content will be here</h6>
            </div>
        </PageTemplate>
    );
};
