import React, {
    ChangeEvent,
    FC,
    useEffect,
    useState,
} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { FORM_FIELD_DATA, roleGroup, roles, RoleTitle, TitleData } from "../libs";
import { GradientButton } from "components/shared";
import { useUpdateProfileInfo } from "pages/profile/libs";
import { IUserProfileInfo } from "@/common/types";
import { queryClient } from "libraries/queryProvider";
import { queryKeys } from "common/constants";
import { InputField } from "./InputField";
import { DropdownField } from "./DropdownField";

interface IProps {
    nextStep: () => void;
    profileInfo?: IUserProfileInfo;
}

const validationSchema = Yup.object({
    first_name: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    role_group: Yup.string().required("Role is required"),
    role: Yup.string().required("Role is required"),
});

const roleOptionsMap: Record<string, { value: string; label: string }[]> = {
  [RoleTitle.GENERAL_ORGANIZARION]: roles.General_Organizational_Roles,
  [RoleTitle.SCIENTIFIC_TECHNICAL]: roles.Scientific_Technical_Roles,
  [RoleTitle.LABORATORY]: roles.Laboratory_Roles,
  [RoleTitle.DOCUMENTATION_REPORTING]: roles.Documentation_Reporting_Roles,
  [RoleTitle.COSMETICS]: roles.Cosmetics,
  [RoleTitle.CHEMICALS]: roles.Chemicals_Manufacturing,
  [RoleTitle.IT]: roles.IT_and_Support_Roles,
};

export const ProfileInformation: FC<IProps> = ({ nextStep, profileInfo }) => {
    const [selectedRoleGroup, setSelectedRoleGroup] = useState<string>("");
    const { mutate: updateProfileInfo } = useUpdateProfileInfo();

    const initialValues = {
      first_name: profileInfo?.first_name || "",
      email: profileInfo?.email || "",
      role_group: profileInfo?.role_group || "",
      role: profileInfo?.role || "",
  };

    const { handleSubmit, values, handleChange, errors, touched, handleBlur } =
        useFormik({
            initialValues,
            validationSchema,
            validateOnChange: true,
            validateOnBlur: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                updateProfileInfo(
                    {
                        first_name: values.first_name,
                        role_group: values.role_group,
                        role: values.role,
                    },
                    {
                        onSuccess() {
                            queryClient.refetchQueries({
                                queryKey: [queryKeys.userProfileInfo],
                            });
                            nextStep();
                        },
                    },
                );
            },
        });

    const handleChangeValueRoleGroup = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        setSelectedRoleGroup(e.target.value);
        handleChange(e);
    };

    useEffect(() => {
        if (profileInfo) {
            setSelectedRoleGroup(profileInfo.role_group || "");
        }
    }, [profileInfo?.role_group]);

    return (
        <div className="w-[550px] h-screen flex flex-col items-center justify-between pt-16">
            <div className="w-full">
                <h2 className="font-semibold text-2xl mb-4">
                    {TitleData[0].pageTitle}
                </h2>
                <form className="w-full">
                    <InputField
                        error={errors?.first_name}
                        touched={touched?.first_name}
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={FORM_FIELD_DATA.name.label}
                        name={FORM_FIELD_DATA.name.name}
                        placeholder={FORM_FIELD_DATA.name.placeholder  || ''}
                    />
                    <InputField
                        error={errors?.email}
                        touched={touched?.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={FORM_FIELD_DATA.email.label}
                        name={FORM_FIELD_DATA.email.name}
                        placeholder={FORM_FIELD_DATA.email.placeholder || ''}
                        readOnly
                    />
                    <DropdownField
                        error={errors?.role_group}
                        touched={touched?.role_group}
                        value={values.role_group}
                        onChange={handleChangeValueRoleGroup}
                        label={FORM_FIELD_DATA.role_group.label}
                        name={FORM_FIELD_DATA.role_group.name}
                        options={roleGroup}
                    />
                    {selectedRoleGroup && (
                        <DropdownField
                            error={errors?.role}
                            touched={touched?.role}
                            value={values.role}
                            onChange={handleChange}
                            label={FORM_FIELD_DATA.role.label}
                            name={FORM_FIELD_DATA.role.name}
                            options={roleOptionsMap[selectedRoleGroup] || []}
                        />
                    )}
                </form>
            </div>
            <div className="w-full text-end mb-16">
                <GradientButton
                    onClick={handleSubmit}
                    label="Save"
                    variant="filled"
                    clasName="w-[168px]"
                    type="submit"
                />
            </div>
        </div>
    );
};
