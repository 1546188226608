import React, { FC, ReactNode } from "react";
import classNames from "classnames";

interface IProps {
    text: string;
    children: ReactNode;
    modalRef: React.RefObject<HTMLDialogElement>;
    onClose: () => void;
    className?: string;
}

export const ModalTemplate: FC<IProps> = ({
    text,
    children,
    modalRef,
    onClose,
    className = "",
}) => (
    <dialog className="modal" ref={modalRef}>
        <div className={classNames("modal-box w-11/12 max-w-7xl", className)}>
            <button
                className="btn btn-sm btn-circle absolute right-2 top-2"
                onClick={onClose}
            >
                ✕
            </button>
            <h3 className="text-lg font-bold">{text}</h3>
            <div className="divider" />
            {children}
        </div>
        <form method="dialog" className="modal-backdrop">
            <button>close</button>
        </form>
    </dialog>
);
