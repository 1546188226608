import React, { FC } from "react";
import classNames from "classnames";
import { v4 as uuids4 } from "uuid";
import { TooltipWrapper } from "../tooltip-wrapper";

interface ITableHeaderCeilProps {
    text?: string;
    className?: string;
    tooltipMessage?: string;
    idx?: number;
    tooltipWidth?: number;
}

export const TableHeaderCeil: FC<ITableHeaderCeilProps> = ({
    text,
    className = "",
    tooltipMessage,
    idx = uuids4(),
    tooltipWidth = 300,
}) => (
    <th
        id={`tooltip-${idx}`}
        className={classNames(
            "p-3 md:p-3 lg:p-3 text-left text-xs md:text-base lg-text-base lg:min-w-[100px] break-words",
            { "cursor-pointer": tooltipMessage },
            className,
        )}
    >
        <span className="mr-1">{text}</span>

        {tooltipMessage && (
            <div className="inline-block">
                <TooltipWrapper
                    sx={{
                        "& .MuiTooltip-tooltip": {
                            maxWidth: tooltipWidth,
                            padding: "8px 12px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                        },
                    }}
                    title={
                        <div className="max-w-full">
                            {tooltipMessage
                                .split("\\n")
                                .map((message, index) => (
                                    <p
                                        className="whitespace-normal break-words"
                                        key={`tooltip-${idx}-${index}`}
                                    >
                                        {message}
                                    </p>
                                ))}
                        </div>
                    }
                >
                    <span className="w-5 h-5 bg-blue-50 hover:bg-blue-100 transition-colors rounded-full flex items-center justify-center cursor-pointer text-sm font-normal text-blue-600">
                        ?
                    </span>
                </TooltipWrapper>
            </div>
        )}
    </th>
);
