import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { routerKeys, storageKeys } from "common/constants";

export const PrivateRoutes = () => {
    const token = localStorage.getItem(storageKeys.accessToken);
    const { pathname } = useLocation();

    if (pathname.startsWith("/api/v1/swagger")) {
        return <Outlet />;
    }


    return token ? <Outlet /> : <Navigate to={routerKeys.auth} />;
};
