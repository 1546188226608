import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface IProps {
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    onSearch?: () => void;
    disabled?: boolean;
}

const styles = {
    container: "w-full mt-3 mb-14 h-18",
    wrapper: "relative w-full h-18 transition-all duration-200",
    input: "text-[#4d596a] subtitle-sm w-full h-18 pl-14 pr-32 border-0 focus:border-0 rounded-md shadow-sm outline-none",
    icon: "absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400",
    button: "absolute right-2 top-1/2 transform -translate-y-1/2 h-18 px-4 font-semibold bg-white text-gray-700 rounded-md outline-none disabled:opacity-50 disabled:cursor-not-allowed",
} as const;

export const SearchProfile: React.FC<IProps> = ({
    placeholder = "Search formula, raw ingredient, sub ingredient or contaminant",
    value,
    onChange,
    onSearch,
    className,
    disabled = false,
}) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && onSearch) {
            e.preventDefault();
            onSearch();
        }
    };

    const handleSearchClick = () => {
        if (onSearch && !disabled) {
            onSearch();
        }
    };

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.wrapper}>
                <FontAwesomeIcon icon={faSearch} className={styles.icon} />
                <input
                    type="text"
                    className={styles.input}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    disabled={disabled}
                />
                <button
                    className={styles.button}
                    onClick={handleSearchClick}
                    disabled={disabled}
                >
                    Search
                </button>
            </div>
        </div>
    );
};
