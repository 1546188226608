import React, { FC, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AxiosError } from "axios";
import {
    defaultErrorToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { InputWrapper } from "components/shared/inputWrapper";
import {
    useCreateSubIngredient,
    useUpdateSubIngredientInRawIngredient,
    useFindSimilarItems,
    useSubIngredientByID,
    useSubIngredients,
} from "../queries";
import {
    IError,
    IOption,
    ISubIngredient,
    ISubIngredientError,
} from "common/types";
import { SearchableSelect } from "components/shared/SearchableSelect";
import { getErrorMessage, getQueryKey } from "common/utils";
import { FormActions } from "components/shared";
import { queryClient } from "libraries/queryProvider";
import { useDebounce, useURLStateSync } from "common/hooks";

interface IProps {
    handleClose: () => void;
    formulas_id?: string;
    raw_ingredients_id?: string;
    maxValue?: number;
    isLink?: boolean;
}

const CreateSchema = Yup.object().shape({
    sicode: Yup.string().trim(),
    name: Yup.string().trim().required("Name is required"),
});

const getSchema = ({ id, maxValue }: { id?: string; maxValue?: number }) => {
    if (id && maxValue !== undefined) {
        return CreateSchema.concat(
            Yup.object().shape({
                weight_percent: Yup.number()
                    .transform((value) => (isNaN(value) ? undefined : value))
                    .max(
                        maxValue,
                        `Weight percent must be less than or equal to ${maxValue}`,
                    )
                    .min(0, "Weight percent must be greater than or equal to 0")
                    .required("Weight percent is required"),
            }),
        );
    }
    return CreateSchema;
};

export const CreateSubIngredientForm: FC<IProps> = ({
    handleClose,
    formulas_id,
    raw_ingredients_id,
    maxValue,
    isLink = false,
}) => {
    const [selectedSubIngredient, setSelectedSubIngredient] =
        useState<IOption | null>(null);
    const [similarSubId, setSimilarSubId] = useState<string>();
    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const isAddingMode = Boolean(formulas_id || raw_ingredients_id);

    const { data: currSubIngredient } =
        useSubIngredientByID(similarSubId) || {};
    const {
        subIngredientOptions = [],
        isLoading,
        fetchNextPage,
        hasNextPage,
    } = useSubIngredients({
        search,
    });

    const { mutate: createSubIngredient } = useCreateSubIngredient();
    const { mutate: updateSubIngredient } =
        useUpdateSubIngredientInRawIngredient();
    const { sorting, getParamsString } = useURLStateSync();

    useEffect(() => {
        if (similarSubId && currSubIngredient) {
            setSelectedSubIngredient({
                value: String(currSubIngredient?.id),
                label: `${currSubIngredient?.sicode}, ${currSubIngredient?.name}`,
            });
        }
    }, [currSubIngredient, similarSubId]);

    const formik = useFormik({
        initialValues: {
            sicode: "",
            name: "",
            ...(raw_ingredients_id ? { weight_percent: "" } : {}),
        },
        validationSchema: getSchema({ id: raw_ingredients_id, maxValue }),
        validateOnChange: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const subIngredientsKey = getQueryKey(
                queryKeys.subIngredients,
                sorting,
                getParamsString,
            );

            const handleSuccess = (
                data: ISubIngredient | ISubIngredientError,
            ) => {
                const response = data as ISubIngredient;

                toast.dismiss(toastId);

                if (raw_ingredients_id) {
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.rawIngredient, raw_ingredients_id],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.rawIngredients],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.formulas],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.lastUpdateFormules],
                    })
                } else {
                    queryClient.invalidateQueries({
                        queryKey: subIngredientsKey,
                    });
                }

                if (!selectedSubIngredient && response) {
                    navigate(`${routerKeys.subIngredient}/${response.id}`);
                }

                setSelectedSubIngredient(null);
                formik.resetForm();
                handleClose();
            };

            const handleError = (err: IError) => {
                toast.update(toastId, defaultErrorToastUpdate);

                const responseError = err?.response?.data?.message || "";

                if (responseError.includes("sicode")) {
                    formik.setFieldError("sicode", responseError);
                }

                const message = getErrorMessage(err as AxiosError);

                formik.setFieldError(
                    "name",
                    typeof message === "string"
                        ? message
                        : "An unexpected error occurred",
                );
            };

            if (selectedSubIngredient) {
                updateSubIngredient(
                    {
                        ...values,
                        sicode: values.sicode?.trim(),
                        name: values.name?.trim(),
                        formulas_id,
                        raw_ingredients_id,
                    },
                    {
                        onSuccess: handleSuccess,
                        onError: handleError,
                    },
                );
            } else {
                createSubIngredient(
                    {
                        ...values,
                        sicode: values.sicode?.trim(),
                        name: values.name?.trim(),
                        formulas_id,
                        raw_ingredients_id,
                    },
                    {
                        onSuccess: handleSuccess,
                        onError: handleError,
                    },
                );
            }
        },
    });

    const { values, errors, touched, handleChange, resetForm } = formik;

    const debaunceDescription = useDebounce(values.name)

    const { data: similar } = useFindSimilarItems(debaunceDescription);

    useEffect(() => {
        if (selectedSubIngredient) {
            const index = selectedSubIngredient.label.indexOf(",");

            formik.setFieldValue(
                "sicode",
                selectedSubIngredient.label.slice(0, index),
            );
            formik.setFieldValue(
                "name",
                selectedSubIngredient.label.slice(index + 1).trim(),
            );
        }
    }, [selectedSubIngredient, formik?.setFieldValue]);

    const handleUpdateRelatedList = useCallback((newValue: IOption) => {
        setSelectedSubIngredient(newValue);
    }, []);

    const handleResetForm = () => {
        resetForm();
        setSelectedSubIngredient(null);
    };

    const handleLoadMore = useCallback(() => {
        if (!isLoading && hasNextPage) {
            fetchNextPage();
        }
    }, [isLoading, hasNextPage, fetchNextPage]);

    const handleInputChange = useCallback((value?: string) => {
        if (value) {
            setSearch(value);
        }
    }, []);

    if (maxValue !== undefined && maxValue <= 0 && raw_ingredients_id) {
        return (
            <div className="w-full">
                <h6 className="text-xl text-gray-500 text-center">
                    In the form, there already exists an equal distribution of
                    Sub Ingredients totaling 100%.
                </h6>
            </div>
        );
    }

    return (
        <>
            <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-4">
                    {isAddingMode && Boolean(subIngredientOptions?.length) && (
                        <div className="px-1">
                            <SearchableSelect
                                isLoading={isLoading}
                                options={subIngredientOptions}
                                values={selectedSubIngredient}
                                onChange={handleUpdateRelatedList}
                                onMenuScrollToBottom={handleLoadMore}
                                onInputChange={handleInputChange}
                            />
                        </div>
                    )}
                    {raw_ingredients_id && (
                        <InputWrapper
                            isError={Boolean(
                                errors.weight_percent && touched.weight_percent,
                            )}
                            error={errors.weight_percent}
                            label="Weight percent"
                        >
                            <input
                                type="number"
                                name="weight_percent"
                                className="w-full input input-bordered"
                                value={values.weight_percent}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    )}
                    <InputWrapper
                        isError={Boolean(errors.name && touched.name)}
                        error={errors.name}
                        label="Name"
                    >
                        <textarea
                            className="textarea textarea-bordered w-full flex-grow"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                                if (selectedSubIngredient) {
                                    setSelectedSubIngredient(null);
                                }

                                handleChange(e);
                            }}
                        />
                    </InputWrapper>

                    <FormActions
                        baseLink={routerKeys.subIngredient}
                        links={similar}
                        handleResetForm={handleResetForm}
                        onClick={isLink ? undefined : setSimilarSubId}
                        submitButtonText={
                            selectedSubIngredient && !isLink
                                ? "Update"
                                : "Create"
                        }
                    />
                </div>
            </form>
        </>
    );
};
