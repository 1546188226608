import classNames from 'classnames';
import { ColumnDef, Row } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify';

import { 
  backendKeys, 
  defaultErrorToastUpdate, 
  defaultSuccessToastUpdate, 
  defaultToastOptions, 
  queryKeys, 
  routerKeys, 
  toastTexts 
} from 'common/constants';
import { IRawIngredient, IRawIngredientSaved } from 'common/types';
import { DataTable, IconLink, SaveButton } from 'components/shared';
import { 
  useFullSavedRawIngredient, 
  useGetSavedData, 
  useRemoveSavedData 
} from 'pages/profile/libs';
import { rawIngredientColumnsConfig } from '../libs/columnsConfigs';

export const RITable = () => {
  const { data: savedRI = [], refetch } = useFullSavedRawIngredient();
  const { mutate: removeSavedRIMutation } = useRemoveSavedData<number>(backendKeys.api.rawIngredients.saved);
  const { refetch: refetchSavedRI } = useGetSavedData<IRawIngredientSaved>(
      queryKeys.savedRawIngredient, 
      backendKeys.api.rawIngredients.saved
  );

  const handleRemove = useCallback((id: number) => {
      const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
      removeSavedRIMutation(id, {
          onSuccess: () => {
              toast.update(toastId, {
                  ...defaultSuccessToastUpdate,
                  render: toastTexts.success,
              });
              if (refetch) {
                  refetch();
              }
              if (refetchSavedRI) {
                  refetchSavedRI();
              }
          },
          onError: () => {
              toast.update(toastId, defaultErrorToastUpdate);
          },
      });
  }, []);

  const columns = useMemo<ColumnDef<IRawIngredient>[]>(() => {
    const staticColumns = [
      {
        id: "unsaved",
        accessorKey: "unsaved",
        header: () => <span>Unsaved</span>,
        cell: ({ row }: { row: Row<IRawIngredient> }) => (
          <SaveButton
            className="flex items-center px-2 hover:cursor-pointer"
            id={row.original.id}
            onAction={handleRemove}
            items={savedRI}
            attribute="id"
          />
        ),
      },
      {
        id: "edit_formula",
        accessorKey: "edit_formula",
        header: () => <span>Edit</span>,
        cell: ({ row }: { row: Row<IRawIngredient> }) => (
          <IconLink to={routerKeys.rawIngredient} id={row.original.id} />
        ),
      },
    ];
  
    const dynamicColumns = rawIngredientColumnsConfig.map(({ key, label, format }) => ({
      accessorKey: key,
      header: () => <span>{label}</span>,
      ...(format && {
        cell: ({ row }: { row: Row<IRawIngredient> }) => <span>{format(row.getValue(key))}</span>,
      }),
    }));
  
    return [...dynamicColumns, ...staticColumns];
  }, [savedRI, savedRI.length, handleRemove]);

  return (
    <div
    className={classNames(
        "bg-profile flex flex-col w-full min-h-[230px] rounded-tl-2xl rounded-tr-2xl px-10 py-4 shadow-md mb-10"
    )}
>
    <h1 className="gray-color text-xl font-medium mb-2">
        Saved Raw Ingredients
    </h1>
    <DataTable
        className="pb-4"
        data={savedRI}
        columns={columns}
    />
</div>
  )
}

