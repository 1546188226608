import classNames from "classnames";
import React from "react";

interface IProps {
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    name: string;
    isDisabled?: boolean;
    type?: string;
}

export const TableInputCeil: React.FC<IProps> = ({
    value = "",
    onChange,
    className = "",
    name,
    isDisabled = false,
    type = "text",
}) => (
    <td className="flex p-3">
        <input
            type={type}
            className={classNames("w-full input input-bordered", className)}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
        />
    </td>
);
