import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import {
    FormulaProfileTab,
    IFormula,
    IPreparedSubIngredient,
    YesNoMaybe,
} from "./types";
import { Header } from "./components";
import { TabContent } from "./components/tabs";
import {
    defaultErrorToastOptions,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { TabsBox } from "components/tabsBox";
import { useRemoveFormula, useUploadFormula } from "./queries";
import { useFormulaProfileState } from "./store";
import { queryClient } from "libraries/queryProvider";
import { useURLStateSync } from "common/hooks";
import { IFormulasResponse } from "../formulas/types";
import { getQueryKey } from "common/utils";

const tabs: FormulaProfileTab[] = [
    "Properties",
    "Composition",
    "MOS",
    "Reports",
    "Testing",
    "Similarity",
];

interface IPreparedObj {
    [key: string]: IPreparedSubIngredient;
}

export const FormulaProfile = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const { sorting, getParamsString, addURLParams } = useURLStateSync();

    const initialTab =
        (new URLSearchParams(window.location.search).get(
            "tab",
        ) as FormulaProfileTab) || tabs[0];
    const [selectedTab, setSelectedTab] = useState(initialTab);

    const { data: formula, isLoading, isError } = useUploadFormula({ id });
    const { mutate: removeFormula } = useRemoveFormula();

    const {
        formulaeExtraInfo,
        clearState,
        setFormulaExtraInfo,
        setRawIngredients,
        setContaminants,
        setSubIngredients,
        setSubIngredientsTotalWeight,
        setTotalEnvironmentalScore,
        setRawIngredientsTotalWeight,
        setIsOwner,
        setKey,
    } = useFormulaProfileState();

    useEffect(() => {
        setIsOwner(true);
        return () => setIsOwner(false);
    }, [setIsOwner]);

    useEffect(() => {
        if (id) {
            setKey([queryKeys.formula, id]);
        }
    }, [id, setKey]);

    useEffect(() => {
        if (formula) {
            clearState();
            const preparedObj: IPreparedObj = {};
            let totalRawIngredientWeightPercent = "0";
            const rawIngredients =
                formula.rawIngredients.map((rawIngredient) => {
                    totalRawIngredientWeightPercent = new BigNumber(
                        totalRawIngredientWeightPercent,
                    )
                        .plus(rawIngredient.formRaw.weight_percent)
                        .toString();

                    return {
                        ...rawIngredient,
                        formRaw: {
                            ...rawIngredient.formRaw,
                            weight_percent: new BigNumber(
                                rawIngredient.formRaw.weight_percent,
                            ).toString(),
                        },
                    };
                }) || [];

            setRawIngredientsTotalWeight(totalRawIngredientWeightPercent);
            setRawIngredients(rawIngredients);

            const contaminants =
                formula.contaminants.map((rawIngredient) => {
                    return {
                        ...rawIngredient,
                        formulasContaminants: {
                            ...rawIngredient.formulasContaminants,
                            ppm: new BigNumber(
                                rawIngredient.formulasContaminants.ppm,
                            ).toString(),
                        },
                    };
                }) || [];

            setContaminants(contaminants);

            if (!Boolean(formula.rawIngredients)) {
                return;
            }

            let subIngredientsTotalWeight = "0";
            let totalEnvironmentalScore = "0";

            formula.rawIngredients.forEach((rawIngredient) => {
                if (rawIngredient.animal_derived === "yes") {
                    setFormulaExtraInfo("animalDerived", "Yes");
                    return true;
                }
            });

            if (
                formula.rawIngredients.some((it) => it.animal_derived === "yes")
            ) {
                setFormulaExtraInfo("animalDerived", "Yes");
                setFormulaExtraInfo("vegan", "No");
            }

            if (
                formula.rawIngredients.every((it) => it.animal_derived === "no")
            ) {
                setFormulaExtraInfo("animalDerived", "No");
                setFormulaExtraInfo("vegan", "Yes");
            }

            if (
                formula.rawIngredients.every(
                    (it) => it.animal_derived === "not_available",
                )
            ) {
                setFormulaExtraInfo("animalDerived", "Not available");
                setFormulaExtraInfo("vegan", "Not available");
            }

            for (const rawIngredient of formula.rawIngredients) {
                const rawWeight = new BigNumber(
                    rawIngredient.formRaw.weight_percent,
                ).div(100);

                if (formulaeExtraInfo.crueltyFree == "Yes") {
                    let crueltyFree: YesNoMaybe = formulaeExtraInfo.crueltyFree;

                    if (Boolean(rawIngredient.tradename)) {
                        for (const tradename of rawIngredient.tradename) {
                            if (tradename?.cfi_acceptable != "Yes") {
                                crueltyFree = "No";
                                break;
                            }
                        }
                    } else {
                        crueltyFree = "Not available";
                    }

                    setFormulaExtraInfo("crueltyFree", crueltyFree);
                }

                if (Boolean(rawIngredient.subIngredients?.length)) {
                    const subIngredients: IPreparedSubIngredient[] =
                        rawIngredient.subIngredients.map((subIngredient) => {
                            const raw_weight_percent = new BigNumber(
                                subIngredient.rawSub.weight_percent || "0",
                            )
                                .times(rawWeight)
                                .toString();

                            const environmental_score = !subIngredient
                                ?.toxicology?.overall_environmental_
                                ? "0"
                                : new BigNumber(raw_weight_percent)
                                      .times(
                                          subIngredient?.toxicology
                                              ?.overall_environmental_ || "0",
                                      )
                                      .div(100)
                                      .toString();

                            subIngredientsTotalWeight = new BigNumber(
                                subIngredientsTotalWeight,
                            )
                                .plus(raw_weight_percent)
                                .toString();

                            totalEnvironmentalScore = new BigNumber(
                                totalEnvironmentalScore,
                            )
                                .plus(environmental_score)
                                .toString();

                            if (
                                formulaeExtraInfo.gluten == "No" &&
                                subIngredient.gluten?.toLowerCase() == "yes"
                            ) {
                                setFormulaExtraInfo("gluten", "Yes");
                            }

                            return {
                                ...subIngredient,
                                environmental_score,
                                rawSub: {
                                    ...subIngredient.rawSub,
                                    raw_weight_percent,
                                },
                            } as unknown as IPreparedSubIngredient;
                        });

                    setTotalEnvironmentalScore(totalEnvironmentalScore);
                    setSubIngredientsTotalWeight(subIngredientsTotalWeight);

                    for (const ingredient of subIngredients) {
                        const key = ingredient.id;

                        if (preparedObj[key]) {
                            preparedObj[key].rawSub.raw_weight_percent =
                                new BigNumber(
                                    preparedObj[key].rawSub.raw_weight_percent,
                                )
                                    .plus(ingredient.rawSub.raw_weight_percent)
                                    .toString();
                            preparedObj[key].environmental_score =
                                new BigNumber(
                                    preparedObj[key].environmental_score,
                                )
                                    .plus(ingredient.environmental_score)
                                    .toString();
                        } else {
                            preparedObj[key] = ingredient;
                        }
                    }
                }
            }

            setSubIngredients(Object.values(preparedObj));
        }
    }, [formula, id]);

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as FormulaProfileTab);
            addURLParams({ tab });
        },
        [addURLParams],
    );

    const handleRemove = useCallback(() => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);

        const formulasKey = getQueryKey(
            queryKeys.formulas,
            sorting,
            getParamsString,
        );

        const previousFormulas =
            queryClient.getQueryData<IFormula[]>(formulasKey);

        queryClient.setQueryData(formulasKey, (old: IFormulasResponse) => ({
            ...old,
            rows: Array.isArray(old.rows)
                ? old.rows.filter((item) => `${item.id}` !== id)
                : [],
        }));

        removeFormula(
            { id },
            {
                onSuccess: () => {
                    toast.update(toastId, defaultSuccessToastUpdate);
                    navigate(routerKeys.formulas);
                },
                onError: () => {
                    queryClient.setQueryData(formulasKey, previousFormulas);
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            },
        );
    }, [id, sorting, getParamsString, removeFormula, navigate]);

    if (isLoading) {
        return <LoadingToast />;
    }

    if (!formula || isError) {
        toast.error(toastTexts.error, defaultErrorToastOptions);
        return <ToastContainer limit={5} />;
    }

    return (
        <>
            <Header />
            <TabsBox
                tabs={tabs}
                selectedTab={selectedTab}
                onSelectTab={handleSelectTab}
                onRemove={handleRemove}
            />
            <TabContent selectedTab={selectedTab} />
            <ToastContainer />
        </>
    );
};
