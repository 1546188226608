import React from "react";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useGetContaminant } from "../queries";
import { EditIcon } from "assets/icons";
import { useContaminantStore } from "../store";
import { UpdateContaminantForm } from "pages/contaminant/contaminants";
import { useModal } from "common/hooks";

export const Header = () => {
    const { data: contaminant } = useGetContaminant();
    const { isOwner } = useContaminantStore();

    const {
        modalRef: updateContaminantRef,
        openModal,
        closeModal,
    } = useModal();

    return (
        <>
            <ModalTemplate
                text="Edit Contaminant"
                onClose={closeModal}
                modalRef={updateContaminantRef}
                className="max-w-[600px]"
            >
                {contaminant && (
                    <UpdateContaminantForm
                        onClose={closeModal}
                        contaminant={contaminant}
                    />
                )}
            </ModalTemplate>
            <div className="md:flex lg:flex items-center p-3 mt-10 mx-10 mb-1 justify-between shadow-sm rounded-lg bg-[#EDE9FE] dark:bg-gray-800">
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {contaminant?.name}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        <b>{contaminant?.description}</b>
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {(isOwner || true) && (
                            <button className="ml-2" onClick={openModal}>
                                <EditIcon />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
};
