export const ActionCardData = {
   FORMULAS: {
      title: 'Create Formula',
      description: 'This is a description for this suggestion',
   },
   REPORTS: {
      title: 'Create Reports',
      description: 'This is a description for this suggestion',
   },
   ACCESS_DATA: {
      title: 'Access your data easily',
      description: 'This is a description for this suggestion',
   },
};