import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormActions, InputWrapper } from "components/shared";
import {
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { IError } from "common/types";
import { buildLink, getQueryKey } from "common/utils";
import { useURLStateSync } from "common/hooks";
import { useCreateFormula, useFindSimilarFormulas } from "../../queries";
import { IFormula } from "pages/formulas/formula-profile/types";
import { queryClient } from "libraries/queryProvider";

interface IProps {
    handleClose: () => void;
}

const CreateSchema = Yup.object().shape({
    description: Yup.string().trim().required("Name is required"),
});

export const CreateFormulaForm: FC<IProps> = ({ handleClose }) => {
    const { mutate: createFormula } = useCreateFormula();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const { sorting, getParamsString } = useURLStateSync();

    const formik = useFormik({
        initialValues: {
            description: "",
        },
        validationSchema: CreateSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            const formulasKey = getQueryKey(
                queryKeys.formulas,
                sorting,
                getParamsString,
            );

            createFormula(
                {
                    body: {
                        ...values,
                        description: values.description.trim(),
                        created_by: keycloak?.tokenParsed?.email,
                    },
                },
                {
                    onSuccess: (response: IFormula) => {
                        toast.update(toastId, {
                            ...defaultSuccessToastUpdate,
                            render: "Formula has been created",
                        });
                        queryClient.invalidateQueries({
                            queryKey: formulasKey,
                        });
                        formik.resetForm();
                        handleClose();

                        const link = buildLink(
                            routerKeys.formulas,
                            response.id,
                        );

                        navigate(link);
                    },
                    onError: (err: IError) => {
                        const message = err?.response?.data?.message || "";

                        if (message.includes("description")) {
                            formik.setFieldError("description", message);
                        }

                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, errors, touched, handleChange, resetForm } = formik;

    const { data: similarFormulas = [] } = useFindSimilarFormulas(
        values.description?.trim(),
    );

    const handleResetForm = () => {
        resetForm();
    };

    return (
        <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4">
                <InputWrapper
                    isError={Boolean(errors.description && touched.description)}
                    error={errors.description}
                    label="Description"
                    helpText="Enter a unique description for the formula."
                    example="Example: Lipstick: Bold Red Colour Rich Cream 007"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <FormActions
                    title="Similar formulas:"
                    baseLink={routerKeys.formulas}
                    links={similarFormulas}
                    handleResetForm={handleResetForm}
                    submitButtonText="Create"
                />
            </div>
        </form>
    );
};
