import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { ISimilar } from "pages/formulas/formula-profile/types/formulaSimilar";
import logo from "assets/images/image.webp";
import { JsPDFWithAutoTable } from "@/common/types";
import { SimilarityReportData } from "../types";
import { IFormula, IFormulaCompareResponse } from "pages/formulas";
import { modifyDate } from "common/utils";

export class SimilarityReportTemplate {
    private doc: JsPDFWithAutoTable;
    private readonly margin = 15;
    private currentY = this.margin;
    private readonly pageHeight: number;

    constructor() {
        this.doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
            compress: true,
        }) as JsPDFWithAutoTable;
        this.pageHeight = this.doc.internal.pageSize.height;
    }

    private addLogo(reportData: SimilarityReportData): void {
        this.ensureSafetyMargin();
        const { email, organization } = reportData || {};

        this.doc.addImage(logo, "WEBP", this.margin - 5, 5, 50, 15);

        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(10);
        this.doc.setTextColor(80, 80, 80);

        const contactInfo = [
            organization?.organisation?.[0] || "SmartSafety",
            "www.smartsafety.edelweissconnect.com",
            email || "",
            organization?.phone?.[0] || "",
        ].filter(Boolean);

        const startX = this.margin * 3 + 50;
        contactInfo.forEach((text, index) => {
            this.doc.text(text, startX, 8 + index * 5);
        });

        this.currentY += 20;
    }

    generate(reportData: SimilarityReportData) {
        this.addLogo(reportData);
        this.addHeader("Formula Similarity Report");
        this.addInfoBox(reportData);
        this.addBaseFormulaSection(reportData.baseFormula);
        this.addSimilarFormulasSection(reportData.similarFormulas);
        if (reportData.similarIngredients) {
            this.addSimilarRawIngredientSection(reportData.similarIngredients);
        }
        if (reportData.similarIngredients) {
            this.addSimilarSubIngredientSection(reportData.similarIngredients);
        }

        return this.doc;
    }

    private addHeader(title: string): void {
        this.ensureSafetyMargin();
        this.doc.setFillColor(240, 242, 245);
        this.doc.rect(
            10,
            this.currentY - 8,
            this.doc.internal.pageSize.width - 20,
            12,
            "F",
        );
        this.doc.setFont("helvetica", "bold");
        this.doc.setFontSize(18);
        this.doc.setTextColor(17, 50, 97);
        this.doc.text(title, this.margin, this.currentY);
        this.currentY += 12;
    }

    private addInfoBox(reportData: SimilarityReportData): void {
        this.ensureSafetyMargin();
        const infoData = [
            ["Base Formula:", reportData.baseFormula.ficode],
            ["Formula Name:", reportData.baseFormula.description],
            ["Generated by:", reportData.author],
            ["Date:", reportData.date],
        ];

        this.doc.autoTable({
            startY: this.currentY,
            head: [],
            body: infoData,
            theme: "plain",
            styles: { fontSize: 10, cellPadding: 2 },
            columnStyles: {
                0: { fontStyle: "bold", cellWidth: 40 },
                1: { cellWidth: 100 },
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private addBaseFormulaSection(baseFormula: IFormula): void {
        this.ensureSafetyMargin();
        this.addSectionTitle("Base Formula Details");

        const baseFormulaData = [
            ["FI Code:", baseFormula.ficode || "-"],
            ["Description:", baseFormula.description || "-"],
            ["Product type:", baseFormula.product_type || "-"],
            ["Physical State:", baseFormula.physical_state || "-"],
            ["Emulsion:", baseFormula.emulsion || "-"],
            [
                "Product Classification:",
                baseFormula.fda_product_classification || "-",
            ],
            ["Flammability:", baseFormula.flammability_code || "-"],
            ["Rinse/Leave-on:", baseFormula.rinseoff_leaveon || "-"],
            [
                "Grams applied per day:",
                baseFormula.grams_applied_per_day || "-",
            ],
            [
                "pH min/max:",
                `${baseFormula.initial_ph_min} / ${baseFormula.initial_ph_max}` ||
                    "-",
            ],
            [
                "Skin retention factor:",
                baseFormula.skin_retention_factor || "-",
            ],

            ["Status:", baseFormula.status || "-"],
            ["Created by:", baseFormula.created_by || "-"],
            ["Date created:", modifyDate(baseFormula.date_created) || "-"],
        ];

        this.addStyledTable(baseFormulaData as [string, string | number][]);
    }

    private addSimilarFormulasSection(similarFormulas: ISimilar[]): void {
        this.ensureSafetyMargin();
        this.addSectionTitle("Similar Formulas");

        const tableHeaders = [
            [
                "FI Code",
                "Description",
                "Product type",
                "Physical State",
                "Emulsion",
                "Class",
                "Flammability",
                "Score",
            ],
        ];

        const tableBody = similarFormulas.map((formula) => [
            formula.similarity.ficode,
            formula.similarity.description,
            formula.similarity.product_type,
            formula.similarity.physical_state,
            formula.similarity.emulsion,
            formula.similarity.fda_product_classification,
            formula.similarity.flammability_code,
            `${formula.score}%`,
        ]);

        this.doc.autoTable({
            startY: this.currentY,
            head: tableHeaders,
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 9, cellPadding: 2, halign: "center" },
            headStyles: {
                fillColor: [17, 50, 97],
                textColor: 255,
                fontSize: 9,
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private addSimilarRawIngredientSection(
        similarFormulas: IFormulaCompareResponse,
    ): void {
        this.ensureSafetyMargin();
        this.addSectionTitle("Compare Raw Ingredients Similarity");

        const tableHeaders = [["RI Code", "Description"]];

        similarFormulas.formulas.forEach(({ ficode, description }) => {
            tableHeaders[0].push(`${ficode} ${description}`);
        });

        const tableBody = similarFormulas.rawIngredients.map((formula) => {
            const row = [formula.ricode, formula.description];

            formula.weight_percents.forEach((percent) => {
                row.push(`${percent}%`);
            });
            return row;
        });

        this.doc.autoTable({
            startY: this.currentY,
            head: tableHeaders,
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 9, cellPadding: 2, halign: "center" },
            headStyles: {
                fillColor: [17, 50, 97],
                textColor: 255,
                fontSize: 9,
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private addSimilarSubIngredientSection(
        similarFormulas: IFormulaCompareResponse,
    ): void {
        this.ensureSafetyMargin();
        this.addSectionTitle("Compare Sub Ingredients Similarity");

        const tableHeaders = [["FI Code", "Description"]];

        similarFormulas.formulas.forEach(({ ficode, description }) => {
            tableHeaders[0].push(`${ficode} ${description}`);
        });

        const tableBody = similarFormulas.subIngredients.map((formula) => {
            const row = [formula.sicode, formula.description];

            formula.weight_percents.forEach((percent) => {
                row.push(`${percent}%`);
            });
            return row;
        });

        this.doc.autoTable({
            startY: this.currentY,
            head: tableHeaders,
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 9, cellPadding: 2, halign: "center" },
            headStyles: {
                fillColor: [17, 50, 97],
                textColor: 255,
                fontSize: 9,
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private addSectionTitle(title: string): void {
        this.ensureSafetyMargin();
        this.doc.setFontSize(14);
        this.doc.setTextColor(17, 50, 97);
        this.doc.text(title, this.margin, this.currentY);
        this.currentY += 6;
    }

    private addStyledTable(data: [string, string | number][]): void {
        this.ensureSafetyMargin();
        this.doc.autoTable({
            startY: this.currentY,
            head: [],
            body: data,
            theme: "plain",
            styles: { fontSize: 10, cellPadding: 2 },
            columnStyles: {
                0: { fontStyle: "bold", cellWidth: 50 },
                1: { cellWidth: 90 },
            },
        });
        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private ensureSafetyMargin(): void {
        if (this.currentY + this.margin > this.pageHeight) {
            this.doc.addPage();
            this.currentY = this.margin;
        }
    }
}
