import React, { Dispatch, FC, SetStateAction } from "react";
import classNames from "classnames";
import { IPreparedSubIngredient } from "pages/formulas/formula-profile/types";
import { SubIngredientLink } from "./SubIngredientLink";
import { CheckboxInput } from "./CheckboxInput";
import { IManualInput } from "../types";
import { TableCeil } from "components/shared";

interface IProps {
    manualInputs: IManualInput[];
    mosSubIngredients: IPreparedSubIngredient[];
    onChange: Dispatch<SetStateAction<IManualInput[]>>;
    headerLength?: number;
}

export const SubIngredientMOSTable: FC<IProps> = ({
    headerLength = 0,
    mosSubIngredients,
    manualInputs,
    onChange,
}) => (
    <>
        {Boolean(mosSubIngredients?.length) ? (
            mosSubIngredients.map((subIngredient, index) => (
                <tr key={subIngredient.id}>
                    <td className="py-2 px-1 sm:px-3 cursor-pointer break-words max-w-sm sm:max-w-sm overflow-hidden text-ellipsis">
                        <SubIngredientLink
                            id={subIngredient.id}
                            sicode={subIngredient.sicode}
                            name={subIngredient.name}
                            description={subIngredient.description}
                        />
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.rawSub?.raw_weight_percent
                            ? `${subIngredient?.rawSub?.raw_weight_percent}%`
                            : ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.skin_retention_factor || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        <CheckboxInput
                            index={index}
                            field="dermal_penetration"
                            value={manualInputs?.[index]?.dermal_penetration}
                            value_fix={
                                manualInputs?.[index]?.dermal_penetration_fix
                            }
                            onChange={onChange}
                        />
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.daily_exposure || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        {subIngredient?.systematic_daily_exposure || ""}
                    </td>
                    <td className="py-2 px-1 sm:px-3 text-center">
                        <CheckboxInput
                            index={index}
                            field="noael"
                            value={manualInputs?.[index]?.noael}
                            value_fix={manualInputs?.[index]?.noael_fix}
                            onChange={onChange}
                        />
                    </td>
                    <td
                        className={classNames("py-2 px-1 sm:px-3 text-center", {
                            error:
                                subIngredient.MOS && +subIngredient.MOS < 100,
                        })}
                    >
                        {subIngredient.MOS || ""}
                    </td>
                </tr>
            ))
        ) : (
            <tr>
                <TableCeil
                    text="No Sub Ingredients found"
                    className="p-3 text-gray-500"
                    colSpan={headerLength}
                />
            </tr>
        )}
    </>
);
