import React, { FC, ReactNode } from "react";
import { HeaderTemplate } from "../shared/layouts";

interface IProps {
    actionCards?: ReactNode;
    children?: ReactNode;
    title?: string;
    subtitle?: string;
    firstName?: string;
    filledButtonTitle?: string;
    outlinedButtonTitle?: string;
    openCreateModal?: () => void;
    uploadButton?: ReactNode;
}

export const PageTemplate: FC<IProps> = ({ 
    actionCards, 
    children, 
    title,
    subtitle, 
    firstName,
    filledButtonTitle,
    openCreateModal,
    uploadButton
}) => {
    return (
        <div className="ml-0 md:ml-[324px] flex-grow">
            <HeaderTemplate
                title={title}
                subtitle={subtitle}
                filledButtonTitle={filledButtonTitle}
                openCreateModal={openCreateModal}
            >
                {uploadButton}
            </HeaderTemplate>
            <main className="p-3">
                {firstName && (
                    <p className="text-gray-700 text-lg">
                        Welcome,{" "}
                        <strong>{firstName ?? "User"}!</strong>{" "}
                        Here&apos;s how you can get the best out of SmartSafety!
                    </p>                    
                )}
                {actionCards && (
                    <div className="grid justify-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-6">
                        {actionCards}
                    </div>
                )}
                {children && <div className="mt-10">{children}</div>}
            </main>
        </div>
    );
};
