import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoadingToast } from "components/shared";
import { SoftDeleteTabsBox } from "components/tabsBox";
import { Header, TabContent } from "./components";
import { useRemoveRawIngredient, useUploadRawIngredient } from "./queries";
import { useRawIngredientStore } from "./store";
import {
    IRawIngredientItem,
    IRawIngredientsResponse,
    RawIngredientProfileTabT,
} from "../types";
import { RawIngredientProfileTab } from "../enums";
import {
    defaultErrorToastOptions,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { getQueryKey } from "common/utils";
import { useURLStateSync } from "common/hooks";
import { queryClient } from "libraries/queryProvider";

const tabs: RawIngredientProfileTabT[] = Object.values(RawIngredientProfileTab);

export const RawIngredientProfile = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const { setKey } = useRawIngredientStore();
    const { sorting, getParamsString, addURLParams } = useURLStateSync();

    const initialTab =
        (new URLSearchParams(window.location.search).get(
            "tab",
        ) as RawIngredientProfileTabT) || tabs[0];
    const [selectedTab, setSelectedTab] = useState(initialTab);

    const {
        data: rawIngredient,
        isLoading,
        isError,
    } = useUploadRawIngredient({ id });

    useEffect(() => {
        if (id) {
            setKey([queryKeys.rawIngredient, id]);
        }
    }, [id, setKey]);

    const handleSelectTab = useCallback(
        (tab: string) => {
            setSelectedTab(tab as RawIngredientProfileTabT);
            addURLParams({ tab });
        },
        [addURLParams],
    );

    const { mutate: removeRawIngredient } = useRemoveRawIngredient();

    const handleRemoveIngredient = useCallback(() => {
        const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
        const rawIngredientsKey = getQueryKey(
            queryKeys.rawIngredients,
            sorting,
            getParamsString,
        );
        const previousRawIngredients =
            queryClient.getQueryData<IRawIngredientItem[]>(rawIngredientsKey);

        queryClient.setQueryData(
            rawIngredientsKey,
            (old: IRawIngredientsResponse) => ({
                ...old,
                rows: old?.rows?.filter((item) => `${item.id}` !== id) || [],
            }),
        );

        removeRawIngredient(id, {
            onSuccess: () => {
                toast.update(toastId, defaultSuccessToastUpdate);
                navigate(routerKeys.rawIngredient);
            },
            onError: () => {
                queryClient.setQueryData(
                    rawIngredientsKey,
                    previousRawIngredients,
                );
                toast.update(toastId, defaultErrorToastUpdate);
            },
        });
    }, [id, sorting, getParamsString, removeRawIngredient, navigate]);

    const alertMsg = `Are you sure you want to mark ${rawIngredient?.description} as inactive?\n It will be removed from new contexts but remain in existing ones.`;

    if (isLoading) return <LoadingToast />;

    if (!rawIngredient || isError) {
        isError
            ? toast.error(toastTexts.error, defaultErrorToastOptions)
            : toast.info(toastTexts.dataLack, defaultErrorToastOptions);
        return <ToastContainer />;
    }

    return (
        <div className="ml-0 md:ml-[324px] flex-grow mt-16">
            <Header />
            <SoftDeleteTabsBox
                tabs={tabs}
                selectedTab={selectedTab}
                onSelectTab={handleSelectTab}
                onMarkAsInactive={handleRemoveIngredient}
                inactivityNotification={alertMsg}
            />
            <TabContent selectedTab={selectedTab} />
            <ToastContainer />
        </div>
    );
};
