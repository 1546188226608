import React, { ChangeEvent, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import {
    defaultErrorToastOptions,
    defaultToastOptions,
    queryKeys,
    toastTexts,
} from "common/constants";
import { IReport } from "common/types";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useModal } from "common/hooks";
import {
    useAddSubIngredientReport,
    useGetSubIngredient,
    useGetSubIngredientReports,
    useRemoveSubIngredientReport,
} from "pages/subIngredient/subIngredientProfile/queries";
import { subIngredientService } from "services";
import { handleDownloadFile } from "common/utils";
import { Button } from "components/shared/button";
import { LoadingToast } from "components/shared/loadingToast";
import { ReportRow } from "./ReportRow";

export const ReportButton = () => {
    const { modalRef, openModal, closeModal } = useModal({ onClose: () => {} });
    const queryClient = useQueryClient();

    const subIngredient = useGetSubIngredient();
    const key = [queryKeys.subIngredientReports, subIngredient?.sicode || ""];

    const { data: reports, refetch } = useGetSubIngredientReports({
        sicode: subIngredient?.sicode || "",
    });

    const { mutate: uploadReport, isPending: isPendingAddReport } =
        useAddSubIngredientReport();
    const { mutate: removeReport, isPending: isPendingRemoveReport } =
        useRemoveSubIngredientReport();

    const handleDownload = useCallback(
        async (file: IReport) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            try {
                const response = await subIngredientService.getReport({
                    sicode: subIngredient?.sicode || "",
                    filename: file.filename,
                });

                handleDownloadFile(response, file.filename);
                toast.dismiss(toastId);
            } catch (error) {
                console.error("Error downloading file:", error);

                toast.update(toastId, {
                    render: toastTexts.error,
                    position: "bottom-left",
                    type: "error",
                    isLoading: false,
                });
            }
        },
        [subIngredient?.sicode],
    );

    const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            console.warn("No file selected.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        uploadReport(
            {
                sicode: subIngredient?.sicode || "",
                body: formData,
                filename: file.name,
            },
            {
                onSuccess: () => {
                    console.info("File uploaded successfully.");
                    refetch();
                },
                onError: (error) => {
                    console.error("Error uploading file:", error);
                    toast.error(toastTexts.error, defaultErrorToastOptions);
                },
            },
        );

        e.target.value = "";
    };

    const handleDeleteFile = useCallback(
        (file: IReport) => {
            removeReport(
                {
                    sicode: subIngredient?.sicode || "",
                    filename: file.filename,
                },
                {
                    onSuccess: () => {
                        queryClient.setQueryData<IReport[]>(key, (prevData) =>
                            prevData?.filter(
                                (prevFile) =>
                                    prevFile.filename !== file.filename &&
                                    prevFile.modified !== file.modified,
                            ),
                        );
                    },
                    onError: (error) => {
                        console.error("Error deleting file:", error);
                        toast.error(toastTexts.error, defaultErrorToastOptions);
                    },
                },
            );
        },
        [key, subIngredient?.sicode],
    );

    return (
        <>
            {(isPendingAddReport || isPendingRemoveReport) && <LoadingToast />}
            <div className="flex w-full md:w-auto lg:w-auto">
                <Button
                    onClick={openModal}
                    text="Reports files"
                    icon={<FontAwesomeIcon icon={faFile} />}
                    className="whitespace-nowrap"
                />
            </div>
            <ModalTemplate
                text={`Sub Ingredient files for ${subIngredient?.name || "unknown"}`}
                modalRef={modalRef}
                onClose={closeModal}
                className="max-w-xl"
            >
                <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg my-3">
                    <tbody>
                        {reports?.length ? (
                            reports.map((file, idx) => (
                                <ReportRow
                                    key={`file-${idx}`}
                                    file={file}
                                    onDeleteFile={handleDeleteFile}
                                    onDownloadFile={handleDownload}
                                />
                            ))
                        ) : (
                            <tr>
                                <td className="w-3/4 text-xs md:text-base lg:text-base p-3">
                                    No file found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="w-full flex-col">
                    <label className="label mb-0">
                        <span className="label-text-alt">
                            Upload a file (doc, docx, pdf, txt):
                        </span>
                    </label>
                    <input
                        className="w-full file-input file-input-bordered"
                        type="file"
                        onChange={handleAddFile}
                        accept=".doc, .docx, .pdf, .txt"
                    />
                </div>
            </ModalTemplate>
        </>
    );
};
