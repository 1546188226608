import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { type JsPDFWithAutoTable, type ReportData } from "./types";
import logo from "assets/images/image.webp";

export class MOSReportTemplate {
    private doc: JsPDFWithAutoTable;
    private readonly margin = 15;
    private currentY = this.margin;

    constructor() {
        this.doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
            compress: true,
        }) as JsPDFWithAutoTable;
    }

    private shouldShowSummary(reportData: ReportData): boolean {
        return Boolean(
            reportData.totalWeight ||
                reportData.highestExposureFactor ||
                reportData.mos,
        );
    }

    private shouldShowMetrics(reportData: ReportData): boolean {
        const { calculationMetrics } = reportData;
        return Boolean(
            calculationMetrics.productType ||
                calculationMetrics.bodyWeight ||
                calculationMetrics.gramsAppliedPerDay ||
                calculationMetrics.skinRetentionFactor,
        );
    }

    private addLogo(reportData: ReportData): void {
        const { email, organization } = reportData || {};

        this.doc.addImage(logo, "WEBP", this.margin - 5, 5, 50, 15);

        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(10);
        this.doc.setTextColor(80, 80, 80);

        const contactInfo = [
            organization?.organisation?.[0] || "SmartSafety",
            "www.smartsafety.edelweissconnect.com",
            email || "",
            organization?.phone?.[0] || "",
        ].filter(Boolean);

        const startX = this.margin * 3 + 50;
        contactInfo.forEach((text, index) => {
            this.doc.text(text, startX, 8 + index * 5);
        });

        this.currentY += 20;
    }

    generate(reportData: ReportData): jsPDF {
        this.addLogo(reportData);
        this.addHeader("Formula MOS Report");
        this.addInfoBox(reportData);

        if (this.shouldShowSummary(reportData)) {
            this.addSummarySection(reportData);
        }

        if (this.shouldShowMetrics(reportData)) {
            this.addMetricsSection(reportData);
        }

        this.addMosCalculationExplanation();

        if (reportData.ingredients.length) {
            this.addIngredientsTable(reportData);
        }

        return this.doc;
    }

    private addHeader(title: string): void {
        this.doc.setFillColor(240, 242, 245);
        this.doc.rect(
            10,
            this.currentY - 8,
            this.doc.internal.pageSize.width - 20,
            12,
            "F",
        );

        this.doc.setFont("helvetica", "bold");
        this.doc.setFontSize(18);
        this.doc.setTextColor(17, 50, 97);
        this.doc.text(title, this.margin, this.currentY);
        this.currentY += 12;
    }

    private addInfoBox(reportData: ReportData): void {
        const infoData = [
            ["FI Code:", reportData.productId],
            ["Formula Name:", reportData.productName],
            ["Generated by:", reportData.author],
            ["Date:", reportData.date],
        ];

        this.doc.autoTable({
            startY: this.currentY,
            head: [],
            body: infoData,
            theme: "plain",
            styles: { fontSize: 10, cellPadding: 2 },
            columnStyles: {
                0: {
                    fontStyle: "bold",
                    cellWidth: 40,
                },
                1: { cellWidth: 100 },
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private addSummarySection(reportData: ReportData): void {
        this.addSectionTitle("MOS Calculation Summary");
        const summaryData: [string, string | number][] = [];

        if (reportData.totalWeight) {
            summaryData.push(["Total Weight:", reportData.totalWeight]);
        }
        if (reportData.highestExposureFactor) {
            summaryData.push([
                "Highest Exposure Factor:",
                reportData.highestExposureFactor,
            ]);
        }
        if (reportData.mos) {
            summaryData.push(["MOS:", reportData.mos]);
        }

        this.addStyledTable(summaryData);
    }

    private addMetricsSection(reportData: ReportData): void {
        this.addSectionTitle("Calculation Metrics");
        const { calculationMetrics } = reportData;
        const metricsData = [
            calculationMetrics.productType && [
                "Product Type:",
                calculationMetrics.productType,
            ],
            calculationMetrics.bodyWeight && [
                "Body Weight:",
                calculationMetrics.bodyWeight,
            ],
            calculationMetrics.gramsAppliedPerDay && [
                "Grams Applied Per Day:",
                calculationMetrics.gramsAppliedPerDay,
            ],
            calculationMetrics.skinRetentionFactor && [
                "Skin Retention Factor:",
                calculationMetrics.skinRetentionFactor,
            ],
        ].filter(Boolean);

        this.addStyledTable(metricsData as [string, string | number][]);
    }

    private addIngredientsTable(reportData: ReportData): void {
        this.addSectionTitle("Ingredients and Exposure Analysis");
        const tableHeaders = [
            [
                "SI Code",
                "Name",
                "Total Weight (%)",
                // "Skin Ret.",
                "Derm. Pen.",
                "Daily Exp.",
                "Syst. Exp.",
                "NOAEL",
                "MOS",
            ],
        ];
        const tableBody = reportData.ingredients.map((ing) => [
            ing.sicode,
            ing.name,
            ing.total_weight,
            // ing.skin_retention_factor,
            ing.dermal_penetration,
            ing.daily_exposure,
            ing.systematic_daily_exposure,
            ing.noael,
            ing.mos,
        ]);

        this.doc.autoTable({
            startY: this.currentY,
            head: tableHeaders,
            body: tableBody,
            theme: "grid",
            styles: { fontSize: 9, cellPadding: 2, halign: "center" },
            columnStyles: { 1: { cellWidth: 50 } },
            headStyles: {
                fillColor: [17, 50, 97],
                textColor: 255,
                fontSize: 9,
            },
        });
    }

    private addSectionTitle(title: string): void {
        this.doc.setFontSize(14);
        this.doc.setTextColor(17, 50, 97);
        this.doc.text(title, this.margin, this.currentY);
        this.currentY += 6;
    }

    private addStyledTable(data: [string, string | number][]): void {
        this.doc.autoTable({
            startY: this.currentY,
            head: [],
            body: data,
            theme: "plain",
            styles: { fontSize: 10, cellPadding: 2 },
            columnStyles: {
                0: {
                    fontStyle: "bold",
                    cellWidth: 50,
                },
                1: { cellWidth: 90 },
            },
        });
        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }

    private renderFormula({
        rows,
        showArrow = true,
    }: {
        rows: string[];
        showArrow?: boolean;
    }) {
        const startX = this.margin;
        const boxHeight = 10 + rows.length * 10;
        const boxWidth = 170;
        const centerX = startX + boxWidth / 2;

        this.doc.setDrawColor(17, 50, 97);
        this.doc.setFillColor(240, 242, 245);
        this.doc.setLineWidth(0.5);
        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(9);
        this.doc.setTextColor(17, 50, 97);

        this.doc.roundedRect(
            startX,
            this.currentY,
            boxWidth,
            boxHeight,
            3,
            3,
            "FD",
        );

        rows.forEach((row, idx) => {
            this.doc.text(row, centerX, this.currentY + 10 + 8 * idx, {
                align: "center",
            });
        });

        this.currentY += boxHeight + 10;

        if (showArrow) {
            this.doc.line(
                startX + boxWidth / 2,
                this.currentY - 10,
                startX + boxWidth / 2,
                this.currentY,
            );
            this.doc.line(
                startX + boxWidth / 2 - 3,
                this.currentY - 5,
                startX + boxWidth / 2,
                this.currentY,
            );
            this.doc.line(
                startX + boxWidth / 2 + 3,
                this.currentY - 5,
                startX + boxWidth / 2,
                this.currentY,
            );
        }
    }

    private addMosCalculationExplanation(): void {
        this.addSectionTitle("MOS Calculation Explanation");

        this.renderFormula({
            rows: [
                "Daily Exposure (DE) = ",
                "(gramsAppliedPerDay x skinRetentionFactor x dermalPenetration / 100 x rawWeightPercent / 100) x 1000",
            ],
        });

        this.renderFormula({
            rows: ["Systemic Exposure (SED) = Daily Exposure / Body Weight"],
        });

        this.renderFormula({
            rows: ["Margin of Safety (MOS) = NOAEL / Systemic Exposure"],
            showArrow: false,
        });

        const legendData = [
            ["Where:", ""],
            ["NOAEL", "No Observed Adverse Effect Level"],
            ["gramsAppliedPerDay", "Amount of product applied per day"],
            [
                "skinRetentionFactor",
                "Fraction of the product retained on the skin",
            ],
            [
                "dermalPenetration",
                "Percentage of the ingredient absorbed through the skin",
            ],
            ["rawWeightPercent", "Percentage of the ingredient in the product"],
            ["bodyWeight", "User's body weight in kg"],
        ];

        this.doc.autoTable({
            startY: this.currentY,
            head: [],
            body: legendData,
            theme: "plain",
            styles: { fontSize: 9, cellPadding: 2 },
            columnStyles: {
                0: {
                    fontStyle: "bold",
                    cellWidth: 50,
                },
                1: { cellWidth: 130 },
            },
        });

        this.currentY = this.doc.lastAutoTable.finalY + 8;
    }
}
