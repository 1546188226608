import React, { FC } from "react";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";
import { ConfidenceComponent } from "../confidence/ConfidenceComponent";
import {
    type RequestStatus,
    type Its3PredictionResponse,
} from "pages/subIngredient/subIngredientProfile/libs";

const classMap = {
    NonSensitizer: ["prediction-category--1", "Not a sensitiser"],
    WeakSensitizer: ["prediction-category--2", "Weak sensitiser"],
    ModerateSensitizer: ["prediction-category--3", "Moderate sensitiser"],
    StrongSensitizer: ["prediction-category--4", "Strong sensitiser"],
};
const buildAttributes = (predictedClass: keyof typeof classMap) =>
    classMap[predictedClass] || ["prediction-category--0", "Inconclusive"];

interface IProps {
    data?: Its3PredictionResponse | null;
    predictedClass?: keyof typeof classMap;
    status?: RequestStatus;
}

export const PredictionComponent: FC<IProps> = ({
    data,
    predictedClass,
    status,
}) => {
    const [divClass, divText] = buildAttributes(
        predictedClass || "NonSensitizer",
    );

    if (status === "PENDING") {
        return (
            <div className="space-y-2">
                <div className="text-sm text-gray-700">Multiple regression</div>
                <div className="flex items-center space-x-2">
                    <CircularProgress size={20} color="inherit" />
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    if (!status) return null;

    return (
        <div>
            <div className="text-sm font-semibold text-blue-700">
                Bayesian network
            </div>
            <div
                className={classNames(
                    "text-white text-sm px-2 py-1.5 text-center mb-1",
                    divClass,
                )}
            >
                {divText}
            </div>
            {data?.predictionResult && (
                <ConfidenceComponent
                    confidence={data.predictionResult?.confidence}
                    withoutMaxWidth={true}
                />
            )}
        </div>
    );
};
