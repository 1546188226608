import React from "react";
import classNames from "classnames";

interface IProps {
    value?: string;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    className?: string;
    name: string;
    isDisabled?: boolean;
    placeholder?: string;
}

export const TableTextareaCeil: React.FC<IProps> = ({
    value = "",
    onChange,
    className = "",
    name,
    isDisabled = false,
    placeholder = "",
}) => (
    <td className="flex p-3">
        <textarea
            className={classNames(
                "textarea textarea-bordered w-full",
                className,
            )}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            placeholder={placeholder}
        />
    </td>
);
