import getting_started from "assets/images/icons/category/getting_started.svg";
import scan_barcode from "assets/images/icons/category/scan-barcode.svg";
import message_search from "assets/images/icons/category/message-search.svg";
import folder_favorite from "assets/images/icons/category/folder-favorite.svg";
import ticket from "assets/images/icons/category/ticket.svg";
import briefcase from "assets/images/icons/category/briefcase.svg";
import { ICategoryData } from "@/common/types";

export const categoryCardData: ICategoryData[] = [
   {
      icon: getting_started,
      title: 'Getting started',
      description: 'Quick guides to help you set up and begin using SmartSafety effectively..',
      link: '#',
   },
   {
      icon: scan_barcode,
      title: 'Subscriptions',
      description: 'Manage your plan, view billing details, and update subscription settings.',
      link: '#',
   },
   {
      icon: message_search,
      title: 'FAQs',
      description: 'Quick answers to commonly asked questions about SmartSafety features and usage.',
      link: '#',
   },
   {
      icon: folder_favorite,
      title: 'Resources',
      description: 'Access tutorials, user guides, and supporting materials for deeper insights.',
      link: '#',
   },
   {
      icon: ticket,
      title: 'Create a ticket',
      description: 'Report an issue or request support directly from our help desk team',
      link: '#',
   },
   {
      icon: briefcase,
      title: 'Terms & Privacy Policy',
      description: 'Review SmartSafety’s terms of use, privacy policy, and data handling practices.',
      link: '#',
   },
]