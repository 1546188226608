import React, { FC } from "react";
import classNames from "classnames";
import { TooltipWrapper } from "components/shared";

interface IProps {
    children: React.ReactNode;
    label: string;
    className?: string;
    tooltipMessage?: string;
}

export const InputWrapper: FC<IProps> = ({
    children,
    label,
    className = "",
    tooltipMessage,
}) => (
    <div className="flex w-full px-4 gap-4">
        <div className="w-[35%] py-2">
            <div className="flex items-center gap-2">
                <label className="text-gray-700 font-medium">{label}</label>
                {tooltipMessage && (
                    <TooltipWrapper title={tooltipMessage}>
                        <span className="ml-2 w-5 h-5 bg-blue-100 rounded-full flex items-center justify-center cursor-pointer">
                            ?
                        </span>
                    </TooltipWrapper>
                )}
            </div>
        </div>
        <div className={classNames("w-[65%]", className)}>{children}</div>
    </div>
);
