import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FormikErrors } from "formik";
import { SectionHeader } from "../SectionHeader";
import { InputBlock } from "../InputBlock";
import { InputLabel } from "../InputLabel";
import { type MoleculeDataModel } from "pages/subIngredient/subIngredientProfile/libs";
import { RequiredDescription } from "../prediction/components/RequiredDescription";

type IProps = {
    values: MoleculeDataModel;
    errors: FormikErrors<MoleculeDataModel>;
    onChange: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
};

const getRequiredFields = ({
    hClatAssayEC150: EC150,
    hClatAssayEC200: EC200,
    hClatAssayCV75: CV75,
    toxtree,
    timesSSNeuralNetwork,
    keratinoSenseAssayJaworskaKEC15: KEC15,
}: {
    hClatAssayEC150: string;
    hClatAssayEC200: string;
    hClatAssayCV75: string;
    toxtree: string;
    timesSSNeuralNetwork: string;
    keratinoSenseAssayJaworskaKEC15: string;
}) => {
    const isAllEmpty = !EC150 && !EC200 && !CV75;
    const isTwoEmpty = !EC150 && !EC200;
    const hasToxtreeOrTimes = (toxtree || timesSSNeuralNetwork) && KEC15;

    return {
        EC150: Boolean(isAllEmpty || (hasToxtreeOrTimes && isTwoEmpty)),
        EC200: Boolean(isAllEmpty || (hasToxtreeOrTimes && isTwoEmpty)),
        CV75: Boolean(isAllEmpty || hasToxtreeOrTimes),
    };
};

const inputClassName =
    "w-full bg-gray-100 rounded-sm px-3 py-1 border-b border-gray-400 focus:outline-none focus:border-gray-600 transition duration-200";

export const AnnTab: FC<IProps> = ({ values, errors, onChange }) => {
    const [isOpenTimes, setIsOpenTimes] = useState(false);
    const [isOpenToxtree, setIsOpenToxtree] = useState(false);

    const handleOpenTimes = useCallback(
        () => setIsOpenTimes((prev) => !prev),
        [],
    );
    const handleOpenToxtree = useCallback(
        () => setIsOpenToxtree((prev) => !prev),
        [],
    );

    const isRequired = getRequiredFields(values);

    const handleSelectChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            const syntheticEvent = {
                target: {
                    name: event.target.name,
                    value: event.target.value,
                },
            } as ChangeEvent<HTMLInputElement | HTMLSelectElement>;
            onChange(syntheticEvent);
        },
        [onChange],
    );
    return (
        <div className="flex flex-col gap-[20px]">
            <section className="flex flex-col gap-[40px]">
                <SectionHeader
                    title="Enter experimental values"
                    text="Input data for assays associated with three key events in the AOP for Skin sensitisation."
                    text2="Recommended to improve prediction estimate."
                />

                <div className="flex flex-col flex-nowrap gap-2 mb-10">
                    <RequiredDescription />
                    <div className="flex flex-wrap gap-5">
                        <InputBlock
                            title="Covalent binding to skin proteins"
                            subtitle="DPRA"
                        >
                            <InputLabel
                                text="DPRACys (% depleted)"
                                tooltipText="Percent of cysteine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRACys}
                                isRequired
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="dpraAssayDPRACys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRACys}
                                />
                            </InputLabel>
                            <InputLabel
                                text="DPRALys (% depleted)"
                                tooltipText="Percent of lysine peptide depletion in the DPRA assay."
                                errorText={errors.dpraAssayDPRALys}
                                isRequired
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="dpraAssayDPRALys"
                                    onChange={onChange}
                                    value={values.dpraAssayDPRALys}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Keratinocyte activation"
                            subtitle="KeratinoSens™"
                        >
                            <InputLabel
                                text="EC1.5 (µM)"
                                tooltipText="Concentration yielding 1.5-fold induction in Nrf2-dependent luciferase activity in the KeratinoSens™ assay."
                                errorText={
                                    errors.keratinoSenseAssayJaworskaKEC15
                                }
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="keratinoSenseAssayJaworskaKEC15"
                                    onChange={onChange}
                                    value={
                                        values.keratinoSenseAssayJaworskaKEC15
                                    }
                                />
                            </InputLabel>
                            <InputLabel
                                text="Imax"
                                tooltipText="keratinoSenseAssayKSImax in the KeratinoSens™ assay."
                                errorText={errors.unclearImax}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="unclearImax"
                                    onChange={onChange}
                                    value={values.unclearImax}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock
                            title="Dendritic cell activation"
                            subtitle="h-CLAT"
                        >
                            <InputLabel
                                text="EC150 (ug/mL)"
                                tooltipText="Concentration yielding 150% induction of CD86 in h-CLAT assay."
                                errorText={errors.hClatAssayEC150}
                                isRequired={isRequired.EC150}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="hClatAssayEC150"
                                    onChange={onChange}
                                    value={values.hClatAssayEC150}
                                />
                            </InputLabel>
                            <InputLabel
                                text="EC200 (ug/mL)"
                                tooltipText="Concentration yielding 200% induction of CD54 in h-CLAT assay."
                                errorText={errors.hClatAssayEC200}
                                isRequired={isRequired.EC200}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="hClatAssayEC200"
                                    onChange={onChange}
                                    value={values.hClatAssayEC200}
                                />
                            </InputLabel>
                            <InputLabel
                                text="CV75 (ug/mL)"
                                tooltipText="25% reduction in cell viability in h-CLAT assay."
                                errorText={errors.hClatAssayCV75}
                            >
                                <input
                                    type="text"
                                    className={inputClassName}
                                    name="hClatAssayCV75"
                                    onChange={onChange}
                                    value={values.hClatAssayCV75}
                                />
                            </InputLabel>
                        </InputBlock>

                        <InputBlock title="In silico" subtitle="TIMES-SS">
                            <InputLabel
                                text="Sensitiser potency"
                                tooltipText="The highest skin sensitisation class predicted by TIMES model."
                                errorText={errors.timesSSNeuralNetwork}
                                isFull
                            >
                                <Select
                                    open={isOpenTimes}
                                    onClick={handleOpenTimes}
                                    fullWidth
                                    className="custom-select bg-white"
                                    name="timesSSNeuralNetwork"
                                    displayEmpty
                                    value={values.timesSSNeuralNetwork}
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="">-</MenuItem>
                                    <MenuItem value="1">
                                        Non Sensitizer
                                    </MenuItem>
                                    <MenuItem value="3">Weak/Moderate</MenuItem>
                                    <MenuItem value="2">
                                        Strong/Extreme
                                    </MenuItem>
                                </Select>
                            </InputLabel>
                            <span className="text-sm text-gray-600">
                                Toxtree
                            </span>
                            <InputLabel
                                text="Structural alerts"
                                tooltipText="Alerts identified by ToxTree software."
                                errorText={errors.toxtree}
                                isFull
                            >
                                <Select
                                    className="custom-select bg-white"
                                    open={isOpenToxtree}
                                    onClick={handleOpenToxtree}
                                    name="toxtree"
                                    value={values.toxtree}
                                    onChange={handleSelectChange}
                                    fullWidth
                                    displayEmpty
                                >
                                    <MenuItem value="">-</MenuItem>
                                    <MenuItem value="1">No alert</MenuItem>
                                    <MenuItem value="3">
                                        Acyl transfer agent
                                    </MenuItem>
                                    <MenuItem value="5">
                                        Micheal-acceptor
                                    </MenuItem>
                                    <MenuItem value="4">
                                        Schiff base formation
                                    </MenuItem>
                                    <MenuItem value="6">
                                        SNAr electrophile
                                    </MenuItem>
                                    <MenuItem value="7">
                                        Pro-reactive compounds
                                    </MenuItem>
                                    <MenuItem value="8">Quinones</MenuItem>
                                </Select>
                            </InputLabel>
                        </InputBlock>
                    </div>
                </div>
            </section>
        </div>
    );
};
