import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useFormulaStore } from "pages/formulas/formulas/store";

interface IProps {
    index: number;
    onRemove: () => void;
}

export const Header: FC<IProps> = ({ index, onRemove }) => {
    const { formula } = useFormulaStore();

    const ricode = formula?.rawIngredients?.[index]?.ricode;

    return (
        <div className="flex justify-between items-center">
            <span className="font-semibold text-xl ">{`#${index + 1}`}</span>
            {ricode && (
                <div>
                    <span className="font-semibold ml-2 text-xl">RI Code:</span>
                    <span className="font-semibold ml-2 text-xl ">
                        {ricode}
                    </span>
                </div>
            )}
            <button
                onClick={onRemove}
                className="black-button !h-12"
                type="button"
            >
                <span className="uppercase text-sm mr-1">
                    Remove Raw Ingredient
                </span>
                <FontAwesomeIcon
                    icon={faTrashCan}
                    size="lg"
                    className="text-primary"
                />
            </button>
        </div>
    );
};
