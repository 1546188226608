import classNames from 'classnames';
import { ColumnDef, Row } from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify';

import { 
  backendKeys, 
  defaultErrorToastUpdate, 
  defaultSuccessToastUpdate, 
  defaultToastOptions, 
  queryKeys, 
  routerKeys, 
  toastTexts 
} from 'common/constants';
import { IContaminant, IContaminantsSaved } from 'common/types';
import { DataTable, IconLink, SaveButton } from 'components/shared';
import { 
   useFullSavedContaminants,
  useGetSavedData, 
  useRemoveSavedData 
} from 'pages/profile/libs';
import { contaminantColumnsConfig } from '../libs/columnsConfigs';

export const ContaminantsTable = () => {
  const { data: savedContaminants = [], refetch } = useFullSavedContaminants();
  const { mutate: removeSavedContaminantMutation } = useRemoveSavedData<number>(backendKeys.api.contaminants.saved);
  const { refetch: refetchSavedontaminant } = useGetSavedData<IContaminantsSaved>(
      queryKeys.savedContaminants, 
      backendKeys.api.contaminants.saved
  );

  const handleRemove = useCallback((id: number) => {
      const toastId = toast.loading(toastTexts.loading, defaultToastOptions);
      removeSavedContaminantMutation(id, {
          onSuccess: () => {
              toast.update(toastId, {
                  ...defaultSuccessToastUpdate,
                  render: toastTexts.success,
              });
              if (refetch) {
                  refetch();
              }
              if (refetchSavedontaminant) {
                  refetchSavedontaminant();
              }
          },
          onError: () => {
              toast.update(toastId, defaultErrorToastUpdate);
          },
      });
  }, []);

  const columns = useMemo<ColumnDef<IContaminant>[]>(() => {
    const staticColumns = [
      {
        id: "unsaved",
        accessorKey: "unsaved",
        header: () => <span>Unsaved</span>,
        cell: ({ row }: { row: Row<IContaminant> }) => (
          <SaveButton
            className="flex items-center px-2 hover:cursor-pointer"
            id={row.original.id}
            onAction={handleRemove}
            items={savedContaminants}
            attribute="id"
          />
        ),
      },
      {
        id: "edit_formula",
        accessorKey: "edit_formula",
        header: () => <span>Edit</span>,
        cell: ({ row }: { row: Row<IContaminant> }) => (
          <IconLink to={routerKeys.contaminants} id={row.original.id} />
        ),
      },
    ];
  
    const dynamicColumns = contaminantColumnsConfig.map(({ key, label, format}) => ({
      accessorKey: key,
      header: () => <span>{label}</span>,
      ...(format && {
        cell: ({ row }: { row: Row<IContaminant> }) => <span>{format(row.getValue(key))}</span>,
      }),
    }));
  
    return [...dynamicColumns, ...staticColumns];
  }, [savedContaminants, savedContaminants.length, handleRemove]);

  return (
    <div
    className={classNames(
        "bg-profile flex flex-col w-full min-h-[230px] rounded-tl-2xl rounded-tr-2xl px-10 py-4 shadow-md mb-10"
    )}
>
    <h1 className="gray-color text-xl font-medium mb-2">
        Saved Contaminants
    </h1>
    <DataTable
        className="pb-4"
        data={savedContaminants}
        columns={columns}
    />
</div>
  )
}