import React, { useMemo, useState } from "react";
import { MultiValue } from "react-select";
import { FormikErrors } from "formik";
import { useGetAllRawIngredients } from "pages/adminPanel/queries";
import { rawIngredientService } from "services";
import { AddNewItemTemplate } from "./AddNewItemTemplate";
import { IParsedFormula } from "../../types";
import { useFormulaStore } from "../../store";
import { IOption } from "common/types";
import { IParsedRawIngredient } from "pages/rawIngredient/types";

interface IProps {
    onClose: () => void;
    setFieldValue: (
        field: string,
        value: unknown,
        shouldValidate?: boolean | undefined,
    ) => Promise<void> | Promise<FormikErrors<IParsedFormula>>;
    values: IParsedFormula;
}

const defaultRawIngredientData = {
    ricode: "",
    description: "",
    weight_percent: "",
    isOwner: false,
    isExist: false,
    contaminants: [],
    subIngredients: [],
};

export const AddRawIngredient: React.FC<IProps> = ({
    onClose,
    setFieldValue,
    values,
}) => {
    const [error, setError] = useState<string | null>(null);
    const [selectedRawIngredient, setSelectedRawIngredient] =
        useState<IOption | null>(null);

    const { data, isLoading } = useGetAllRawIngredients();

    const options = useMemo(() => {
        if (data) {
            return data.map((item) => {
                return {
                    value: String(item?.id),
                    label: `${item?.ricode}, ${item?.description}`,
                };
            }) as unknown as MultiValue<IOption>;
        } else {
            return [];
        }
    }, [data]);

    const handleUpdateRelatedList = (newValue: IOption) => {
        setError(null);
        setSelectedRawIngredient(newValue);
    };

    const { addRawIngredient } = useFormulaStore();

    const handleCreate = () => {
        if (selectedRawIngredient) {
            rawIngredientService
                .getRawIngredient({ id: selectedRawIngredient.value })
                .then((res) => {
                    const newItem = {
                        ricode: res.ricode,
                        description: res.description || "",
                        weight_percent: "",
                        isOwner: false,
                        isExist: true,
                        contaminants: res.contaminants.map((ingredient) => ({
                            name: ingredient.name,
                            description: ingredient.description,
                            ppm:
                                ingredient.rawIngredientsContaminants?.ppm ||
                                "",
                        })),
                        subIngredients: res.subIngredients.map(
                            (ingredient) => ({
                                sicode: ingredient.sicode,
                                description: ingredient.description,
                                weight_percent:
                                    ingredient.rawIngredientsSubIngredients
                                        .weight_percent || "",
                            }),
                        ),
                    };

                    setFieldValue("rawIngredients", [
                        newItem,
                        ...values.rawIngredients,
                    ]);

                    addRawIngredient(
                        newItem as unknown as IParsedRawIngredient,
                    );
                    onClose();
                })
                .catch(() => {
                    setError("An error occurred! Please try again");
                });
        } else {
            setFieldValue("rawIngredients", [
                defaultRawIngredientData,
                ...values.rawIngredients,
            ]);
            onClose();
        }
    };

    return (
        <AddNewItemTemplate
            isLoading={isLoading}
            options={options}
            values={selectedRawIngredient}
            onChange={handleUpdateRelatedList}
            handleCreate={handleCreate}
            onClose={onClose}
            error={error}
        />
    );
};
