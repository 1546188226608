import React, { FC, useState } from "react";
import { ActionCard, ModalTemplate, Slider } from "components/shared";
import { SwiperSlide } from "swiper/react";
import { GetStartedData } from "../../enums";
import "swiper/css/bundle";
import { CreateSubIngredientForm } from "pages/subIngredient/subIngredients/components";
import { useModal } from "common/hooks";
import { CreateRawIngredientForm } from "pages/rawIngredient/rawIngredients/components";
import { CreateContaminantForm } from "pages/contaminant/contaminants";
import { CreateFormulaForm } from "../createFormulaForm";

interface IProps {
    activeItem: string | null;
    closeModal: () => void;
}

const ActiveTab: FC<IProps> = ({ activeItem, closeModal }) => {
    switch (activeItem) {
        case "raw_ingredient":
            return <CreateRawIngredientForm handleClose={closeModal} isLink />;
        case "sun_ingredient":
            return <CreateSubIngredientForm handleClose={closeModal} isLink />;
        case "contaminants":
            return <CreateContaminantForm handleClose={closeModal} />;
        case "formulas":
            return <CreateFormulaForm handleClose={closeModal} />;
        default:
            return null;
    }
};

export const GetStartedCards: FC = () => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [title, setTitle] = useState<string>("");

    const { modalRef, openModal, closeModal } = useModal();

    const handleOpenModal = (item: string, title: string) => {
        setTitle(title);
        setActiveItem(item);
        openModal();
    };

    return (
        <div>
            <h5 className="ml-16 text-[#113261] mb-5">
                This is the suggested workflow to get you started
            </h5>
            <ModalTemplate
                text={title}
                onClose={closeModal}
                modalRef={modalRef}
                className="max-w-[600px]"
            >
                {activeItem && (
                    <ActiveTab
                        activeItem={activeItem}
                        closeModal={closeModal}
                    />
                )}
            </ModalTemplate>
            <Slider>
                {GetStartedData.map(
                    ({ title, description, activeItem, titleModal }) => (
                        <SwiperSlide key={title}>
                            <ActionCard
                                className="h-[70px] md:h-[90px]"
                                title={title}
                                buttonText="Enter data"
                                onButtonClick={() =>
                                    handleOpenModal(activeItem, titleModal)
                                }
                            >
                                <p>{description}</p>
                            </ActionCard>
                        </SwiperSlide>
                    ),
                )}
            </Slider>
        </div>
    );
};
