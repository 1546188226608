import React, { FC } from "react";

interface IProps {
   text: string;
   onClick: () => void;
   isActive?: boolean;
   className?: string
}

export const MenuButton: FC<IProps> = ({
   text,
   onClick,
   isActive = false,
   className = "",
}) => {
   return (
       <div
           className={`flex items-center justify-center w-[129px] h-[61px] rounded-full cursor-pointer transition-transform transform hover:scale-105 hover:border-green-500
               ${isActive ? "bg-mint-color gray-color font-semibold text-md border-2 border-green-500" : "bg-profile gray-color font-semibold text-md"} 
               ${className}`}
           onClick={onClick}
       >
           {text}
       </div>
   );
};