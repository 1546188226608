import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { MOBILE_NAV_ITEMS } from "../libs";

interface MobileMenuProps {
    isVisible: boolean;
    isAuthenticated: boolean;
}

export const MobileMenu = ({ isVisible, isAuthenticated }: MobileMenuProps) => (
    <div
        className={classNames("mobile-menu", "md:hidden", {
            hidden: !isVisible && isAuthenticated,
        })}
    >
        {MOBILE_NAV_ITEMS.map((item) => (
            <NavLink
                key={item.to}
                to={item.to}
                className={classNames("mobile-link", item.className)}
            >
                {item.text}
            </NavLink>
        ))}
    </div>
);
