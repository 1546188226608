import React, { FC, useCallback, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { PageTemplate } from "components/page-template";
import { Filters } from "./components/filters";
import {
    backendKeys,
    defaultErrorToastUpdate,
    defaultSuccessToastUpdate,
    defaultToastOptions,
    formulaContent,
    queryKeys,
    routerKeys,
    toastTexts,
} from "common/constants";
import { LoadingToast } from "components/shared/loadingToast";
import { useGetFormulas, useLastUpdateFormules } from "./queries";
import { ModalTemplate } from "components/shared/modalTemplate";
import { CreateFormulaForm } from "./components/createFormulaForm";
import {
    DataTable,
    IconLink,
    SaveButton,
    UpdateCardsBlock,
} from "components/shared";
import { useFilter, useModal, useURLStateSync } from "common/hooks";
import { IFormulaItem } from "./types";
import {
    useGetUserProfile,
    useGetSavedData,
    useSaveDataMutation,
} from "pages/profile/libs";
import { formatLabel, modifyDate } from "common/utils";
import { queryClient } from "../../../libraries/queryProvider";
import { ItemTitle } from "components/sidebar";
import { UploadFormula } from "./components/uploadFormula";
import { SectionTitle } from "./enums";
import { SearchFilterPanel } from "components/search-filter-panel";
import { GetStartedCards } from "./components/getStartedCards";
import { CompositionStatusBlock } from "./components/compositionStatusBlock/CompositionStatusBlock";
import { IFormulaSaved } from "@/common/types";

const TitleSection: FC<{text: string}> = ({ text }) => (
    <div className="text-[#113261] text-2.5xl font-semibold ml-16">{text}</div>
);

export const Formulas = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const {
        filters,
        pagination,
        sorting,
        setFilters,
        setPagination,
        setSorting,
        getParamsString,
    } = useURLStateSync();

    const { data: formulasData, isLoading } = useGetFormulas({
        queryParams: getParamsString(),
        sorting,
    });
    
    const { data: lastUpdateFormulas } = useLastUpdateFormules({
        queryParams: "offset=0&limit=3",
    });
    
    const {
        modalRef: createFormulaRef,
        openModal,
        closeModal,
    } = useModal();

    const {
        handleUpdateFilters,
        handleFilterVisible,
        handleUpdateSearch,
        handleRemoveFilter,
    } = useFilter({
        setFilters,
        setIsFilterVisible,
    });

    const { mutate: saveFormula } = useSaveDataMutation<{ userId: string; formulaId: number }>(
        backendKeys.api.formulas.save
    );
    const { data: userInfo } = useGetUserProfile();
    const { data: savedFormulas } = useGetSavedData<IFormulaSaved>(
        queryKeys.savedFormulas, 
        backendKeys.api.formulas.saved
    );
    
    const formik = useFormik({
        initialValues: {
            userId: userInfo?.id || "",
            formulaId: 0,
        },

        onSubmit: (values) => {
            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            saveFormula(values, {
                onSuccess: () => {
                    toast.update(toastId, {
                        ...defaultSuccessToastUpdate,
                        render: toastTexts.saved,
                    });
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.savedFormulas],
                    });
                    queryClient.refetchQueries({
                        queryKey: [queryKeys.fullSavedFormulas],
                    });
                },
                onError: () => {
                    toast.update(toastId, defaultErrorToastUpdate);
                },
            });
        },
    });

    const handleSaveFormula = useCallback(
        (formulaId: number) => {
            if (!userInfo?.id) return;

            formik.setValues({ userId: userInfo.id, formulaId });
            formik.submitForm();
        },
        [userInfo?.id, formik],
    );

    const columns = useMemo<ColumnDef<IFormulaItem>[]>(
        () => [
            {
                accessorKey: "ficode",
                header: () => <span className="w-[80px]">FI Code</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[80px]"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    >
                        <span
                            className="truncate w-[80px]"
                            title={row.getValue("ficode")}
                        >
                            {row.getValue("ficode")}
                        </span>
                    </IconLink>
                ),
            },
            {
                accessorKey: "description",
                header: () => <span>Description</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="primary-color hover:font-bold w-[150px] lg:w-[200px]"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    >
                        <p
                            className="truncate ... w-[150px] lg:w-[200px] cursor-pointer"
                            title={row.getValue("description")}
                        >
                            {row.getValue("description")}
                        </p>
                    </IconLink>
                ),
            },
            {
                accessorKey: "status",
                header: () => <span>Status</span>,
                cell: ({ row }) => (
                    <span>{formatLabel(row.getValue("status"))}</span>
                ),
            },
            {
                accessorKey: "created_by",
                header: () => <span>Created By</span>,
            },
            {
                accessorKey: "date_created",
                header: () => <span>Date Created</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_created"))}</span>
                ),
            },
            {
                accessorKey: "date_modified",
                header: () => <span>Date Modified</span>,
                cell: ({ row }) => (
                    <span>{modifyDate(row.getValue("date_modified"))}</span>
                ),
            },
            {
                id: "unsaved",
                accessorKey: "unsaved",
                enableSorting: false,
                header: () => <span className="flex justify-end">Save</span>,
                cell: ({ row }) => (
                    <SaveButton
                        className="flex justify-end hover:cursor-pointer"
                        id={row.original.id}
                        onAction={handleSaveFormula}
                        items={savedFormulas || []}
                        attribute="formulaId"
                        isDisabled
                    />
                ),
            },
            {
                id: "action",
                accessorKey: "action",
                enableSorting: false,
                header: () => <span className="flex justify-end">Edit</span>,
                cell: ({ row }) => (
                    <IconLink
                        className="justify-end"
                        to={routerKeys.formulas}
                        id={row.original.id}
                    />
                ),
            },
        ],
        [savedFormulas, savedFormulas?.length, handleSaveFormula],
    );

    const tableData = formulasData?.rows || [];
    const totalCount = formulasData?.count || 0;

    return (
        <PageTemplate 
            title={ItemTitle.FORMULAS}
            filledButtonTitle="Formula"
            openCreateModal={openModal}
            uploadButton={<UploadFormula/>}
        >
            <TitleSection text={SectionTitle.GET_STARTED}/>

            <GetStartedCards/>

            <CompositionStatusBlock 
                completed={formulasData?.completedCount} 
                incompleted={formulasData?.incompletedCount}
            />

            <TitleSection text={SectionTitle.UPDATE_FORMULAS}/>

            <UpdateCardsBlock 
                openCreateModal={openModal} 
                formulasData={lastUpdateFormulas} 
                title='New Formula'
            />

            <TitleSection text={SectionTitle.ALL_FORMULAS}/>
            <div className="md:flex lg:flex py-4">
                <ModalTemplate
                    text="Create Formula"
                    onClose={closeModal}
                    modalRef={createFormulaRef}
                    className="max-w-[600px]"
                >
                    <CreateFormulaForm handleClose={closeModal} />
                </ModalTemplate>

                <div className="w-full">
                    <SearchFilterPanel
                        placeholder={formulaContent.placeholder}
                        searchValue={filters?.query?.value}
                        updateSearch={handleUpdateSearch}
                        isFilterVisible={isFilterVisible}
                        onFilterVisible={handleFilterVisible}
                        filters={filters}
                        onRemoveFilter={handleRemoveFilter}
                    >
                        <Filters
                            onUpdateFilters={handleUpdateFilters}
                            filters={filters}
                        />
                    </SearchFilterPanel>    

                    <DataTable
                        data={tableData}
                        columns={columns}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalCount={totalCount}
                        isFilterVisible={isFilterVisible}
                        sorting={sorting}
                        setSorting={setSorting}
                        position={false}
                    >
                        
                    </DataTable>

                    {isLoading ? (
                        <LoadingToast />
                    ) : (
                        <ToastContainer limit={3} />
                    )}
                </div>
            </div>
        </PageTemplate>
    );
};
