import React from "react";
import { TableHeadRow } from "..";
import { TableCeil, TableHeaderCeil } from "components/shared";
import { useFormulaProfileState } from "pages/formulas/formula-profile/store";
import { useNavigate } from "react-router-dom";
import { routerKeys } from "common/constants";

export const SubIngredients = () => {
    const {
        subIngredients,
        totalEnvironmentalScore,
        subIngredientsTotalWeight,
    } = useFormulaProfileState();
    const navigate = useNavigate();

    const navigateToSubIngredientPage = (id: string | number) => {
        navigate(`${routerKeys.subIngredient}/${id}`);
    };

    return (
        <div>
            <table className="w-full bg-grey-100 rounded-lg overflow-hidden sm:shadow-lg">
                <thead className="bg-indigo-100">
                    <TableHeadRow>
                        <TableHeaderCeil text="SI Code" />
                        <TableHeaderCeil text="Name" />
                        <TableHeaderCeil
                            text="w/w %"
                            tooltipMessage="w/w % (weight/weight percentage) represents the mass of the solute\n relative to the mass of the solution, expressed as a percentage.\n It is calculated by dividing the mass of the solute by the total mass\n of the solution and then multiplying by 100.
"
                        />
                        <TableHeaderCeil text="Env. score" />
                    </TableHeadRow>
                </thead>
                <tbody>
                    {subIngredients?.sort((a, b) =>
                        a?.name.localeCompare(b?.name),
                    )?.length ? (
                        <>
                            {subIngredients.map((subIngredient) => (
                                <tr key={subIngredient.id}>
                                    <TableCeil text={subIngredient.sicode} />
                                    <TableCeil
                                        text={subIngredient.name}
                                        onClick={() =>
                                            navigateToSubIngredientPage(
                                                subIngredient.id,
                                            )
                                        }
                                        className="cursor-pointer"
                                    />
                                    <TableCeil
                                        text={
                                            subIngredient.rawSub
                                                .raw_weight_percent
                                        }
                                    />
                                    <TableCeil
                                        text={subIngredient.environmental_score}
                                    />
                                </tr>
                            ))}
                            <tr>
                                <TableCeil />
                                <TableCeil
                                    className="font-bold"
                                    text="Total scores:"
                                />
                                <TableCeil text={subIngredientsTotalWeight} />
                                <TableCeil text={totalEnvironmentalScore} />
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <TableCeil text="No Sub Ingredient found" />
                            <TableCeil />
                            <TableCeil />
                            <TableCeil />
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
