import React, { FC, useState } from "react";
import classNames from "classnames";
import { SearchProfile } from "components/shared";
import { articleCardData, categoryCardData, HelpDeskTitle } from "./libs/enums";
import { ArticleCard, CategoryCard } from "./components";
import { PageTemplate } from "components/page-template";
import { ItemTitle } from "components/sidebar";

interface IProps {
    className?: string;
}

interface TitleProps {
    text: string;
}

export const Title: FC<TitleProps> = ({ text }) => (
    <div className="text-[#113261] text-xl font-medium mb-2">{text}</div>
);

interface IProps {
    className?: string;
}

export const HelpDesk: FC<IProps> = ({ className = "" }) => {
    const [value, setValue] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    return (
        <PageTemplate 
            title={ItemTitle.HELP_DESK}
            subtitle="Discover how to use SmartSafety and find answers to your questions"
        >
            <div
                className={classNames(
                    "flex flex-col m-auto w-full min-h-[230px] max-w-[950px] rounded-tl-2xl rounded-tr-2xl px-10 py-4",
                    className,
                )}
            >
                <Title text={HelpDeskTitle.WELCOME} />
                <SearchProfile
                    className="flex"
                    placeholder="Search topics (tutorials, getting started, contact, etc)"
                    value={value}
                    onChange={handleChange}
                />
                <Title text={HelpDeskTitle.CATEGORIES} />
                <div className="flex flex-wrap justify-between gap-y-7 mb-20">
                    {categoryCardData.map(({ link, title, icon, description }) => (
                        <CategoryCard
                            key={title}
                            link={link}
                            icon={icon}
                            title={title}
                            description={description}
                        />
                    ))}
                </div>
                <Title text={HelpDeskTitle.POPULAR_ARTICLES} />
                <div>
                    {articleCardData.map(({ title, description }) => (
                        <ArticleCard
                            key={title}
                            title={title}
                            description={description}
                        />
                    ))}
                </div>
            </div>
        </PageTemplate>
    );
};
