import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface IProps {
    children: ReactNode;
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: true,
            staleTime: 1000 * 60 * 5,
            gcTime: 1000 * 60 * 20,
            retry: 3,
            retryDelay: 3000,
        },
        mutations: {
            retry: 2,
        },
    },
});

export const QueryProvider: React.FC<IProps> = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {/* {process.env.NODE_ENV === "development" && <ReactQueryDevtools />} */}
        </QueryClientProvider>
    );
};
