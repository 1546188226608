import React, { FC } from "react";
import { Download } from "assets/icons";
import templateFile from "assets/files/template.xlsx";

interface IProps {
    onUploadClick: () => void;
    onCloseClick: () => void;
}

export const TemplateUploader: FC<IProps> = ({
    onUploadClick,
    onCloseClick,
}) => (
    <div className="flex flex-col items-center">
        <a
            className="mb-8 text-primary-main-gray hover:text-primary-main-dgray flex items-center"
            href={templateFile}
            download="formula_template.xlsx"
        >
            <Download className="mr-2" />
            Download Template (Excel)
        </a>
        <div className="flex flex-end gap-2 mt-8">
            <button type="button" className="btn-green" onClick={onUploadClick}>
                Upload Formula
            </button>
            <button className="btn-clear" onClick={onCloseClick}>
                Close
            </button>
        </div>
    </div>
);
