import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Button } from "components/shared/button";
import { ModalTemplate } from "components/shared/modalTemplate";
import { Tab } from "../types";
import { useModal } from "common/hooks";
import { CreateRawIngredientForm } from "pages/rawIngredient/rawIngredients/components";
import { CreateSubIngredientForm } from "pages/subIngredient/subIngredients/components";
import { CreateContaminantForm } from "pages/contaminant/contaminants";

const modalComponents = {
    "Raw Ingredients": {
        component: CreateRawIngredientForm,
        text: "Raw Ingredient",
    },
    "Sub Ingredients": {
        component: CreateSubIngredientForm,
        text: "Sub Ingredient",
    },
    Contaminants: {
        component: CreateContaminantForm,
        text: "Contaminant",
    },
};

interface IProps {
    selectedTab: Tab;
}

export const AddRelatedIngredients: FC<IProps> = ({ selectedTab }) => {
    const { id = "" } = useParams();
    const { modalRef, openModal, closeModal } = useModal();
    const { component: ModalComponent } = modalComponents[selectedTab] || {};

    const showAddButton = selectedTab === "Raw Ingredients";

    return (
        <div className="w-full flex my-4">
            {showAddButton ? (
                <Button
                    className="flex items-center bg-[#113261] text-white text-base border-none sm:shadow-lg hover:text-[#113261]"
                    text={`Add ${modalComponents[selectedTab]?.text || ""}`}
                    onClick={openModal}
                />
            ) : (
                <div className="w-[120px] h-[49px]" />
            )}

            <ModalTemplate
                text={`Add ${modalComponents[selectedTab]?.text || ""}`}
                onClose={closeModal}
                modalRef={modalRef}
                className="max-w-[600px]"
            >
                {ModalComponent && (
                    <ModalComponent formulas_id={id} handleClose={closeModal} />
                )}
            </ModalTemplate>
        </div>
    );
};
