import React, { FC, useEffect, useState, ReactNode } from "react";
import { SearchBlock } from "pages/dashboard/components";

interface IProps {
    title?: string;
    titleIcon?: ReactNode;
    subtitle?: string;
    children: ReactNode;
    withSearch?: boolean;
    searchValue?: string;
    searchPlaceholder?: string;
    onChange?: (value: string) => void;
    callback?: () => void;
    createFn?: () => void;
    additionalChild?: ReactNode;
    additionalActions?: ReactNode;
    buttonBlock?: ReactNode;
}

const DEBOUNCE_DELAY = 400;

export const PageTemplate: FC<IProps> = ({
    title,
    subtitle,
    children,
    withSearch = true,
    searchValue = "",
    searchPlaceholder,
    onChange,
    buttonBlock = null,
    titleIcon = null,
}) => {
    const [value, setValue] = useState(searchValue);

    useEffect(() => {
        setValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (!onChange) return;

        let isActive = true;
        const t = setTimeout(() => {
            if (isActive) {
                onChange(value);
            }
        }, DEBOUNCE_DELAY);

        return () => {
            isActive = false;
            clearTimeout(t);
        };
    }, [value, onChange]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    return (
        <div className="flex flex-col flex-grow">
            <div className="grid lg:grid-cols-2 gap-4 mx-10 my-8 min-h-[110px]">
                <div>
                    {title && (
                        <h2 className="flex text-2xl font-semibold items-center">
                            <span className="mr-2">{title}</span>
                            {titleIcon}
                        </h2>
                    )}
                    {subtitle && (
                        <p className="text-[#4d596a] text-sm md:text-base lg:text-base">
                            {subtitle}
                        </p>
                    )}
                </div>
            </div>
            {withSearch && (
                <div className="flex flex-col md:flex-row gap-8 mx-10 my-3">
                    <SearchBlock
                        placeholder={searchPlaceholder}
                        value={value}
                        onChange={handleChange}
                        className="flex-grow"
                    />

                    <div className="flex items-center flex-shrink-0">
                        {buttonBlock}
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};
