import React, { FC, useMemo } from "react";
import classNames from "classnames";

interface IProps {
    label: string;
    variant?: "filled" | "outlined";
    onClick?: () => void;
    clasName?: string;
    type?: "button" | "submit" | "reset" | undefined;
}
const baseStyle =
    "py-1 px-4 rounded-xl font-medium focus:outline-none transition bg-top hover:bg-bottom transition-all duration-300";

export const GradientButton: FC<IProps> = ({
    label,
    variant = "filled",
    onClick,
    clasName,
    type = "button",
}) => {
    const styles = useMemo(
        () =>
            variant === "filled"
                ? "text-white shadow-md bg-gradient-to-b from-[#2B60AB] to-[#113261] bg-[length:100%_200%]"
                : "text-[#113261] shadow-md bg-gradient-to-b from-[#D9D8D8] to-[#A1A1A1] bg-[length:100%_200%]",
        [variant],
    );

    return (
        <button type={type}  className={classNames(clasName, baseStyle, styles, "shadow-")} onClick={onClick}>
            {label}
        </button>
    );
};