import { PageTemplate } from "components/page-template";
import React from "react";
import { DetailRow } from "./components";
import { ItemTitle } from "components/sidebar";

export const Report = () => {
    return (
        <PageTemplate 
            title={ItemTitle.REPORTS}
        >
            <div className="grid bg-white py-4 px-8">
                <div>Details</div>
                <div className="grid grid-cols-2 py-4">
                    <div className="text-primary-brand-main">Folders</div>
                    <div className="text-gray-400 text-base flex justify-end">
                        <img
                            src="../../assets/images/icons/attach.svg"
                            className="h-5 p-1"
                            alt=""
                        />
                        <p className="text-sm">Attached Documents</p>
                    </div>
                </div>
                <hr />
                <div className="flex flex-col">
                    <DetailRow
                        name="Dissney Dossier Row"
                        author="Jane Smith"
                        date="Oct 31 1987"
                    />
                    <DetailRow
                        name="Dissney Dossier Row"
                        author="Jane Smith"
                        date="Oct 31 1987"
                    />
                    <DetailRow
                        name="Dissney Dossier Row"
                        author="Jane Smith"
                        date="Oct 31 1987"
                    />
                </div>
            </div>
        </PageTemplate>
    );
};
