import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../button";
import classNames from "classnames";

interface ActionCardProps {
    title: string;
    buttonText: string;
    children?: ReactNode;
    onButtonClick?: () => void;
    onCloseClick?: () => void;
    className?: string;
}

export const ActionCard: React.FC<ActionCardProps> = ({
    title,
    buttonText,
    onButtonClick,
    onCloseClick,
    children,
    className
}) => {
    return (
        <div className="bg-white shadow-md rounded-lg w-full max-w-[300px] sm:max-w-[400px] md:max-w-[300px]">
            <div className={classNames("relative bg-blue-100 w-full rounded-t-md text-[#113261]", className)}>
                {onCloseClick && (
                    <Button
                        classNameContainer="absolute top-1 right-1"
                        variant="ghost"
                        size="xs"
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                )}
            </div>

            <div className="flex flex-col justify-between px-4 h-auto min-h-[200px]">
                <div>
                    <h3 className="text-lg md:text-lg font-medium text-center mb-2">{title}</h3>
                    {children}                
                </div>
                <Button
                    className="mt-auto w-full mb-4 py-2 px-4 border border-[#DDD] rounded-xl hover:bg-[#113261] hover:text-white transition"
                    variant="outline"
                    onClick={onButtonClick}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};