import React, { ChangeEvent, FC } from "react";

interface IProps {
    value: string;
    label: string;
    onChange: (e: ChangeEvent<HTMLInputElement>, filterValue?: string) => void;
    isChecked: boolean;
    isDisabled?: boolean;
}

export const CheckboxGroup: FC<IProps> = ({
    value,
    label,
    onChange,
    isChecked,
    isDisabled = false,
}) => {
    return (
        <label className="label cursor-pointer justify-start">
            <input
                type="checkbox"
                className="checkbox"
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange(e, label)
                }
                checked={isChecked}
                disabled={isDisabled}
            />
            <span className="label-text">{label}</span>
        </label>
    );
};
