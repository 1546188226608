import React from "react";
import { useOwner } from "common/hooks";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const SidebarHeader = () => {
    const { userName, userProfile } = useOwner();

    return (
        <div className="px-10 pb-4 pt-8 bg-[#113261] border-b border-white">
            <div className="flex flex-col">
                <div className="h-[25px] flex items-center justify-between">
                    {userProfile?.isLoading ? (
                        <div className="flex items-center">
                            <Oval
                                height={25}
                                width={25}
                                color="white"
                                secondaryColor="#BFDBFE"
                                strokeWidth={4}
                            />
                        </div>
                    ) : (
                        <h2 className="text-[25px] font-semibold text-white">
                            {userName}
                        </h2>
                    )}
                    <FontAwesomeIcon
                        size="xl"
                        icon={faChevronDown}
                        className="text-white cursor-pointer"
                    />
                </div>
                <p className="text-lg text-white mt-1">Owner</p>
            </div>
        </div>
    );
};