import classNames from 'classnames';
import React, { FC } from 'react';

interface IProps {
   status: string;
}

export const LifeCycleStage: FC<IProps> = ({status}) => {
  return (
    <div className='flex justify-center items-center gap-x-2 mb-6 text-[#113261]'>
      <p className='font-extralight text-xs text-inherit'>
         Life cycle stage:  
      </p>
      <p className='first-letter:uppercase font-normal text-xs text-inherit'>{status}</p>
      <div className={classNames('w-[14px] h-[14px] rounded-full', 
         {
           "bg-[#66B12A]": status === "discontinued",
           "bg-[#E38C27]": status === "pre-approval",
           "bg-[#F12323]": status !== "discontinued" && status !== "pre-approval"
         }
      )}></div>
    </div>
  )
}
