import React, { FC } from "react";
import { Avatar } from "@mui/material";
import classNames from "classnames";
import { IUser } from "common/types";

interface IProps {
    name: string;
    position: string;
    location?: Pick<IUser, "country_name" | "city">;
    avatarSize?: string;
    className?: string;
}

export const ProfileCard: FC<IProps> = ({
    name,
    position,
    location = {},
    avatarSize = "138",
    className = "",
}) => {
    return (
        <div
            className={classNames(
                "bg-profile flex flex-col md:flex-row items-center w-full min-h-[230px] rounded-tl-2xl rounded-tr-2xl px-10 shadow-md",
                className,
            )}
        >
            <div className="flex justify-center md:justify-start w-full md:w-auto mb-4 md:mb-0">
                <Avatar
                    sx={{
                        width: Number(avatarSize),
                        height: Number(avatarSize),
                        bgcolor: "primary.main",
                        fontSize: `${Number(avatarSize) / 2}px`,
                    }}
                >
                    {name
                        .split(" ")
                        .map((word) => word[0])
                        .join("")}
                </Avatar>
            </div>
            <div className="flex flex-col justify-center md:ml-6 text-center md:text-left">
                <h6 className="primary-color text-xl font-bold mb-2">{name}</h6>
                <div className="primary-color text-md mb-1">{position}</div>
                {(location?.country_name || location?.city) && (
                    <div className="primary-color text-md">
                        Location of user - {location?.country_name}
                        {location?.city ? `, ${location?.city}` : ""}
                    </div>
                )}
            </div>
        </div>
    );
};
